import React from 'react';

const RevenueIcon = (props: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.8333 5.50004H14.1667C14.1667 3.20004 12.3 1.33337 10 1.33337C7.7 1.33337 5.83333 3.20004 5.83333 5.50004H4.16667C3.25 5.50004 2.50833 6.25004 2.50833 7.16671L2.5 17.1667C2.5 18.0834 3.25 18.8334 4.16667 18.8334H15.8333C16.75 18.8334 17.5 18.0834 17.5 17.1667V7.16671C17.5 6.25004 16.75 5.50004 15.8333 5.50004ZM10 3.00004C11.3833 3.00004 12.5 4.11671 12.5 5.50004H7.5C7.5 4.11671 8.61667 3.00004 10 3.00004ZM10 11.3334C7.7 11.3334 5.83333 9.46671 5.83333 7.16671H7.5C7.5 8.55004 8.61667 9.66671 10 9.66671C11.3833 9.66671 12.5 8.55004 12.5 7.16671H14.1667C14.1667 9.46671 12.3 11.3334 10 11.3334Z"
        fill="#777E90"
      />
    </svg>
  );
};

export default RevenueIcon;
