import React from 'react';

const AdminIcon = (props: any) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.19037 3.08956C8.15095 3.70975 8.82456 4.75871 8.94776 5.97688C9.33917 6.16521 9.77413 6.27386 10.2349 6.27386C11.9178 6.27386 13.2818 4.8694 13.2818 3.13688C13.2818 1.40417 11.9178 0 10.2349 0C8.56748 4.95472e-05 7.21568 1.37929 7.19037 3.08956ZM5.19417 9.51267C6.87674 9.51267 8.24085 8.1078 8.24085 6.37578C8.24085 4.64351 6.87674 3.2389 5.19417 3.2389C3.51147 3.2389 2.14678 4.64346 2.14678 6.37578C2.14683 8.1078 3.51147 9.51267 5.19417 9.51267ZM6.48663 9.72616H3.90148C1.75042 9.72616 0 11.5281 0 13.7431V16.9985L0.00822965 17.0494L0.225853 17.1191C2.27879 17.7792 4.06231 18 5.53043 18C8.39731 18 10.0597 17.1583 10.1621 17.1045L10.3657 16.9984H10.3873V13.743C10.3874 11.5281 8.63798 9.72616 6.48663 9.72616ZM11.5281 6.48796H8.9623C8.93443 7.5449 8.4963 8.4967 7.80339 9.18085C9.71551 9.76625 11.1145 11.5917 11.1145 13.748V14.7511C13.6473 14.6551 15.1071 13.9164 15.2033 13.8666L15.407 13.76H15.4286V10.504C15.4289 8.28969 13.6786 6.48796 11.5281 6.48796Z"
        fill="white"
      />
    </svg>
  );
};

export default AdminIcon;
