import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Badge } from 'antd';

import AppDropdown from '../AppDropdown';
import EllipsisText from '../EllipsisText';

import NotifyIcon from 'resources/svg/notify_icon.svg';

import selectedAddress from 'redux/address/selector';
import { useSocket } from 'hooks/useSocket';
import { useAppSelector } from 'hooks/useStore';

import { checkSusscessRequest } from 'services/api';
import notificationService from 'services/notification';

import { formatCurrency, formatDate, formatDecimal, shortenAddress } from 'utils';

import LENGTH_CONSTANTS from 'constants/length';
import { EVENT_NOTIFICATION, FORMAT_DATE_PICKER, FORMAT_TIME_PICKER, SOCKET_EVENT, ZERO_VALUE } from 'constants/common';
import { renderRoutes } from 'constants/routes';
import { NFT_DETAIL_TABS } from 'constants/nft';

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;
const {
  ADMIN_PUT_ON_SALE,
  BUY_FROM_USER,
  ACTIVE_SELLORDER,
  DEACTIVE_SELLORDER,
  BUY_FROM_ADMIN,
  DEACTIVE_SELLORDER_ADMIN,
} = EVENT_NOTIFICATION;

const Notification = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { address } = useAppSelector(selectedAddress.getAddress);

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalUnread, setTotalUnread] = useState(ZERO_VALUE);
  const [totalNotification, setTotalNotification] = useState(ZERO_VALUE);
  const [listNotification, setListNotification] = useState([]) as Array<any>;

  const handleAddNotification = (data: any) => {
    const newListNotification = [{ ...data, isRead: false }, ...listNotification];
    setListNotification(newListNotification);
    setTotalUnread(totalUnread + 1);
  };

  useSocket({
    event: SOCKET_EVENT.NOTIFICATION,
    handleEvent: handleAddNotification,
    dependences: [totalUnread, listNotification],
  });

  const getListNotification = async (page: number) => {
    try {
      const response = await notificationService.getListNotification({ page, limit: DEFAULT_PAGE_SIZE });
      if (checkSusscessRequest(response)) {
        const { docs = [], totalDocs = 0, totalUnread = 0 } = response?.data || {};

        setListNotification(page === DEFAULT_PAGE ? [...docs] : [...listNotification, ...docs]);
        setTotalNotification(totalDocs);
        setTotalUnread(totalUnread);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (address) {
      setTimeout(() => getListNotification(DEFAULT_PAGE));
    }
  }, [address]);

  const getMoreNotification = () => {
    setPage(page + 1);
    getListNotification(page + 1);
  };
  const renderNotificationContent = (notification: any = {}) => {
    const { type, payload = {} } = notification;
    const { nftName, price, currency, buyerAddress } = payload;
    switch (type) {
      case ADMIN_PUT_ON_SALE:
        return {
          tooltipText: t('notification.txt_admin_put_on_sale_tooltip', {
            name: nftName,
            price: formatDecimal(price),
            currency,
          }),
          text: t('notification.txt_admin_put_on_sale', { name: nftName, price: formatDecimal(price), currency }),
          router: renderRoutes.NFT_DETAIL(notification?.nftId),
        };
      case BUY_FROM_USER:
      case BUY_FROM_ADMIN:
        return {
          tooltipText: t('notification.txt_buy_from_user_tooltip', {
            name: nftName,
            price: formatDecimal(price),
            currency,
            wallet: shortenAddress(buyerAddress),
          }),
          text: t('notification.txt_buy_from_user', {
            name: nftName,
            price: formatDecimal(price),
            currency,
            wallet: shortenAddress(buyerAddress),
          }),
          router: renderRoutes.NFT_DETAIL(notification?.nftId, NFT_DETAIL_TABS.SALE_HISTORY.query),
        };
      case ACTIVE_SELLORDER:
        return {
          tooltipText: t('notification.txt_active_sellorder_tooltip', {
            name: nftName,
          }),
          text: t('notification.txt_active_sellorder', {
            name: nftName,
          }),
          router: renderRoutes.NFT_DETAIL(notification?.nftId),
        };
      case DEACTIVE_SELLORDER:
        return {
          tooltipText: t('notification.txt_deactivate_sellorder_tooltip', {
            name: nftName,
          }),
          text: t('notification.txt_deactivate_sellorder', {
            name: nftName,
          }),
          router: renderRoutes.NFT_DETAIL(notification?.nftId),
        };
      case DEACTIVE_SELLORDER_ADMIN:
        return {
          tooltipText: t('notification.txt_deactivate_sellorder_admin_tooltip', {
            name: nftName,
          }),
          text: t('notification.txt_deactivate_sellorder_admin', {
            name: nftName,
          }),
          router: renderRoutes.NFT_DETAIL(notification?.nftId),
        };
      default:
        return {
          tooltipText: '',
          text: '',
          router: '/',
        };
    }
  };

  const handleClickNotification = (notification: any, asPath: string) => async (event: any) => {
    event.preventDefault();
    history.push(asPath);

    if (!notification?.isRead) {
      try {
        const response = await notificationService.setMarkAsRead(notification?._id);
        if (checkSusscessRequest(response)) {
          const newListNotification = listNotification.map((item: any) => {
            return notification?._id === item?._id ? { ...notification, isRead: true } : item;
          });
          setTotalUnread(totalUnread > 0 ? totalUnread - 1 : totalUnread);
          setListNotification(newListNotification);
        }
      } catch (error) {}
    }
  };

  const menu = () => (
    <div className="notification-card">
      <p className="title">{t('notification.txt_title')}</p>
      {totalNotification > ZERO_VALUE ? (
        <InfiniteScroll
          dataLength={listNotification?.length}
          next={getMoreNotification}
          hasMore={listNotification?.length < totalNotification}
          loader={null}
          scrollableTarget="scrollableDiv"
          height="80%"
        >
          {listNotification?.map((notification: any) => {
            const createdDate = notification?.createdAt;
            const content = renderNotificationContent(notification);

            return (
              <div
                key={notification?._id}
                className="group"
                onClick={handleClickNotification(notification, content?.router as string)}
              >
                <div className="content">
                  <EllipsisText text={content?.text} className="text" innerHtml tooltipText={content?.tooltipText} />
                  <p className="sub-text">
                    <span>{formatDate(createdDate, FORMAT_DATE_PICKER)}</span>
                    <span>{formatDate(createdDate, FORMAT_TIME_PICKER)}</span>
                  </p>
                </div>
                <div className="effect">{!notification?.isRead ? <div className="dot" /> : null}</div>
              </div>
            );
          })}
        </InfiniteScroll>
      ) : (
        <div className="notification-empty-text">{t('message.E9')}</div>
      )}
    </div>
  );

  return (
    <AppDropdown overlay={menu} placement="bottomRight" className="notification" trigger={'click'}>
      <Badge count={totalUnread ? totalUnread : null} className="notification-icon">
        <img src={NotifyIcon} />
      </Badge>
    </AppDropdown>
  );
};

export default Notification;
