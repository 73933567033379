import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import BigNumber from 'bignumber.js';
import { DURATION, PUBLISH_DATE } from 'constants/common';
import { Col, Row, Tooltip } from 'antd';
import { NFT_DEFAULT_CREATE_FIELD } from 'constants/nft';
import Modal from 'components/Modal';
import { disabledEndDateWL, disabledStartDateWL, limitMaxlengNumber, limitMaxlengNumberIncludeDecimal } from 'utils';
import {
  disabledTime,
  disabledTimeEndPool,
  setValueEndTimePool,
  validateEdition,
  validateEndTimeStaking,
  validatePublishDate,
  validateTotalReward,
} from '../Utils';
import isString from 'lodash/isString';
import { trim } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import HelpIcon from 'resources/svg/help_icon.svg';
import AppNumber from 'components/AppNumber';

const AdminAtrribute = ({ formikRef, poolDetail }: any) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext() as any;
  const [endTimePool, setEndTimePool] = useState();
  const [visible, setVisible] = useState(false);

  const tokeReward: any = [
    {
      name: 'BCN',
      value: 'bcn',
    },
  ];

  const handleFieldChange = (setFieldValue: any, name: string) => (value: any) => {
    setFieldValue(name, isString(value) ? trim(value) : value).then(() => {
      if (name !== 'endTimePool') {
        setValueEndTimePool(formikRef, setEndTimePool);
      }
    });
  };

  const onOpenModalMinimunReward = () => {
    setVisible(!visible);
  };

  const handleFieldChangeRadio = (setFieldValue: any, name: string) => (value: any) => {
    const type = value?.target?.value;
    setFieldValue(name, type);
    if (type === PUBLISH_DATE.PICK_A_DATE) {
      setFieldValue('publishTime', moment());
    }
  };
  const minimumReward = useMemo(() => {
    BigNumber.config({
      EXPONENTIAL_AT: 100,
    });
    let minimumReward = '--';
    if (values?.editionLimit && values?.duration) {
      const timeStart =
        values?.publishType === PUBLISH_DATE.NOW ? Math.floor(Date.now() / 1000) : moment(values?.publishTime)?.unix();
      const reward = new BigNumber(0.000000000000000001)
        .multipliedBy(new BigNumber(values?.editionLimit))
        .multipliedBy(new BigNumber(moment(values.endTimePool)?.unix() - Number(timeStart)));
      minimumReward = reward?.toString();
    } else if (poolDetail?.name) {
      const reward = new BigNumber(0.000000000000000001)
        .multipliedBy(new BigNumber(values?.editionLimit))
        .multipliedBy(new BigNumber(moment(poolDetail.endTimePool)?.unix() - Number(poolDetail.publishTime)));
      minimumReward = reward?.toString();
    }
    return minimumReward;
  }, [values, poolDetail]);
  return (
    <Card className="admin-creation-page-attribute">
      <Row gutter={20}>
        <Col md={24} xs={24}>
          <FormItem
            name={'name'}
            label={t('pool_management.txt_pool_name')}
            required
            placeholder={t('pool_management.txt_pool_name')}
            maxLength={256}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={3} xs={24} className="launch-time-col">
          <FormItem
            name="publishType"
            label={t('pool_management.txt_publish_date')}
            defaultValue={PUBLISH_DATE.NOW}
            options={[
              { value: PUBLISH_DATE.NOW, name: t('pool_management.txt_now') },
              { value: PUBLISH_DATE.PICK_A_DATE, name: t('pool_management.txt_pick_date') },
            ]}
            typeInput={TYPE_INPUT.RADIO}
            required
            placeholder={t('pool_management.txt_publish_date')}
            onChange={handleFieldChangeRadio(setFieldValue, 'publishType')}
            className="launch-time-radio"
            labelTootip={
              <Tooltip title={'The time when the pool will be public to users'} overlayClassName="tooltip-detail">
                <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
              </Tooltip>
            }
          />
        </Col>
        <Col md={9} xs={24}>
          {[PUBLISH_DATE.PICK_A_DATE].includes(formikRef?.current?.values?.publishType) && (
            <FormItem
              format="YYYY-MM-DD HH:mm:ss"
              className="publish-time"
              name={'publishTime'}
              placeholder={t('pool_management.txt_chose_date')}
              onChange={handleFieldChange(setFieldValue, 'publishTime')}
              disabledDate={disabledEndDateWL()}
              typeInput={TYPE_INPUT.DATE}
              disabledTime={disabledTime}
              showTime
              showNow
              required
              validate={(e: any) => validatePublishDate(values, e)}
            />
          )}
        </Col>

        <Col md={12} xs={24}>
          <FormItem
            name={'duration'}
            label={t('pool_management.txt_duration')}
            required
            placeholder={t('pool_management.txt_select_duration')}
            typeInput={TYPE_INPUT.SELECT}
            options={DURATION}
            thousandSeparator
            onChange={handleFieldChange(setFieldValue, 'duration')}
            labelTootip={
              <Tooltip
                title={
                  'The period that the pool requires NFTs to be staked in to receive rewards. If users unstake before the duration term is over, the rewards will be lost. Once the duration term ends, users can either unstake to redeem their NFTs and receive settlement rewards or continue staking their NFTs in the pool to earn staking rewards until the pool ends'
                }
                overlayClassName="tooltip-detail"
              >
                <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
              </Tooltip>
            }
          />
        </Col>
      </Row>
      <Row className="nft-creation-page-list-for-sale__content" gutter={20}>
        <Col md={12} xs={24}>
          <FormItem
            format="YYYY-MM-DD HH:mm:ss"
            className="end-time-staking"
            name={'endTimeStaking'}
            placeholder={t('pool_management.txt_chose_date')}
            onChange={handleFieldChange(setFieldValue, 'endTimeStaking')}
            disabledDate={disabledStartDateWL()}
            typeInput={TYPE_INPUT.DATE}
            disabledTime={disabledTime}
            showTime
            showNow
            required
            label={t('pool_management.txt_end_time_staking')}
            validate={(e: any) => validateEndTimeStaking(values, e)}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItem
            format="YYYY-MM-DD HH:mm:ss"
            className="end-time-pool"
            name={'endTimePool'}
            placeholder={t('pool_management.txt_chose_date')}
            onChange={handleFieldChange(setFieldValue, 'endTimePool')}
            disabledDate={disabledEndDateWL(endTimePool)}
            typeInput={TYPE_INPUT.DATE}
            disabledTime={(value: any) => disabledTimeEndPool(value, endTimePool)}
            showTime
            showNow
            required
            disabled={!formikRef?.current?.values?.endTimeStaking || !formikRef?.current?.values?.duration}
            label={t('pool_management.txt_end_time_pool')}
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={12} xs={24}>
          <FormItem
            name={'editionLimit'}
            label={t('pool_management.txt_pool_edition')}
            required
            typeInput={TYPE_INPUT.NUMBER}
            placeholder={t('pool_management.txt_placeholder_edition')}
            thousandSeparator
            decimalScale={0}
            isAllowed={limitMaxlengNumber(12)}
            validate={(e: any) => validateEdition(e)}
            labelTootip={
              <Tooltip
                title={'The maximum number of NFTs can be staked in the pool at one time'}
                overlayClassName="tooltip-detail"
              >
                <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
              </Tooltip>
            }
          />
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={12} xs={24}>
          <FormItem
            typeInput={TYPE_INPUT.NUMBER}
            name="amount"
            thousandSeparator
            placeholder={t('pool_management.txt_pool_total_placeholder')}
            required
            className="content__listing"
            label={t('pool_management.txt_pool_total')}
            isAllowed={limitMaxlengNumberIncludeDecimal(12)}
            validate={(e: any) => validateTotalReward(e, minimumReward)}
            labelTootip={
              <Tooltip
                title={'Total Rewards is the total amount of token allocated to the pool as rewards for the stakers'}
                overlayClassName="tooltip-detail"
              >
                <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
              </Tooltip>
            }
            addonAfter={
              <FormItem
                typeInput={TYPE_INPUT.SELECT}
                name={NFT_DEFAULT_CREATE_FIELD.CURRENCY}
                defaultValue={'bcn'}
                options={tokeReward}
                disabled={true}
              ></FormItem>
            }
          ></FormItem>
        </Col>
      </Row>
      <div>
        Minimum rewards:{' '}
        {Number(minimumReward) > 0 ? <AppNumber value={minimumReward} isNotFormatDecimal /> : minimumReward} &nbsp;{' '}
        {minimumReward && 'BCN'}
        <Tooltip title={'How to calculate'} overlayClassName="tooltip-detail">
          <img src={HelpIcon} className={classNames('icon cursor-pointer')} onClick={onOpenModalMinimunReward} />
        </Tooltip>
      </div>
      <Modal visible={visible} onClose={onOpenModalMinimunReward} width={565}>
        <div className="minimum-reward-modal">
          <div className="minimum-reward-title">Minimum Rewards</div>
          <ul>
            <li>
              Minimum rewards is the minimum amount of rewards required for operate the pool within the duration set
            </li>
            <li>Minimum Rewards = Minium rewards per edition x Maximum edition limit x Pool duration</li>
            <li>Minimum rewards per edition = 0.000000000000000001 BCN /s</li>
          </ul>
        </div>
      </Modal>
    </Card>
  );
};

export default AdminAtrribute;
