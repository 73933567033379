import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader';
import AppButton from 'components/AppButton';
import WhiteList from './components/WhiteList';

import LENGTH_CONSTANTS from 'constants/length';
import { TYPE_OF_ANT_DESIGN } from 'constants/nft';
import ModalCreateWhitelist from './components/ModalCreateWhitelist';
import { useDeleteWhiteList, useGetDetailWhiteList, useUploadWhitelist } from './hooks';
import showMessage from 'components/Message';
import ModalDeleteWhitelist from './components/ModalDeleteWhitelist';
import { useHistory, useLocation } from 'react-router-dom';
import { routeURLs } from 'constants/routes';

export interface ParamsWhiteTypes {
  keyword?: string;
  page: number;
  limit: number;
  date?: string;
}

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;

const initParams = {
  keyword: '',
  page: DEFAULT_PAGE,
  limit: DEFAULT_PAGE_SIZE,
};

const WhitelistManagement = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { onUploadWhitelist } = useUploadWhitelist();
  const { whitelistDetail, handleGetDetailWhiteList } = useGetDetailWhiteList();
  const { onDeleteWhiteList } = useDeleteWhiteList();
  const [visible, setVisible] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const formRef = useRef<any>(null);
  const [isRefreshTable, setIsRefreshTable] = useState(false);

  const [paramsList, setParamsList] = useState<{ params: ParamsWhiteTypes }>({
    params: initParams,
  });
  useEffect(() => {
    if (location?.hash === '#openCreateWhiteList') {
      setVisible(true);
      history.replace(routeURLs.WHITELIST);
    }
  }, [location?.hash]);

  const handleSubmit = (values: any) => {
    setParamsList({
      ...paramsList,
      params: {
        ...values,
      },
    });
  };

  const handleToggleCreateWhitelist = () => {
    setVisible(!visible);
  };

  const hanOpenModalCreate = () => {
    handleToggleCreateWhitelist();
    setIsEditModal(false);
  };
  const handleToggleDeleteWhitelist = () => {
    setVisibleModalDelete(!visibleModalDelete);
  };

  const onSuccess = (id?: string) => {
    formRef?.current?.resetForm();
    setIsRefreshTable(true);
    setParamsList({
      params: {
        page: paramsList.params.page,
        limit: paramsList.params.limit,
        date: new Date().toString(),
      },
    });
    showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, id ? 'message.S3' : 'message.S10');
    handleToggleCreateWhitelist();
  };
  const onSuccessDelete = () => {
    formRef?.current?.resetForm();
    setIsRefreshTable(true);
    setParamsList({
      params: {
        page: paramsList.params.page,
        limit: paramsList.params.limit,
        date: new Date().toString(),
      },
    });
    showMessage(TYPE_OF_ANT_DESIGN.SUCCESS, 'message.S11');
    handleToggleDeleteWhitelist();
    toggleLoading(false);
  };
  const onFinallyDelete = () => {
    formRef?.current?.resetForm();
    setIsRefreshTable(true);
    setParamsList({
      params: {
        page: paramsList.params.page,
        limit: paramsList.params.limit,
        date: new Date().toString(),
      },
    });
    handleToggleDeleteWhitelist();
    toggleLoading(false);
  };
  const onFinally = () => {
    toggleLoading(false);
  };

  const onSubmitUpload = (data: any) => {
    onUploadWhitelist(data, onSuccess, onFinally);
  };

  const toggleLoading = (loading: boolean) => {
    setLoading(loading);
  };
  const handleOpenModalEditWhiteList = (id: string) => () => {
    setIsEditModal(true);
    handleGetDetailWhiteList(id, handleToggleCreateWhitelist, setLoading);
  };

  const handleOpenModalDeleteWhiteList = (id: string) => () => {
    handleGetDetailWhiteList(id, handleToggleDeleteWhitelist, setLoading);
  };

  const handleDeleteWhiteList = (id: string) => () => {
    setLoading(true);
    onDeleteWhiteList(id, onSuccessDelete, onFinallyDelete);
  };

  const handleRenderModalUploadWhiteList = useMemo(() => {
    return (
      <ModalCreateWhitelist
        visible={visible}
        onClose={handleToggleCreateWhitelist}
        title={isEditModal ? t('whitelist.txt_edit_whitelist') : t('whitelist.txt_create_whitelist')}
        onSubmitUpload={onSubmitUpload}
        loading={loading}
        toggleLoading={toggleLoading}
        whitelistDetail={isEditModal ? whitelistDetail : ''}
      />
    );
  }, [whitelistDetail, visible, loading, isEditModal]);

  const handleRenderModalDeleteWhiteList = useMemo(() => {
    return (
      <ModalDeleteWhitelist
        visible={visibleModalDelete}
        onClose={handleToggleDeleteWhitelist}
        title={t('whitelist.txt_delete_whitelist')}
        onSubmitDelete={handleDeleteWhiteList}
        whitelistDetail={whitelistDetail}
        loading={loading}
      />
    );
  }, [visibleModalDelete, loading, whitelistDetail]);

  return (
    <div className="nft-management-page">
      <div className="nft-management-page__header">
        <PageHeader title={t('whitelist.txt_whitelist_management')} />
        <AppButton
          text={`+ ${t('whitelist.txt_create_whitelist')}`}
          onClick={hanOpenModalCreate}
          variant="primary"
          className="nft-management-page__button"
        />
      </div>
      <div className="nft-management-page__app-tab">
        <WhiteList
          values={paramsList}
          onSubmit={handleSubmit}
          handleEditWhiteList={handleOpenModalEditWhiteList}
          handleDeleteWhiteList={handleOpenModalDeleteWhiteList}
          formRef={formRef}
          isRefreshTable={isRefreshTable}
        />
      </div>
      {handleRenderModalUploadWhiteList}
      {handleRenderModalDeleteWhiteList}
    </div>
  );
};

export default WhitelistManagement;
