import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppTab from 'components/AppTab';
import PageContent from 'components/PageContent';
import Hero from './Hero';
import Item from './Item';

import { DASHBOARD_TOP_NFTS_TABS } from 'constants/nft';

const { ITEM, HERO } = DASHBOARD_TOP_NFTS_TABS;

const TopNfts = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(HERO.key);

  const handleChangeTab = (value: string) => setActiveTab(value);

  const listTab = [
    {
      key: HERO.key,
      tab: t(HERO.label),
      content: <Hero />,
    },
    {
      key: ITEM.key,
      tab: t(ITEM.label),
      content: <Item />,
    },
  ] as any;

  return (
    <PageContent className="h-full">
      <div className="dashboad-top-nfts">
        <AppTab
          listTab={listTab}
          activeKey={activeTab}
          onChangeTab={handleChangeTab}
          tabBarExtraContent={<div className="title">{t('dashboard.txt_top_nfts')}</div>}
          className="dashboad-top-nfts__tab"
        />
      </div>
    </PageContent>
  );
};

export default TopNfts;
