import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import isNil from 'lodash/isNil';

import NumberFormat from 'components/NumberFormat';
import { TYPE_INPUT } from 'components/FormItem';
import AppAddress from 'components/AppAddress';
import InfoItem, { InfoItemType } from '../InfoItem';
import { NftDetailContext } from 'pages/nft-detail';

import { formatCurrency, formatPadStart, getNumberValue } from 'utils';

import { EMPTY_DEFAULT_TEXT } from 'constants/common';
import { NFT_PERCENTAGE_SUFFIX, NFT_STANDARD, NFT_USD_DECIMAL_SCALE } from 'constants/nft';
import AppNumber from 'components/AppNumber';

const SaleOrder = () => {
  const { t } = useTranslation();
  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { token = {}, saleOrder = {}, code, royaltyFee } = nftDetail;

  const nftStandard = t(NFT_STANDARD.find((standard) => standard?.value === token?.standard)?.label as string);

  const saleOrderInfo = [
    {
      label: t('nft_detail.txt_total_supply'),
      value: token?.totalSupply,
      type: TYPE_INPUT.NUMBER,
      helpText: t('nft_detail.txt_tooltip_supply'),
    },
    {
      label: t('nft_detail.txt_total_minted'),
      value: token?.totalMinted,
      type: TYPE_INPUT.NUMBER,
      helpText: t('nft_detail.txt_tooltip_minted'),
    },
    {
      label: t('nft_detail.txt_onsale_quantity'),
      value: getNumberValue(saleOrder?.quantity),
      type: TYPE_INPUT.NUMBER,
      helpText: t('nft_detail.txt_tooltip_onsale_quantity'),
    },
    {
      label: t('nft_detail.txt_listing_price'),
      value: saleOrder?.unitPrice,
      type: TYPE_INPUT.NUMBER,
      prefix: saleOrder?.currency?.symbol,
    },
    {
      label: t('nft_detail.txt_nft_id'),
      value: `# ${formatPadStart(code)}`,
    },
    {
      label: t('nft_detail.txt_nft_standard'),
      value: nftStandard,
    },
    {
      label: t('nft_detail.txt_contract_address'),
      value: <AppAddress address={token?.address} />,
    },
    {
      label: t('nft_detail.txt_royalties'),
      value: `${royaltyFee}${NFT_PERCENTAGE_SUFFIX}`,
      helpText: t('nft_detail.txt_tooltip_royalties'),
    },
  ];

  return (
    <Row gutter={20}>
      {saleOrderInfo.map((saleOrder: InfoItemType, index: number) => {
        return (
          <InfoItem key={index} label={saleOrder?.label} helpText={saleOrder?.helpText}>
            {saleOrder?.type === TYPE_INPUT.NUMBER ? (
              !isNil(saleOrder?.value) ? (
                <>
                  <AppNumber value={saleOrder?.value} isNotFormatDecimal />
                  &nbsp;
                  {saleOrder?.prefix}
                </>
              ) : (
                EMPTY_DEFAULT_TEXT
              )
            ) : (
              saleOrder?.value
            )}
          </InfoItem>
        );
      })}
    </Row>
  );
};

export default SaleOrder;
