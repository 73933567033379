import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import App from './App';
import AppConnectWalletWrapper from 'components/AppConnectWalletWrapper';

import { persistor, store } from './redux/configStore';
import { namespace as AuthenticationNamespace } from 'redux/authentication/slice';

import reportWebVitals from './reportWebVitals';
import { getToken } from 'services/api';
import { LIBRARY_CONSTANTS } from 'constants/library';

import './App.css';
import 'antd/dist/antd.css';
import './styles/_app.scss';

const onBeforeLift: any = (store: any) => () => {
  const state = store.getState();
  getToken(state?.[AuthenticationNamespace]?.authenticationToken);
};

ReactDOM.render(
  <Web3ReactProvider getLibrary={LIBRARY_CONSTANTS.getLibrary}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift(store)}>
        <AppConnectWalletWrapper>
          <App />
        </AppConnectWalletWrapper>
      </PersistGate>
    </Provider>
  </Web3ReactProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
