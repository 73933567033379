import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import SearchForm from '../SearchForm';
import WhiteListColumn from '../WhiteListColumn';
import ItemWithLabel from 'components/ItemWithLabel';
import NumberFormat from 'components/NumberFormat';

import { useGetListWhiteList } from 'pages/whitelist-management/hooks';

type WhiteListProps = {
  values: any;
  onSubmit: (values: any) => void;
  handleEditWhiteList: (id: string) => any;
  handleDeleteWhiteList: (id: string) => any;
  formRef?: any;
  isRefreshTable: any;
};

const WhiteList = ({
  values,
  onSubmit,
  handleEditWhiteList,
  handleDeleteWhiteList,
  formRef,
  isRefreshTable,
}: WhiteListProps) => {
  const { t } = useTranslation();

  const { params, searched } = values;
  const { data, total, loading } = useGetListWhiteList(params);
  const emtpyText = searched ? t('') : (t('') as string);

  return (
    <Fragment>
      <SearchForm onSubmit={onSubmit} params={params} formRef={formRef} />
      <ItemWithLabel label={`${t('whitelist.txt_total_whitelist')}:`}>
        <NumberFormat thousandSeparator value={total} displayType="text" />
      </ItemWithLabel>
      <WhiteListColumn
        total={total}
        loading={loading}
        data={data}
        onSetParams={onSubmit}
        params={params}
        emtpyText={emtpyText}
        handleEditWhiteList={handleEditWhiteList}
        handleDeleteWhiteList={handleDeleteWhiteList}
        isRefreshTable={isRefreshTable}
      />
    </Fragment>
  );
};

export default WhiteList;
