import React from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import classNames from 'classnames';

import NumberFormat from 'components/NumberFormat';
import EllipsisText from 'components/EllipsisText';
import ResponsiveImage from 'components/ResponsiveImage';

import { formatDate, formatPadStart } from 'utils';
import { renderRoutes } from 'constants/routes';
import { NFT_STATUS, NFT_DETAIL_TABS } from 'constants/nft';
import { ROLE } from 'constants/common';
const { PROFILE } = NFT_DETAIL_TABS;
export const columns = (t: TFunction, page: number, limit: number, role: any) => [
  {
    title: t('nft_management.txt_no'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 50,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('nft_management.txt_created_date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    sorter: true,
    render: (value: any) => formatDate(value),
  },
  {
    title: t('nft_management.txt_nft_id'),
    dataIndex: 'code',
    key: 'code',
    width: 75,
    sorter: true,
    render: (value: any) => `# ${formatPadStart(value)}`,
  },
  {
    title: t('nft_management.txt_nft_name'),
    dataIndex: 'name',
    key: 'name',
    width: 125,
    sorter: true,
    ellipsis: true,
    render: (_value: string, row: any) => {
      return (
        <div className="view-nft-name">
          <ResponsiveImage src={`${row?.image?.smallUrl}?random=${new Date().getTime()}`} />
          <EllipsisText text={row?.name} />
        </div>
      );
    },
  },
  {
    title: t('nft_management.txt_total_supply'),
    dataIndex: 'totalSupply',
    key: 'totalSupply',
    width: 100,
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_management.txt_total_minted'),
    dataIndex: 'totalMinted',
    key: 'totalMinted',
    width: 100,
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_management.txt_onsale_quantity'),
    dataIndex: 'onSaleQuantity',
    key: 'onSaleQuantity',
    width: 100,
    sorter: true,
    render: (value: any) => {
      return <NumberFormat thousandSeparator value={value} displayType="text" />;
    },
  },
  {
    title: t('nft_management.txt_status'),
    dataIndex: 'status',
    key: 'status',
    width: 75,
    render: (value: string) => {
      const curStatus = NFT_STATUS.find((status) => status?.value === value) as any;
      return (
        <div className={classNames('view-nft-status', `view-nft-status-${curStatus?.value}`)}>{t(curStatus?.name)}</div>
      );
    },
  },
  {
    title: t('nft_management.txt_action'),
    dataIndex: '_id',
    key: '_id',
    width: 100,
    render: (value: any, row: any) => {
      let className = 'text-disabled';
      if (role !== ROLE.CREATOR_ADMIN.value) {
        if (row?.status !== NFT_STATUS?.[0]?.value || row?.totalMinted > 0) {
          className = '';
        }
      }

      return (
        <span className="action-nft-management">
          <span>
            <Link className="view-nft-detail" to={renderRoutes.NFT_DETAIL(value, PROFILE.query)}>
              {t('common.txt_view_detail')}
            </Link>
          </span>
          |
          <span className={className}>
            <Link className="view-nft-detail" to={renderRoutes.NFT_UPDATE_IPFS(row?._id as string)}>
              {t('common.txt_update_ipfs')}
            </Link>
          </span>
        </span>
      );
    },
  },
];
