import React from 'react';

import ItemWithLabel from 'components/ItemWithLabel';

type ItemProps = {
  children?: any;
  title?: string;
  helpText?: string;
};

const Item = ({ title, helpText, children }: ItemProps) => {
  return (
    <div className="overview-item">
      <ItemWithLabel label={title} helpText={helpText} />
      {children}
    </div>
  );
};

export default Item;
