import { useEffect, useState } from 'react';
import { omit } from 'lodash';

import selectedAddress from 'redux/address/selector';

import PoolServices from 'services/pool';

import { useAppSelector } from 'hooks/useStore';
import { convertBigNumberToPrice, ORDERS } from 'utils';
import { NFT_MANAGEMENT_FIELD, NFT_MANAGEMENT_FIELD_SORTER } from 'constants/nft';
import poolServices from 'services/pool';
import { checkSusscessRequest } from 'services/api';
import showMessage from 'components/Message';
import TYPE_CONSTANTS from 'constants/type';

const { SORT } = NFT_MANAGEMENT_FIELD;

const { DEFAULT, CREATED_AT, NFT_NAME } = NFT_MANAGEMENT_FIELD_SORTER;

const { ASC, DESC, FIELD, ORDER } = ORDERS;

export const useGetListPool = (params: any) => {
  const { address } = useAppSelector(selectedAddress.getAddress);

  const [listPool, setListPool] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [summary, setSummary] = useState();

  const INDEXED_SORTER = {
    [DEFAULT]: { [CREATED_AT]: DESC },
    [CREATED_AT]: { [CREATED_AT]: DESC },
    [NFT_NAME]: { [NFT_NAME]: ASC, [CREATED_AT]: DESC },
    editionLimit: { editionLimit: ASC, [CREATED_AT]: DESC },
    amount: { amount: ASC, [CREATED_AT]: DESC },
  };
  useEffect(() => {
    handleGetListPool();
  }, [params, address]);

  const handleGetListPool = async () => {
    setLoading(true);
    try {
      const newParams = omit({ ...params }, [FIELD, ORDER]) as any;
      const field = params?.[FIELD] || DEFAULT;

      for (const key in INDEXED_SORTER?.[field]) {
        if (key === field && params?.[ORDER] && params?.[FIELD]) {
          newParams[`${SORT}[${key}]`] = params?.[ORDER];
        } else {
          newParams[`${SORT}[${key}]`] = INDEXED_SORTER?.[field]?.[key];
        }
      }
      const response = await PoolServices.handleGetList(newParams);
      const { docs = [], totalDocs = 0, summary } = response?.data || {};
      setSummary(summary);
      setListPool(docs);
      setTotal(totalDocs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    data: listPool,
    loading,
    total,
    summary,
  };
};

export const useShowHidePool = () => {
  const [loadingShowHide, setLoading] = useState(false);
  const handleShowHidePool = async (values: any, onSuccess: any) => {
    setLoading(true);
    try {
      const response: any = await poolServices.handleUpdatePool(values, values?.row?._id);
      if (checkSusscessRequest(response)) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, values?.isShow ?'message.S17':'message.S16',{poolName: values?.row?.name});
        onSuccess();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loadingShowHide: loadingShowHide,
    onShowHidePool: handleShowHidePool,
  };
};

export const useUpdateTransactionDeposit = () => {
  const handleUpdateTransaction = async (
    data: any,
    { onSuccess, onError }: { onSuccess: () => void; onError: () => void },
  ) => {
    try {
      const response = await poolServices.handleUpdateTransactionDeposit({
        totalReserve: convertBigNumberToPrice(data?.totalReserve),
      });
      if (checkSusscessRequest(response)) {
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    } catch (error) {}
  };

  return {
    onUpdateTransactionDeposit: handleUpdateTransaction,
  };
};
