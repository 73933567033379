import { api } from 'services/api';

import { NFTParamsTypes } from 'pages/nft-management';

class PoolServices {
  handleGetList = (params: NFTParamsTypes) => {
    return api.get('admin/staking', params);
  };

  handleCreatePool = (data: any) => {
    return api.post('admin/staking', data);
  };

  handleUpdatePool = (data: any, id: string) => {
    return api.patch(`admin/staking/${id}`, data);
  };

  handleGetPoolDetail = (id: string) => {
    return api.get(`admin/staking/${id}`);
  };

  handleDeletePool = (id: string) => {
    return api.delete(`admin/staking/${id}`);
  };
  handleUpdateTransactionStaking = (id: string, data: any) => {
    return api.patch(`admin/staking/${id}/hash`, data);
  };

  handleUpdateTransactionDeposit = (data: any) => {
    return api.post(`update-totalReserve`, data);
  };
}

const poolServices = new PoolServices();

export default poolServices;
