import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import noop from 'lodash/noop';

import AppLoading from 'components/AppLoading';
import PageContent from 'components/PageContent';
import NumberFormat from 'components/NumberFormat';
import ItemWithLabel from 'components/ItemWithLabel';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import Item from './Item';

import { getNumberValue } from 'utils';
import { useGetDashboardOverview } from 'pages/home/hooks';
import { useGetConfig } from 'hooks/useGetConfig';

import { DOLLAR_TEXT, EMPTY_DEFAULT_TEXT, TOKEN_SUPPORT_DEFAULT } from 'constants/common';
import { REVENUE_DECIMAL_SCALE } from 'constants/revenue';
import { DASHBOARD_OVERVIEW, DASHBOARD_TIME_TYPES } from 'constants/nft';
import AppNumber from 'components/AppNumber';

const { TIME_TYPE } = DASHBOARD_OVERVIEW;
const { SELECT } = TYPE_INPUT;

const initialValues = {
  [TIME_TYPE]: DASHBOARD_TIME_TYPES[0].value,
};

const Overview = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initialValues);

  const { currency = {} } = useGetConfig();
  const { overviewInfo = {}, loading } = useGetDashboardOverview(params);

  const { sumUsdRevenue, sumRevenue, sumUsdVolume, sumVolume, soldNfts, users, nfts, newUsers } = overviewInfo as any;

  const dashboardTimeTypeOptions = DASHBOARD_TIME_TYPES.map((dataType) => ({
    ...dataType,
    name: t(dataType?.name),
  }));

  const handleFieldChange = (setFieldValue: any, field: string) => (val: any) => {
    setParams({
      ...params,
      [field]: val,
    });
    setFieldValue(field, val);
  };

  const saleOverview = [
    {
      title: t('dashboard.txt_sale_volume'),
      helperText: t('dashboard.txt_volume_tooltip'),
      valueSymbol: sumVolume,
      valueUsd: sumUsdVolume,
    },
    {
      title: t('dashboard.txt_sale_revenue'),
      helperText: t('dashboard.txt_revenue_tooltip'),
      valueSymbol: sumRevenue,
      valueUsd: sumUsdRevenue,
    },
  ];

  const infoOverview = [
    {
      title: t('dashboard.txt_nfts'),
      detail: [
        {
          label: t('dashboard.txt_created_nfts'),
          value: getNumberValue(nfts),
        },
        {
          label: t('dashboard.txt_sold_nfts'),
          value: getNumberValue(soldNfts),
        },
      ],
    },
    {
      title: t('dashboard.txt_users'),
      detail: [
        {
          label: t('dashboard.txt_total_users'),
          value: getNumberValue(users),
        },
        {
          label: t('dashboard.txt_new_users'),
          value: getNumberValue(newUsers),
        },
      ],
    },
  ];

  return (
    <PageContent className="h-full">
      <Formik initialValues={initialValues} onSubmit={noop}>
        {({ setFieldValue }) => {
          return (
            <Form>
              <div className="dashboad-overview">
                <div className="search">
                  <span className="title">{t('dashboard.txt_overview')}</span>
                  <div className="filter">
                    <FormItem
                      name={TIME_TYPE}
                      typeInput={SELECT}
                      options={dashboardTimeTypeOptions}
                      onChange={handleFieldChange(setFieldValue, TIME_TYPE)}
                    />
                  </div>
                </div>
              </div>

              <AppLoading loading={loading}>
                {saleOverview.map((sale: any, index: number) => (
                  <Item title={sale?.title} helpText={sale?.helperText} key={index}>
                    <div className="content-single">
                      <img src={currency.icon} />
                      <div className="currency">
                        <p className="symbol">
                          {sale?.valueSymbol ? (
                            <AppNumber value={sale?.valueSymbol} isNotFormatDecimal />
                          ) : (
                            EMPTY_DEFAULT_TEXT
                          )}
                          &nbsp;
                          <span className="opacity-5">{TOKEN_SUPPORT_DEFAULT.symbol}</span>
                        </p>
                        <p className="usd">
                          {sale?.valueSymbol ? (
                            <AppNumber value={sale?.valueUsd} isNotFormatDecimal isUsd={true} />
                          ) : (
                            `~ $ ${EMPTY_DEFAULT_TEXT}`
                          )}
                        </p>
                      </div>
                    </div>
                  </Item>
                ))}

                {infoOverview?.map((info, index) => (
                  <Item title={info?.title} key={index}>
                    <div className="content-multi">
                      {info.detail.map((detail, index) => (
                        <ItemWithLabel key={index} label={detail?.label}>
                          <NumberFormat thousandSeparator value={getNumberValue(detail?.value)} displayType="text" />
                        </ItemWithLabel>
                      ))}
                    </div>
                  </Item>
                ))}
              </AppLoading>
            </Form>
          );
        }}
      </Formik>
    </PageContent>
  );
};

export default Overview;
