import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import showMessage from 'components/Message';

import nftServices from 'services/nft';
import { checkSusscessRequest } from 'services/api';

import TYPE_CONSTANTS from 'constants/type';
import { routeURLs } from 'constants/routes';
import HTTP_STATUS_CONTSTANTS from 'constants/httpStatus';

const CREATE_NFT_STATUS = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  SUCCESS_WITH_ERROR: 'SUCCESS_WITH_ERROR',
  SUBMIT_FOR_APPROVAL: 'SUBMIT_FOR_APPROVAL',
};

export const useCreateMultiNFT = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleCreateNFT = async (data: any, isSellOrder?: boolean, setCreateNftStatus?: any, setErrorsWhitelist?: any, t?: any) => {
    setLoading(true);
    try {
      const response: any = await nftServices.handleCreateMultiNFT(data);
      if (response.status === HTTP_STATUS_CONTSTANTS.ERROR && response.data?.code === 'E0' && response.data?.nonExistedWhitelists)
        setErrorsWhitelist(response.data?.nonExistedWhitelists.map((e: any) => ({ line: e.line, err: [t('message.whitelistNotExist', { name: e.whitelistName })] })))
      if (checkSusscessRequest(response)) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, isSellOrder ? 'message.S5' : 'message.S2');
        history.push(routeURLs.NFT);
      }
    } catch (error) {
    } finally {
      setLoading(false);
      setCreateNftStatus(CREATE_NFT_STATUS.SUCCESS)
    }
  };

  return {
    loading,
    onCreateNFT: handleCreateNFT,
  };
};
