import moment from 'moment';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

import CommonRarity from '../resources/svg/rarity/Common.svg';
import RareRarity from '../resources/svg/rarity/Rare.svg';
import SuperRareRarity from '../resources/svg/rarity/SuperRare.svg';
import Legendary from '../resources/svg/rarity/Legendary.svg';
import SmgWeapon from '../resources/svg/typeofweapon/SMG.svg';
import SGWeapon from '../resources/svg/typeofweapon/SG.svg';
import SRWeapon from '../resources/svg/typeofweapon/SR.svg';
import ARWeapon from '../resources/svg/typeofweapon/AR.svg';
import LMGWeapon from '../resources/svg/typeofweapon/LMG.svg';
import {
  EXTENSION_3D_SUPPORT_ARRAY,
  MAX_LENGTH_PRICE,
  NFT_ATTRIBUTE_CREATED_FIELD,
  NFT_ATTRIBUTE_CREATED_FIELD_SLIDER,
  NFT_DECIMAL_SCALE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_MEDIA,
  NFT__CREATED_WHITELIST,
} from 'constants/nft';
import {
  EMPTY_DEFAULT_TEXT,
  htmlEntities,
  HTTP_RESPONSE,
  IMAGE_TYPE,
  MAX_NFT_CODE_LENGTH,
  NFT_FORMAT,
  PUBLISH_DATE,
  TOKEN_SUPPORT_DEFAULT,
  ZERO_VALUE,
} from 'constants/common';
import LENGTH_CONSTANTS from 'constants/length';
import { applyThousandSeparator, splitDecimal } from 'components/NumberFormat/utils';
import { trim } from 'lodash';
import nftServices from 'services/nft';
import { Tooltip } from 'antd';

const { MIN_VALUE } = LENGTH_CONSTANTS;

export const DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const ANTD_ORDERS = {
  ASCEND: 'ascend',
  DESCEND: 'descend',
};

export const ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
  FIELD: 'field',
  ORDER: 'order',
};

export const clearRequestParams = (params?: any) => {
  const newParams = {} as any;
  const cloneParams = { ...params };
  for (const field in cloneParams) {
    if (cloneParams?.[field] || cloneParams?.[field] === 0 || cloneParams?.[field] === false) {
      newParams[field] = cloneParams?.[field];
    }
  }
  return newParams;
};

const { FILE, FILE_PREVIEW, TOTAL_SUPPLY, IS_PUT_ON_SALE, CURRENCY, IMAGE_MEDIUM, IMAGE_SMALL, DESCRIPTION } =
  NFT_DEFAULT_CREATE_FIELD;

export const formatCurrency = (
  value: any,
  options: { isNotCompare?: boolean; decimal?: number; isNotFormatDecimal?: boolean } = {},
) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });

  const { isNotCompare, decimal = NFT_DECIMAL_SCALE, isNotFormatDecimal } = options;

  const formatDecimal = isNotFormatDecimal ? undefined : decimal;

  if (!value) {
    return ZERO_VALUE;
  }
  if (isNotCompare) {
    return new BigNumber(value).toFormat();
  }

  return new BigNumber(value).isLessThan(new BigNumber(MIN_VALUE))
    ? new BigNumber(MIN_VALUE).toFormat()
    : new BigNumber(value).toFormat(formatDecimal);
};
export const formatNumberCurrency = (number: any, options?: any) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });
  const value = new BigNumber(number)?.toString();
  const dataSplit = value?.split('.');
  const dataPreNumber = dataSplit?.[0]?.replace(/,/g, '');
  const data = {
    result: '',
    isDecimalSmall: false,
  };
  if (dataPreNumber.length > 12) {
    data.result = dataPreNumber?.slice(0, 12);
    return data;
  }
  const dataDecimalNumber = dataSplit?.[1];
  const dataDecimalNumberSlice =
    dataDecimalNumber?.length > 0
      ? `${dataDecimalNumber
          ?.slice(0, options?.minDecimal - 1 ? options?.minDecimal : 12 - dataPreNumber.length)
          .toString()}`
      : '';
  let decimalPrice = '';
  if (dataDecimalNumberSlice) {
    decimalPrice = `.${dataDecimalNumberSlice}`;
  }
  if (dataDecimalNumberSlice?.[dataDecimalNumberSlice?.length - 1] == '0' && dataPreNumber == '0') {
    decimalPrice = `.${dataDecimalNumberSlice?.slice(0, dataDecimalNumberSlice.length - 1)}1`;
  }

  if (new BigNumber(number).isLessThan(new BigNumber(`1e-${options?.minDecimal || 12}`)) && number != '0') {
    data.isDecimalSmall = true;
  }

  const result = `${dataPreNumber}${decimalPrice}`;
  data.result = result;
  return data;
};
export const formatDecimal = (number: any) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });
  const value = new BigNumber(number)?.toString();
  const { beforeDecimal, afterDecimal } = splitDecimal(value);
  const beforeDecimalLast = applyThousandSeparator(beforeDecimal, ',', 'thousand');
  const numStr = `${beforeDecimalLast}${afterDecimal?.length > 0 ? '.' : ''}${afterDecimal}`;
  return numStr;
};
export const shortenAddress = (address: string, number = -4, totalPrefixNumber = 6) => {
  if (address) {
    const first = address.slice(0, totalPrefixNumber);
    const last = address.slice(number);
    return `${first}...${last}`;
  }
  return '--';
};

export const getCurrentTime = () => {
  return moment().valueOf();
};

export const formatDate = (date: moment.MomentInput | any, type = DATE_FORMAT) => {
  return moment(date).format(type);
};

export const setOrderSorter = (order: string | null | undefined) => {
  const newOrder =
    (order === ANTD_ORDERS.ASCEND && ORDERS.ASC) || (order === ANTD_ORDERS.DESCEND && ORDERS.DESC) || null;
  return newOrder;
};

export const get3DFileType = (fileName: string) => {
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1)?.toLowerCase() || '';
  const type = `3d/${extension}`;
  if (EXTENSION_3D_SUPPORT_ARRAY.includes(type)) {
    return type;
  }
  return '';
};

export const getFullFormatedNFT = (value: any) => {
  return value?.fileList?.[0]?.type || get3DFileType(value?.fileList?.[0]?.name) || IMAGE_TYPE;
};

export const getFormatedNFT = (value: any) => {
  const fullFormat = getFullFormatedNFT(value);
  return fullFormat?.split('/')[0] || NFT_MEDIA.IMAGE;
};

export const limitPercentage = (inputObj: any) => {
  const MAX_PERCENT = 99.99;
  const { value } = inputObj;
  if (Number(value) > MAX_PERCENT) {
    return false;
  }
  return true;
};

export const limitPercentageWhitelist = (inputObj: any) => {
  const MAX_PERCENT = 100;
  const { value } = inputObj;
  if (Number(value) > MAX_PERCENT) {
    return false;
  }
  return true;
};
export const limitMaxlengNumber =
  (maxLength: number = MAX_LENGTH_PRICE) =>
  (inputObj: any) => {
    const { value } = inputObj;
    const integerPath = (value || '').split('.')[0];
    return integerPath.length <= maxLength;
  };

export const limitMaxlengNumberIncludeDecimal =
  (maxLength: number = MAX_LENGTH_PRICE) =>
  (inputObj: any) => {
    const { value } = inputObj;
    const integerPath = (value || '').split('.')[0];
    const decimalPath = (value || '').split('.')[1];
    const lengthNumber = Number(integerPath?.length || 0) + Number(decimalPath?.length || 0);
    return lengthNumber <= maxLength;
  };

export const limitMaxlengNumberSlider =
  (max: number = MAX_LENGTH_PRICE) =>
  (inputObj: any) => {
    const { value } = inputObj;
    return value <= max;
  };

export const getAttributeFieldNFTValues = (values: any) => {
  const data = Object.values(NFT_ATTRIBUTE_CREATED_FIELD).reduce((acc: any, field: string | any) => {
    acc[field] = values?.attributes?.[field]?.text || values?.attributes?.[field];
    return acc;
  }, {});
  const dataSlider = Object.values(NFT_ATTRIBUTE_CREATED_FIELD_SLIDER).reduce((acc: any, field: string | any) => {
    acc[`${field}Input`] = values?.attributes?.[field]?.text || values?.attributes?.[field];
    return acc;
  }, {});
  return { ...data, ...dataSlider };
};

export const getWhitelistNFTValues = (values: any) => {
  const data = Object.values(NFT__CREATED_WHITELIST).reduce((acc: any, field: string | any) => {
    if (field === NFT__CREATED_WHITELIST.NUMBER_OF_PUCHASE) {
      acc[field] = values?.whitelist?.[field] === 'true';
    } else {
      acc[field] = values?.whitelist?.[field];
    }
    if (field === NFT__CREATED_WHITELIST.START_TIME || field === NFT__CREATED_WHITELIST.END_TIME) {
      acc[field] = values?.whitelist?.[field] ? moment(convertUtcToCurrentTime(values?.whitelist?.[field])) : '';
    }

    if (values?.whitelist?.[NFT__CREATED_WHITELIST.WHITELIST_NAME]) {
      acc[NFT__CREATED_WHITELIST.IS_PUT_WHITELIST] = true;
    }
    return acc;
  }, {});
  return { ...data };
};

export const getDefaultFieldNFTValues = (values: any) => {
  return Object.values(NFT_DEFAULT_CREATE_FIELD).reduce((acc: any, field: string | any) => {
    switch (field) {
      case FILE:
        acc[FILE] = {
          fileList: [{ type: values?.media?.type || IMAGE_TYPE }],
          previewContent: values?.media?.url || values?.image?.url || '',
        };
        break;
      case FILE_PREVIEW:
        acc[FILE_PREVIEW] = {
          fileList: [],
          previewContent: values?.image?.url || '',
        };
        break;
      case IMAGE_MEDIUM:
        acc[IMAGE_MEDIUM] = `${values?.image?.mediumUrl}?random=${new Date().getTime()}`;
        break;
      case IMAGE_SMALL:
        acc[IMAGE_SMALL] = `${values?.image?.smallUrl}?random=${new Date().getTime()}`;
        break;
      case TOTAL_SUPPLY:
        acc[TOTAL_SUPPLY] = values?.token?.[TOTAL_SUPPLY]?.toString();
        break;
      case IS_PUT_ON_SALE:
        acc[IS_PUT_ON_SALE] = false;
        break;
      case CURRENCY:
        acc[CURRENCY] = TOKEN_SUPPORT_DEFAULT.value;
        break;
      case DESCRIPTION:
        acc[DESCRIPTION] = values?.description?.replaceAll('\r\n', '\n').toString();
        break;
      default:
        acc[field] = values?.[field]?.toString() || '';
        break;
    }
    return acc;
  }, {});
};

export const getFieldValues = (values: any, objectField: any = NFT_ATTRIBUTE_CREATED_FIELD) => {
  return Object.values(objectField).reduce((acc: any, field: string | any) => {
    if (field === NFT_DEFAULT_CREATE_FIELD.FILE || field === NFT_DEFAULT_CREATE_FIELD.FILE_PREVIEW) {
      acc[field] = values?.[field]?.previewContent;
    } else {
      acc[field] = values?.[field];
    }
    return acc;
  }, {});
};

export const checkValueNftChange = (preVal: object, newVal: object, isEditing?: boolean) => {
  let newPrevNft = { ...preVal };

  if (isEditing) {
    newPrevNft = {
      ...getDefaultFieldNFTValues(preVal),
      ...getAttributeFieldNFTValues(preVal),
    };
  }

  // prev
  const defaultPrevValues = getFieldValues(newPrevNft, NFT_DEFAULT_CREATE_FIELD) as object;
  const attributePrevValues = getFieldValues(newPrevNft) as object;

  // new
  const defaultNewValues = getFieldValues(newVal, NFT_DEFAULT_CREATE_FIELD) as object;
  const attributeNewValues = getFieldValues(newVal) as object;

  const prevNft = { ...defaultPrevValues, ...attributePrevValues };
  const newNft = { ...defaultNewValues, ...attributeNewValues };

  return JSON.stringify(prevNft) !== JSON.stringify(newNft);
};

export const checkValuePoolChange = (preVal: any, newVal: any) => {
  const preData: any = {
    name: preVal?.name,
    duration: preVal?.duration?.toString(),
    endTimeStaking: preVal?.endTimeStaking?.toString(),
    endTimePool: preVal?.endTimePool?.toString(),
    publishType: preVal?.publishType,
    amount: preVal?.amount?.toString(),
    editionLimit: preVal?.editionLimit?.toString(),
  };
  if (preVal?.publishType === PUBLISH_DATE.PICK_A_DATE) {
    preData.publishTime = preVal?.publishTime?.toString();
  }
  const newData: any = {
    name: newVal?.name,
    duration: newVal?.duration?.toString(),
    endTimeStaking: newVal?.endTimeStaking ? moment(newVal?.endTimeStaking)?.unix()?.toString() : '',
    endTimePool: newVal?.endTimePool ? moment(newVal?.endTimePool)?.unix()?.toString() : '',
    publishType: newVal?.publishType,
    amount: newVal?.amount?.toString(),
    editionLimit: newVal?.editionLimit?.toString(),
  };

  if (newVal?.publishType === PUBLISH_DATE.PICK_A_DATE) {
    newData.publishTime = newVal?.publishTime ? moment(newVal?.publishTime)?.unix()?.toString() : '';
  }

  if (JSON.stringify(preData) == JSON.stringify(newData)) {
    return false;
  } else {
    return true;
  }
};

export const formatPadStart = (value: any) => {
  if (!value) {
    return EMPTY_DEFAULT_TEXT;
  }
  return value.length > MAX_NFT_CODE_LENGTH ? shortenAddress(value) : value;
};

export const getNumberValue = (value?: number) => {
  return value ?? ZERO_VALUE;
};

export const getValueAttribute = (attributes: any, field: string) => attributes?.[field]?.text || attributes?.[field];

export const getImageAttribute = (attributes: any, field: string) => attributes?.[field]?.imgUrl;
type urlImageType = {
  'rarity.common': string;
  'rarity.legendary': string;
  'rarity.rare': string;
  'rarity.superrare': string;
  'typeofweapon.smg': string;
  'typeofweapon.sr': string;
  'typeofweapon.lmg': string;
  'typeofweapon.ar': string;
  'typeofweapon.sg': string;
};
export const urlImage: urlImageType = {
  'rarity.common': CommonRarity,
  'rarity.legendary': Legendary,
  'rarity.rare': RareRarity,
  'rarity.superrare': SuperRareRarity,
  'typeofweapon.smg': SmgWeapon,
  'typeofweapon.sr': SRWeapon,
  'typeofweapon.lmg': LMGWeapon,
  'typeofweapon.ar': ARWeapon,
  'typeofweapon.sg': SGWeapon,
};
export const getImageAttributes = (attributes: any, field: string) => {
  const folder = field?.toLowerCase();
  const file = attributes[field]?.text?.replace(/ /g, '')?.toLowerCase();
  const url = `${folder}.${file}`;
  if (!file || !folder) {
    return false;
  }
  return (urlImage as any)[url];
};
export const getRowKey = (row: any) => row?._id;

export const nFormatter = (num: any, digits: any = 2) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : num.toFixed(8);
};

export const getStartDateTimestamp = (value: any) => {
  if (!value) {
    return;
  }
  return moment(value).clone().startOf('days').toISOString();
};

export const getEndDateTimestamp = (value: any) => {
  if (!value) {
    return;
  }
  return moment(value).clone().endOf('days').toISOString();
};

export const getDuration = (from: any, until: any) => {
  const duration = moment.duration(until.diff(from));
  return duration;
};

export const disabledFromDate = (date: any) => (current: moment.Moment) => {
  return (date && current?.clone()?.endOf('day') > date?.clone()?.endOf('day')) || current > moment();
};

export const disabledUntilDate = (date: any) => (current: moment.Moment) => {
  return (date && current?.clone()?.startOf('day') < date?.clone()?.startOf('day')) || current > moment();
};

export const disabledStartDateWL = (date?: any) => (current: moment.Moment) => {
  return (
    (date && current?.clone()?.endOf('day') > date?.clone()?.endOf('day')) ||
    current?.clone()?.startOf('day') < moment()?.clone()?.startOf('day')
  );
};

export const disabledEndDateWL = (date?: any) => (current: moment.Moment) => {
  return (
    (date && current?.clone()?.startOf('day') < date?.clone()?.startOf('day')) ||
    current?.clone()?.startOf('day') < moment()?.clone()?.startOf('day')
  );
};

export const convertTimeToUtc = (date: any, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment.utc(date).format(format);
};

export const convertUtcToCurrentTime = (date: any, format = 'YYYY-MM-DD HH:mm:ss') => {
  const local = (date && moment.utc(date).local().format(format)) || '';
  return local;
};

export const convertTimeStampUtcToDate = (date: any, format = 'YYYY-MM-DD HH:mm:ss') => {
  const newDate = (date && moment(date * 1000).format(format)) || '';

  const local = (newDate && moment.utc(newDate).local().format(format)) || '';
  return newDate;
};

// export const isAddress = (address: string) => {
//   let exactAddress;
//   try {
//     exactAddress = Web3.utils.toChecksumAddress(address?.trim());
//   } catch (error) {}
//   return address === exactAddress;
// };

export const isAddress = (address: string) => {
  let res;
  try {
    const exactAddress = Web3.utils.toChecksumAddress(address?.trim());
    res = Web3.utils.checkAddressChecksum(exactAddress);
  } catch (error) {}
  return res ? true : false;
};

export const convertAddress = (address: string) => {
  return Web3.utils.toChecksumAddress(address?.trim());
};

export const checkAddress = (address: string) => {
  return Web3.utils.isAddress(address?.toLowerCase());
};

export function formatCommaNumber(num: any) {
  if (!num) return 0;
  const p = num.toString().split('.');
  p[0] = p[0]
    .split('')
    .reverse()
    .reduce(function (acc: any, num: any, i: any) {
      return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc;
    }, '');
  return p[1] ? p.join('.') : p[0];
}

export const generateNftCode = (nftName = '') => {
  const pureName = nftName.replace(/[^0-9a-zA-Z\s]+/g, '');
  const shortName = pureName.trim().toLowerCase();
  return shortName.replace(/\s{1,}/g, '-');
};

export const stripContent = (value: any, maxLength: any, isHtml?: boolean) => {
  if (isHtml && value) {
    return trim(stripHtml(value)).slice(0, maxLength);
  }
  if (value) {
    return value.trim().slice(0, maxLength);
  }
  return '';
};

export function stripHtml(html: string) {
  if (!html) {
    return '';
  }
  return replaceHtmlEntities(addNewLineAfterBrAndPTag(html).replace(/<[^>]*>?/gm, ''));
}

export const replaceHtmlEntities = (str: any) => {
  if (!str) {
    return '';
  }
  const textOnly = str.replace(/&[a-z]+;/g, (match: any) => {
    const entity = (htmlEntities as any)?.[match];
    if (entity) {
      return entity;
    }
    return;
  });
  return textOnly;
};

export const convertPriceBigNumber = (value: any, coinDecimal = 18) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });
  return new BigNumber(value).multipliedBy(new BigNumber(Math.pow(10, coinDecimal)));
};

export const convertBigNumberToPrice = (value: any, coinDecimal = 18) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });
  return new BigNumber(value).dividedBy(new BigNumber(Math.pow(10, coinDecimal)));
};

export const minusBigNumber = (value: any, coinDecimal = 18) => {
  BigNumber.config({
    EXPONENTIAL_AT: 100,
  });
  return new BigNumber(value).dividedBy(new BigNumber(Math.pow(10, coinDecimal)));
};

export const addNewLineAfterBrAndPTag = (str: any) => {
  if (!str) {
    return '';
  }
  return str
    .replace(/<br\s*\/?>/gi, '$&\n')
    .replace(/<\/\s?p>/g, '$&\n')
    .replace(/<\/li>/g, '$&\n');
};

export const customUploadRequest = async (params: any, userId: any) => {
  const { file, onSuccess, onError } = params;
  const time = new Date().getTime();

  try {
    const fileSplited = (file.name || '').split('.');
    const ext = fileSplited[fileSplited.length - 1];
    const fileNameS3 = `${userId?.toLowerCase()}-${time}-${file.uid}.${ext}`;
    const res = await nftServices.getPresignedUrl({ name: fileNameS3 });
    const presignUrl = res?.data?.putPresignedURL;
    const putFileToS3Res = await nftServices.putPresignToS3(presignUrl, file);
    if (putFileToS3Res.status === HTTP_RESPONSE.SUCCESS) {
      onSuccess({ url: res?.data?.result?.getPresignedS3Url, fileNameS3 });
      return res?.data?.result?.getPresignedS3Url;
    }
  } catch (e) {
    console.log('e', e);
    onError('Upload to S3 Error');
    return;
  }
  onError('Upload to S3 Error');
  return;
};

export const getNftFormat = (value: any, file?: any) => {
  const fullFormat = getNftFullFormat(value, file);
  return fullFormat?.split('/')[0] || NFT_FORMAT.IMAGE;
};

export const getNftFullFormat = (value: any, file?: any) => {
  if (file) {
    return file.type || get3DFileType(file.name) || 'image/png';
  }
  return value?.fileList?.[0]?.type || get3DFileType(value?.fileList?.[0]?.name) || 'image/png';
};

export const exportExcel = (workBookName: any, workSheetName: string, columns: any, data: any) => async () => {
  const workbook = new Excel.Workbook();

  try {
    const fileName = workBookName;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true };

    // loop through all of the columns and set the alignment with width.

    worksheet.columns.forEach((column: any) => {
      // column.width = 52;
      column.alignment = { horizontal: 'center' };
    });

    // loop through data and add each one to worksheet
    data.forEach((singleData: any) => {
      worksheet.addRow(singleData);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row: any) => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell: any) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error: any) {
    console.error('<<<ERRROR>>>', error);
    console.error('Something Went Wrong', error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};

export const renderName = (name: string, slice: number) => {
  const isRenderTooltip = name?.length > slice ? true : false;
  const poolName = isRenderTooltip ? `${name.slice(0, slice)} ...` : name;
  return isRenderTooltip ? <Tooltip title={name}>{poolName}</Tooltip> : name;
};
