import React from 'react';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';

type NFTGroupButtonProps = {
  isSubmit: boolean;
  onDiscard: () => void;
  address?: string;
};

const AdminGroupButton = ({ isSubmit, onDiscard, address }: NFTGroupButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="admin-creation-page-group-button">
      <AppButton
        text={address ? t('admin_management.txt_update_changes') : t('admin_management.txt_save_changes')}
        htmlType="submit"
        disabled={isSubmit}
        loading={isSubmit}
        variant="primary"
      />
      <AppButton text={t('admin_management.txt_cancel')} onClick={onDiscard} disabled={isSubmit} />
    </div>
  );
};

export default AdminGroupButton;
