import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import AppButton from 'components/AppButton';
import PoolList from './components/PoolList';

import LENGTH_CONSTANTS from 'constants/length';
import { routeURLs } from 'constants/routes';
import { NFT_MANAGEMENT_FIELD, NFT_TABS } from 'constants/nft';
import { useGetListPool, useShowHidePool } from './hooks';
import DepositButton from './components/DepositButton';
import ModalConfirm from 'components/Modal/ModalConfirm';

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;
const { KEYWORD, STATUS, PAGE, LIMIT, TYPE } = NFT_MANAGEMENT_FIELD;

const initParams = {
  [KEYWORD]: '',
  [STATUS]: '',
  [PAGE]: DEFAULT_PAGE,
  [LIMIT]: DEFAULT_PAGE_SIZE,
};

const PoolManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { loadingShowHide, onShowHidePool }: any = useShowHidePool();
  const [visibleModalShowPool, setVisibleModalShowPool] = useState<boolean>(false);
  const [dataShowHidePool, setDataShowHidePool] = useState<any>();
  const [poolList, setPoolList] = useState<any>({
    params: { ...initParams },
  });

  const dataList: any = useGetListPool(poolList?.params);

  const handleOpenModalShowPool = () => {
    setVisibleModalShowPool(!visibleModalShowPool)
  }
  const onSuccessShowHidePool = () => {
    handleOpenModalShowPool()
    poolList.params.date = new Date();
    setPoolList({
      ...poolList,
      params: {
        ...poolList?.params,
        date: new Date(),
      },
    });
  };

  const onSuccessDeposit = () => {
    poolList.params.date = new Date();
    setPoolList({
      ...poolList,
      params: {
        ...poolList?.params,
        date: new Date(),
      },
    });
  };
  const handleShowHidePool = (isShow: boolean, row: any) => {
    const data = {
      isShow: isShow,
      row: row,
    };
    handleOpenModalShowPool()
    setDataShowHidePool(data)
  };

  const handleSubmit = (values: any) => {
    setPoolList({
      ...poolList,
      params: {
        ...values,
      },
    });
  };

  const handleRedirectAdminCreationPage = () => history.push(routeURLs.POOL_CREATION);

  const modalConfirmShowHide = useMemo(() => {
    let description = `Once you hide the pool, it will be invisible to your users. Do you want to hide the pool named <b>${dataShowHidePool?.row?.name}<b>`
    let title = 'Hiding Pool'
    if(dataShowHidePool?.isShow){
      description = `Once you show the pool, it will be visible to your users. Do you want to show the pool named <b>${dataShowHidePool?.row?.name}<b>`
      title = 'Showing Pool'
    }
      return (
        <ModalConfirm
          visible={visibleModalShowPool}
          onClose={handleOpenModalShowPool}
          title={title}
          innerHtml
          decsription={description}
          confirmText="Yes"
          onConfirm={()=> onShowHidePool(dataShowHidePool, onSuccessShowHidePool)}
          loading={loadingShowHide}
        />
      );
  }, [ visibleModalShowPool,dataShowHidePool,loadingShowHide]);

  return (
    <div className="nft-management-page">
      <div className="nft-management-page__header">
        <PageHeader title={t('pool_management.txt_pool_management')} />
        <div className="group-button-staking-list">
          <AppButton
            text={`${t('pool_management.txt_create_pool')}`}
            onClick={handleRedirectAdminCreationPage}
            variant="primary"
            className="nft-management-page__button"
          />
          &nbsp;&nbsp;
          <DepositButton onSuccessDeposit={onSuccessDeposit} dataList={dataList}/>
        </div>
      </div>
      <div className="nft-management-page__app-tab">
        <PoolList
          values={poolList}
          onSubmit={handleSubmit}
          loadingShowHide={loadingShowHide}
          handleShowHidePool={handleShowHidePool}
          dataList={dataList}
        />
      </div>
      {modalConfirmShowHide}
    
    </div>
  );
};

export default PoolManagement;
