import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import EllipsisText from 'components/EllipsisText';
import { NftDetailContext } from 'pages/nft-detail';

import WarningIcon from 'resources/svg/warning_icon.svg';

import { getNumberValue } from 'utils';

import { NFT_STATUS } from 'constants/nft';

const WarningBanner = () => {
  const { t } = useTranslation();
  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { saleOrder = {}, token = {}, status } = nftDetail;

  const totalMinted = getNumberValue(token?.totalMinted);
  const totalSupply = getNumberValue(token?.totalSupply);
  const onSaleQuantity = getNumberValue(saleOrder?.quantity);

  const supplyMinusMinted = totalSupply - totalMinted;
  const isShowWarningBanner = status === NFT_STATUS[1].value && saleOrder?.isInvalid;

  return (
    <Fragment>
      {isShowWarningBanner && (
        <div className="warning-banner">
          <img src={WarningIcon} />
          <EllipsisText
            className="warning-banner__text"
            text={t('nft_detail.txt_warning_banner', {
              onSaleQuantity,
              number: supplyMinusMinted,
            })}
          />
        </div>
      )}
    </Fragment>
  );
};

export default WarningBanner;
