import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';

import FormItem, { TYPE_INPUT } from 'components/FormItem';
import { NftDetailContext } from 'pages/nft-detail';

import RefreshIcon from 'resources/svg/refresh_icon.svg';

import { useChangeAddress } from 'hooks/useChangeAddress';
import LENGTH_CONSTANTS from 'constants/length';
import { NFT_MANAGEMENT_FIELD } from 'constants/nft';

const { DEFAULT_PAGE } = LENGTH_CONSTANTS;

const { KEYWORD } = NFT_MANAGEMENT_FIELD;

const initialValues = {
  [KEYWORD]: '',
};

const SearchForm = ({ onSetParams, params }: any) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>(null);

  const { isCompletedMint }: any = useContext(NftDetailContext);

  useEffect(() => {
    if (isCompletedMint) {
      handleResetForm();
    }
  }, [isCompletedMint]);

  const handleInputSearch = (setFieldValue: any) => (value: string) => {
    setFieldValue([KEYWORD], value?.trim());
    onSetParams({
      ...params,
      [KEYWORD]: value?.trim(),
      page: DEFAULT_PAGE,
    });
  };

  const handleSubmit = (values: any) => handleInputSearch(values?.[KEYWORD]) as any;

  const handleResetForm = () => {
    formikRef?.current?.resetForm();
    onSetParams({
      ...params,
      ...initialValues,
      page: DEFAULT_PAGE,
    });
  };

  useChangeAddress({ callback: handleResetForm });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} innerRef={formikRef}>
      {({ setFieldValue }) => (
        <Form className="nft-detail-search-form">
          <Row gutter={20} className="nft-detail-search-form__row">
            <Col lg={10} sm={12} xs={24}>
              <FormItem
                name={KEYWORD}
                placeholder={t('nft_detail.txt_search_form')}
                onSearch={handleInputSearch(setFieldValue)}
                typeInput={TYPE_INPUT.SEARCH}
              />
            </Col>
            <Col lg={10} sm={12} xs={24}>
              <img src={RefreshIcon} onClick={handleResetForm} className="cursor-pointer" />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default SearchForm;
