import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import showMessage from 'components/Message';

import nftServices from 'services/nft';
import { checkSusscessRequest } from 'services/api';

import TYPE_CONSTANTS from 'constants/type';
import { renderRoutes, routeURLs } from 'constants/routes';
import whitelistService from 'services/whitelist';

export const useCreateOrEditNFT = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleCreateNFT = async (data: any, isSellOrder?: boolean) => {
    setLoading(true);
    try {
      const response: any = await nftServices.handleCreateNFT(data);
      if (checkSusscessRequest(response)) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, isSellOrder ? 'message.S5' : 'message.S2');
        history.push(renderRoutes.NFT_DETAIL(response?.data?._id));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleEditNFT = async (data: any, isSellOrder: boolean, id: string) => {
    setLoading(true);
    try {
      const response = await nftServices.handleUpdateNFT(data, id);
      if (checkSusscessRequest(response)) {
        showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, isSellOrder ? 'message.S5' : 'message.S3');
        history.push(renderRoutes.NFT_DETAIL(response?.data?._id));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onCreateNFT: handleCreateNFT,
    onEditNFT: handleEditNFT,
  };
};

export const useGetDetailNFT = (id: string) => {
  const history = useHistory();
  const [nftDetail, setNftDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      handleGetNftDetail(id);
    }
  }, [id]);

  const handleGetNftDetail = async (id: string) => {
    setLoading(true);
    try {
      const response = await nftServices.handleGetNftDetail(id);
      if (checkSusscessRequest(response)) {
        setNftDetail(response?.data?.[0]);
      } else {
        history.push(routeURLs.HOME);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    nftDetail,
    loading,
    onGetNftDetail: handleGetNftDetail,
  };
};

export const useGetListWhiteList = () => {
  const history = useHistory();
  const [listWhiteList, setListWhiteList] = useState();

  useEffect(() => {
    handleGetListWhiteList();
  }, []);

  const handleGetListWhiteList = async () => {
    try {
      const response = await whitelistService.handleGetListWhitelistApi();
      const { docs = [], totalDocs = 0 } = response?.data || {};
      setListWhiteList(docs);
    } catch (error) {
    } finally {
    }
  };

  return {
    listWhiteList,
  };
};
