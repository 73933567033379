import React, { Fragment } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ModalComponent from 'components/Modal';
import AppAddress from 'components/AppAddress';
import AppButton from 'components/AppButton';
import PreviewNFT from 'components/PreviewNFT';
import EllipsisText from 'components/EllipsisText';

import AppIcon from 'resources/svg/app_icon.svg';

import {
  formatCurrency,
  formatDate,
  getAttributeFieldNFTValues,
  getDefaultFieldNFTValues,
  getNumberValue,
} from 'utils';
import { renderRoutes, externalRoutes } from 'constants/routes';
import { NFT_MARKET_CHANNEL } from 'constants/nft';
import AppLoading from 'components/AppLoading';
import { useGetConfig } from 'hooks/useGetConfig';
import { TOKEN_SUPPORTS, TOKEN_SUPPORT_DEFAULT } from 'constants/common';
import AppNumber from 'components/AppNumber';

type RevenueDetailModalProps = {
  visible: boolean;
  onClose?: () => void;
  dataRevenue?: any;
  loading?: boolean;
};

type renderDetailItemProps = {
  label: string;
  value?: number | string;
  prefixIcon?: string;
  currency?: string;
  usd?: number | string;
  address?: string;
  isAdmin?: boolean;
  noShort?: boolean;
  royalties?: number | string;
  className?: string;
};

const RevenueDetailModal = ({ visible, onClose, dataRevenue, loading }: RevenueDetailModalProps) => {
  const { t } = useTranslation();
  const { saleOrder = {}, tokenUserBuy = {} } = dataRevenue;
  const { currencies } = useGetConfig();
  const usdRate = currencies?.find((currency: any) => currency?.name == dataRevenue?.tokenUserBuy?.currency);
  const priceUsd = getNumberValue(dataRevenue?.tokenUserBuy?.price * usdRate?.usd);
  const subTotal = getNumberValue(dataRevenue?.tokenUserBuy?.subTotal);
  const subTotalUsd = getNumberValue(dataRevenue?.tokenUserBuy?.subTotal * usdRate?.usd);
  const isPrimary = saleOrder?.type === NFT_MARKET_CHANNEL[1].value;
  const attributeFieldValues = getAttributeFieldNFTValues(dataRevenue?.nft || {}) as object;
  const defaultFieldValues = getDefaultFieldNFTValues(dataRevenue?.nft || {}) as object;
  const nft = {
    ...defaultFieldValues,
    ...attributeFieldValues,
  };

  const primaryDetailItem = [
    {
      label: 'revenue_management.txt_price',
      value: dataRevenue?.tokenUserBuy?.price,
      prefixIcon: TOKEN_SUPPORTS[dataRevenue?.tokenUserBuy?.currency?.toUpperCase()]?.icon,
      currency: dataRevenue?.tokenUserBuy?.currency?.toUpperCase(),
      usd: priceUsd,
    },
    {
      label: 'revenue_management.txt_subtotal',
      value: subTotal,
      prefixIcon: TOKEN_SUPPORTS[dataRevenue?.tokenUserBuy?.currency?.toUpperCase()]?.icon,
      currency: dataRevenue?.tokenUserBuy?.currency?.toUpperCase(),
      usd: subTotalUsd,
      className: 'line-sale-detail',
    },
    {
      label: 'revenue_management.txt_seller',
      address: dataRevenue?.fromAddress,
      isAdmin: true,
    },
    {
      label: 'revenue_management.txt_buyer',
      address: dataRevenue?.toAddress,
    },
  ];
  const secondaryDetailItem = [
    {
      label: 'revenue_management.txt_price',
      value: saleOrder?.unitPrice,
      prefixIcon: TOKEN_SUPPORTS[tokenUserBuy?.currency?.toUpperCase()]?.icon,
      currency: tokenUserBuy?.currency?.toUpperCase(),
      usd: priceUsd,
    },
    {
      label: 'revenue_management.txt_subtotal',
      value: subTotal,
      prefixIcon: TOKEN_SUPPORTS[tokenUserBuy?.currency?.toUpperCase()]?.icon,
      currency: tokenUserBuy?.currency?.toUpperCase(),
      usd: subTotalUsd,
    },
    {
      label: 'revenue_management.txt_royalties',
      royalties: tokenUserBuy?.royaltyFee,
      value: tokenUserBuy?.royaltyFee,
      prefixIcon: TOKEN_SUPPORTS[tokenUserBuy?.currency?.toUpperCase()]?.icon,
      currency: tokenUserBuy?.currency?.toUpperCase(),
      usd: tokenUserBuy?.royaltyFee,
      className: 'line-sale-detail',
    },
    {
      label: 'revenue_management.txt_token_id',
      address: saleOrder?.tokenId,
      noShort: true,
    },
    {
      label: 'revenue_management.txt_seller',
      address: dataRevenue?.fromAddress,
    },
    {
      label: 'revenue_management.txt_buyer',
      address: dataRevenue?.toAddress,
    },
  ];

  const detailItem = isPrimary ? primaryDetailItem : secondaryDetailItem;

  const renderDetailItem = ({
    label,
    value,
    prefixIcon,
    currency,
    usd,
    address,
    isAdmin,
    noShort,
    royalties,
    className,
  }: renderDetailItemProps) => {
    return (
      <div className={classNames('general', className)}>
        <div className="general__label">
          <EllipsisText text={t(label)} />
          {/* {royalties && <EllipsisText className="royalties-percent" text={`(${royalties}%)`} />} */}
          {royalties && (
            <>
              &nbsp;(
              <EllipsisText
                className="royalties-percent"
                text={<AppNumber value={dataRevenue?.nft?.royaltyFee} isNotFormatDecimal />}
              />
              %&nbsp;)
            </>
          )}
        </div>
        <div className="general__content">
          {AppIcon && (
            <Fragment>
              <div className="content-currency">
                {prefixIcon && <img src={prefixIcon} className="app-icon" />}
                {value && <EllipsisText className="value" text={<AppNumber value={value} isNotFormatDecimal />} />}
                {currency && <EllipsisText text={currency} className="currency" />}
              </div>
              {usd && (
                <EllipsisText
                  className="content-usd"
                  text={<AppNumber value={usd} isNotFormatDecimal isUsd={true} />}
                />
              )}
            </Fragment>
          )}
          {address && (
            <div className="address">
              {isAdmin && <EllipsisText className="address__admin" text={`(${t('common.txt_admin')})`} />}
              {noShort ? <AppAddress address={address} isShorten={false} /> : <AppAddress address={address} />}
            </div>
          )}
        </div>
      </div>
    );
  };
  return (
    <ModalComponent visible={visible} width={796} onClose={onClose}>
      <div className="sale-detail-modal">
        <AppLoading loading={loading}>
          <EllipsisText
            text={
              dataRevenue?.saleOrder?.type === NFT_MARKET_CHANNEL[2].value
                ? t('revenue_management.txt_secondary_sale_details')
                : t('revenue_management.txt_primary_sale_details')
            }
            className="title"
          />
          <EllipsisText text={formatDate(dataRevenue?.createdAt)} className="time-sale" />
          <Row className="sale-detail-modal__row">
            <Col md={10}>
              <PreviewNFT nft={nft} previewSrc={dataRevenue?.nft?.image?.url} code={dataRevenue?.nft?.code} />
            </Col>
            <Col md={14}>
              <div className="general">
                <EllipsisText text={t('revenue_management.txt_quantity')} className="general__quantity" />
                <EllipsisText text={dataRevenue?.quantity} />
              </div>
              {detailItem?.map((item, index) => {
                return <Fragment key={index}>{renderDetailItem(item)}</Fragment>;
              })}
              <div className="general">
                <a href={renderRoutes.NFT_DETAIL(dataRevenue?.nft?.id)} target="_blank" rel="noreferrer">
                  <AppButton text={t('revenue_management.txt_view_nft_detail')} className="button-detail" />
                </a>
                <a href={externalRoutes.BSC_SCAN(dataRevenue?.hash)} target="_blank" rel="noreferrer">
                  <AppButton text={t('revenue_management.txt_on_bscscan')} className="button-bscscan" />
                </a>
              </div>
            </Col>
          </Row>
        </AppLoading>
      </div>
    </ModalComponent>
  );
};

export default RevenueDetailModal;
