import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { ethers } from 'ethers';

import ExchangeABI from 'constants/abi/exchange.json';
import Erc20ABI from 'constants/abi/erc20abi.json';
import { PROXY_ADDRESS } from 'connectors/constants';
import WALLET_STATUS from 'constants/walletStatus';
import { NFT_TRANSACTION_STATUS } from 'constants/nft';
import { MAX_ALLOWANCE, ROLE } from 'constants/common';
import adminServices from 'services/admin';
import { checkSusscessRequest } from 'services/api';
import { routeURLs } from 'constants/routes';
import showMessage from 'components/Message';
import TYPE_CONSTANTS from 'constants/type';
import Web3 from 'web3';
import { convertAddress, convertPriceBigNumber } from 'utils';
import BigNumber from 'bignumber.js';

export function isAddress(address: string) {
  try {
    return getAddress(address);
  } catch {
    return false;
  }
}

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library?.getSigner(account)?.connectUnchecked();
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || isNativeToken(address)) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function isNativeToken(address: string) {
  return address === AddressZero;
}

export default class BaseWalletService {
  address: string | null;
  needTobeInitiated: any;
  initUnit256: any;

  constructor(props: any) {
    this.address = props?.address;
  }

  isAdmin = async (library: any, account: string) => {
    try {
      const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);
      const response = await contract.isAdmin(account);

      return response;
    } catch (error) {
      return false;
    }
  };

  verifyLoginSignature = async ({
    library,
    creator,
    cancelMetamask,
  }: {
    library: any;
    creator: string;
    cancelMetamask: () => void;
  }) => {
    let signVerify: any = null;
    let hasnVerify = null;

    try {
      hasnVerify = ethers.utils.solidityKeccak256(['address'], [creator]);

      const signHashBytes = ethers.utils.arrayify(hasnVerify);

      if (library?.provider?.wc) {
        const wcMessage = ethers.utils.hexlify(signHashBytes);
        signVerify = await library.provider.wc.signPersonalMessage([wcMessage, creator]);
      } else {
        const signer = await library.getSigner(creator);
        signVerify = await signer.signMessage(signHashBytes);
      }
      return signVerify;
    } catch (error: any) {
      console.log('error :>> ', error);
      if (isRejectedMetamask(error)) {
        cancelMetamask && cancelMetamask();
      } else {
      }
    }
  };

  cancelSellOrder = async ({
    account,
    library,
    data,
    onCancelMetamask,
    onCallback,
    onError,
  }: {
    account?: string;
    library?: any;
    data?: Array<any> | any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);

    try {
      const response = await contract.handleCancelOrder(...data);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onCallback && onCallback({ hash: receipt?.transactionHash, status: NFT_TRANSACTION_STATUS.SUCCESS });
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error :>> ', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  mintNFT = async ({
    account,
    library,
    data,
    onCancelMetamask,
    onCallback,
    onError,
  }: {
    account?: string;
    library?: any;
    data?: Array<any> | any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);

    try {
      const response = await contract.handleMintRequestByAdmin(...data);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onCallback && onCallback({ hash: receipt?.transactionHash, status: NFT_TRANSACTION_STATUS.SUCCESS });
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  addAdminContract = async ({
    account,
    library,
    data,
    onCancelMetamask,
    onCallback,
    onError,
    responseCreate,
    dataApi,
    id,
  }: {
    account?: string;
    library?: any;
    data?: any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
    responseCreate: any;
    dataApi: any;
    id: string;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);
    let fnc = '';
    if (data?.role === ROLE.CREATOR_ADMIN.value) {
      fnc = 'setCreatorAdmin';
    }
    if (data?.role === ROLE.SALE_ADMIN.value) {
      fnc = 'setSaleAdmin';
    }
    const address = convertAddress(data?.address);
    try {
      const response = await contract[fnc](address);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          if (onCallback && responseCreate) {
            onCallback({ hash: receipt?.transactionHash, id: responseCreate?._id });
          } else {
            const response = await adminServices.handleUpdateAdmin(dataApi, id);
            if (checkSusscessRequest(response)) {
              onCallback && onCallback({ hash: receipt?.transactionHash, id: response?.data?._id });
            }
          }
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error :>> ', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  revokeAdminContract = async ({
    account,
    library,
    data,
    onCancelMetamask,
    onCallback,
    onError,
    responseCreate,
    dataApi,
    id,
  }: {
    account?: string;
    library?: any;
    data?: any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
    responseCreate: any;
    dataApi: any;
    id: string;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);
    let fnc = '';
    if (data?.role === ROLE.CREATOR_ADMIN.value) {
      fnc = 'revokeCreatorAdmin';
    }
    if (data?.role === ROLE.SALE_ADMIN.value) {
      fnc = 'revokeSaleAdmin';
    }

    const address = convertAddress(data?.address);
    try {
      const response = await contract[fnc](address);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          if (onCallback && responseCreate) {
            onCallback({ hash: receipt?.transactionHash, id: responseCreate?._id });
          } else {
            const response = await adminServices.handleUpdateAdmin(dataApi, id);
            if (checkSusscessRequest(response)) {
              onCallback && onCallback({ hash: receipt?.transactionHash, id: response?.data?._id });
            }
          }
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error :>> ', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  setAdminFee = async ({
    account,
    library,
    data,
    onError,
    onCancelMetamask,
    onCallback,
  }: {
    account?: string;
    library?: any;
    data?: any;
    onCancelMetamask?: () => void;
    onError?: () => void;
    onCallback?: (hash?: any) => void;
    responseCreate: any;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);

    try {
      const response = await contract.setAdminFee(data);

      if (response?.hash) {
        const receipt = await response?.wait();

        if (receipt?.status) {
          onCallback && onCallback(receipt?.transactionHash);
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error :>> ', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  addPoolContract = async ({
    account,
    library,
    dataApi,
    onCancelMetamask,
    onCallback,
    onError,
  }: {
    account?: string;
    library?: any;
    dataApi?: any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);
    const fnc = 'createPool';

    try {
      const dataContract = dataApi?.data?.dataSign;
      const response = await contract[fnc](...dataContract);
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onCallback && onCallback({ hash: receipt?.transactionHash, id: dataApi?.data?.data?.[0]?._id });
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error :>> ', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  depositPoolContract = async ({
    account,
    library,
    amount,
    onCancelMetamask,
    onCallback,
    onError,
  }: {
    account?: string;
    library?: any;
    amount?: any;
    onCancelMetamask?: () => void;
    onCallback?: (hash?: any) => void;
    onError?: () => void;
  }) => {
    const contract = getContract(PROXY_ADDRESS, ExchangeABI.output.abi, library, account);
    const totalReserve = convertPriceBigNumber(amount);
    try {
      const response = await contract.depositFund(totalReserve?.toString());
      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onCallback &&
            onCallback({
              hash: receipt?.transactionHash,
              status: NFT_TRANSACTION_STATUS.SUCCESS,
              totalReserve: totalReserve,
            });
        } else {
          onError && onError();
        }
      }
    } catch (error: any) {
      console.log('error', error);
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  setAllowanceERC20 = async ({
    account,
    library,
    tokenAllowanceERC20,
    onSuccess,
    onError,
    onCancelMetamask,
  }: {
    account?: string;
    library?: any;
    tokenAllowanceERC20: string;
    onCancelMetamask?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
  }) => {
    try {
      const contract = getContract(tokenAllowanceERC20, Erc20ABI.output.abi, library, account);
      const response = await contract.increaseAllowance(PROXY_ADDRESS, MAX_ALLOWANCE);

      if (response?.hash) {
        const receipt = await response.wait();
        if (receipt?.status) {
          onSuccess && onSuccess();
        } else {
          return;
        }
      }
    } catch (error: any) {
      if (isRejectedMetamask(error)) {
        onCancelMetamask && onCancelMetamask();
      } else {
        onError && onError();
      }
    }
  };

  checkDepositApproved = async ({ account, library, contractAddress }: any) => {
    try {
      const contract = getContract(contractAddress, Erc20ABI.output.abi, library, account);
      const response = await contract.allowance(account, PROXY_ADDRESS);
      return new BigNumber(response.toString());
    } catch (e) {
      console.error(e);
      return 0;
    }
  };
}

export function isRejectedMetamask(error: any) {
  return WALLET_STATUS.CANCEL_METAMASK === error?.code || WALLET_STATUS.ACTION_REJECTED === error?.code;
}
