import React from 'react';
import NumberFormat from '../NumberFormat';
import { Tooltip } from 'antd';
import { formatNumberCurrency } from 'utils';

import { NFT_DECIMAL_SCALE } from 'constants/nft';
import { TooltipPlacement } from 'antd/lib/tooltip';

type AppNumberProps = {
  value: number | any;
  decimalScale?: number;
  className?: string;
  suffix?: string;
  isNotFormatDecimal?: boolean;
  minDecimal?: number;
  isUsd?: boolean;
  placement?: TooltipPlacement;
  isRenderPreText?: boolean;
};

const AppNumber = ({
  value,
  className,
  decimalScale = NFT_DECIMAL_SCALE,
  isNotFormatDecimal,
  minDecimal,
  isUsd,
  placement,
  isRenderPreText = true,
  ...props
}: AppNumberProps) => {
  const data: any = formatNumberCurrency(value, { minDecimal });
  const renderPreTextCurrency = () => {
    let preTextCurrency = '';
    if (isUsd) {
      preTextCurrency = '~ $ ';
    }
    if (data?.isDecimalSmall) {
      preTextCurrency = '< ';
    }
    return preTextCurrency;
  };
  return (
    <Tooltip
      placement={placement}
      title={<NumberFormat thousandSeparator value={value} displayType="text" decimalScale={30} {...props} />}
    >
      <span>
        {isRenderPreText && renderPreTextCurrency()}
        <NumberFormat thousandSeparator value={data?.result} displayType="text" decimalScale={30} {...props} />
      </span>
    </Tooltip>
  );
};

export default AppNumber;
