import React from 'react';

function EyeInvisibleIcon(props: any) {
  return (
    <svg width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.102 14.3696C15.0717 14.3696 16.0017 14.0328 16.6874 13.4333C17.3731 12.8339 17.7583 12.0208 17.7583 11.173C17.7583 11.0793 17.7534 10.9866 17.7442 10.895L13.784 14.3574C13.8888 14.3653 13.9946 14.3696 14.102 14.3696ZM26.2043 1.28498L24.8097 0.0668242C24.7607 0.0240358 24.6943 0 24.6251 0C24.5558 0 24.4894 0.0240358 24.4405 0.0668242L20.8717 3.18786C18.9029 2.3082 16.6899 1.86838 14.2326 1.86838C7.95812 1.86838 3.27677 4.7254 0.188512 10.4394C0.0643789 10.6693 0 10.9202 0 11.1744C0 11.4285 0.0643789 11.6795 0.188512 11.9093C1.42251 14.1818 2.90842 16.0033 4.64624 17.3736L1.19268 20.3919C1.14374 20.4347 1.11625 20.4928 1.11625 20.5533C1.11625 20.6139 1.14374 20.6719 1.19268 20.7147L2.58632 21.9332C2.63529 21.976 2.70169 22 2.77093 22C2.84016 22 2.90656 21.976 2.95554 21.9332L26.2043 1.60808C26.2286 1.58687 26.2478 1.56169 26.261 1.53397C26.2741 1.50625 26.2809 1.47654 26.2809 1.44653C26.2809 1.41652 26.2741 1.38681 26.261 1.35909C26.2478 1.33137 26.2286 1.30619 26.2043 1.28498ZM8.35639 11.173C8.3563 10.3053 8.61326 9.45243 9.10224 8.69741C9.59121 7.9424 10.2955 7.31098 11.1466 6.86468C11.9976 6.41838 12.9664 6.17241 13.9585 6.15074C14.9506 6.12907 15.9322 6.33243 16.8076 6.74101L15.2204 8.12871C14.5803 7.94951 13.8961 7.92793 13.2429 8.06633C12.5897 8.20473 11.9927 8.49777 11.5174 8.91329C11.0422 9.32881 10.707 9.85076 10.5487 10.4219C10.3904 10.993 10.4151 11.5912 10.62 12.1508L9.03281 13.5385C8.58739 12.8109 8.35501 11.9982 8.35639 11.173Z"
        fill="#8B8EA9"
      />
      <path
        d="M28.8074 10.578C27.634 8.50226 26.2372 6.80962 24.6173 5.50012L19.8121 9.53642C20.2172 10.4258 20.3069 11.3946 20.0706 12.3259C19.8343 13.2571 19.2819 14.111 18.4803 14.7843C17.6787 15.4576 16.6621 15.9216 15.5533 16.1201C14.4446 16.3186 13.2911 16.2432 12.2323 15.9029L8.15649 19.3263C10.0474 20.0612 12.1505 20.4287 14.4659 20.4287C20.8732 20.4287 25.6537 17.6258 28.8074 12.0201C28.9342 11.7946 29 11.5484 29 11.2991C29 11.0497 28.9342 10.8035 28.8074 10.578Z"
        fill="#8B8EA9"
      />
    </svg>
  );
}

export default EyeInvisibleIcon;
