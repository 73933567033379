import React from 'react';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';

import { columns } from './columns';
import Table from 'components/Table';

import { setOrderSorter, getRowKey } from 'utils';
import { SorterTable } from 'types';
import LENGTH_CONSTANTS from 'constants/length';
import { REVENUE_FIELD } from 'constants/revenue';

const { FIELD, ORDER, PAGE } = REVENUE_FIELD;

const { DEFAULT_PAGE } = LENGTH_CONSTANTS;

type listSaleProps = {
  total?: number | any;
  listSale?: any;
  limit: number;
  page: number;
  loading?: boolean;
  params?: any;
  listColumn?: any;
  handleChangePaging?: (page: number, limit: number) => void;
  onSetParams: (values: any) => void;
};

const List = ({
  total,
  listSale,
  loading,
  limit,
  page,
  params,
  listColumn,
  handleChangePaging,
  onSetParams,
}: listSaleProps) => {
  const { t } = useTranslation();

  const handleChangeTable = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    _extra: TableCurrentDataSource<any>,
  ) => {
    const { order, field } = sorter as SorterTable;
    
    const newOrder = setOrderSorter(order);

    const dataFilter: any = {
      ...params,
      [FIELD]: field,
      [PAGE]: DEFAULT_PAGE,
    };

    if (newOrder) {
      dataFilter.order = newOrder;
    } else {
      delete dataFilter.order;
      delete dataFilter.field;
    }

    onSetParams(dataFilter);
  };

  const selectedColumns = columns(t, page, limit).filter((column) => listColumn.includes(column.key));

  return (
    <Table
      total={total}
      columns={selectedColumns}
      dataSource={listSale}
      loading={loading}
      pageSize={limit}
      current={page}
      onChangePagination={handleChangePaging}
      onChangeTable={handleChangeTable}
      rowKey={getRowKey}
    />
  );
};

export default List;
