import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import EllipsisText from 'components/EllipsisText';
import { NftDetailContext } from 'pages/nft-detail';

import { formatDate } from 'utils';
import { NFT_STATUS } from 'constants/nft';

const General = () => {
  const { t } = useTranslation();

  const { nftDetail = {} } = useContext(NftDetailContext) as any;

  const { name, createdAt, status, description } = nftDetail;
  const curStatus = NFT_STATUS.find((item) => item?.value === status) as any;

  return (
    <Fragment>
      <EllipsisText text={name} className="nft-detail-info__name" />
      <p className="nft-detail-info__date">
        {t('nft_detail.txt_created_at')} {formatDate(createdAt)}
      </p>
      <div className={classNames('nft-detail-info__status view-nft-status', `view-nft-status-${curStatus?.value}`)}>
        {t(curStatus?.name)}
      </div>
      <div className="nft-detail-info__description">{description}</div>
    </Fragment>
  );
};

export default General;
