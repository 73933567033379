import React from 'react';
import { TFunction } from 'react-i18next';

import EllipsisText from 'components/EllipsisText';

export const columns = (
  t: TFunction,
  page: number,
  limit: number,
  handleEditWhiteList: (id: string) => any,
  handleDeleteWhiteList: (id: string) => any,
  isRefreshTable?: any,
  sortedInfo?: any,
) => [
  {
    title: t('nft_management.txt_no'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 50,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('whitelist.txt_whitelist_name'),
    dataIndex: 'name',
    key: 'name',
    width: 100,
    sorter: true,
    ellipsis: true,
    sortOrder: isRefreshTable ? null : sortedInfo.columnKey === 'name' && sortedInfo.order,
  },
  {
    title: t('whitelist.txt_number_of_address'),
    dataIndex: 'numberOfAddresses',
    key: 'numberOfAddresses',
    width: 75,
    sorter: true,
    ellipsis: true,
    sortOrder: isRefreshTable ? null : sortedInfo.columnKey === 'numberOfAddresses' && sortedInfo.order,
    render: (value: any, row: any) => {
      return <EllipsisText text={row?.userAddresses?.length} />;
    },
  },
  {
    title: t('nft_management.txt_action'),
    dataIndex: '_id',
    key: '_id',
    width: 75,
    render: (value: string) => {
      return (
        <div className="action-column">
          <span onClick={handleEditWhiteList(value)}>Edit</span>
          <span onClick={handleDeleteWhiteList(value)}>Delete</span>
        </div>
      );
    },
  },
];
