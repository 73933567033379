import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';

import AppButton from 'components/AppButton';
import ModalStep from 'components/Modal/ModalStep';
import ModalConfirm from 'components/Modal/ModalConfirm';
import { NftDetailContext } from 'pages/nft-detail';
import { useCreateTransaction, useUpdateTransaction } from 'pages/nft-detail/hooks';

import selectedAddress from 'redux/address/selector';
import { useAppSelector } from 'hooks/useStore';
import { useSocket } from 'hooks/useSocket';

import MetamaskService from 'services/account/MetamaskService';
import { SOCKET_EVENT, ZERO_VALUE } from 'constants/common';
import { NFT_TRANSACTION_TYPE } from 'constants/nft';

const REMOVE_FROM_SALE_STEPS = {
  PROCESSING: 1,
  SUCCESSFUL: 2,
  FAILED: 3,
};

const { PROCESSING, SUCCESSFUL, FAILED } = REMOVE_FROM_SALE_STEPS;

const RemoveFromSaleButton = ({ visiblity }: { visiblity?: boolean }) => {
  const { t } = useTranslation();
  const { library } = useWeb3React();
  const { nftDetail = {}, onGetNftDetail } = useContext(NftDetailContext) as any;

  const { address } = useAppSelector(selectedAddress.getAddress);
  const { saleOrder = {} } = nftDetail;

  const [visible, setVisible] = useState(false);
  const [transactionId, setTransactionId] = useState('') as any;
  const [stepRemoveFromSale, setStepRemoveFromSale] = useState(ZERO_VALUE);
  const [isCompletedRemoveFromSale, setIsCompletedRemoveFromSale] = useState(false);

  const failed = FAILED === stepRemoveFromSale;
  const successful = SUCCESSFUL === stepRemoveFromSale;
  const processing = PROCESSING === stepRemoveFromSale;

  const { onCreateTransaction } = useCreateTransaction();
  const { onUpdateTransaction } = useUpdateTransaction();

  const handleToggleRemoveFormSaleNFT = () => setVisible(!visible);

  const handleUpdateRemoveFromSaleStep = (value: number) => setStepRemoveFromSale(value);

  const handleCloseRemoveFromSaleModal = () => handleUpdateRemoveFromSaleStep(ZERO_VALUE);

  const handleRemoveFromSaleError = () => handleUpdateRemoveFromSaleStep(FAILED);

  const handleDisplayRemoveFromSaleSuccess = () => {
    handleUpdateRemoveFromSaleStep(SUCCESSFUL);
    onGetNftDetail(nftDetail?._id);
  };

  const handleConfirmRemoveFromSaleSuccess = () => {
    if (!isCompletedRemoveFromSale) {
      setIsCompletedRemoveFromSale(true);
      handleDisplayRemoveFromSaleSuccess();
    }
  };

  const handleCompleteRemoveFromSale = (transactionId: string, data?: any) => {
    onUpdateTransaction(transactionId, data, {
      onError: handleRemoveFromSaleError,
      onSuccess: handleConfirmRemoveFromSaleSuccess,
    });
  };

  const handleEvent = (data: any) => {
    if (!isCompletedRemoveFromSale && data?.transactionId === transactionId) {
      handleDisplayRemoveFromSaleSuccess();
    }
  };

  useSocket({
    event: SOCKET_EVENT.REMOVE_FROM_SALE,
    handleEvent,
    dependences: [transactionId, isCompletedRemoveFromSale],
  });

  const handleApproveRemoveFromSale = async (transactionId: string, data: any) => {
    setTransactionId(transactionId);
    const wallet = new MetamaskService().getInstance();
    await wallet.cancelSellOrder({
      account: address,
      library,
      data,
      onCallback: (data: any) => handleCompleteRemoveFromSale(transactionId, data),
      onCancelMetamask: handleCloseRemoveFromSaleModal,
      onError: handleRemoveFromSaleError,
    });
  };

  const handleStartRemoveFromSale = () => {
    setStepRemoveFromSale(REMOVE_FROM_SALE_STEPS.PROCESSING);
    handleToggleRemoveFormSaleNFT();
    onCreateTransaction(
      {
        type: NFT_TRANSACTION_TYPE.DELISTED,
        transactionId: saleOrder?.transactionId,
      },
      {
        onSuccess: (id: string, data: any) => handleApproveRemoveFromSale(id, data),
        onError: handleRemoveFromSaleError,
      },
    );
  };

  return (
    <Fragment>
      {visiblity && (
        <AppButton
          text={t('nft_detail.txt_remove_from_sale')}
          variant="primary"
          onClick={handleToggleRemoveFormSaleNFT}
        />
      )}

      <ModalConfirm
        visible={visible}
        onClose={handleToggleRemoveFormSaleNFT}
        onConfirm={handleStartRemoveFromSale}
        noCancel
        title={t('nft_detail.txt_remove_from_sale')}
        innerHtml
        className="nft-detail-page-remove-from-sale-modal"
        decsription={t('nft_detail.txt_remove_from_sale_desciption', { name: nftDetail?.name })}
        confirmText={t('nft_detail.txt_proceed_to_remove')}
      />

      <ModalStep
        visible={ZERO_VALUE !== stepRemoveFromSale}
        failed={failed}
        successful={successful}
        processing={processing}
        showCloseIcon={failed || successful}
        maskClosable={failed || successful}
        onFailedClose={handleCloseRemoveFromSaleModal}
        onSuccessfulClose={handleCloseRemoveFromSaleModal}
        textSuccessfulDescription={t('nft_detail.txt_remove_from_sale_message', { name: nftDetail?.name })}
        innerHtml
      />
    </Fragment>
  );
};

export default RemoveFromSaleButton;
