import { FC } from 'react';

import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import SuccessIcon from 'resources/svg/success.svg';
import { Button } from 'antd';
import AppButton from 'components/AppButton';

const ModalVerifySucces: FC<{ visible: boolean; onClose: any; onCreateMultipleNft: any; wrapClassName?: any }> = ({
  visible,
  onClose,
  onCreateMultipleNft,
  wrapClassName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible} onClose={onClose} width={565} wrapClassName={wrapClassName}>
      <div className="modal-verify-bulk">
        <div className="modal-verify-bulk__title">{t('nftBulk.verifySuccess')}</div>
        <div className="modal-verify-bulk__desc">{t('nftBulk.verifySuccessDesc')}</div>
        <img className="modal-verify-bulk__icon" src={SuccessIcon} />
        <div className="modal-verify-bulk__button">
          <AppButton
            className="button--third small"
            onClick={onCreateMultipleNft}
            variant="primary"
            text={t('nftBulk.startCreateBulk')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalVerifySucces;
