import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import moment from 'moment';

import noop from 'lodash/noop';

import AppLoading from 'components/AppLoading';
import PageContent from 'components/PageContent';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import Chart from './Chart';

import { useGetSaleAnalytics } from 'pages/home/hooks';

import {
  DASHBOARD_CURRENCY,
  DASHBOARD_SALE_ANALYTICS,
  DASHBOARD_DATA_TYPES,
  DASHBOARD_TIME_TYPES,
  DASHBOARD_TIME_FORMAT,
} from 'constants/nft';
import { formatDate, getDuration } from 'utils';

const { TIME_TYPE, DATA_TYPE, CURRENCY } = DASHBOARD_SALE_ANALYTICS;
const { SELECT } = TYPE_INPUT;
const { HOUR, MONTH_DAY, MONTH_YEAR, TOOLTIP, YEAR } = DASHBOARD_TIME_FORMAT;

const initialValues = {
  [CURRENCY]: DASHBOARD_CURRENCY[0].value,
  [DATA_TYPE]: DASHBOARD_DATA_TYPES[0].value,
  [TIME_TYPE]: DASHBOARD_TIME_TYPES[0].value,
};

const SaleAnalytics = () => {
  const { t } = useTranslation();

  const [params, setParams] = useState(initialValues);

  const { loading, saleAnalytics = [] } = useGetSaleAnalytics(params) as any;

  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);

  const createChartData = (chartParams: any) => {
    console.log('chartParams :>> ', chartParams);
    const labels: any = [];
    const data = saleAnalytics?.map((sale: any) => {
      const obj: any = {
        tooltip: formatDate(sale?._id, TOOLTIP),
      };

      // label
      const timeType = chartParams[DASHBOARD_SALE_ANALYTICS.TIME_TYPE];
      switch (true) {
        case timeType === DASHBOARD_TIME_TYPES[1].value:
          obj.label = formatDate(sale?._id, HOUR) + 'h';
          break;
        case timeType === DASHBOARD_TIME_TYPES[2].value || timeType === DASHBOARD_TIME_TYPES[3].value:
          obj.label = formatDate(sale?._id, MONTH_DAY);
          break;
        case timeType === DASHBOARD_TIME_TYPES[4].value || timeType === DASHBOARD_TIME_TYPES[5].value:
          obj.label = formatDate(sale?._id, MONTH_YEAR);
          break;

        default:
          const from = moment(saleAnalytics[0]._id);
          const until = moment(saleAnalytics[saleAnalytics.length - 1]._id);
          const duration = getDuration(from, until);

          switch (true) {
            case duration.asHours() < 24:
              obj.label = formatDate(sale?._id, HOUR) + 'h';
              break;
            case duration.asMonths() <= 1:
              obj.label = formatDate(sale?._id, MONTH_DAY);
              break;
            case duration.asMonths() > 1 && duration.asMonths() <= 12:
              obj.label = formatDate(sale?._id, MONTH_YEAR);
              break;
            default:
              obj.label = formatDate(sale?._id, YEAR);
              break;
          }
          break;
      }

      // label display
      if (labels.indexOf(obj.label) === -1) {
        labels.push(obj.label);
      }

      // value
      const currency = chartParams[DASHBOARD_SALE_ANALYTICS.CURRENCY];
      const dataType = chartParams[DASHBOARD_SALE_ANALYTICS.DATA_TYPE];

      const totalValue = dataType === DASHBOARD_DATA_TYPES[0].value ? sale?.totalVolume : sale?.totalRevenue;
      const totalValueUSD = dataType === DASHBOARD_DATA_TYPES[0].value ? sale?.totalVolumeUsd : sale?.totalRevenueUsd;

      obj.value = currency === DASHBOARD_CURRENCY[1].value ? (obj.value = totalValueUSD) : (obj.value = totalValue);

      return obj;
    });
    setChartData(data);
    setChartLabels(labels);
  };

  useEffect(() => {
    if (saleAnalytics) {
      createChartData(params);
    }
  }, [saleAnalytics]);

  const dashboardDataTypeOptions = DASHBOARD_DATA_TYPES.map((dataType) => ({
    ...dataType,
    name: t(dataType?.name),
  }));

  const dashboardTimeTypeOptions = DASHBOARD_TIME_TYPES.map((dataType) => ({
    ...dataType,
    name: t(dataType?.name),
  }));

  const handleFieldChange = (setFieldValue: any, field: string) => (val: any) => {
    setFieldValue(field, val);
    setParams({
      ...params,
      [field]: val,
    });
    if (field !== TIME_TYPE) {
      createChartData({
        ...params,
        [field]: val,
      });
    }
  };

  return (
    <PageContent className="h-full">
      <div className="dashboad-sale-analytics">
        <Formik initialValues={initialValues} onSubmit={noop}>
          {({ setFieldValue }) => {
            return (
              <Form>
                <div className="search">
                  <span className="title">{t('dashboard.txt_sale_analytics')}</span>
                  <div className="filter">
                    <FormItem
                      name={CURRENCY}
                      typeInput={SELECT}
                      options={DASHBOARD_CURRENCY}
                      onChange={handleFieldChange(setFieldValue, CURRENCY)}
                    />
                    <FormItem
                      name={DATA_TYPE}
                      typeInput={SELECT}
                      options={dashboardDataTypeOptions}
                      onChange={handleFieldChange(setFieldValue, DATA_TYPE)}
                    />
                    <FormItem
                      name={TIME_TYPE}
                      typeInput={SELECT}
                      options={dashboardTimeTypeOptions}
                      onChange={handleFieldChange(setFieldValue, TIME_TYPE)}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        <AppLoading loading={loading}>
          <Chart data={chartData} labels={chartLabels} />
        </AppLoading>
      </div>
    </PageContent>
  );
};

export default SaleAnalytics;
