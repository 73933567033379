import { useEffect, useState } from 'react';
import { omit } from 'lodash';

import selectedAddress from 'redux/address/selector';

import { useAppSelector } from 'hooks/useStore';
import { ORDERS } from 'utils';
import { NFT_MANAGEMENT_FIELD } from 'constants/nft';
import { checkSusscessRequest } from 'services/api';
import whitelistService from 'services/whitelist';

const { SORT } = NFT_MANAGEMENT_FIELD;

const { ASC, DESC, FIELD, ORDER } = ORDERS;

export const useGetListWhiteList = (params: any) => {
  const { address } = useAppSelector(selectedAddress.getAddress);

  const [listWhiteList, setListWhiteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const INDEXED_SORTER: any = {
    name: { name: DESC },
    numberOfAddresses: { numberOfAddresses: DESC },
  };

  useEffect(() => {
    handleGetListWhiteList();
  }, [params, address]);

  const handleGetListWhiteList = async () => {
    setLoading(true);
    try {
      const newParams = omit({ ...params }, [FIELD, ORDER]) as any;
      const field = params?.[FIELD];

      for (const key in INDEXED_SORTER?.[field]) {
        if (key === field && params?.[ORDER] && params?.[FIELD]) {
          newParams[`${SORT}[${key}]`] = params?.[ORDER];
        } else {
          newParams[`${SORT}[${key}]`] = INDEXED_SORTER?.[field]?.[key];
        }
      }
      const response = await whitelistService.handleGetListWhitelistApi(newParams);
      const { docs = [], totalDocs = 0 } = response?.data || {};
      setListWhiteList(docs);
      setTotal(totalDocs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    data: listWhiteList,
    loading,
    total,
  };
};
export const useUploadWhitelist = () => {
  const handleUploadWhitelist = async (data: any, onSuccess: any, onFinally: any) => {
    data.name = data?.name?.replace(/\s+/g, ' ').trim();
    try {
      let response;
      if (data?.id) {
        response = await whitelistService.handleEditWhitelistApi(data);
      } else {
        response = await whitelistService.handleUploadWhitelistApi(data);
      }

      if (checkSusscessRequest(response)) {
        onSuccess(data?.id);
        onFinally();
      } else {
      }
    } catch (error) {
    } finally {
      onFinally();
    }
  };

  return {
    onUploadWhitelist: handleUploadWhitelist,
  };
};

export const useGetDetailWhiteList = () => {
  const [whitelistDetail, setWhiteListDetail] = useState<any>();
  const handleGetDetailWhiteList = async (
    id: string,
    handleToggleCreateWhitelist: () => any,
    setLoading?: (loading: boolean) => void,
  ) => {
    try {
      setLoading && setLoading(true);
      handleToggleCreateWhitelist();
      const response = await whitelistService.handleGetDetailWhitelistApi(id);
      if (checkSusscessRequest(response)) {
        setWhiteListDetail(response?.data);
        setLoading && setLoading(false);
      } else {
        setLoading && setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading && setLoading(false);
    }
  };
  return { whitelistDetail, handleGetDetailWhiteList };
};

export const useDeleteWhiteList = () => {
  const onDeleteWhiteList = async (id: string, onSuccessDelete: () => any, onFinallyDelete: any) => {
    try {
      const response = await whitelistService.handleGetDeleteWhitelistApi(id);
      if (checkSusscessRequest(response)) {
        onSuccessDelete();
      } else {
      }
    } catch (error) {
    } finally {
      onFinallyDelete();
    }
  };
  return { onDeleteWhiteList };
};
