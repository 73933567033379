import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppTab from 'components/AppTab';
import PageHeader from 'components/PageHeader';
import Primary from './components/Primary';
import Secondary from './components/Secondary';
import ExportButton from './components/ExportButton';

import { REVENUE_TABS } from 'constants/revenue';

const RevenueManagement = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(REVENUE_TABS.PRIMARY.key);

  const listTab = [
    {
      key: REVENUE_TABS.PRIMARY.key,
      tab: t(REVENUE_TABS.PRIMARY.label),
      content: <Primary />,
    },
    {
      key: REVENUE_TABS.SECONDARY.key,
      tab: t(REVENUE_TABS.SECONDARY.label),
      content: <Secondary />,
    },
  ];

  const handleChangeTab = (value: string) => setActiveTab(value);

  return (
    <div className="revenue-management-page">
      <div className="revenue-management-page__header">
        <PageHeader title={t('revenue_management.txt_manage_revenue')} />
        <ExportButton />
      </div>
      <div className="revenue-management-page__body">
        <AppTab onChangeTab={handleChangeTab} activeKey={activeTab} listTab={listTab} />
      </div>
    </div>
  );
};

export default RevenueManagement;
