import { api } from 'services/api';

class ConfigServices {
  handleGetConfig = () => {
    return api.get('config');
  };

  handleSetFeeRate = (data: any) => {
    return api.post('config', data);
  };
}

const configServices = new ConfigServices();

export default configServices;
