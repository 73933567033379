import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ModalComponent from 'components/Modal';
import AppButton from 'components/AppButton';

type ModalConfirmProps = {
  visible: boolean;
  onClose?: () => void;
  onConfirm?: any;
  title?: any;
  decsription?: any;
  confirmText?: any;
  afterClose?: any;
  className?: any;
  innerHtml?: boolean;
  noCancel?: boolean;
  loading?: boolean;
  wrapClassName?: any;
  isShowButton?: boolean;
};

const ModalConfirm = ({
  visible,
  onClose,
  onConfirm,
  title,
  decsription,
  confirmText,
  className,
  innerHtml,
  noCancel,
  loading,
  isShowButton = true,
  ...props
}: ModalConfirmProps) => {
  const { t } = useTranslation();

  return (
    <ModalComponent visible={visible} width={550} onClose={onClose} {...props}>
      <div className={classNames('confirm-modal', className)}>
        <div className="title">{title}</div>
        {innerHtml ? (
          <div className="description" dangerouslySetInnerHTML={{ __html: decsription }} />
        ) : (
          <div className="description">{decsription}</div>
        )}
        {isShowButton &&
          (noCancel ? (
            <AppButton
              className="button-proceed"
              onClick={onConfirm}
              text={confirmText || t('common.txt_delete')}
              variant="primary"
              loading={loading}
              disabled={loading}
            />
          ) : (
            <div className="confirm-modal__button-group">
              <AppButton className="btn-close" onClick={onClose} text={t('common.txt_cancel')} disabled={loading} />

              <AppButton
                className="button-confirm"
                onClick={onConfirm}
                text={confirmText || t('common.txt_delete')}
                variant="primary"
                disabled={loading}
                loading={loading}
              />
            </div>
          ))}
      </div>
    </ModalComponent>
  );
};

export default ModalConfirm;
