import React, { ReactNode } from 'react';
import { Col } from 'antd';

import ItemWithLabel from 'components/ItemWithLabel';

type InfoItemProps = {
  label?: string;
  children?: ReactNode;
  helpText?: string;
};

export type InfoItemType = {
  label: string;
  value: ReactNode;
  type?: string;
  prefix?: string;
  helpText?: string;
};

const InfoItem = ({ label, children, helpText }: InfoItemProps) => {
  return (
    <Col lg={6} md={12} sm={12}>
      <ItemWithLabel label={label} className="nft-detail-info__item" helpText={helpText}>
        {children}
      </ItemWithLabel>
    </Col>
  );
};

export default InfoItem;
