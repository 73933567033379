import React from 'react';

import General from './General';
import SaleOrder from './SaleOrder';
import Attribute from './Attribute';
import Whitelist from './Whitelist';

const Info = () => {
  return (
    <div className="nft-detail-info">
      <General />
      <SaleOrder />
      <Attribute />
      <Whitelist />
    </div>
  );
};

export default Info;
