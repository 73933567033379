import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import { omitBy } from 'lodash';

import FormItem, { TYPE_INPUT } from 'components/FormItem';

import RefreshIcon from 'resources/svg/refresh_icon.svg';

import { useChangeAddress } from 'hooks/useChangeAddress';
import { ORDERS } from 'utils';
import { NFTParamsTypes } from 'pages/nft-management';
import LENGTH_CONSTANTS from 'constants/length';
import { NFT_STATUS, NFT_MANAGEMENT_FIELD, STATUS_NFT } from 'constants/nft';

const { KEYWORD, STATUS, PAGE } = NFT_MANAGEMENT_FIELD;
const { DEFAULT_PAGE } = LENGTH_CONSTANTS;
const { FIELD, ORDER } = ORDERS;

type SearchFormProps = {
  onSubmit: (values: any) => void;
  params: NFTParamsTypes;
};

const initialValues = {
  [KEYWORD]: '',
  [STATUS]: null,
};

const SearchForm = ({ onSubmit, params }: SearchFormProps) => {
  const { t } = useTranslation();
  const formRef = useRef<any>(null);

  const formatStatusOptions = STATUS_NFT.map((status) => ({
    ...status,
    name: t(status?.name),
  }));

  const handleChangeField =
    (setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, field: string) =>
    (value: string) => {
      setFieldValue(field, value?.trim());
      onSubmit({
        ...params,
        [field]: value?.trim(),
        [PAGE]: DEFAULT_PAGE,
      });
    };

  const handleResetForm = () => {
    formRef?.current?.resetForm();
    onSubmit({
      ...omitBy(params, [FIELD, ORDER]),
      ...initialValues,
      [PAGE]: DEFAULT_PAGE,
    });
  };

  useChangeAddress({ callback: handleResetForm });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
      {({ setFieldValue }) => (
        <Form className="search-form nft-management-search-form">
          <Row gutter={20} className="nft-management-search-form__row">
            <Col lg={8} sm={12} xs={24}>
              <FormItem
                name={KEYWORD}
                className="search-form__name--input"
                placeholder={t('nft_management.txt_search_placeholder')}
                onSearch={handleChangeField(setFieldValue, KEYWORD)}
                typeInput={TYPE_INPUT.SEARCH}
              />
            </Col>
            <Col lg={6} sm={12} xs={24}>
              <FormItem
                placeholder={t('nft_management.txt_status_placeholder')}
                options={formatStatusOptions}
                name={STATUS}
                onChange={handleChangeField(setFieldValue, STATUS)}
                typeInput={TYPE_INPUT.SELECT}
                allowClear
              />
            </Col>
            <Col lg={10} sm={24} xs={24} className="nft-management-search-form__group-button">
              <img src={RefreshIcon} onClick={handleResetForm} className="cursor-pointer" />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SearchForm;
