import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { number, object, string, ref, date } from 'yup';
import ModalConfirm from 'components/Modal/ModalConfirm';

import { externalRoutes } from 'constants/routes';
import { Divider, Tooltip } from 'antd';
import ModalStep from 'components/Modal/ModalStep';
import { ZERO_VALUE } from 'constants/common';
import { REMOVE_FROM_SALE_STEPS } from 'constants/nft';
import AppButton from 'components/AppButton';
import MetamaskService from 'services/account/MetamaskService';
import { useWeb3React } from '@web3-react/core';
import { useAppSelector } from 'hooks/useStore';
import selectedAddress from 'redux/address/selector';
import { convertPriceBigNumber, limitMaxlengNumber } from 'utils';
import showMessage from 'components/Message';
import TYPE_CONSTANTS from 'constants/type';
import NumberFormat from 'components/NumberFormat';
import { useUpdateTransactionDeposit } from 'pages/pool-management/hooks';
import AppNumber from 'components/AppNumber';
import { Formik, Form } from 'formik';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import { getPoolSchema } from 'utils/schema';
import { validateEdition } from 'pages/pool-creation/components/Utils';
import HelpIcon from 'resources/svg/help_icon.svg';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
const { PROCESSING, SUCCESSFUL, FAILED } = REMOVE_FROM_SALE_STEPS;

const DepositButton = ({ onSuccessDeposit, dataList }: any) => {
  const { t } = useTranslation();
  const wallet = new MetamaskService().getInstance();
  const formikRef = useRef(null) as any;
  const history = useHistory();
  const { library } = useWeb3React();
  const [visible, setVisible] = useState(false);
  const [stepDeposit, setStepDeposit] = useState(ZERO_VALUE);
  const [isNeedApproveCurrency, setIsNeedApproveCurrency] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [transactionHash, setTransactionHash] = useState<string>('');
  const { onUpdateTransactionDeposit } = useUpdateTransactionDeposit();
  const failed = FAILED === stepDeposit;
  const successful = SUCCESSFUL === stepDeposit;
  const processing = PROCESSING === stepDeposit;
  const { address } = useAppSelector(selectedAddress.getAddress);
  const [depositAmount, setDepositAmount] = useState<number>(0);
  const totalReward = Number(dataList?.summary?.totalReward);
  const totalReserve = Number(dataList?.summary?.totalReserve);
  const totalRewardClaimed = Number(dataList?.summary?.totalRewardClaimed);
  const requiredReward = new BigNumber(totalReward).minus(
    new BigNumber(totalReserve).plus(new BigNumber(totalRewardClaimed)),
  );

  const initFormValue = { depositAmount: '' };

  const handleIsCheckDepositApproved = async () => {
    const response = await wallet.checkDepositApproved({
      account: address,
      library,
      contractAddress: process.env.REACT_APP_PUBLIC_TOKEN_BCN,
    });
    const value = convertPriceBigNumber(depositAmount);
    // setIsNeedApproveCurrency(response == 0 || value.gt(response));
    setIsNeedApproveCurrency(response == 0);
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      handleIsCheckDepositApproved();
    }
  }, [visible, depositAmount]);

  const handleToggleDepositPool = () => setVisible(!visible);

  const handleUpdateDepositStep = (value: number) => setStepDeposit(value);

  const handleCloseDepositModal = () => {
    handleUpdateDepositStep(ZERO_VALUE);
  };

  const handleDepositedError = () => {
    handleToggleDepositPool();
    handleUpdateDepositStep(FAILED);
  };

  const handleDepositedSuccess = () => {
    handleUpdateDepositStep(SUCCESSFUL);
    onSuccessDeposit();
  };

  const handleCompleteDeposited = (data: any) => {
    setTransactionHash(data?.hash);
    onUpdateTransactionDeposit(data, {
      onError: handleDepositedError,
      onSuccess: handleDepositedSuccess,
    });
  };

  const handleApproveCurrencySuccess = () => {
    setStepDeposit(ZERO_VALUE);
    showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, t('message.S18'));
    setVisible(true);
    // handleToggleDepositPool();
  };
  const handleCancleApproveMemask = () => {
    setStepDeposit(ZERO_VALUE);
    handleToggleDepositPool();
  };

  const handleApproveCurrencyFailed = () => {
    handleUpdateDepositStep(ZERO_VALUE);
    showMessage(TYPE_CONSTANTS.MESSAGE.ERROR, t('message.E31'));
  };

  const onDepositPool = async (depositAmount: any) => {
    setStepDeposit(PROCESSING);
    setDepositAmount(depositAmount);
    handleToggleDepositPool();
    if (isNeedApproveCurrency) {
      await wallet.setAllowanceERC20({
        account: address,
        library,
        tokenAllowanceERC20: process.env.REACT_APP_PUBLIC_TOKEN_BCN,
        onSuccess: handleApproveCurrencySuccess,
        onError: handleApproveCurrencyFailed,
        onCancelMetamask: handleCancleApproveMemask,
      });
    } else {
      await wallet.depositPoolContract({
        account: address,
        library,
        amount: depositAmount,
        onCancelMetamask: handleCloseDepositModal,
        onCallback: (data: any) => handleCompleteDeposited(data),
        onError: handleDepositedError,
      });
    }
  };

  const onSubmit = (e: any) => {
    onDepositPool(e.depositAmount);
  };

  const depositSchema = () => {
    return object({
      depositAmount: string()
        .trim()
        .required(t('message.E5', { field: 'Deposit Amount' })),
    });
  };
  const onChangeValue = (e: any) => {
    setDepositAmount(e?.target?.value);
  };
  const contentDeposit = () => {
    return (
      <>
        <div className="deposit-attribute">
          <div className="attribute-title">Total Rewards</div>
          <div>
            <AppNumber value={totalReward} isNotFormatDecimal />
          </div>
        </div>
        <div className="deposit-attribute">
          <div className="attribute-title">Total Reserve</div>
          <div>
            <AppNumber value={totalReserve} isNotFormatDecimal />
          </div>
        </div>
        <div className="deposit-attribute">
          <div className="attribute-title">Claimed Rewards</div>
          <div>
            <AppNumber value={totalRewardClaimed} isNotFormatDecimal />
          </div>
        </div>
        <Divider />
        <div className="deposit-attribute">
          <div className="attribute-title-result">
            Required Rewards{' '}
            <Tooltip title={'The amount that you need to reward your stakers'} overlayClassName="tooltip-detail">
              <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
            </Tooltip>
          </div>
          <div className="attribute-title">
            <AppNumber
              value={requiredReward?.isGreaterThan(new BigNumber(0)) ? requiredReward?.toString() : 0}
              isNotFormatDecimal
            />{' '}
            BCN
          </div>
        </div>

        <div className="deposit-form">
          <div className="deposit-amount">
            {isNeedApproveCurrency ? (
              <div className="text-color-red">
                Please approve the smart contract before depositing <br></br>
              </div>
            ) : (
              <div className="deposit-amount-text">Deposit Amount </div>
            )}
            <Formik
              innerRef={formikRef}
              initialValues={initFormValue}
              onSubmit={onSubmit}
              validationSchema={!isNeedApproveCurrency && depositSchema}
            >
              {({ values }: any) => {
                return (
                  <Form>
                    {!isNeedApproveCurrency && (
                      <FormItem
                        name={'depositAmount'}
                        required
                        placeholder="Deposit Amount"
                        typeInput={TYPE_INPUT.NUMBER}
                        thousandSeparator
                        isAllowed={limitMaxlengNumber(12)}
                        validate={(e: any) => validateEdition(e)}
                        onChange={onChangeValue}
                      />
                    )}

                    <br></br>
                    {isNeedApproveCurrency && (
                      <>
                        <br></br>
                        <br></br>
                      </>
                    )}

                    <AppButton
                      htmlType="submit"
                      className="button-confirm"
                      text={isNeedApproveCurrency ? 'Approve' : 'Deposit'}
                      variant="primary"
                      disabled={loading}
                      loading={loading}
                    />
                    <br></br>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        <br></br>
      </>
    );
  };

  const renderDepositSuccessText = useMemo(
    () => (
      <div>
        <div>
          You have successfully deposited <b>{depositAmount}</b> to the address of{' '}
          <b>{process.env.REACT_APP_PUBLIC_PROXY_CONTRACT}</b>
        </div>
        <a href={externalRoutes.BSC_SCAN(transactionHash)} target="_blank" rel="noreferrer">
          <AppButton text={t('nft_detail.txt_view_on_bscscan')} className="description__button" />
        </a>
      </div>
    ),
    [transactionHash, depositAmount],
  );

  return (
    <Fragment>
      <div className="deposit-button" onClick={handleToggleDepositPool}>
        Deposit
      </div>
      <ModalConfirm
        visible={visible}
        onClose={handleToggleDepositPool}
        title="Deposit Rewards"
        noCancel={true}
        decsription={contentDeposit()}
        confirmText={isNeedApproveCurrency ? 'Approve' : 'Deposit'}
        onConfirm={onDepositPool}
        loading={loading}
        className="deposit-modal"
        isShowButton={false}
      />
      <ModalStep
        visible={ZERO_VALUE !== stepDeposit}
        failed={failed}
        successful={successful}
        processing={processing}
        showCloseIcon={failed || successful}
        maskClosable={failed || successful}
        onFailedClose={handleCloseDepositModal}
        onSuccessfulClose={handleCloseDepositModal}
        successfulDescription={renderDepositSuccessText}
        successfulTitle="Successful Deposit"
        failedTitle="Unsuccessful Deposit"
        failedDescription="Your deposit was unsuccessful. Please try again later"
        innerHtml
      />
    </Fragment>
  );
};

export default DepositButton;
