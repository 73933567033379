import { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatNumberCurrency, getNumberValue } from 'utils';

type ChartProps = {
  data?: Array<any>;
  labels?: Array<any>;
};

const CustomTooltip = (params: any) => {
  const { active, payload } = params;
  if (active && payload && payload.length) {
    const tooltip = payload[0].payload.tooltip;
    return (
      <div className="chart-tooltip">
        <p className="label">{`${tooltip}`}</p>
        <p>
          Value: <b>{getNumberValue(payload[0].value)}</b>
        </p>
      </div>
    );
  }

  return null;
};

const Chart = ({ data, labels }: ChartProps) => {
  return (
    <ResponsiveContainer className="chart">
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" ticks={labels} />
        <YAxis
          type="number"
          tickFormatter={(value) => {
            const data = formatNumberCurrency(value, { minDecimal: 8 });
            if (data.isDecimalSmall) {
              return `< ${data?.result}`;
            } else {
              return `${data?.result}`;
            }
          }}
          domain={[0, 'auto']}
          allowDecimals={true}
          width={100}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Chart;
