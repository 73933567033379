import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Col, Row } from 'antd';

import AppButton from 'components/AppButton';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import NumberFormat from 'components/NumberFormat';

import { useGetConfig } from 'hooks/useGetConfig';
import { limitMaxlengNumber } from 'utils';
import { DOLLAR_TEXT, TOKEN_SUPPORTS, TOKEN_SUPPORT_DEFAULT } from 'constants/common';
import {
  MAX_LENGTH_TOTAL_SUPPLY,
  NFT_DECIMAL_SCALE,
  NFT_DECIMAL_SCALE_PRICE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_DEFAULT_PRICE,
  NFT_POSITIVE_SCALE,
} from 'constants/nft';
import { NftType } from 'connectors/constants';

const { QUANTITY, UNIT_PRICE } = NFT_DEFAULT_CREATE_FIELD;

type SaleOrderFormProps = {
  maxOnSaleQuantity: number;
  nftDetail: any;
  formikRef: any;
};

const SaleOrderForm = ({ maxOnSaleQuantity, nftDetail, formikRef }: SaleOrderFormProps) => {
  const { t } = useTranslation();
  const { weaponCurrency, landCurrency, currencies } = useGetConfig();
  const { values, setFieldValue } = useFormikContext() as any;

  const unitPrice = values?.[UNIT_PRICE];
  const unitCurrency = useMemo(() => {
    if (currencies) {
      return currencies.find((value: any) => value.symbol === formikRef?.current?.values?.currency)?.usd;
    }
  }, [formikRef?.current?.values?.currency, currencies]);

  const whitelistPrice = useMemo(() => {
    const currentPrice = Number(formikRef?.current?.values?.[UNIT_PRICE]);
    const discountValue = Number(nftDetail?.whitelist?.discountValue);
    return (currentPrice * (100 - discountValue)) / 100;
  }, [formikRef?.current?.values?.[UNIT_PRICE]]);

  const handleSetMaxQuantity = () => setFieldValue(QUANTITY, maxOnSaleQuantity);

  const selectAfter = useMemo(() => {
    if (nftDetail?.attributes?.type?.text === NftType.WEAPON && weaponCurrency) {
      return weaponCurrency?.map((value: any) => {
        return {
          label: value?.symbol,
          value: value?.symbol,
          disabled: value?.symbol === TOKEN_SUPPORTS.BNB.symbol ? false : true,
        };
      });
    } else if (nftDetail?.attributes?.type?.text === NftType.LAND && landCurrency) {
      return landCurrency?.map((value: any) => {
        return {
          label: value?.symbol,
          value: value?.symbol,
          disabled: value?.symbol === TOKEN_SUPPORTS.BNB.symbol ? false : true,
        };
      });
    }
  }, [nftDetail?.attributes?.type?.text, weaponCurrency]);
  return (
    <Row gutter={60}>
      <Col span={24}>
        <FormItem
          typeInput={TYPE_INPUT.NUMBER}
          name={QUANTITY}
          placeholder={t('nft_list_for_sale.txt_on_sale_quantity_placeholder')}
          required
          thousandSeparator
          decimalScale={NFT_POSITIVE_SCALE}
          description={t('nft_list_for_sale.txt_on_sale_quantity_description')}
          label={t('nft_list_for_sale.txt_on_sale_quantity')}
          isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
          appendInput={
            <AppButton
              text={t('nft_list_for_sale.txt_max')}
              className="field__button"
              onClick={handleSetMaxQuantity}
              variant="primary"
            />
          }
        />
      </Col>
      <Col span={24}>
        <FormItem
          typeInput={TYPE_INPUT.NUMBER}
          name={UNIT_PRICE}
          thousandSeparator
          placeholder={t('nft_list_for_sale.txt_listing_price_placeholder')}
          required
          className="content__listing"
          decimalScale={NFT_DECIMAL_SCALE_PRICE}
          description={t('nft_list_for_sale.txt_listing_price_description')}
          label={t('nft_list_for_sale.txt_listing_price')}
          isAllowed={limitMaxlengNumber()}
          addonAfter={
            <FormItem
              typeInput={TYPE_INPUT.SELECT}
              name={NFT_DEFAULT_CREATE_FIELD.CURRENCY}
              defaultValue={TOKEN_SUPPORT_DEFAULT.value}
              options={selectAfter}
            ></FormItem>
          }
        >
          <p className="content__price">
            {unitPrice && unitCurrency ? (
              <>
                ~ $
                <NumberFormat
                  thousandSeparator
                  value={unitPrice * unitCurrency}
                  displayType="text"
                  decimalScale={NFT_DECIMAL_SCALE}
                />
              </>
            ) : (
              NFT_DEFAULT_PRICE
            )}
          </p>
        </FormItem>
      </Col>
      {nftDetail?.whitelist?.discountValue && (
        <Col span={24}>
          <div className="whitelist-price">
            <p>WhiteList Price</p>
            <p className="content__price">
              {unitPrice && unitCurrency ? (
                <>
                  ~ $
                  <NumberFormat
                    thousandSeparator
                    value={whitelistPrice * unitCurrency}
                    displayType="text"
                    decimalScale={NFT_DECIMAL_SCALE}
                  />
                </>
              ) : (
                NFT_DEFAULT_PRICE
              )}
            </p>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default SaleOrderForm;
