const WhitelistIcon = (props: any) => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11 3H14C15.1046 3 16 3.89543 16 5V17C16 18.1046 15.1046 19 14 19H2C0.895431 19 0 18.1046 0 17V5C0 3.89543 0.895431 3 2 3H5C5 1.34315 6.34315 0 8 0C9.65685 0 11 1.34315 11 3ZM9 3C9 3.55228 8.55229 4 8 4C7.44772 4 7 3.55228 7 3C7 2.44772 7.44772 2 8 2C8.55229 2 9 2.44772 9 3ZM2 7C2 6.44772 2.44772 6 3 6H13C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8H3C2.44772 8 2 7.55228 2 7ZM3 10C2.44772 10 2 10.4477 2 11C2 11.5523 2.44772 12 3 12H13C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10H3ZM3 14C2.44772 14 2 14.4477 2 15C2 15.5523 2.44772 16 3 16H6C6.55228 16 7 15.5523 7 15C7 14.4477 6.55228 14 6 14H3Z" fill="#6FCF97"/>
    </svg>
  );
};

export default WhitelistIcon;

