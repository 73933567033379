import { number, object, string, ref, date } from 'yup';
import { TFunction } from 'react-i18next';

import {
  MAX_LENGTH_DESCRIPTION,
  MAX_VALUE_ROYALTY_FEE,
  MAX_VALUE_TOTAL_COPIES,
  MIN_VALUE_TOTAL_COPIES,
  NFT_ATTRIBUTE_CREATED_FIELD,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_MEDIA,
  NFT_MINTED_FIELD,
} from 'constants/nft';
import { isAddress } from 'utils';
import { NftType } from 'connectors/constants';
import { SETTING_FEE_RATE_MAX, SETTING_FIELD } from 'constants/setting';
import moment from 'moment';
import { PUBLISH_DATE } from 'constants/common';

const {
  NAME,
  ROYALTYFEE,
  IS_PUT_ON_SALE,
  TOTAL_SUPPLY,
  QUANTITY,
  UNIT_PRICE,
  DESCRIPTION,
  FILE,
  FILE_PREVIEW,
  WHITELIST_NAME,
  DISCOUNT,
  NUMBER_OF_ITEM,
  NUMBER_OF_PUCHASE,
  IS_PUT_WHITELIST,
  START_TIME,
  END_TIME,
} = NFT_DEFAULT_CREATE_FIELD;
const { DPS, ACCURACY, FIRING_RATE, MOBILITY, RANGE, TYPE, MAP, RARITY, TYPEOFWEAPON } = NFT_ATTRIBUTE_CREATED_FIELD;
const { TO_ADDRESS } = NFT_MINTED_FIELD;

const isNumberGreaterThanZero = (value: any) => {
  if (value == '.') return true;
  const data = Number(value) > 0 ? true : false;
  return data;
};
const checkRoyalties = (value: any) => {
  if (Number(value) > 50) {
    return false;
  } else {
    return true;
  }
};

const checkDiscount = (value: any) => {
  if (Number(value) > 100) {
    return false;
  } else {
    return true;
  }
};
const checkDiscountGreaterThan0 = (value: any) => {
  if (Number(value) <= 0) {
    return false;
  } else {
    return true;
  }
};

const checkMaxTotalSupply = (totalSupply: number, totalItemPurchases: number) => {
  if (totalItemPurchases > totalSupply) {
    return false;
  } else {
    return true;
  }
};

const checkStartTimeAndEndTime = (startTime: any, endTime: any) => {
  const start = moment(startTime)?.unix();
  const end = moment(endTime)?.unix();
  if (start > end) {
    return false;
  }
  return true;
};

const checkTimeCurrent = (time: any) => {
  if (moment(time)?.unix() < moment()?.unix()) {
    return false;
  }
  return true;
};

const isSameFeeRate = (value: any, serviceFee: any) => {
  if (Number(value) === Number(serviceFee)) return false;
  return true;
};

export const getNftSchemaUpdateIPFS = (t: TFunction) => {
  return object({
    [FILE]: object().shape({
      previewContent: string().required(t('message.E5', { field: t('nft_creation.txt_nft_content') })),
    }),
    [FILE_PREVIEW]: object().when(FILE, {
      is: (val: any) => {
        return val?.fileList?.[0]?.type?.split('/')?.[0] !== NFT_MEDIA.IMAGE;
      },
      then: object().shape({
        previewContent: string().required(t('message.E5', { field: t('nft_creation.txt_nft_preview') })),
      }),
    }),
  });
};

export const getNftSchema = (t: TFunction, formikRef: any) => {
  return object({
    [FILE]: object().shape({
      previewContent: string().required(t('message.E5', { field: t('nft_creation.txt_nft_content') })),
    }),
    [FILE_PREVIEW]: object().when(FILE, {
      is: (val: any) => {
        return val?.fileList?.[0]?.type?.split('/')?.[0] !== NFT_MEDIA.IMAGE;
      },
      then: object().shape({
        previewContent: string().required(t('message.E5', { field: t('nft_creation.txt_nft_preview') })),
      }),
    }),
    [NAME]: string()
      .trim()
      .required(t('message.E5', { field: t('nft_creation.txt_name') })),

    [TYPE]: string()
      .nullable()
      .required(t('message.E5', { field: t('nft_creation.txt_type') })),

    [TOTAL_SUPPLY]: number()
      .required(t('message.E5', { field: t('nft_creation.txt_total_supply') }))
      .min(MIN_VALUE_TOTAL_COPIES, t('message.E6'))
      .max(MAX_VALUE_TOTAL_COPIES, t('message.E4')),

    [ROYALTYFEE]: string()
      .required(t('message.E5', { field: t('nft_creation.txt_royalties') }))
      .test('isNumberGreaterThanZero', t('message.E6'), (value: any) => {
        return isNumberGreaterThanZero(value);
      })
      .test('checkRoyalties', t('message.E3', { number: MAX_VALUE_ROYALTY_FEE }), (value: any) => {
        return checkRoyalties(value);
      }),

    [DESCRIPTION]: string().max(MAX_LENGTH_DESCRIPTION, t('message.E13')),

    [`${DPS}Input`]: number()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.WEAPON) {
            return true;
          }
        },
        then: number().required(t('message.E5', { field: 'DPS' })),
      }),

    [`${ACCURACY}Input`]: number()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.WEAPON) {
            return true;
          }
        },
        then: number().required(t('message.E5', { field: 'Accuracy' })),
      }),

    [`${FIRING_RATE}Input`]: number()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.WEAPON) {
            return true;
          }
        },
        then: number().required(t('message.E5', { field: 'Firing Rate' })),
      }),

    [`${RANGE}Input`]: number()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.WEAPON) {
            return true;
          }
        },
        then: number().required(t('message.E5', { field: 'Range' })),
      }),

    [MAP]: string()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.LAND) {
            return true;
          }
        },
        then: string()
          .nullable()
          .required(t('message.E5', { field: t('nft_creation.txt_map') })),
      }),

    [TYPEOFWEAPON]: string()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.WEAPON) {
            return true;
          }
        },
        then: string()
          .nullable()
          .required(t('message.E5', { field: t('nft_creation.txt_type_of_weapon') })),
      }),

    [RARITY]: string()
      .nullable()
      .when(`${TYPE}`, {
        is: (value: string) => {
          if (value === NftType.WEAPON) {
            return true;
          }
        },
        then: string()
          .nullable()
          .required(t('message.E5', { field: t('nft_creation.txt_rarity') })),
      }),

    [QUANTITY]: number().when(IS_PUT_ON_SALE, {
      is: (value: boolean) => !!value,
      then: number()
        .positive(t('message.E6'))
        .required(t('message.E5', { field: t('nft_creation.txt_onsale_quantity') }))
        .min(MIN_VALUE_TOTAL_COPIES, t('message.E6'))
        .max(MAX_VALUE_TOTAL_COPIES, t('message.E4'))
        .when(TOTAL_SUPPLY, {
          is: (value: any) => !!value,
          then: number().max(ref(TOTAL_SUPPLY), (value) => t('message.E11', { number: value?.max })),
        }),
    }),
    [UNIT_PRICE]: string().when(`${IS_PUT_ON_SALE}`, {
      is: (value: boolean) => !!value,
      then: string()
        .required(t('message.E5', { field: t('nft_creation.txt_listing_price') }))
        .test('isNumberGreaterThanZero', t('message.E6'), (value: any) => {
          return isNumberGreaterThanZero(value);
        }),
    }),

    [WHITELIST_NAME]: string()
      .nullable()
      .when(IS_PUT_WHITELIST, {
        is: (value: boolean) => {
          return !!value;
        },
        then: string()
          .nullable()
          .trim()
          .required(t('message.E5', { field: t('nft_creation.txt_search_whitelist_placeholder') })),
      }),

    [DISCOUNT]: string().when(IS_PUT_WHITELIST, {
      is: (value: boolean) => !!value,
      then: string()
        .nullable()
        .required(t('message.E5', { field: t('nft_creation.txt_discount') }))
        .test('checkDiscount', t('message.E28', { number: 100 }), (value: any) => {
          return checkDiscount(value);
        })
        .test('checkDiscountGreaterThan0', t('message.E6', { number: 0 }), (value: any) => {
          return checkDiscountGreaterThan0(value);
        }),
    }),

    [NUMBER_OF_ITEM]: string().when(NUMBER_OF_PUCHASE, {
      is: (value: boolean) => !!value,
      then: string()
        .nullable()
        .required(t('message.E5', { field: t('nft_creation.txt_limit_number_purchase') }))
        .test('checkMaxTotalSupply', t('message.E29'), (value: any) => {
          const totalSupply = formikRef?.current?.values?.totalSupply;
          return checkMaxTotalSupply(Number(totalSupply), Number(value));
        })
        .test('checkDiscountGreaterThan0', t('message.E6', { number: 0 }), (value: any) => {
          return checkDiscountGreaterThan0(value);
        }),
    }),

    [START_TIME]: string()
      .nullable()
      .when(IS_PUT_WHITELIST, {
        is: (value: boolean) => {
          return !!value;
        },
        then: string()
          .nullable()
          .trim()
          .required(t('message.E5', { field: t('nft_creation.whitelist_start_time') }))
          .test('checkStartTimeAndEndTime', t('message.E37'), (value: any) => {
            const endTime = formikRef?.current?.values?.endTime;
            return checkStartTimeAndEndTime(value, endTime);
          })
          .test('checkTimeCurrent', t('message.E36'), (value: any) => {
            return checkTimeCurrent(value);
          }),
      }),

    [END_TIME]: string()
      .nullable()
      .when(IS_PUT_WHITELIST, {
        is: (value: boolean) => {
          return !!value;
        },
        then: string()
          .nullable()
          .trim()
          .required(t('message.E5', { field: t('nft_creation.whitelist_end_time') }))
          .test('checkStartTimeAndEndTime', t('message.E35'), (value: any) => {
            const startTime = formikRef?.current?.values?.startTime;
            return checkStartTimeAndEndTime(startTime, value);
          })
          .test('checkTimeCurrent', t('message.E38'), (value: any) => {
            return checkTimeCurrent(value);
          }),
      }),
  });
};

export const getListForSaleSchema = (t: TFunction, maxQuantity: number) =>
  object().shape({
    [QUANTITY]: number()
      .positive(t('message.E6'))
      .required(t('message.E5', { field: t('nft_list_for_sale.txt_on_sale_quantity') }))
      .max(
        maxQuantity,
        t('message.E11', {
          number: maxQuantity,
        }),
      ),
    [UNIT_PRICE]: string()
      // .positive(t('message.E6'))
      .required(t('message.E5', { field: t('nft_list_for_sale.txt_listing_price') }))
      .test('isNumberGreaterThanZero', t('message.E6'), (value: any) => {
        return isNumberGreaterThanZero(value);
      }),
  });

export const getMintSchema = (t: TFunction, maxQuantity: number) =>
  object().shape({
    [QUANTITY]: number()
      .positive(t('message.E6'))
      .required(t('message.E5', { field: t('nft_detail.txt_mint_quantity') }))
      .max(
        maxQuantity,
        t('message.E88', {
          number: maxQuantity,
        }),
      ),
    [TO_ADDRESS]: string()
      .trim()
      .required(t('message.E5', { field: t('nft_detail.txt_recipient_wallet_address') }))
      .test('isAddress', t('message.E16'), (value: any) => {
        return isAddress(value);
      }),
  });

export const getExportModalSchema = (t: TFunction) => {
  return object({
    from: date()
      .nullable()
      .required(t('message.E5', { field: 'Start Date' })),
    until: date()
      .nullable()
      .required(t('message.E5', { field: 'End Date' })),
  });
};

export const getAdminSchema = (t: TFunction) => {
  return object({
    name: string()
      .trim()
      .required(t('message.E5', { field: 'Admin Name' })),

    address: string()
      .trim()
      .required(t('message.E5', { field: 'Wallet Address' }))
      .test('isAddress', t('message.E16'), (value: any) => {
        return isAddress(value);
      }),
  });
};

export const getPoolSchema = (t: TFunction, formikRef: any) => {
  return object({
    name: string()
      .trim()
      .required(t('message.E5', { field: 'Pool Name' })),
    duration: string()
      .nullable()
      .trim()
      .required(t('message.E5', { field: 'Duration Lock' })),
    endTimeStaking: string()
      .nullable()
      .required(t('message.E5', { field: 'End Time For Staking' })),
    endTimePool: string()
      .nullable()
      .required(t('message.E5', { field: 'End Time For Pool' })),
    editionLimit: string().required(t('message.E5', { field: 'Editions Limit ' })),
    amount: string().required(t('message.E5', { field: 'Total Rewards' })),
  });
};

export const getSettingFeeRateSchema = (t: TFunction, serviceFee: any) => {
  return object({
    [SETTING_FIELD.FEE_RATE]: number()
      .required(t('message.E5', { field: 'Fee Rate' }))
      .max(SETTING_FEE_RATE_MAX, t('renting_message.E1'))
      .test('isNumberGreaterThanZero', t('message.E6'), (value: any) => {
        return isNumberGreaterThanZero(value);
      })
      .test('sameFeeRate', t('renting_message.E2'), (value: any) => {
        return isSameFeeRate(value, serviceFee);
      }),
  });
};
