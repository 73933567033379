import React, { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Spin } from 'antd';

import Modal from 'components/Modal';
import LoadingIcon from 'components/LoadingIcon';

import WrongNetworkIcon from 'resources/images/wrong_network_icon.png';

import selectedConnection from 'redux/connection/selector';

import { useAppSelector } from 'hooks/useStore';
import { NETWORK_NAMES, SupportedChainId } from 'connectors/constants';
import { setupNetwork } from 'utils/wallet';

const { Paragraph } = Typography;

type ModalWrongNetworkProps = {};

const ModalWrongNetwork: FC<ModalWrongNetworkProps> = () => {
  const { t } = useTranslation();

  const { isWrongNetwork } = useAppSelector(selectedConnection.getConnection);

  const targetChainId = SupportedChainId.BSC;

  useEffect(() => {
    if (isWrongNetwork) {
      const switchNetwork = async () => {
        if (targetChainId) {
          await setupNetwork(targetChainId);
        }
      };

      switchNetwork();
    }
  }, [isWrongNetwork]);

  return (
    <Modal visible={isWrongNetwork} maskClosable={false} showCloseIcon={false} destroyOnClose>
      <div className="wrong-network-modal">
        <Spin size="large" indicator={<LoadingIcon src={WrongNetworkIcon} />} />
        <Paragraph className="title">{t('common.txt_wrong_network_title')}</Paragraph>
        <p
          dangerouslySetInnerHTML={{
            __html: t('common.txt_wrong_network_content', { networkName: NETWORK_NAMES.BSC }),
          }}
          className="sub-title"
        />
      </div>
    </Modal>
  );
};

export default ModalWrongNetwork;
