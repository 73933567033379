import { useEffect, useState } from 'react';
import omit from 'lodash/omit';

import selectedAddress from 'redux/address/selector';
import { useAppSelector } from 'hooks/useStore';

import transactionServices from 'services/transaction';
import { checkSusscessRequest } from 'services/api';

import { getEndDateTimestamp, getStartDateTimestamp, ORDERS } from 'utils';

import { ZERO_VALUE } from 'constants/common';
import { REVENUE_FIELD, REVENUE_FIELD_SORTER } from 'constants/revenue';

const { FROM, UNTIL, SORT } = REVENUE_FIELD;

const { DEFAULT, CREATED_AT, NFT_ID, NFT_NAME, QUANTITY, UNIT_PRICE, SUB_TOTAL, REVENUE } = REVENUE_FIELD_SORTER;

const { ASC, DESC, FIELD, ORDER } = ORDERS;

export const useGetRevenue = (params?: any) => {
  const { address } = useAppSelector(selectedAddress.getAddress);

  const [total, setTotal] = useState(ZERO_VALUE);
  const [listRevenues, setListRevenues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSale, setDataSale] = useState([]);

  const INDEXED_SORTER = {
    [DEFAULT]: { [CREATED_AT]: DESC },
    [CREATED_AT]: { [CREATED_AT]: DESC },
    [NFT_ID]: { [NFT_ID]: ASC, [CREATED_AT]: DESC },
    [NFT_NAME]: { [NFT_NAME]: ASC, [CREATED_AT]: DESC },
    [QUANTITY]: { [QUANTITY]: DESC, [UNIT_PRICE]: ASC, [CREATED_AT]: DESC },
    [UNIT_PRICE]: { [UNIT_PRICE]: ASC, [CREATED_AT]: DESC },
    [SUB_TOTAL]: { [SUB_TOTAL]: ASC, [CREATED_AT]: DESC },
    [REVENUE]: { [REVENUE]: ASC, [CREATED_AT]: DESC },
  };

  useEffect(() => {
    handleGetListRevenues(params);
  }, [params, address]);

  const handleGetListRevenues = async (params?: any) => {
    setLoading(true);
    try {
      const newParams = omit({ ...params }, [ORDERS.FIELD, ORDERS.ORDER]) as any;
      newParams.from = getStartDateTimestamp(params?.[FROM]);
      newParams.until = getEndDateTimestamp(params?.[UNTIL]);
      const field = params?.[FIELD] || DEFAULT;

      for (const key in INDEXED_SORTER?.[field]) {
        if (key === field && params?.[ORDER] && params?.[FIELD]) {
          newParams[`${SORT}[${key}]`] = params?.[ORDER];
        } else {
          newParams[`${SORT}[${key}]`] = INDEXED_SORTER?.[field]?.[key];
        }
      }
      const response = await transactionServices.handleGetTransaction(newParams);
      const { docs = [], totalDocs = 0, summary = [] } = response?.data || {};

      setListRevenues(docs);
      setTotal(totalDocs);
      setDataSale(summary);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleExportRevenue = async (params: any, { onError, onSuccess }: any) => {
    try {
      const newParams = { ...params } as any;
      newParams.from = params?.[FROM] && getStartDateTimestamp(params?.[FROM].startOf(params?.[FROM]));
      newParams.until = params?.[UNTIL] && getEndDateTimestamp(params?.[UNTIL].endOf(params?.[UNTIL]));
      const response = await transactionServices.handleGetTransaction(newParams);
      if (checkSusscessRequest(response)) {
        const { docs = [] } = response?.data || {};
        onSuccess && onSuccess(docs);
      } else {
        onError && onError();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { data: listRevenues, total, loading, dataSale, onExportRevenue: handleExportRevenue };
};

export const useGetTransactionDetail = () => {
  const [revenueDetail, setRevenueDetail] = useState({}) as any;
  const [loading, setLoading] = useState(false);

  const handleGetTransactionDetail = async (id: string) => {
    setLoading(true);
    try {
      const response = await transactionServices.handleGetTransactionDetail(id);
      if (checkSusscessRequest(response)) {
        setRevenueDetail(response?.data?.[0]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return { loading, onGetTransactionDetail: handleGetTransactionDetail, revenueDetail };
};
