import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ModalConfirm from 'components/Modal/ModalConfirm';
import showMessage from 'components/Message';
import { useDeleteNFT } from 'pages/nft-detail/hooks';
import { NftDetailContext } from 'pages/nft-detail';

import { routeURLs } from 'constants/routes';
import TYPE_CONSTANTS from 'constants/type';

const DeleteButton = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { nftDetail = {} }: any = useContext(NftDetailContext) as any;
  const [visible, setVisible] = useState(false);

  const handleToggleDeleteNFT = () => setVisible(!visible);

  const handleSuccessulDeletion = () => {
    history.push(routeURLs.NFT);
    handleToggleDeleteNFT();
    showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, 'message.S4');
  };

  const handleErrorDeletion = () => {
    history.push(routeURLs.NFT);
    handleToggleDeleteNFT();
  };

  const { loading, onDeleteNFT } = useDeleteNFT(nftDetail?._id, {
    onSuccess: handleSuccessulDeletion,
    onError: handleErrorDeletion,
  });

  return (
    <Fragment>
      <div className="item" onClick={handleToggleDeleteNFT}>
        {t('nft_detail.txt_delete_nft')}
      </div>
      <ModalConfirm
        visible={visible}
        onClose={handleToggleDeleteNFT}
        noCancel
        title={t('nft_detail.txt_delete_nft')}
        innerHtml
        className="nft-detail-page-delete-modal"
        decsription={t('nft_detail.txt_delete_nft_description', { name: nftDetail?.name })}
        confirmText={t('nft_detail.txt_proceed_to_delete')}
        onConfirm={onDeleteNFT}
        loading={loading}
      />
    </Fragment>
  );
};

export default DeleteButton;
