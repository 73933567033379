import { useEffect, useState } from 'react';
import moment from 'moment';
import omit from 'lodash/omit';

import transactionServices from 'services/transaction';
import { checkSusscessRequest } from 'services/api';

import { getEndDateTimestamp, getStartDateTimestamp } from 'utils';
import { DASHBOARD_TIME_TYPES } from 'constants/nft';

export const useGetSaleAnalytics = (params?: any) => {
  const [saleAnalytics, setSaleAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetSaleAnalytics();
  }, [params?.timeType]);

  const handleGetSaleAnalytics = async () => {
    setLoading(true);

    const newParams = { ...params } as any;

    if (params?.timeType && DASHBOARD_TIME_TYPES[0].value !== params?.timeType) {
      const today = moment();
      newParams.from = getStartDateTimestamp(today.startOf(params?.timeType));
      newParams.to = getEndDateTimestamp(today.endOf(params?.timeType));
    }

    try {
      const response = await transactionServices.handleGetSaleAnalytics(newParams);
      if (checkSusscessRequest(response)) {
        setSaleAnalytics(response?.data || []);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    saleAnalytics,
    loading,
  };
};

export const useGetDashboardOverview = (params?: any) => {
  const [overviewInfo, setOverviewInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetDashboardOverview();
  }, [params]);

  const handleGetDashboardOverview = async () => {
    const newParams = { ...params } as any;
    if (params?.timeType && DASHBOARD_TIME_TYPES[0].value !== params?.timeType) {
      const today = moment();
      newParams.from = getStartDateTimestamp(today.startOf(params?.timeType));
      newParams.to = getEndDateTimestamp(today.endOf(params?.timeType));
    }

    setLoading(true);
    try {
      const response = await transactionServices.handleGetOverview(newParams);
      if (checkSusscessRequest(response)) {
        setOverviewInfo(response?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    overviewInfo,
    loading,
  };
};

export const useGetTransactionOverview = () => {
  const [listTransaction, setListTransaction] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetDashboardOverview();
  }, []);

  const handleGetDashboardOverview = async () => {
    setLoading(true);
    try {
      const response = await transactionServices.handleGetTransaction();
      if (checkSusscessRequest(response)) {
        const { docs = [] } = response?.data;
        setListTransaction(docs);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    data: listTransaction,
    loading,
  };
};

export const useGetTopNfts = (params?: any) => {
  const [listTopNfts, setListTopNfts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetTopNftsOverview();
  }, []);

  const handleGetTopNftsOverview = async () => {
    setLoading(true);
    try {
      const response = await transactionServices.handleGetTopNfts(params);
      if (checkSusscessRequest(response)) {
        const { docs = [] } = response?.data;
        setListTopNfts(docs);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    data: listTopNfts,
    loading,
  };
};
