import { api } from 'services/api';

import { NFTParamsTypes } from 'pages/nft-management';

class AdminServices {
  handleGetList = (params: NFTParamsTypes) => {
    return api.get('admin/users', params);
  };

  handleCreateADmin = (data: any) => {
    return api.post('admin/users', data);
  };

  handleUpdateAdmin = (data: any, id: string) => {
    return api.patch(`admin/users/${id}`, data);
  };

  handleGetAdminDetail = (adress: string) => {
    return api.get(`admin/users/${adress}`);
  };
  handleUpdateTransaction = (id: string) => {
    return api.get(`admin/users/status/${id}`);
  };
}

const adminServices = new AdminServices();

export default adminServices;
