import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SearchForm from '../SearchForm';
import PoolColumn from '../PoolColumn';
import NumberFormat from 'components/NumberFormat';

import { useGetListPool } from 'pages/pool-management/hooks';
import AppNumber from 'components/AppNumber';
import BigNumber from 'bignumber.js';

type HeroProps = {
  values: any;
  onSubmit: (values: any) => void;
  loadingShowHide?: boolean;
  handleShowHidePool?: any;
  dataList: any;
};

const PoolList = ({ values, onSubmit, loadingShowHide, handleShowHidePool, dataList }: HeroProps) => {
  const { t } = useTranslation();

  const { params, searched } = values;
  const { data, total, loading, summary }: any = dataList;
  const emtpyText = searched ? t('') : (t('') as string);

  const colorTextReserve = useMemo(() => {
    return Number(summary?.totalReserve) / Number(summary?.totalReward) < 0.3 ? 'reserve_red' : '';
  }, [summary?.totalReserve, summary?.totalReward]);

  const totalReward = Number(dataList?.summary?.totalReward);
  const totalReserve = Number(dataList?.summary?.totalReserve);
  const totalRewardClaimed = Number(dataList?.summary?.totalRewardClaimed);
  const requiredReward = new BigNumber(totalReward).minus(
    new BigNumber(totalReserve).plus(new BigNumber(totalRewardClaimed)),
  );
  return (
    <Fragment>
      <SearchForm onSubmit={onSubmit} params={params} />
      <div className="total-pool">
        <div>
          Total Pools: &nbsp;
          <NumberFormat thousandSeparator value={total} displayType="text" />
        </div>
        <div className={colorTextReserve}>
          {`Total Reserve/Required Rewards:`}{' '}
          <AppNumber value={summary?.totalReserve} isNotFormatDecimal minDecimal={2} />
          /
          <AppNumber
            value={requiredReward?.isGreaterThan(new BigNumber(0)) ? requiredReward?.toString() : 0}
            isNotFormatDecimal
            placement="topRight"
            minDecimal={2}
          />
        </div>
      </div>
      <PoolColumn
        total={total}
        loading={loading || loadingShowHide}
        data={data}
        onSetParams={onSubmit}
        params={params}
        emtpyText={emtpyText}
        onShowHidePool={handleShowHidePool}
      />
    </Fragment>
  );
};

export default PoolList;
