import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import Card from 'components/AppCard';
import FormItem from 'components/FormItem';
import DraggerMedia from '../DraggerMedia';

import { LIST_PREVIEW_SUPPORT_FILE, MAX_PREVIEW_SIZE, NFT_MEDIA, NFT_DEFAULT_CREATE_FIELD } from 'constants/nft';
import { getFormatedNFT } from 'utils';

const NFTContent = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext() as any;

  const nftFormat = getFormatedNFT(values?.file);

  return (
    <Card className="nft-creation-page-content">
      <div className="content">
        <div className="content__title">{`${t('nft_creation.txt_nft_content')} *`}</div>
        <div className="content__sub-title">{t('nft_creation.txt_nft_desc_content')}</div>
        <FormItem
          name={NFT_DEFAULT_CREATE_FIELD.FILE}
          typeInput={null}
          component={DraggerMedia}
          errorField={`${NFT_DEFAULT_CREATE_FIELD.FILE}.previewContent`}
        />
      </div>

      {nftFormat !== NFT_MEDIA.IMAGE && (
        <div className="preview-content">
          <div className="preview-content__title">{`${t('nft_creation.txt_nft_preview')} *`}</div>
          <div className="preview-content__sub-title">{t('nft_creation.txt_nft_desc_preview')}</div>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.FILE_PREVIEW}
            typeInput={null}
            component={DraggerMedia}
            errorField={`${NFT_DEFAULT_CREATE_FIELD.FILE_PREVIEW}.previewContent`}
            listFileTypeSupport={LIST_PREVIEW_SUPPORT_FILE}
            maxSize={MAX_PREVIEW_SIZE}
          />
        </div>
      )}
    </Card>
  );
};

export default NFTContent;
