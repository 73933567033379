import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import PageHeader from 'components/PageHeader';
import Overview from './components/Overview';
import SaleAnalytics from './components/SaleAnalytics';
import RecentTransaction from './components/RecentTransactions';
import TopNfts from './components/TopNfts';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className="dash-board">
      <PageHeader title={t('dashboard.txt_welcome_admin')} />

      <Row gutter={[20, 20]} className="mt-20">
        <Col md={14} sm={24}>
          <SaleAnalytics />
        </Col>
        <Col md={10} sm={24}>
          <Overview />
        </Col>
      </Row>
      <Row gutter={[20, 20]} className="mt-20">
        <Col md={14} sm={24}>
          <RecentTransaction />
        </Col>
        <Col md={10} sm={24}>
          <TopNfts />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
