import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';
import AppDropdown from 'components/AppDropdown';
import DeleteButton from '../DeleteButton';
import RemoveFromSale from '../RemoveFromSaleButton';
import MintButton from '../MintButton';
import ListForSaleButton from '../ListForSaleButton';
import { NftDetailContext } from 'pages/nft-detail';

import SelectIcon from 'resources/svg/select_icon.svg';

import { getNumberValue } from 'utils';
import { NFT_STATUS } from 'constants/nft';
import { ROLE, ZERO_VALUE } from 'constants/common';
import { externalRoutes, renderRoutes } from 'constants/routes';
import { useGetConfig } from 'hooks/useGetConfig';
import selectAuthentication from 'redux/authentication/selector';
import { useAppSelector } from 'hooks/useStore';

const NftDetailGroupButtonHeader = () => {
  const { t } = useTranslation();

  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { token = {}, saleOrder = {}, status } = nftDetail;
  const { ipfsGateway } = useGetConfig();
  const totalMinted = getNumberValue(token?.totalMinted);
  const totalSupply = getNumberValue(token?.totalSupply);
  const onSaleQuantity = getNumberValue(saleOrder?.quantity);
  const limitMinted = totalSupply - totalMinted - onSaleQuantity;
  const { role } = useAppSelector(selectAuthentication.getAuthenticationToken);

  const isNftOffsale = NFT_STATUS[0].value === status;
  const isNftOnSale = NFT_STATUS[1].value === status;

  const menu = (
    <div className="nft-detail-group-button-header__menu">
      {isNftOffsale && (
        <Fragment>
          <Link to={renderRoutes.NFT_EDITION(nftDetail?._id as string)}>
            <div className="item">{t('nft_detail.txt_edit_nft')}</div>
          </Link>
          {totalMinted <= 0 && <DeleteButton />}
        </Fragment>
      )}
      <a target="_blank" rel="noreferrer" href={externalRoutes.MARKET_NFT_DETAIL(nftDetail?._id)}>
        <div className="item">{t('nft_detail.txt_view_on_market')}</div>
      </a>
      <a href={externalRoutes.BSC_SCAN_ADDRESS(token?.address)} target="_blank" rel="noreferrer">
        {token?.cid ? (
          <div className="item">{t('nft_detail.txt_view_on_bscscan')}</div>
        ) : (
          <div className="item border-bottom-none">{t('nft_detail.txt_view_on_bscscan')}</div>
        )}
      </a>
      {token?.cid && (
        <a href={`${ipfsGateway}${token?.cid}`} target="_blank" rel="noreferrer">
          <div className="item border-bottom-none">{t('nft_detail.txt_view_on_ipfs')}</div>
        </a>
      )}
    </div>
  );

  return (
    <div className="nft-detail-group-button-header">
      {role !== ROLE.CREATOR_ADMIN.value && (
        <>
          <ListForSaleButton visiblity={isNftOffsale} id={nftDetail?._id} />
          <RemoveFromSale visiblity={isNftOnSale} />
          <MintButton visiblity={limitMinted > ZERO_VALUE} />
        </>
      )}

      <AppDropdown overlay={menu}>
        <AppButton text={t('nft_detail.txt_more_actions')} afterIcon={<img src={SelectIcon} />} />
      </AppDropdown>
    </div>
  );
};

export default NftDetailGroupButtonHeader;
