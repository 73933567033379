import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Image } from 'antd';

import LoginBackground from 'resources/images/login_background.png';
import LoginLogo from 'resources/images/login_logo.png';
import MetaMaskLogo from 'resources/svg/metamask_logo.svg';
import NextIcon from 'resources/svg/next_icon.svg';

import AppButton from 'components/AppButton';

import { useAppDispatch } from 'hooks/useStore';
import { handleSetLoadingMetamask } from 'redux/connection/slice';

function Login() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleConnectMetamask = () => dispatch(handleSetLoadingMetamask(true));

  return (
    <Row className="login-page">
      <Col md={12} sm={0} className="login-page--left">
        <Image src={LoginBackground} preview={false} height="100%" width="100%" />
      </Col>
      <Col md={12} sm={24} className="login-page--right">
        <div className="content">
          <img src={LoginLogo} />
          <p className="title">{t('login.txt_connect_wallet')}</p>
          <p className="sub-title">{t('login.txt_connect_sub_title')}</p>
          <AppButton
            text={
              <Fragment>
                <img src={MetaMaskLogo} className="button__icon" />
                <span className="button__text">{t('login.txt_metamask')}</span>
              </Fragment>
            }
            onClick={handleConnectMetamask}
            className="content__button"
            afterIcon={<img src={NextIcon} />}
          />
        </div>
        <div className="footer">© {t('login.txt_footer')}</div>
      </Col>
    </Row>
  );
}

export default Login;
