import { FC } from 'react';

import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import FailedIcon from 'resources/svg/failed.svg';
import TableComponent from 'components//Table';
import { exportExcel } from 'utils';

const getColumnsTableError = (t: any) => {
  return [
    {
      title: t('common.no_column'),
      dataIndex: 'index',
      key: 'index',
      width: '20%',
    },
    {
      title: t('common.error'),
      dataIndex: 'error',
      key: 'name',
      width: '80%',
    },
  ];
};

const ModalVerifyFailed: FC<{ visible: boolean; onClose: any; verifyErrors: any, wrapClassName?: any }> = ({
  visible,
  onClose,
  verifyErrors,
  wrapClassName
}) => {
  const { t } = useTranslation();

  const dataTableError = verifyErrors.map((item: any, index: any) => ({
    fileName: item.fileName,
    error: item.error,
    index: index + 1,
  }));

  const columns = [
    { header: 'No', key: 'index', width: 10 },
    { header: 'Errors', key: 'error', width: 52 },
  ];

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      width={565}
      wrapClassName={wrapClassName}
    >
      <div className="modal-verify-bulk">
        <div className="modal-verify-bulk__title">{t('nftBulk.verifyFailed')}</div>
        <div className="modal-verify-bulk__desc">{t('nftBulk.verifyFailedDesc')}</div>
        <img className="modal-verify-bulk__icon" src={FailedIcon} />
        <a
          className="download-file-verify"
          onClick={exportExcel('File-errors-upload', 'my-sheet', columns, dataTableError)}
        >
          {t('nftBulk.downloadErrorsVerify')}
        </a>
        <TableComponent
          showPagination={false}
          // pagination={false}
          bordered
          dataSource={dataTableError}
          columns={getColumnsTableError(t)}
          scroll={{ y: 300 }}
          rowKey="index"
          className="nft-bulk__table-error"
          total={0}
        />
      </div>
    </Modal>
  );
};

export default ModalVerifyFailed;
