import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout as LayoutAntd } from 'antd';

import Sider from './Sider';
import Header from './Header';
import Content from './Content';

const Layout = ({ children }: any) => {
  const location = useLocation();
  const history = useHistory();

  const selectedKey = `/${location.pathname?.split('/')[1]}`;

  const handleRedirectPage = (e: any) => history.push(e.key);

  return (
    <div>
      <LayoutAntd className="app-layout">
        <Sider selectedKey={selectedKey} onRedirectPage={handleRedirectPage} />
        <LayoutAntd>
          <Header />
          <Content>{children}</Content>
        </LayoutAntd>
      </LayoutAntd>
    </div>
  );
};

export default Layout;
