import React, { Fragment, useContext, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';
import ModalStep from 'components/Modal/ModalStep';
import MintModal from './MintModal';
import { NftDetailContext } from 'pages/nft-detail';
import { useCreateTransaction, useUpdateTransaction } from 'pages/nft-detail/hooks';

import selectedAddress from 'redux/address/selector';
import MetamaskService from 'services/account/MetamaskService';

import { useAppSelector } from 'hooks/useStore';
import { useSocket } from 'hooks/useSocket';

import { SOCKET_EVENT, ZERO_VALUE } from 'constants/common';
import { MIN_VALUE_TOTAL_COPIES, NFT_MINTED_FIELD, NFT_TRANSACTION_TYPE, REMOVE_FROM_SALE_STEPS } from 'constants/nft';
import { externalRoutes } from 'constants/routes';
import { convertAddress } from 'utils';

const { QUANTITY, TO_ADDRESS } = NFT_MINTED_FIELD;

const { PROCESSING, SUCCESSFUL, FAILED } = REMOVE_FROM_SALE_STEPS;

const MintButton = ({ visiblity }: { visiblity?: boolean }) => {
  const { t } = useTranslation();
  const { library } = useWeb3React();

  const {
    nftDetail,
    onGetNftDetail,
    transactionId,
    onSetTransactionId,
    onSetTransactionHash,
    transactionHash,
    isCompletedMint,
    onSetIsCompletedMint,
  }: any = useContext(NftDetailContext);
  const { address } = useAppSelector(selectedAddress.getAddress);

  const [quantity, setQuantity] = useState('');
  const [visible, setVisible] = useState(false);
  const [stepMint, setStepMint] = useState(ZERO_VALUE);

  const { onCreateTransaction } = useCreateTransaction();
  const { onUpdateTransaction } = useUpdateTransaction();

  const failed = FAILED === stepMint;
  const successful = SUCCESSFUL === stepMint;
  const processing = PROCESSING === stepMint;

  const handleToggleMintNFT = () => setVisible(!visible);

  const handleUpdateMintedStep = (value: number) => setStepMint(value);

  const handleCloseMintedModal = () => {
    handleUpdateMintedStep(ZERO_VALUE);
    onSetIsCompletedMint(false);
    onGetNftDetail(nftDetail?._id);
  };

  const handleMintedError = () => {
    handleUpdateMintedStep(FAILED);
    onGetNftDetail(nftDetail?._id);
  };

  const handleMintedSuccess = () => {
    if (!isCompletedMint) {
      handleShowMintedSuccess();
    }
  };

  const handleCompleteMinted = (transactionId: string, data: { hash: string; status: string }) => {
    onSetTransactionHash(data?.hash);
    onUpdateTransaction(transactionId, data, {
      onError: handleMintedError,
      onSuccess: handleMintedSuccess,
    });
  };

  const handleShowMintedSuccess = () => {
    onSetIsCompletedMint(true);
    handleUpdateMintedStep(SUCCESSFUL);
    onGetNftDetail(nftDetail?._id);
  };

  const handleEvent = (data: any) => {
    if (!isCompletedMint && data?.transactionId === transactionId) {
      handleShowMintedSuccess();
    }
  };

  useSocket({
    event: SOCKET_EVENT.ADMIN_MINT,
    handleEvent,
    dependences: [transactionId, isCompletedMint],
  });

  const handleApproveMinted = async (transactionId: string, data?: any) => {
    onSetTransactionId(transactionId);
    const wallet = new MetamaskService().getInstance();
    await wallet.mintNFT({
      account: address,
      library,
      data,
      onCallback: (data: any) => handleCompleteMinted(transactionId, data),
      onCancelMetamask: handleCloseMintedModal,
      onError: handleMintedError,
    });
  };

  const handleSubmit = (values: any) => {
    setQuantity(values?.[QUANTITY] as string);
    setStepMint(PROCESSING);
    handleToggleMintNFT();
    onCreateTransaction(
      {
        type: NFT_TRANSACTION_TYPE.ADMIN_MINTED,
        nftId: nftDetail?._id,
        [QUANTITY]: Number(values?.[QUANTITY]),
        [TO_ADDRESS]: convertAddress(values?.[TO_ADDRESS]?.trim()),
      },
      {
        onSuccess: (id: string, data: any) => handleApproveMinted(id, data),
        onError: handleMintedError,
      },
    );
  };

  const renderMintSuccessText = useMemo(
    () => (
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: t(
              parseInt(quantity) > MIN_VALUE_TOTAL_COPIES
                ? 'nft_detail.txt_mint_success_message'
                : 'nft_detail.txt_mint_single_success_message',
              { number: quantity, name: nftDetail?.name },
            ),
          }}
        />
        <a href={externalRoutes.BSC_SCAN(transactionHash)} target="_blank" rel="noreferrer">
          <AppButton text={t('nft_detail.txt_view_on_bscscan')} className="description__button" />
        </a>
      </div>
    ),
    [quantity, transactionHash, nftDetail?.name],
  );

  return (
    <Fragment>
      {visiblity && <AppButton text={t('nft_detail.txt_mint_nft')} variant="primary" onClick={handleToggleMintNFT} />}

      <MintModal
        visible={visible}
        onClose={handleToggleMintNFT}
        title={t('nft_detail.txt_mint_nft')}
        onSubmit={handleSubmit}
      />

      <ModalStep
        visible={ZERO_VALUE !== stepMint}
        failed={failed}
        successful={successful}
        processing={processing}
        showCloseIcon={failed || successful}
        maskClosable={failed || successful}
        onFailedClose={handleCloseMintedModal}
        onSuccessfulClose={handleCloseMintedModal}
        successfulDescription={renderMintSuccessText}
        innerHtml
      />
    </Fragment>
  );
};

export default MintButton;
