import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import SearchForm from '../SearchForm';
import AdminColumn from '../AdminColumn';
import ItemWithLabel from 'components/ItemWithLabel';
import NumberFormat from 'components/NumberFormat';

import { useGetListAdmin } from 'pages/admin-management/hooks';

type HeroProps = {
  values: any;
  onSubmit: (values: any) => void;
};

const AdminList = ({ values, onSubmit }: HeroProps) => {
  const { t } = useTranslation();

  const { params, searched } = values;
  const { data, total, loading } = useGetListAdmin(params);
  const emtpyText = searched ? t('') : (t('') as string);

  return (
    <Fragment>
      <SearchForm onSubmit={onSubmit} params={params} />
      <ItemWithLabel label={`${t('admin_management.txt_total_admin')}:`}>
        <NumberFormat thousandSeparator value={total} displayType="text" />
      </ItemWithLabel>
      <AdminColumn
        total={total}
        loading={loading}
        data={data}
        onSetParams={onSubmit}
        params={params}
        emtpyText={emtpyText}
      />
    </Fragment>
  );
};

export default AdminList;
