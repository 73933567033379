import React, { ReactNode } from 'react';
import classNames from 'classnames';

type PageContentProps = {
  className?: string | any;
  children?: ReactNode;
};

const PageContent = ({ className, children, ...props }: PageContentProps) => {
  return (
    <div className={classNames('page-content', { [className]: !!className })} {...props}>
      {children}
    </div>
  );
};

export default PageContent;
