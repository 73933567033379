import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import RevenueDetailModal from '../DetailModal';

import { useGetTransactionDetail } from 'pages/revenue-management/hooks';

type ViewDetailButtonProps = {
  revenueId: string;
};

const ViewDetailButton = ({ revenueId }: ViewDetailButtonProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { onGetTransactionDetail, revenueDetail, loading } = useGetTransactionDetail();

  const handleShowDetailModal = () => {
    setVisible(true);
    onGetTransactionDetail(revenueId);
  };

  const handleCloseDetailModal = () => {
    setVisible(false);
  };

  return (
    <div>
      <span className="view-nft-detail" onClick={handleShowDetailModal}>
        {t('common.txt_view_detail')}
      </span>
      <RevenueDetailModal
        visible={visible}
        onClose={handleCloseDetailModal}
        dataRevenue={revenueDetail}
        loading={loading}
      />
    </div>
  );
};

export default ViewDetailButton;
