import moment from 'moment';

const range = (start: any, end: any) => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};
export const disabledTime = (timeField: any, currentSelectTime: any, type: string) => {
  if (timeField) {
    const dateField = timeField?.format('DD/MM/YYYY');
    const selectDate = currentSelectTime?.format('DD/MM/YYYY');

    const hourCheck = timeField?.format('HH');
    const selectHour = currentSelectTime?.format('HH');

    const minutesCheck = timeField?.format('mm');
    const selectMinutes = currentSelectTime?.format('mm');

    const currentDate = moment()?.format('DD/MM/YYYY');
    const dateSelect = currentSelectTime?.format('DD/MM/YYYY');

    const currentHour = moment()?.format('HH');
    const hourSelect = currentSelectTime?.format('HH');

    const currentMinutes = moment()?.format('mm');
    const minutesSelect = currentSelectTime?.format('mm');

    const date = moment();
    const hourNow = date?.hour();
    const minutesNow = date?.minutes();
    const secondsNow = date?.seconds();

    const hour = timeField?.hour();
    const minutes = timeField?.minutes();
    const second = timeField?.second();

    const dataCheck: any = {};

    if (type === 'START_TIME') {
      if (dateField === selectDate) {
        dataCheck.disabledHours = () => range(hour + 1, 24);
      }
      if (dateField === selectDate && hourCheck === selectHour) {
        dataCheck.disabledMinutes = () => range(minutes + 1, 60);
      }
      if (dateField === selectDate && hourCheck === selectHour && minutesCheck === selectMinutes) {
        dataCheck.disabledSeconds = () => range(second + 1, 60);
      }

      ///
      if (dateField !== currentDate) {
        if (currentDate === dateSelect) {
          dataCheck.disabledHours = () => range(0, hourNow);
        }
        if (currentDate === dateSelect && currentHour === hourSelect) {
          dataCheck.disabledMinutes = () => range(0, minutesNow);
        }
        if (currentDate === dateSelect && currentHour === hourSelect && currentMinutes === minutesSelect) {
          dataCheck.disabledSeconds = () => range(0, secondsNow);
        }
      }
      ///

      if (dateField === currentDate) {
        if (currentDate === dateSelect) {
          const dataDisable = [...range(0, hourNow), ...range(hour + 1, 24)];
          dataCheck.disabledHours = () => dataDisable;
        }
        if (currentDate === dateSelect && currentHour === hourSelect && hourCheck === hourSelect) {
          const dataDisable = [...range(0, minutesNow), ...range(minutes + 1, 60)];
          dataCheck.disabledMinutes = () => dataDisable;
        }
        if (currentDate === dateSelect && currentHour === hourSelect && hourCheck !== hourSelect) {
          const dataDisable = [...range(0, minutesNow)];
          dataCheck.disabledMinutes = () => dataDisable;
        }
        if (
          currentDate === dateSelect &&
          currentHour === hourSelect &&
          currentMinutes === minutesSelect &&
          minutesCheck === minutesSelect
        ) {
          const dataDisable = [...range(0, secondsNow), ...range(second + 1, 60)];
          dataCheck.disabledSeconds = () => dataDisable;
        }

        if (
          currentDate === dateSelect &&
          currentHour === hourSelect &&
          currentMinutes === minutesSelect &&
          minutesCheck !== minutesSelect
        ) {
          const dataDisable = [...range(0, secondsNow)];
          dataCheck.disabledSeconds = () => dataDisable;
        }
      }
      ///
    } else {
      if (dateField === selectDate) {
        dataCheck.disabledHours = () => range(0, hour);
      }
      if (dateField === selectDate && hourCheck === selectHour) {
        dataCheck.disabledMinutes = () => range(0, minutes);
      }
      if (dateField === selectDate && hourCheck === selectHour && minutesCheck === selectMinutes) {
        dataCheck.disabledSeconds = () => range(0, second);
      }

      ////
      if (dateField !== currentDate) {
        if (currentDate === dateSelect) {
          dataCheck.disabledHours = () => range(0, hourNow);
        }
        if (currentDate === dateSelect && currentHour === hourSelect) {
          dataCheck.disabledMinutes = () => range(0, minutesNow);
        }
        if (currentDate === dateSelect && currentHour === hourSelect && currentMinutes === minutesSelect) {
          dataCheck.disabledSeconds = () => range(0, secondsNow);
        }
      }
      ///

      if (dateField === currentDate) {
        if (currentDate === dateSelect) {
          const dataDisable = [...range(0, hourNow), ...range(0, hour)];
          dataCheck.disabledHours = () => dataDisable;
        }
        if (currentDate === dateSelect && currentHour === hourSelect) {
          const dataDisable = [...range(0, minutesNow), ...range(0, minutes)];
          dataCheck.disabledMinutes = () => dataDisable;
        }
        if (currentDate === dateSelect && currentHour === hourSelect && currentMinutes === minutesSelect) {
          const dataDisable = [...range(0, secondsNow), ...range(0, second)];
          dataCheck.disabledSeconds = () => dataDisable;
        }
      }
    }
    return dataCheck;
  } else {
    const currentDate = moment()?.format('DD/MM/YYYY');
    const dateSelect = currentSelectTime?.format('DD/MM/YYYY');

    const currentHour = moment()?.format('HH');
    const hourSelect = currentSelectTime?.format('HH');

    const currentMinutes = moment()?.format('mm');
    const minutesSelect = currentSelectTime?.format('mm');

    const date = moment();
    const hourNow = date?.hour();
    const minutesNow = date?.minutes();
    const secondsNow = date?.seconds();
    const dataCheck: any = {};

    if (currentDate === dateSelect) {
      dataCheck.disabledHours = () => range(0, hourNow);
    }
    if (currentDate === dateSelect && currentHour === hourSelect) {
      dataCheck.disabledMinutes = () => range(0, minutesNow);
    }
    if (currentDate === dateSelect && currentHour === hourSelect && currentMinutes === minutesSelect) {
      dataCheck.disabledSeconds = () => range(0, secondsNow);
    }
    return dataCheck;
  }
};
