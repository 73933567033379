import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import trim from 'lodash/trim';

import AppLoading from 'components/AppLoading';
import PageHeader from 'components/PageHeader';
import NFTContent from './components/NFTContent';
import NFTAtrribute from './components/NFTAtrribute';
import NFTPreview from './components/NFTPreview';
import NFTListForSale from './components/NFTListForSale';
import NFTGroupButton from './components/NFTGroupButton';
import ModalUnsavedChange from 'components/Modal/ModalUnsaveChange';

import selectedConfig from 'redux/config/selector';
import { useCreateOrEditNFT, useGetDetailNFT, useGetListWhiteList } from './hooks';
import { useAppSelector } from 'hooks/useStore';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';

import {
  checkValueNftChange,
  clearRequestParams,
  convertTimeToUtc,
  getAttributeFieldNFTValues,
  getDefaultFieldNFTValues,
  getFormatedNFT,
  getWhitelistNFTValues,
} from 'utils';
import { getNftSchema } from 'utils/schema';
import {
  NFT_ATTRIBUTE_CREATED_FIELD,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_MEDIA,
  SERVER_PARAMS_CONFIG,
  STATUS_NFT,
} from 'constants/nft';

import { renderRoutes, routeURLs } from 'constants/routes';
import { NftType } from 'connectors/constants';
import { ROLE, TOKEN_SUPPORT_DEFAULT } from 'constants/common';
import { TYPE_INPUT } from 'components/FormItem';
import selectAuthentication from 'redux/authentication/selector';
import NFTWhitelist from './components/NFTWhitelist';
import moment from 'moment';

const {
  NAME,
  ROYALTYFEE,
  IS_PUT_ON_SALE,
  TOTAL_SUPPLY,
  QUANTITY,
  CURRENCY,
  UNIT_PRICE,
  DESCRIPTION,
  IMAGE_MEDIUM,
  IMAGE_SMALL,
  FILE,
  FILE_PREVIEW,
  IS_PUT_WHITELIST,

  WHITELIST_NAME,
  DISCOUNT,
  NUMBER_OF_ITEM,
  NUMBER_OF_PUCHASE,
  START_TIME,
  END_TIME,
} = NFT_DEFAULT_CREATE_FIELD;
const { TOKEN, SALE_ORDER, ATTRIBUTES, WHITELIST } = SERVER_PARAMS_CONFIG;
const { DPS, ACCURACY, FIRING_RATE, MOBILITY, RANGE, TYPE, MAP, RARITY, TYPEOFWEAPON } = NFT_ATTRIBUTE_CREATED_FIELD;
const FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';
const initFormValue = {
  [NAME]: '',
  [FILE]: {
    fileList: [],
    previewContent: '',
  },
  [FILE_PREVIEW]: {
    fileList: [],
    previewContent: '',
  },
  [IMAGE_MEDIUM]: '',
  [IMAGE_SMALL]: '',
  [ROYALTYFEE]: '',
  [TOTAL_SUPPLY]: '',
  [DESCRIPTION]: '',
  [IS_PUT_ON_SALE]: false,
  [IS_PUT_WHITELIST]: false,
  [UNIT_PRICE]: '',
  [QUANTITY]: '',
  [CURRENCY]: TOKEN_SUPPORT_DEFAULT.value,
  [`${DPS}Input`]: 0,
  [`${ACCURACY}Input`]: 0,
  [`${FIRING_RATE}Input`]: 0,
  [`${MOBILITY}Input`]: 0,
  [`${RANGE}Input`]: 0,
  [RARITY]: null,
  [TYPEOFWEAPON]: null,
  [MAP]: null,
  [TYPE]: NftType.WEAPON,
  [WHITELIST_NAME]: null,
  [DISCOUNT]: '',
  [NUMBER_OF_ITEM]: '',
  [NUMBER_OF_PUCHASE]: false,

  [START_TIME]: '',
  [END_TIME]: '',
} as any;

const NFTCreation = () => {
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;
  const { id } = useParams() as string | any;
  const { loading: loadingCreateNFT, onCreateNFT, onEditNFT } = useCreateOrEditNFT();
  const { listWhiteList } = useGetListWhiteList();
  const { role } = useAppSelector(selectAuthentication.getAuthenticationToken);

  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = [] } = general;

  const { nftDetail, loading: loadingDetailNFT } = useGetDetailNFT(id) as any;

  let isDisable = false;
  if (nftDetail?.token?.totalMinted > 0 && nftDetail?.status === STATUS_NFT?.[1]?.value) {
    isDisable = true;
  }

  const backUrl = id ? renderRoutes.NFT_DETAIL(id) : routeURLs.NFT;

  const { visibleModalUnsaved, setValueChange, onCloseModalUnsaved, afterCloseModalUnsaved, onBackClick, onDiscard } =
    useWarnModalPage(backUrl);

  useEffect(() => {
    if (nftDetail?._id) {
      const attributeFieldValues = getAttributeFieldNFTValues(nftDetail) as object;
      const defaultFieldValues = getDefaultFieldNFTValues(nftDetail) as object;
      const defaultFieldWhitelistValues = getWhitelistNFTValues(nftDetail) as object;
      formikRef.current.setValues({
        ...defaultFieldValues,
        ...attributeFieldValues,
        ...defaultFieldWhitelistValues,
      });
    }
  }, [nftDetail, attributes]);

  const getOriginFile = (file: any) => get(file, ['fileList', 0, 'originFileObj']);

  const handleSubmit = async (values: any = {}) => {
    const { file, filePreview, imageMedium, imageSmall } = values;

    let image = getOriginFile(file);
    const mediaType = getFormatedNFT(values?.file);
    if (mediaType !== NFT_MEDIA.IMAGE) {
      image = getOriginFile(filePreview);
    }

    let data = {
      [NAME]: values?.[NAME] && values?.[NAME].replace(/\s+/g, ' ').trim(),
      mediaFile: mediaType !== NFT_MEDIA.IMAGE ? getOriginFile(file) : undefined,
      image,
      mediaType: mediaType !== NFT_MEDIA.IMAGE ? mediaType : undefined,
      [IMAGE_MEDIUM]: image && imageMedium,
      [IMAGE_SMALL]: image && imageSmall,
      [DESCRIPTION]: trim(values?.[DESCRIPTION]),
      [ROYALTYFEE]: values?.[ROYALTYFEE],
      [IS_PUT_ON_SALE]: values?.[IS_PUT_ON_SALE]?.toString(),
      [`${TOKEN}[${TOTAL_SUPPLY}]`]: values?.[TOTAL_SUPPLY],
      [`${SALE_ORDER}[${QUANTITY}]`]: values?.[QUANTITY],
      [`${SALE_ORDER}[${CURRENCY}]`]: values?.[CURRENCY]?.toLowerCase(),
      [`${SALE_ORDER}[${UNIT_PRICE}]`]: values?.[UNIT_PRICE],

      [`${ATTRIBUTES}[${TYPEOFWEAPON}]`]: values?.[TYPEOFWEAPON],
      [`${ATTRIBUTES}[${RARITY}]`]: values?.[RARITY],
      [`${ATTRIBUTES}[${DPS}]`]: values?.['dpsInput'],
      [`${ATTRIBUTES}[${ACCURACY}]`]: values?.['accuracyInput'],
      [`${ATTRIBUTES}[${FIRING_RATE}]`]: values?.['firingrateInput'],
      [`${ATTRIBUTES}[${MOBILITY}]`]: values?.['mobilityInput'],
      [`${ATTRIBUTES}[${RANGE}]`]: values?.['rangeInput'],
      [`${ATTRIBUTES}[${TYPE}]`]: values?.[TYPE],
      [`${ATTRIBUTES}[${MAP}]`]: values?.[MAP],
    } as any;

    if (values?.[IS_PUT_WHITELIST]) {
      const startTime = convertTimeToUtc(values?.[START_TIME], FORMAT_DATE);
      const endTime = convertTimeToUtc(values?.[END_TIME], FORMAT_DATE);
      const dataWL = {
        [`${WHITELIST}[${WHITELIST_NAME}]`]: values?.[WHITELIST_NAME],
        [`${WHITELIST}[${DISCOUNT}]`]: values?.[DISCOUNT],
        [`${WHITELIST}[${NUMBER_OF_PUCHASE}]`]: values?.[NUMBER_OF_PUCHASE],
        [`${WHITELIST}[${START_TIME}]`]: startTime,
        [`${WHITELIST}[${END_TIME}]`]: endTime,
      };
      if (values?.limitedPurchases) {
        dataWL[`${WHITELIST}[${NUMBER_OF_ITEM}]`] = values?.[NUMBER_OF_ITEM];
      }
      data = {
        ...data,
        ...dataWL,
      };
    }
    const formatData = clearRequestParams(data);
    const formData = new FormData();
    for (const key in formatData) {
      formData.append(key, formatData[key]);
    }
    if (id) {
      onEditNFT(formData, values?.[IS_PUT_ON_SALE], id);
    } else {
      onCreateNFT(formData, values?.[IS_PUT_ON_SALE]);
    }
  };

  const nftSchema = getNftSchema(t, formikRef);
  return (
    <AppLoading loading={loadingDetailNFT || loadingCreateNFT}>
      <div className="nft-creation-page">
        <PageHeader
          showBack
          title={!id ? t('nft_creation.txt_nft_creation') : t('nft_creation.txt_edit_nft')}
          onBack={onBackClick}
        />

        <Formik innerRef={formikRef} initialValues={initFormValue} onSubmit={handleSubmit} validationSchema={nftSchema}>
          {({ values }: any) => {
            setValueChange(checkValueNftChange(id ? nftDetail : initFormValue, values, !!id));
            return (
              <Form className="nft-creation-page-form">
                <Row gutter={20} justify="space-between">
                  <Col lg={16} xs={24}>
                    <NFTContent disabled={isDisable} />
                    <NFTAtrribute formikRef={formikRef} disabled={isDisable} />
                    <NFTWhitelist formikRef={formikRef} listWhiteList={listWhiteList} />
                    {role !== ROLE.CREATOR_ADMIN.value && (
                      <NFTListForSale formikRef={formikRef} nftDetail={nftDetail} />
                    )}
                    <NFTGroupButton isSubmit={loadingCreateNFT} onDiscard={onDiscard} id={id} />
                  </Col>
                  <Col lg={8} xs={24}>
                    <NFTPreview nftDetail={nftDetail} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>

        <ModalUnsavedChange
          visible={visibleModalUnsaved}
          onClose={onCloseModalUnsaved}
          backUrl={backUrl}
          afterClose={afterCloseModalUnsaved}
        />
      </div>
    </AppLoading>
  );
};

export default NFTCreation;
