import React, { Fragment } from 'react';
import { TFunction } from 'i18next';

import AppNumber from 'components/AppNumber';
import AppAddress from 'components/AppAddress';
import EllipsisText from 'components/EllipsisText';
import NumberFormat from 'components/NumberFormat';
import ResponsiveImage from 'components/ResponsiveImage';
import ViewDetailButton from '../ViewDetailButton';

import { formatCurrency, formatDate, formatPadStart } from 'utils';
import { REVENUE_TYPE } from 'constants/revenue';
import { NFT_USD_DECIMAL_SCALE } from 'constants/nft';

export const columns = (t: TFunction, page: number, limit: number) => [
  {
    title: t('revenue_management.txt_no'),
    width: 50,
    key: 'no',
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('revenue_management.txt_sale_date'),
    width: 50,
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (value: any) => formatDate(value),
  },
  {
    title: t('revenue_management.txt_type'),
    width: 50,
    dataIndex: 'nft.token.standard',
    key: 'nft.token.standard',
    render: (_value: any, row: any) => {
      return <EllipsisText text={row?.nft?.attributes?.type?.text} />;
    },
  },
  {
    title: t('revenue_management.txt_nft_id'),
    width: 50,
    dataIndex: 'nft.id',
    key: 'nft.id',
    sorter: true,
    render: (_value: any, row: any) => (
      <span className="nft-id">
        # <AppAddress address={formatPadStart(row?.nft?.code)} isShorten={false} />
      </span>
    ),
  },
  {
    title: t('revenue_management.txt_nft_name'),
    width: 50,
    dataIndex: 'nft.name',
    key: 'nft.name',
    sorter: true,
    ellipsis: true,
    render: (_value: any, row: any) => {
      return (
        <div className="view-nft-name">
          <ResponsiveImage src={`${row?.nft?.image?.smallUrl}?random=${new Date().getTime()}`} />
          <EllipsisText text={row?.nft?.name} />
        </div>
      );
    },
  },
  {
    title: t('revenue_management.txt_seller'),
    width: 50,
    dataIndex: 'fromAddress',
    key: 'fromAddress',
    render: (value: any) => <AppAddress address={value} />,
  },
  {
    title: t('revenue_management.txt_buyer'),
    width: 50,
    dataIndex: 'toAddress',
    key: 'toAddress',
    render: (value: any) => <AppAddress address={value} />,
  },
  {
    title: t('revenue_management.txt_token_id'),
    width: 50,
    dataIndex: 'saleOrder.tokenId',
    key: 'saleOrder.tokenId',
    render: (_value: any, row: any) => (
      <span className="nft-id">
        # <AppAddress address={row?.saleOrder?.tokenId} isShorten={false} />
      </span>
    ),
  },
  {
    title: t('revenue_management.txt_quantity'),
    width: 50,
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: true,
    render: (_value: any, row: any) => (
      <NumberFormat thousandSeparator value={row?.tokenUserBuy?.quantity} displayType="text" />
    ),
  },
  {
    title: t('revenue_management.txt_price'),
    width: 50,
    dataIndex: 'unitPriceBNB',
    key: 'saleOrder.unitPrice',
    sorter: true,
    render: (_value: any, row: any) => {
      return (
        <>
          <AppNumber value={row?.tokenUserBuy?.price} isNotFormatDecimal />
          &nbsp;
          {row?.tokenUserBuy?.currency?.toUpperCase()}
        </>
      );
    },
  },
  {
    title: t('revenue_management.txt_subtotal'),
    width: 50,
    dataIndex: 'subTotal',
    key: 'subTotal',
    sorter: true,
    render: (_value: any, row: any) => {
      const subTotal = row?.tokenUserBuy?.subTotal;
      return (
        <>
          <AppNumber value={subTotal} isNotFormatDecimal />
          &nbsp; {row?.tokenUserBuy?.currency?.toUpperCase()}
        </>
      );
    },
  },
  {
    title: t('revenue_management.txt_royalties'),
    width: 50,
    dataIndex: 'revenue',
    key: 'revenue',
    sorter: true,
    render: (_value: any, row: any) => {
      const royaltyFee = row?.tokenUserBuy?.royaltyFee;
      return (
        <>
          <AppNumber value={royaltyFee} decimalScale={NFT_USD_DECIMAL_SCALE} isNotFormatDecimal />
          &nbsp; {row?.tokenUserBuy?.currency?.toUpperCase()}
        </>
      );
    },
  },
  {
    title: t('revenue_management.txt_actions'),
    width: 50,
    dataIndex: '_id',
    key: 'actions',
    render: (value: any) => {
      return (
        <Fragment>
          <ViewDetailButton revenueId={value} />
        </Fragment>
      );
    },
  },
];
