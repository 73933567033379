import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import AppButton from 'components/AppButton';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import ModalComponent from 'components/Modal';
import { Spin, Upload } from 'antd';
import { LIST_FILE_EXCEL, TYPE_OF_ANT_DESIGN } from 'constants/nft';
import showMessage from 'components/Message';
import Excel, { Buffer } from 'exceljs';
import { isEmpty, isString, trim } from 'lodash';
import {
  MAX_SIZE_NFT_INFORMATION_EXCEL,
  MAX_SIZE_WHITELIST_INFORMATION_EXCEL,
  MAX_SIZE_WHITELIST_INFORMATION_EXCEL_MB,
} from 'constants/bulkUploadNft';
import { useEffect, useRef, useState } from 'react';
import { isAddress } from 'utils';
import { object, string } from 'yup';

type ModalProps = {
  visible: boolean;
  onClose?: () => void;
  title?: string;
  onSubmitUpload: (values?: any) => void;
  loading: boolean;
  toggleLoading: any;
  whitelistDetail: any;
};

const ModalCreateWhitelist = ({
  visible,
  onClose,
  title,
  onSubmitUpload,
  loading,
  toggleLoading,
  whitelistDetail,
  ...props
}: ModalProps) => {
  const { t } = useTranslation();
  const [initFormValue, setInitFormValue] = useState<any>({
    name: '',
  });
  const [userAddresses, setUserAddress] = useState<string[]>([]);
  const [userAddressError, setUserAddressError] = useState<string[]>([]);
  const [totalError, setTotalError] = useState<number | undefined>();

  const formikRef = useRef(null) as any;
  useEffect(() => {
    if (whitelistDetail) {
      setInitFormValue({ name: whitelistDetail?.name });
      setUserAddress(whitelistDetail?.userAddresses);
      setUserAddressError([]);
    } else {
      setInitFormValue({
        name: '',
      });
      setUserAddress([]);
      setUserAddressError([]);
    }
  }, [whitelistDetail, visible]);

  const beforeUpload = (file: any) => {
    try {
      toggleLoading(true);
      const { name, size, type } = file;
      if (size > MAX_SIZE_WHITELIST_INFORMATION_EXCEL) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E117', { size: MAX_SIZE_WHITELIST_INFORMATION_EXCEL_MB });
        return Upload.LIST_IGNORE;
      }
      if (!LIST_FILE_EXCEL.includes(type)) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E34');
      }
    } catch (error) {
    } finally {
      toggleLoading(false);
    }
  };

  const onStart = async (info: any) => {
    try {
      toggleLoading(true);
      const fileRows: any = await readWorkbook(info.file.originFileObj);
      if (isEmpty(fileRows) && info?.file?.status != 'uploading') {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E126');
      }
      const rowData = fileRows?.map((value: any) => {
        return value?.row?.[1];
      });
      const data = rowData?.filter((val: string, index: number) => {
        if (val) return val;
      });
      const dataAddress = data?.filter((val: string, index: number) => {
        return data.indexOf(val) === index;
      });
      const dataError = data?.filter((value: any) => {
        return !isAddress(value) && value;
      });
      if (dataError?.length > 0) {
        const dataErrorAddress = dataError?.filter((val: string, index: number) => {
          return dataError.indexOf(val) === index;
        });
        setUserAddressError(dataErrorAddress);
        setTotalError(dataError?.length);
        setUserAddress([]);
      } else {
        setUserAddressError([]);
        setUserAddress(dataAddress);
      }
    } catch (error) {
    } finally {
      toggleLoading(false);
    }
  };

  const readWorkbook = (file: any) => {
    return new Promise((resolve, reject) => {
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      const rowsValue: any[] = [];

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;

        wb.xlsx
          .load(buffer as Buffer)
          .then((workbook) => {
            workbook.eachSheet((sheet) => {
              sheet.eachRow((row: any, rowNumber: any) => {
                const rowValues = (row.values || []).map((item: any) => (isString(item) ? trim(item) : item));
                if (rowNumber > 2 && !rowValues.every((data: any) => !trim(data))) {
                  rowsValue.push({ row: rowValues, line: rowNumber });
                }
              });
            });
            resolve(rowsValue);
          })
          .catch(() => {
            reject();
          });
      };
    });
  };

  const handleUpload = (value: any) => {
    toggleLoading(true);
    const data: any = {
      name: value.name,
      userAddresses,
    };
    if (whitelistDetail) {
      data.id = whitelistDetail?._id;
    }
    onSubmitUpload(data);
  };

  return (
    <ModalComponent
      visible={visible}
      width={1000}
      onClose={onClose}
      showCloseIcon={true}
      {...props}
      wrapClassName="modal-create-whitelist"
    >
      <div className="create-whitelist-modal">
        <div className="title">{title}</div>
        <Spin spinning={loading}>
          <Formik
            initialValues={initFormValue}
            innerRef={formikRef}
            enableReinitialize={true}
            onSubmit={handleUpload}
            validationSchema={object().shape({
              name: string()
                .trim()
                .required(t('message.E5', { field: t('whitelist.txt_whitelist_name') })),
            })}
          >
            {({ setFieldValue, values }) => {
              return (
                <Form className="mint-modal-form">
                  <FormItem
                    name="name"
                    containerClassName="mint-modal-form__input"
                    placeholder={t('whitelist.txt_whitelist_name_placeholder')}
                    required
                    label={t('whitelist.txt_whitelist_name')}
                    maxLength={100}
                  />
                  <br></br>
                  <div className="whitelist-upload">
                    <span className="form-item__label">{t('whitelist.txt_whitelist_address')} *</span>
                    {!whitelistDetail && (
                      <div className="group-button-upload">
                        <a
                          className="nft-bulk__card__download-template"
                          href="/file/whitelistTemplate.xlsx"
                          download="Upload File Template WhiteList.xlsx"
                        >
                          <AppButton text={t('whitelist.txt_download_template')} variant="default" />
                        </a>
                        <Upload beforeUpload={beforeUpload} itemRender={() => null} onChange={onStart} maxCount={1}>
                          <AppButton text={t('whitelist.txt_upload')} variant="default" />
                        </Upload>
                      </div>
                    )}
                  </div>
                  {userAddressError?.length > 0 && (
                    <div className="error-message">{t('whitelist.txt_address_error', { totalError: totalError })}</div>
                  )}
                  <div className="list-address">
                    {userAddresses?.length === 0 && userAddressError?.length === 0 && (
                      <div className="empty-data">{t('whitelist.txt_empty_data')}</div>
                    )}
                    {userAddresses?.length > 0 &&
                      userAddresses?.map((address: any, index: number) => {
                        return (
                          <div key={index}>
                            <span>{index + 1} . </span>
                            <span>{address}</span>
                          </div>
                        );
                      })}

                    {userAddressError?.length > 0 &&
                      userAddressError?.map((address: any, index: number) => {
                        return (
                          <div key={index} className="address-error">
                            <span>{index + 1} . </span>
                            <span>{address}</span>
                          </div>
                        );
                      })}
                  </div>

                  <div className="group-button">
                    <AppButton
                      htmlType="submit"
                      className="mint-modal-form__button"
                      text={whitelistDetail ? t('whitelist.txt_edit') : t('whitelist.txt_create')}
                      variant="primary"
                      disabled={userAddresses?.length > 0 ? false : true}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Spin>
      </div>
    </ModalComponent>
  );
};

export default ModalCreateWhitelist;
