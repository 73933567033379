import { useTranslation } from 'react-i18next';
import { Col, Progress, Row, Upload } from 'antd';
import { FC, useState } from 'react';
import TableComponent from 'components//Table';
import { LIST_FILE_EXCEL, TYPE_OF_ANT_DESIGN } from 'constants/nft';
import { getListNftFromExcel } from 'utils/bulkUpload';
import SuccessIcon from 'resources/svg/success.svg';
import WarningIcon from 'resources/svg/icon_warning.svg';
import ExcelIcon from 'resources/svg/excel_icon.svg';
import Excel, { Buffer } from 'exceljs';
import { MAX_SIZE_NFT_INFORMATION_EXCEL, MAX_SIZE_NFT_INFORMATION_EXCEL_MB } from 'constants/bulkUploadNft';
import DeleteIcon from 'resources/svg/delete_folder_icon.svg';
import EllipsisText from 'components/EllipsisText';
import showMessage from 'components/Message';
import { isEmpty, isString, trim } from 'lodash';
import ContentCard from 'components/CardContent';
import AppButton from 'components/AppButton';
import TYPE_CONSTANTS from 'constants/type';

export const UPLOAD_FILE_STATUS = {
  NOT_UPLOAD: 'NOT_UPLOAD',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  PROGRESS: 'PROGRESS',
};

const getColumnsTableError = (t: any) => {
  return [
    {
      title: t('nftBulk.line'),
      dataIndex: 'line',
      key: 'line',
      width: '20%',
    },
    {
      title: t('common.error'),
      dataIndex: 'err',
      key: 'err',
      width: '80%',
      render: (arrErr: any) => arrErr.map((err: any, idx: any) => <div key={idx}>{err}</div>),
    },
  ];
};

const NftBulkInformation: FC<any> = ({
  setDataExcel,
  uploadFileStatus,
  setUploadFileStatus,
  seFileCSVName,
  errorsWhitelist,
  setErrorsWhitelist,
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState('');
  const [errors, setErrors] = useState<any>([]);
  const [progressUpload, setProgressUpload] = useState(0);

  const beforeUpload = (file: any) => {
    const { name, size, type } = file;

    if (size > MAX_SIZE_NFT_INFORMATION_EXCEL) {
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E117', { size: MAX_SIZE_NFT_INFORMATION_EXCEL_MB });
      return Upload.LIST_IGNORE;
    }

    if (!LIST_FILE_EXCEL.includes(type)) {
      showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E34');
      return Upload.LIST_IGNORE;
    }
    setFileName(name);
    seFileCSVName(name);
  };

  const onRemoveFile = () => {
    setFileName('');
    setErrors([]);
    setErrorsWhitelist([]);
    setUploadFileStatus(UPLOAD_FILE_STATUS.NOT_UPLOAD);
    setProgressUpload(0);
  };

  const onStart = async (info: any) => {
    const fileRows: any = await readWorkbook(info.file.originFileObj);

    if (isEmpty(fileRows)) {
      setUploadFileStatus(UPLOAD_FILE_STATUS.ERROR);
    }

    const { listErrors, listNft, emptyFile } = getListNftFromExcel(fileRows);

    if (emptyFile) {
      showMessage(TYPE_CONSTANTS.MESSAGE.ERROR, 'message.uploadFileInvalid');
      return;
    }

    if (listErrors) {
      setErrors(listErrors);
      setUploadFileStatus(UPLOAD_FILE_STATUS.ERROR);
      return;
    }
    setProgressUpload(100);
    setUploadFileStatus(UPLOAD_FILE_STATUS.SUCCESS);
    setDataExcel(listNft);
  };

  const readWorkbook = (file: any) => {
    return new Promise((resolve, reject) => {
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      const rowsValue: any[] = [];

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;

        wb.xlsx
          .load(buffer as Buffer)
          .then((workbook) => {
            workbook.eachSheet((sheet) => {
              sheet.eachRow((row: any, rowNumber: any) => {
                const rowValues = (row.values || []).map((item: any) => (isString(item) ? trim(item) : item));

                if (rowNumber > 1 && !rowValues.every((data: any) => !trim(data))) {
                  rowsValue.push({ row: rowValues, line: rowNumber });
                }
              });
            });
            resolve(rowsValue);
          })
          .catch(() => {
            reject();
          });
      };
    });
  };

  return (
    <ContentCard className="nft-bulk__card">
      <Row justify="space-between" align="middle">
        <Col>
          <div className="nft-bulk__card__title">{t('nftBulk.nftInfo')} *</div>
        </Col>
        <Col>
          <a
            className="nft-bulk__card__download-template"
            href="/file/nftTemplate.xlsx"
            download="Bulk Upload File Template.xlsx"
          >
            {t('nftBulk.downloadTemplate')}
          </a>
        </Col>
      </Row>
      <div className="nft-bulk__card__desc">
        {t('nftBulk.nftInfoDesc', {
          maxSize: MAX_SIZE_NFT_INFORMATION_EXCEL_MB,
        })}
      </div>

      {fileName && (
        <>
          <Row align="middle" wrap={false} className="nft-bulk-content__folder w-100">
            <img src={ExcelIcon} className="nft-bulk-content__folder__icon"></img>
            {uploadFileStatus === UPLOAD_FILE_STATUS.SUCCESS ? (
              <EllipsisText className="nft-bulk-content__folder__name" text={fileName} />
            ) : (
              <div className="w-100">
                <EllipsisText className="nft-bulk-content__folder__name" text={fileName} />
                <Progress percent={progressUpload} showInfo={false} className="nft-bulk-content__folder__progress" />
              </div>
            )}
            <img src={DeleteIcon} className="nft-bulk-content__folder__icon-delete" onClick={onRemoveFile} />
          </Row>
          {(uploadFileStatus === UPLOAD_FILE_STATUS.ERROR || errorsWhitelist?.length > 0) && (
            <>
              <Row align="middle" className="nft-bulk-content__folder__error">
                <img src={WarningIcon} />
                <span>{t('common.somethingWentWrong')}</span>
              </Row>
              <div className="nft-bulk-content__folder__error--desc">{t('nftBulk.nftContentError')}</div>
              <TableComponent
                showPagination={false}
                // pagination={false}
                total={0}
                bordered
                dataSource={errors?.length > 0 ? errors : errorsWhitelist}
                columns={getColumnsTableError(t)}
                scroll={{ y: 300 }}
                rowKey="line"
                className="nft-bulk__table-error"
              />
            </>
          )}
          {uploadFileStatus === UPLOAD_FILE_STATUS.SUCCESS && errorsWhitelist?.length === 0 && (
            <Row align="middle" className="nft-bulk-content__folder__success">
              <img src={SuccessIcon} />
              <span>{t('nftBulk.fileValid')}</span>
            </Row>
          )}
        </>
      )}
      <div className="nft-bulk__card__upload">
        {uploadFileStatus !== UPLOAD_FILE_STATUS.SUCCESS && uploadFileStatus !== UPLOAD_FILE_STATUS.ERROR && (
          <Upload beforeUpload={beforeUpload} itemRender={() => null} onChange={onStart} maxCount={1}>
            <AppButton className="button--third small" variant="primary" text={t('nftBulk.uploadFile')} />
          </Upload>
        )}
      </div>
    </ContentCard>
  );
};

export default NftBulkInformation;
