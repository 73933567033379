import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';

import { ROLE } from 'constants/common';

const AdminAtrribute = ({ formikRef, adminDetail }: any) => {
  const { t } = useTranslation();
  const { address } = useParams() as string | any;
  const roleText = Object.values(ROLE)?.find((val) => val.value === adminDetail.role);
  return (
    <Card className="admin-creation-page-attribute">
      <div className="admin-create-field">
        <div className="label">{t('admin_management.txt_status')} *</div>
        <div className="field-swicth">
          <FormItem
            typeInput={TYPE_INPUT.SWITCH}
            name="status"
            disabled={adminDetail?.role === ROLE.SUPER_ADMIN.value ? true : false}
          />
          <span>{formikRef?.current?.values?.status === false ? 'Inactive' : 'Active'}</span>
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="admin-create-field">
        <div className="label">{t('admin_management.txt_admin_name')} *</div>
        <div className="field">
          <FormItem name="name" required placeholder={t('admin_management.txt_name_placeholder')} maxLength={20} />
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="admin-create-field">
        <div className="label">{t('admin_management.txt_admin_wallet')} *</div>
        <div className="field">
          <FormItem
            name="address"
            required
            placeholder={t('admin_management.txt_wallet_placeholder')}
            maxLength={256}
            disabled={address ? true : false}
          />
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="admin-create-field">
        <div className="label">{t('admin_management.txt_admin_role')} *</div>
        <div className="field">
          {!address && (
            <FormItem
              name="role"
              defaultValue={ROLE.CREATOR_ADMIN.value}
              options={[
                { value: ROLE.CREATOR_ADMIN.value, name: t('admin_management.txt_role_creater') },
                { value: ROLE.SALE_ADMIN.value, name: t('admin_management.txt_role_sale') },
              ]}
              typeInput={TYPE_INPUT.RADIO}
              required
              placeholder={t('nft_creation.txt_name_placeholder')}
              disabled={address ? true : false}
            />
          )}
          {address && roleText?.text}
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="admin-create-field">
        <div className="label">{t('admin_management.txt_admin_description')}</div>
        <div className="field">
          <FormItem
            name="description"
            placeholder={t('admin_management.txt_description_placeholder')}
            typeInput={TYPE_INPUT.TEXTAREA}
            maxLength={500}
          />
        </div>
      </div>
    </Card>
  );
};

export default AdminAtrribute;
