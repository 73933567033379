import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import AppTab from 'components/AppTab';
import PageHeader from 'components/PageHeader';
import AppLoading from 'components/AppLoading';
import NftProfile from './components/NftProfile';
import WarningBanner from './components/WarningBanner';
import NftSaleHistory from './components/NftSaleHistory';
import GroupButtonHeader from './components/NftDetailGroupButtonHeader';

import { useGetDetailNFT } from 'pages/nft-creation/hooks';

import { routeURLs } from 'constants/routes';
import { NFT_DETAIL_TABS, PAGE_TAB_QUERY } from 'constants/nft';

const { PROFILE, SALE_HISTORY } = NFT_DETAIL_TABS;

export const NftDetailContext = React.createContext({}) as any;

const NFTDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams() as string | any;
  const queryString = require('query-string');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { nftDetail, onGetNftDetail, loading } = useGetDetailNFT(id);

  const [activeTab, setActiveTab] = useState({ tab: PROFILE.query, isClick: false });
  const [transactionId, setTransactionId] = useState('');
  const [transactionHash, setTransactionHash] = useState('');
  const [isCompletedMint, setIsCompletedMint] = useState(false);

  const { pathname, search } = history?.location;

  const listTabQuery = Object.values(NFT_DETAIL_TABS).map((tab) => tab?.query);
  const parsedSearch = queryString.parse(search);
  const currentQuery = parsedSearch?.[PAGE_TAB_QUERY];

  useEffect(() => {
    if (!activeTab?.isClick) {
      const tab = currentQuery && listTabQuery.includes(currentQuery) ? currentQuery : PROFILE.query;
      history.replace({ pathname, search: `?${PAGE_TAB_QUERY}=${tab}` });
      setActiveTab({
        ...activeTab,
        isClick: true,
        tab: tab,
      });
    }
  }, [currentQuery, activeTab]);

  const handleChangeTab = (value: string) => {
    setActiveTab({
      ...activeTab,
      tab: value,
      isClick: true,
    });
    history.replace({ pathname, search: `?${PAGE_TAB_QUERY}=${value}` });
  };

  const handleNFTBack = () => history.push(routeURLs.NFT);

  const listTab = [
    {
      key: PROFILE.query,
      tab: t(PROFILE.label),
      content: <NftProfile />,
    },
    {
      key: SALE_HISTORY.query,
      tab: t(SALE_HISTORY.label),
      content: <NftSaleHistory />,
    },
  ];

  return (
    <NftDetailContext.Provider
      value={{
        nftDetail,
        onGetNftDetail,

        onSetTransactionId: setTransactionId,
        transactionId,

        transactionHash,
        onSetTransactionHash: setTransactionHash,

        isCompletedMint,
        onSetIsCompletedMint: setIsCompletedMint,
      }}
    >
      <AppLoading loading={loading}>
        <div className="nft-detail-page">
          <WarningBanner />
          <div className="nft-detail-page__header">
            <PageHeader showBack title={t('nft_detail.txt_nft_detail')} onBack={handleNFTBack} />
            <GroupButtonHeader />
          </div>
          <div className="nft-detail-page__app-tab">
            <AppTab onChangeTab={handleChangeTab} activeKey={activeTab.tab} listTab={listTab} />
          </div>
        </div>
      </AppLoading>
    </NftDetailContext.Provider>
  );
};

export default NFTDetail;
