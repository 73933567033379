import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Col, Row } from 'antd';

import AppLoading from 'components/AppLoading';
import PageHeader from 'components/PageHeader';
import PoolAtrribute from './components/PoolAtrribute';
import PoolGroupButton from './components/PoolGroupButton';

import selectedConfig from 'redux/config/selector';
import { useCreateOrEditPool, useGetDetailPool, useUpdateTransactionCreatePool } from './hooks';
import { useAppSelector } from 'hooks/useStore';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';

import { checkValuePoolChange, convertTimeStampUtcToDate, convertTimeToUtc } from 'utils';
import { getPoolSchema } from 'utils/schema';

import { externalRoutes, renderRoutes, routeURLs } from 'constants/routes';
import { PUBLISH_DATE, ZERO_VALUE } from 'constants/common';
import ModalStep from 'components/Modal/ModalStep';
import { REMOVE_FROM_SALE_STEPS } from 'constants/nft';
import AppButton from 'components/AppButton';
import ModalConfirm from 'components/Modal/ModalConfirm';
import showMessage from 'components/Message';
import TYPE_CONSTANTS from 'constants/type';
import moment from 'moment';
import ModalUnsavedChange from 'components/Modal/ModalUnsaveChange';

const initFormValue = {
  name: '',
  amount: '',
  duration: null,
  endTimeStaking: '',
  endTimePool: '',
  publishTime: '',
  tokenReward: 'bcn',
  editionLimit: '',
  publishType: PUBLISH_DATE.NOW,
} as any;

const PoolCreation = () => {
  const { PROCESSING, SUCCESSFUL, FAILED } = REMOVE_FROM_SALE_STEPS;
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;
  const { id } = useParams() as string | any;
  const { loading: loadingCreatePool, onCreatePool } = useCreateOrEditPool();
  const { onUpdateTransaction } = useUpdateTransactionCreatePool();

  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = [] } = general;
  const { poolDetail, loading: loadingDetailPool } = useGetDetailPool(id) as any;

  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const history = useHistory();
  const [stepDeposit, setStepDeposit] = useState(ZERO_VALUE);
  const [transactionHash, setTransactionHash] = useState('');
  const [dataCreate, setDataCreate] = useState<any>();
  const [poolId, setPoolId] = useState<string>('');
  const failed = FAILED === stepDeposit;
  const successful = SUCCESSFUL === stepDeposit;
  const processing = PROCESSING === stepDeposit;

  const backUrl = id ? renderRoutes.POOL_DETAIL(id) : routeURLs.POOL;
  const { visibleModalUnsaved, onCloseModalUnsaved, afterCloseModalUnsaved, onBackClick, onDiscard, setValueChange } =
    useWarnModalPage(backUrl);

  useEffect(() => {
    if (poolDetail?._id) {
      formikRef.current.setValues({
        name: poolDetail?.name,
        amount: poolDetail?.amount,
        duration: poolDetail?.duration?.toString(),
        endTimeStaking: moment(convertTimeStampUtcToDate(poolDetail?.endTimeStaking)),
        endTimePool: moment(convertTimeStampUtcToDate(poolDetail?.endTimePool)),
        publishTime: moment(convertTimeStampUtcToDate(poolDetail?.publishTime)),
        tokenReward: poolDetail?.tokenReward,
        editionLimit: poolDetail?.editionLimit,
        publishType: poolDetail?.publishType,
      });
    }
  }, [poolDetail, attributes]);

  const handleSubmitPool = async (values: any = {}) => {
    const data = {
      ...values,
      name: values?.name?.replace(/\s+/g, ' ').trim(),
    };
    handleToggleCreatePool();
    setDataCreate(data);
  };

  const handleCompleteDeposit = (data: any) => {
    setPoolId(data?.id);
    setTransactionHash(data?.hash);
    onUpdateTransaction(data, {
      onError: handleDepositError,
      onSuccess: () => {
        handleUpdateDepositStep(SUCCESSFUL);
      },
    });
  };

  const handleDepositError = () => {
    handleUpdateDepositStep(FAILED);
  };

  const handleUpdateDepositStep = (value: number) => setStepDeposit(value);

  const handleCloseDepositModal = () => {
    if (stepDeposit === SUCCESSFUL) {
      handleUpdateDepositStep(ZERO_VALUE);
      history.push(renderRoutes.POOL_DETAIL(poolId));
      // showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, id ? 'message.S15' : 'message.S14', { poolName: dataCreate?.name });
    }
    handleUpdateDepositStep(ZERO_VALUE);
  };

  const handleCompleteDepositIsDraft = (data: any) => {
    history.push(renderRoutes.POOL_DETAIL(data?.data?.[0]?._id));
    showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, id ? 'message.S15' : 'message.S14', { poolName: dataCreate?.name });
  };

  const renderDepositSuccessText = useMemo(
    () => (
      <div>
        <>
          <p>
            Your pool name <b>{dataCreate?.name}</b> has been successfully launched. Please transfer{' '}
            <b>{dataCreate?.amount} BCN</b> to the address of <b>{process.env.REACT_APP_PUBLIC_PROXY_CONTRACT}</b> for
            operation
          </p>
        </>
        <a href={externalRoutes.BSC_SCAN(transactionHash)} target="_blank" rel="noreferrer">
          <AppButton text={t('nft_detail.txt_view_on_bscscan')} className="description__button" />
        </a>
      </div>
    ),
    [transactionHash],
  );

  const handleToggleCreatePool = () => {
    setVisibleModalConfirm(!visibleModalConfirm);
  };
  const onConfirmCreatePool = () => {
    if (!dataCreate?.isDraft) {
      setStepDeposit(PROCESSING);
    }
    handleToggleCreatePool();
    const data: any = {
      name: dataCreate?.name,
      duration: dataCreate?.duration,
      endTimeStaking: moment(dataCreate?.endTimeStaking)?.unix(),
      endTimePool: moment(dataCreate?.endTimePool)?.unix(),
      publishTime: moment(dataCreate?.publishTime)?.unix(),
      amount: dataCreate?.amount,
      tokenReward: id ? dataCreate?.tokenReward?.name : dataCreate?.tokenReward,
      editionLimit: dataCreate?.editionLimit,
      publishType: dataCreate?.publishType,
      isDraft: dataCreate?.isDraft,
    };
    if (!dataCreate?.isDraft) {
      data.isShow = true;
    }

    onCreatePool({
      values: data,
      id,
      onCallback: (data: any) => handleCompleteDeposit(data),
      onCancelMetamask: handleCloseDepositModal,
      onError: handleDepositError,
      handleCompleteDepositIsDraft: handleCompleteDepositIsDraft,
    });
  };
  const onBackClickPage = () => {
    history.push(backUrl);
  };

  const modalConfirm = useMemo(() => {
    if (dataCreate) {
      const date = dataCreate?.publishTime
        ? moment(dataCreate?.publishTime).format('YYYY-MM-DD HH:mm:ss')
        : moment().format('YYYY-MM-DD HH:mm:ss');
      let title = 'Launching Pool';
      let decsription = `Are you sure you want to launch the pool named <b>${dataCreate?.name}</b> on <b>
        ${date}</b> ?`;
      if (dataCreate?.isDraft && !id) {
        title = 'Saving Pool';
        decsription = `Are you sure you want to save the pool named <b>${dataCreate?.name}</b> as draft ?`;
      }
      if (id && dataCreate?.isDraft) {
        title = 'Saving Changes';
        decsription = `Are you sure you want to save all the changes made to the pool named <b>${dataCreate?.name}</b> ?`;
      }

      return (
        <ModalConfirm
          visible={visibleModalConfirm}
          onClose={handleToggleCreatePool}
          title={title}
          innerHtml
          decsription={decsription}
          confirmText="Yes"
          onConfirm={onConfirmCreatePool}
        />
      );
    }
  }, [dataCreate, visibleModalConfirm]);

  const poolSchema = getPoolSchema(t, formikRef);

  return (
    <AppLoading loading={loadingDetailPool || loadingCreatePool}>
      <div className="admin-creation-page">
        <PageHeader
          showBack
          title={!id ? t('pool_management.txt_create_pool') : t('pool_management.txt_edit_pool')}
          onBack={onBackClick}
        />

        <Formik
          innerRef={formikRef}
          initialValues={initFormValue}
          onSubmit={handleSubmitPool}
          validationSchema={poolSchema}
        >
          {({ values }: any) => {
            setValueChange(checkValuePoolChange(id ? poolDetail : initFormValue, values));
            return (
              <Form className="admin-creation-page-form">
                <Row gutter={20} justify="space-between">
                  <Col lg={24} xs={24}>
                    <PoolAtrribute formikRef={formikRef} poolDetail={poolDetail} />
                    <PoolGroupButton isSubmit={loadingCreatePool} onDiscard={onDiscard} formikRef={formikRef} id={id} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
      {modalConfirm}
      <ModalStep
        visible={ZERO_VALUE !== stepDeposit}
        failed={failed}
        successful={successful}
        processing={processing}
        showCloseIcon={failed || successful}
        maskClosable={failed || successful}
        onFailedClose={handleCloseDepositModal}
        onSuccessfulClose={handleCloseDepositModal}
        successfulDescription={renderDepositSuccessText}
        successfulTitle="Successfully Launched"
        failedTitle="Unsuccessfully Launched"
        failedDescription="Your launching was unsuccessful. Please try again later"
        innerHtml
      />
      <ModalUnsavedChange
        visible={visibleModalUnsaved}
        onClose={onCloseModalUnsaved}
        backUrl={backUrl}
        afterClose={afterCloseModalUnsaved}
      />
    </AppLoading>
  );
};

export default PoolCreation;
