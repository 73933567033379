import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';
import { routeURLs } from 'constants/routes';
import { Button, Col, Row } from 'antd';

import { MAX_SIZE_NFT_PREVIEW, UPLOAD_FOLDER_STATUS, VERIFY_BULK_NFT_STATUS } from 'constants/bulkUploadNft';
import { isEmpty, keyBy } from 'lodash';
import { NFT_FORMAT } from 'constants/common';
import ModalCreateBulkSuccess from './components/ModalCreateNftBulk/ModalCreateBulkSuccess';
import ModalCreating from './components/ModalCreateNftBulk/ModalCreating';
import ModalVerifyFailed from './components/ModalVerifyNftBulk/ModalVerifyFailed';
import ModalVerifySuccess from './components/ModalVerifyNftBulk/ModalVerifySuccess';
import NftBulkInformation, { UPLOAD_FILE_STATUS } from './components/NftBulkInformation';
import NftBulkContent from './components/NftBulkContents';
import ModalUnsavedChange from 'components/Modal/ModalUnsaveChange';
import { useCreateMultiNFT } from './hooks';
import { MAX_PREVIEW_SIZE } from 'constants/nft';

const CREATE_NFT_STATUS = {
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  SUCCESS_WITH_ERROR: 'SUCCESS_WITH_ERROR',
  SUBMIT_FOR_APPROVAL: 'SUBMIT_FOR_APPROVAL',
};

const NFTCreationMultiple = () => {
  const { t } = useTranslation();

  const [listFileUploaded, setListFileUploaded] = useState<any>([]);
  const [dataExcel, setDataExcel] = useState<any>([]);

  const [uploadFolderStatus, setUploadFolderStatus] = useState(UPLOAD_FOLDER_STATUS.NOT_UPLOAD);
  const [uploadFileStatus, setUploadFileStatus] = useState(UPLOAD_FILE_STATUS.NOT_UPLOAD);
  const [verifyStatus, setVerifyStatus] = useState<any>(null);
  const [verifyErrors, setVerifyErrors] = useState<any>([]);
  const [createNftStatus, setCreateNftStatus] = useState<any>();
  const [createNftRes, setCreateNftRes] = useState<any>({});
  const { loading: loadingCreateNFT, onCreateNFT } = useCreateMultiNFT();
  const [fileCSVName, seFileCSVName] = useState('');
  const [errorsWhitelist, setErrorsWhitelist] = useState([]);

  const { visibleModalUnsaved, setValueChange, onCloseModalUnsaved, afterCloseModalUnsaved, onBackClick, onDiscard } =
    useWarnModalPage(routeURLs.NFT);

  useEffect(() => {
    setValueChange(!isEmpty(listFileUploaded) || !isEmpty(dataExcel));
  }, [JSON.stringify(listFileUploaded), JSON.stringify(dataExcel)]);

  const onCreateMultipleNft = () => {
    const fileUploadedTree = keyBy(listFileUploaded, 'name');

    const dataCreateNft = dataExcel.map((item: any) => {
      debugger;
      const {
        description,
        name,
        nftContent,
        nftPreview,
        numberOfCopies,
        royaltyFee,
        type,
        accuracy,
        dps,
        firingrate,
        map,
        range,
        rarity,
        row,
        typeofweapon,
        whitelist,
        limitOfPurchase,
        discount,
        startDate,
        endDate,
      } = item;
      const fileContentType = fileUploadedTree[nftContent]?.fileType;
      const fileNameS3 = fileUploadedTree[nftContent]?.fileNameS3;
      const filePreviewNameS3 = fileUploadedTree[nftPreview]?.fileNameS3;
      // const fileSize = fileUploadedTree[nftContent]?.size;

      const filePreviewType =
        fileContentType !== NFT_FORMAT.IMAGE && nftPreview ? fileUploadedTree[nftPreview]?.fileType : fileContentType;

      let imageUrl = fileNameS3;
      let imageType = fileContentType;

      if (fileContentType !== NFT_FORMAT.IMAGE) {
        imageUrl = filePreviewNameS3;
        imageType = filePreviewType;
      }

      const whitelistObj = whitelist
        ? {
            whitelistName: whitelist,
            discountValue: discount,
            limitedPurchases: !!limitOfPurchase,
            itemsPerWalletAddress: limitOfPurchase,
            startTime: startDate,
            endTime: endDate,
          }
        : {};

      return {
        name,
        description,
        royaltyFee,
        isPutOnSale: false,
        token: {
          totalSupply: numberOfCopies,
        },
        saleOrder: {
          currency: 'bnb',
        },
        attributes: {
          accuracy,
          dps,
          firingrate,
          map,
          range,
          rarity,
          row,
          typeofweapon,
          type,
        },
        imageUrl,
        imageType,
        mediaUrl: fileContentType !== NFT_FORMAT.IMAGE ? fileNameS3 : undefined,
        mediaType: fileContentType !== NFT_FORMAT.IMAGE ? fileContentType : undefined,
        whitelist: whitelistObj,
      };
    });

    onCreateNFT({ nftData: dataCreateNft, fileName: fileCSVName }, false, setCreateNftStatus, setErrorsWhitelist, t);
    setCreateNftStatus(CREATE_NFT_STATUS.PROCESSING);
    setVerifyStatus(null);
  };

  const onVerifyData = () => {
    const fileUploadedTree = keyBy(listFileUploaded, 'name');
    const newListError: any = [];

    dataExcel.forEach((item: any) => {
      const { nftContent, nftPreview, row } = item;

      const nftFileType = fileUploadedTree[nftContent]?.fileType;
      const nftPreviewSize = fileUploadedTree[nftPreview]?.size;

      if (nftPreview && nftPreviewSize > MAX_SIZE_NFT_PREVIEW) {
        newListError.push({
          fileName: nftContent,
          error: t('message.E110', { imageName: fileUploadedTree[nftPreview]?.name, size: MAX_PREVIEW_SIZE }),
        });
      }

      if (nftContent && !fileUploadedTree[nftContent]) {
        newListError.push({
          fileName: nftContent,
          error: t('common.errorNotFound', { field: nftContent }),
        });
      }
      if (nftFileType && nftFileType !== NFT_FORMAT.IMAGE && !nftPreview) {
        newListError.push({
          fileName: nftContent,
          error: t('nftBulk.requiredPreview', { row: row }),
        });
      }
      if (nftPreview && !fileUploadedTree[nftPreview]) {
        newListError.push({
          fileName: nftPreview,
          error: t('common.errorNotFound', { field: nftPreview }),
        });
      }
    });
    if (!isEmpty(newListError)) {
      setVerifyStatus(VERIFY_BULK_NFT_STATUS.FAILED);
    } else {
      setVerifyStatus(VERIFY_BULK_NFT_STATUS.SUCCESS);
    }
    setVerifyErrors(newListError);
  };

  const onCloseModalCreateNftSuccess = () => {
    const { successQuantity } = createNftRes || {};
    if (!!successQuantity) {
      // navigateToUrl(routeURLs.NFT);
    } else {
      setCreateNftStatus(null);
    }
  };

  const onCloseModalVerify = () => {
    setVerifyStatus(null);
    setVerifyErrors([]);
  };

  return (
    <div className="nft-bulk--wrap">
      <PageHeader showBack title={t('nft_creation.txt_create_nft_multiple')} onBack={onBackClick} />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <NftBulkContent
            listFileUploaded={listFileUploaded}
            setListFileUploaded={setListFileUploaded}
            uploadStatus={uploadFolderStatus}
            setUploadStatus={setUploadFolderStatus}
          />
        </Col>
        <Col span={12}>
          <NftBulkInformation
            dataExcel={dataExcel}
            setDataExcel={setDataExcel}
            uploadFileStatus={uploadFileStatus}
            setUploadFileStatus={setUploadFileStatus}
            seFileCSVName={seFileCSVName}
            errorsWhitelist={errorsWhitelist}
            setErrorsWhitelist={setErrorsWhitelist}
          />
        </Col>
      </Row>

      <Row className="btn-group">
        <Button ghost className="btn-group__discard" onClick={onDiscard}>
          {t('nftBulk.discardBtn')}
        </Button>
        <Button
          className="btn-group__create"
          disabled={
            uploadFolderStatus !== UPLOAD_FOLDER_STATUS.SUCCESS || uploadFileStatus !== UPLOAD_FILE_STATUS.SUCCESS
          }
          onClick={onVerifyData}
        >
          {t('common.verify')}
        </Button>
        {verifyStatus === VERIFY_BULK_NFT_STATUS.FAILED && (
          <ModalVerifyFailed
            visible={true}
            verifyErrors={verifyErrors}
            onClose={onCloseModalVerify}
            wrapClassName="nft-bulk--wrap"
          />
        )}
        {verifyStatus === VERIFY_BULK_NFT_STATUS.SUCCESS && (
          <ModalVerifySuccess
            visible={true}
            onCreateMultipleNft={onCreateMultipleNft}
            onClose={onCloseModalVerify}
            wrapClassName="nft-bulk--wrap"
          />
        )}
        {createNftStatus === CREATE_NFT_STATUS.PROCESSING && <ModalCreating visible={loadingCreateNFT} />}
        {createNftStatus === CREATE_NFT_STATUS.SUCCESS && (
          <ModalCreateBulkSuccess
            visible={true}
            dataSuccess={createNftRes}
            onClose={onCloseModalCreateNftSuccess}
            wrapClassName="nft-bulk--wrap"
          />
        )}
      </Row>
      <ModalUnsavedChange
        visible={visibleModalUnsaved}
        onClose={onCloseModalUnsaved}
        backUrl={routeURLs.NFT}
        afterClose={afterCloseModalUnsaved}
      />
    </div>
  );
};

export default NFTCreationMultiple;
