import React, { FC, Fragment, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import noop from 'lodash/noop';

import ModalWrongNetwork from 'components/Modal/ModalWrongNetwork';
import ModalConnectWallet from 'components/Modal/ModalConnectWallet';
import showMessage from 'components/Message';

import selectedAddress from 'redux/address/selector';
import selectAuthentication from 'redux/authentication/selector';
import selectedConnection from 'redux/connection/selector';
import { handleSetLoadingMetamask, handleSetWrongNetwork } from 'redux/connection/slice';
import { handleAddAddressNetWork, handleSetAddressNetwork } from 'redux/address/slice';
import {
  handleSetAuthenticationToken,
  handleSetIsShowMessageForbidden,
  handleSetNameAdmin,
  handleSetRoleAdmin,
} from 'redux/authentication/slice';

import { useGetAppConfig } from 'hooks/useGetAppConfig';
import { useConnectWallet } from 'hooks/useConnectWallet';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';

import MetamaskService from 'services/account/MetamaskService';
import loginServices from 'services/login';
import { checkSusscessRequest, getToken } from 'services/api';

import { setupNetwork } from 'utils/wallet';
import TYPE_CONSTANTS from 'constants/type';
import { SupportedChainId, SUPPORTED_CHAIN_IDS } from 'connectors/constants';
import { injected } from 'connectors';

const AppConnectWalletWrapper: FC<{
  children: any;
}> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { chainId, account, active, library } = useWeb3React();
  const { connectInjected } = useConnectWallet();

  const { address, listAddress } = useAppSelector(selectedAddress.getAddress);
  const { authenticationToken, isShowMessageForbidden } = useAppSelector(selectAuthentication.getAuthenticationToken);
  const { isConnectingWallet } = useAppSelector(selectedConnection.getConnection);

  useGetAppConfig();

  useEffect(() => {
    if (address && !active) {
      connectInjected();
    }
  }, [address, active]);

  useEffect(() => {
    const isWrongNetwork = authenticationToken && chainId && !SUPPORTED_CHAIN_IDS.includes(chainId);
    dispatch(handleSetWrongNetwork(isWrongNetwork));
  }, [chainId, authenticationToken]);

  const setUpAddress = async (account: string, chainId: any) => {
    if (!SUPPORTED_CHAIN_IDS.includes(chainId)) {
      const result = await setupNetwork(SupportedChainId.BSC);
      if (!result) {
        handleCancelLoadingMetamask();
      }
      return;
    }
    const wallet = new MetamaskService().getInstance();

    // check is admin
    const isAdmin = await handleCheckIsAdmin(wallet);
    if (isAdmin) {
      if (!listAddress?.[account]) {
        handleLoginForFirstTime(wallet);
      } else {
        handleLoginWithExistedAccount(account, isConnectingWallet);
      }
    }
  };

  useEffect(() => {
    if (account && chainId && !isConnectingWallet) {
      setUpAddress(account, chainId);
    }
  }, [account, chainId]);

  useEffect(() => {
    if (account && chainId && isConnectingWallet) {
      setUpAddress(account, chainId);
    }
  }, [isConnectingWallet, account, chainId]);

  useEffect(() => {
    if (isShowMessageForbidden) {
      showMessage(TYPE_CONSTANTS.MESSAGE.ERROR, 'message.E12');
    }
  }, [isShowMessageForbidden]);

  useEffect(() => {
    injected.on('Web3ReactDeactivate', () => {
      handleDisconnect();
    });

    return () => {
      injected.removeListener('Web3ReactDeactivate', noop);
    };
  }, [active, account]);

  const handleCancelLoadingMetamask = () =>
    setTimeout(() => {
      dispatch(handleSetLoadingMetamask(false));
    }, 1000);

  const handleDisconnect = () => {
    dispatch(handleSetAddressNetwork({}));
    dispatch(handleSetAuthenticationToken(''));
    getToken('');
  };

  const handleLoginFailed = () => {
    handleDisconnect();
    showMessage(TYPE_CONSTANTS.MESSAGE.ERROR, 'message.E12');
  };

  const handleCheckIsAdmin = async (wallet: MetamaskService) => {
    const isAdmin = await wallet.isAdmin(library, account as string);
    if (!isAdmin) {
      handleCancelLoadingMetamask();
      handleLoginFailed();
    }
    return isAdmin;
  };

  const handleLoginForFirstTime = async (wallet: MetamaskService) => {
    const signature = (await wallet.verifyLoginSignature({
      creator: account as string,
      library,
      cancelMetamask: () => {
        handleDisconnect();
        handleCancelLoadingMetamask();
      },
    })) as string;

    if (signature) {
      await handleLogin({
        address: account as string,
        signature,
        success: () => {
          dispatch(
            handleAddAddressNetWork({
              address: account,
              signature,
            }),
          );
          dispatch(
            handleSetAddressNetwork({
              chainId,
              address: account,
            }),
          );
        },
        fail: handleLoginFailed,
      });
    }
  };

  const handleLoginWithExistedAccount = async (account: string, isConnectingWallet: boolean) => {
    handleLogin({
      address: account as string,
      signature: listAddress?.[account]?.signature as string,
      success: () => {
        dispatch(
          handleSetAddressNetwork({
            chainId,
            address: account,
          }),
        );
      },
      fail: handleLoginFailed,
    });
  };

  const handleLogin = async ({
    address,
    signature,
    success,
    fail,
  }: {
    address: string;
    signature: string;
    success: () => void;
    fail: () => void;
  }) => {
    const data = {
      address,
      signature,
    };

    try {
      const response = await loginServices.handleLogin(data);
      if (checkSusscessRequest(response)) {
        const token = response?.data?.token;
        const role = response?.data?.role;
        const name = response?.data?.name;
        success();
        getToken(token);
        dispatch(handleSetAuthenticationToken(token));
        dispatch(handleSetRoleAdmin(role));
        dispatch(handleSetNameAdmin(name));
        dispatch(handleSetIsShowMessageForbidden(false));
      } else {
        fail();
      }
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      handleCancelLoadingMetamask();
    }
  };

  return (
    <Fragment>
      {children}

      <ModalWrongNetwork />
      <ModalConnectWallet />
    </Fragment>
  );
};

export default AppConnectWalletWrapper;
