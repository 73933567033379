import React from 'react';
import { useTranslation } from 'react-i18next';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

import Table from 'components/Table';
import { columns } from './columns';

import LENGTH_CONSTANTS from 'constants/length';

import { setOrderSorter, getRowKey } from 'utils';
import { SorterTable } from 'types';
import { NFT_SALE_HISTORY_FIELD } from 'constants/nft';

const { ORDER, PAGE } = NFT_SALE_HISTORY_FIELD;

const { DEFAULT_PAGE } = LENGTH_CONSTANTS;

type listSaleProps = {
  total: number | any;
  listSale: Array<any> | undefined;
  limit: number;
  page: number;
  loading: boolean;
  params: any;
  handleChangePaging: (page: number, limit: number) => void;
  onSetParams: (values: any) => void;
};

const ListSale = ({
  total,
  listSale,
  limit,
  page,
  loading,
  params,
  handleChangePaging,
  onSetParams,
}: listSaleProps) => {
  const { t } = useTranslation();

  const handleChangeTable = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    _extra: TableCurrentDataSource<any>,
  ) => {
    const { order, field } = sorter as SorterTable;
    const newOrder = setOrderSorter(order);

    onSetParams({
      ...params,
      field,
      [ORDER]: newOrder,
      [PAGE]: DEFAULT_PAGE,
    });
  };

  return (
    <div>
      <Table
        total={total}
        columns={columns(t, page, limit)}
        dataSource={listSale}
        loading={loading}
        pageSize={limit}
        current={page}
        onChangePagination={handleChangePaging}
        onChangeTable={handleChangeTable}
        rowKey={getRowKey}
      />
    </div>
  );
};

export default ListSale;
