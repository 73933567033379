import React from 'react';
import { TFunction } from 'react-i18next';

import AppAddress from 'components/AppAddress';
import NumberFormat from 'components/NumberFormat';

import { externalRoutes } from 'constants/routes';
import { NFT_PREFIX_CODE } from 'constants/nft';

export const columns = (t: TFunction, page: number, limit: number, tokenAddress: string) => [
  {
    title: t('nft_detail.txt_no'),
    width: 100,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('nft_detail.txt_current_owner'),
    width: 150,
    dataIndex: 'address',
    key: 'address',
    render: (_value: any, row: any) => {
      return (
        <div>
          <AppAddress address={row?.address} subAddress={row?.isAddressAdmin && t('common.txt_admin')} />
        </div>
      );
    },
  },
  {
    title: t('nft_detail.txt_token_id'),
    width: 150,
    dataIndex: 'tokenId',
    key: 'tokenId',
    render: (value: any) => (
      <AppAddress customAddress={`${NFT_PREFIX_CODE}${value}`} address={value} isShorten={false} />
    ),
  },
  {
    title: t('nft_detail.txt_minted_by'),
    width: 150,
    dataIndex: 'mintedBy',
    key: 'mintedBy',
    render: (_value: any, row: any) => {
      return (
        <div>
          <AppAddress address={row?.mintedBy} subAddress={row?.isMintedByAdmin && t('common.txt_admin')} />
        </div>
      );
    },
  },
  {
    title: t('nft_detail.txt_quantity'),
    width: 150,
    dataIndex: 'quantity',
    key: 'quantity',
    render: (value: any) => <NumberFormat thousandSeparator value={value} displayType="text" />,
  },
  {
    title: t('nft_detail.txt_actions'),
    dataIndex: 'address',
    key: 'address',
    width: 100,
    render: (_value: any, row: any) => {
      return (
        <a
          className="view-nft-detail"
          href={externalRoutes.BSC_SCAN_TOKEN(tokenAddress, row?.tokenId)}
          target="_blank"
          rel="noreferrer"
        >
          {t('nft_detail.txt_view_on_bscscan')}
        </a>
      );
    },
  },
];
