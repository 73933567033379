import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';

import PlayIcon from 'resources/svg/play_icon.svg';
import VolumeIcon from 'resources/svg/volume_icon.svg';
import PauseIcon from 'resources/svg/pause_icon.svg';
import MuteIcon from 'resources/svg/mute_icon.svg';
import FullScreenIcon from 'resources/svg/full_screen_icon.svg';

import { PERCENTAGE_NUMBER, SECOND_NUMBER } from 'constants/common';
import { NFT_PERCENTAGE_SUFFIX } from 'constants/nft';

import styles from './styles.module.scss';

const MAX_DEGIT_NUMBER = 10;

const MediaPlayer = ({
  src,
  controllerClassName,
  isVideo,
  videoClassName,
  wrapperClassName,
  isShowFullScreen = true,
}: {
  src: any;
  controllerClassName?: any;
  isVideo?: boolean;
  videoClassName?: any;
  wrapperClassName?: any;
  isShowFullScreen?: boolean;
}) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [showController, setShowController] = useState(true);

  // references
  const audioPlayer = useRef<any>(); // reference our audio component
  const progressBar = useRef<any>(); // reference our progress bar
  const animationRef = useRef<any>(); // reference the animation

  const calculateTime = (secs: any) => {
    const minutes = Math.floor(secs / SECOND_NUMBER);
    const returnedMinutes = minutes < MAX_DEGIT_NUMBER ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % SECOND_NUMBER);
    const returnedSeconds = seconds < MAX_DEGIT_NUMBER ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };
  const remainTime = calculateTime(duration - currentTime);
  const playTime = calculateTime(currentTime);

  const togglePlayPause = (e: any) => {
    e.preventDefault();
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    if (progressBar?.current?.value) {
      progressBar.current.value = audioPlayer?.current?.currentTime;
    }

    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar?.current?.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    const percentage = (progressBar?.current?.value / duration) * PERCENTAGE_NUMBER;
    progressBar?.current?.style?.setProperty('--seek-before-width', `${percentage}${NFT_PERCENTAGE_SUFFIX}`);
    setCurrentTime(progressBar?.current?.value);
  };

  const toggleMute = () => {
    setIsMuted((state) => !state);
  };
  const openFullscreen = () => {
    if (audioPlayer?.current?.requestFullscreen) {
      audioPlayer?.current?.requestFullscreen();
    } else if (audioPlayer?.current?.webkitRequestFullscreen) {
      /* Safari */
      audioPlayer?.current?.webkitRequestFullscreen();
    } else if (audioPlayer?.current?.msRequestFullscreen) {
      /* IE11 */
      audioPlayer?.current?.msRequestFullscreen();
    }
  };
  useEffect(() => {
    const setAudioData = () => {
      const seconds = audioPlayer.current.duration;
      setDuration(seconds);
      progressBar.current.max = seconds;
    };
    audioPlayer?.current?.addEventListener('loadeddata', setAudioData);

    return () => {
      audioPlayer?.current?.removeEventListener('loadeddata', setAudioData);
    };
  }, []);
  const handleMouseOver = () => {
    setShowController(true);
  };
  const handleMouseOut = () => {
    isPlaying && setShowController(false);
  };

  return (
    <div className={cx(styles.audioPlayer, wrapperClassName)} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {isVideo ? (
        <video
          onClick={togglePlayPause}
          className={cx(styles.video, videoClassName)}
          ref={audioPlayer}
          src={src}
          muted={isMuted}
          preload="metadata"
          loop
        />
      ) : (
        <audio ref={audioPlayer} src={src} muted={isMuted} preload="metadata" loop />
      )}

      <div className={cx(styles.controller, controllerClassName, isVideo && !showController && styles.hidden)}>
        <button type="button" onClick={togglePlayPause} className={cx(styles.playPause, styles.button)}>
          <img src={isPlaying ? PauseIcon : PlayIcon} className={styles.play} />
        </button>

        <div className={styles['progress-wrap']}>
          <input type="range" className={styles.progressBar} ref={progressBar} onChange={changeRange} />
        </div>

        <div className={styles.duration}>
          {duration && !isNaN(duration) && duration !== Infinity ? `-${remainTime}` : playTime}
        </div>

        <button type="button" className={cx(styles.mute, styles.button)} onClick={toggleMute}>
          <img src={isMuted ? MuteIcon : VolumeIcon} />
        </button>

        {isVideo && isShowFullScreen && (
          <button type="button" onClick={openFullscreen} className={cx(styles['full-screen'], styles.button)}>
            <img src={FullScreenIcon} />
          </button>
        )}
      </div>
    </div>
  );
};

export default MediaPlayer;
