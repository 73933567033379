import { useTranslation } from 'react-i18next';
import { Button, Progress, Row, Upload } from 'antd';
import { FC, useEffect, useState } from 'react';

import ContentCard from 'components/CardContent';
import { LIST_FILE_NFT_SUPPORT, MAX_FILE_SIZE_MB, NFT_FORMAT } from 'constants/common';

// import { LINK_ZENDESK } from '@common//constant/link';
import FolderIcon from 'resources/svg/folder_icon.svg';
import DeleteFolderIcon from 'resources/svg/delete_folder_icon.svg';
import WarningIcon from 'resources/svg/icon_warning.svg';
import SuccessIcon from 'resources/svg/success.svg';
import TableComponent from 'components//Table';
import { isEmpty } from 'lodash';
import { customUploadRequest, get3DFileType, getNftFormat } from 'utils';
// import { selectUserId } from 'redux/login/selector';
import showMessage from 'components/Message';
import { useAppSelector } from 'hooks/useStore';
import { MAX_FILE_SIZE_BYTE, MIN_HEIGHT_IMAGE_PREVIEW, MIN_WIDTH_IMAGE_PREVIEW, TYPE_OF_ANT_DESIGN } from 'constants/nft';
import EllipsisText from 'components/EllipsisText';
import { MAX_FOLDER_BULK_NFT_NUMBER_FILE, MAX_FOLDER_BULK_NFT_SIZE, MAX_FOLDER_BULK_NFT_SIZE_GB, UPLOAD_FOLDER_STATUS } from 'constants/bulkUploadNft';
import AppButton from 'components/AppButton';
import selectedAddress from 'redux/address/selector';

const getColumnsTableError = (t: any) => {
  return [
    {
      title: t('nftBulk.fileName'),
      dataIndex: 'fileName',
      key: 'fileName',
      width: '50%',
    },
    {
      title: t('common.error'),
      dataIndex: 'error',
      key: 'name',
      width: '50%',
    },
  ];
};

type NftBulkContentProps = {
  listFileUploaded: any;
  setListFileUploaded: any;
  uploadStatus: any;
  setUploadStatus: any;
};

const NftBulkContent: FC<NftBulkContentProps> = ({
  listFileUploaded,
  setListFileUploaded,
  uploadStatus,
  setUploadStatus,
}) => {
  const { t } = useTranslation();
  const [folderName, setFolderName] = useState('');
  const [errors, setErrors] = useState<any>([]);
  const [progressUpload, setProgressUpload] = useState(0);

  const { address } = useAppSelector(selectedAddress.getAddress);

  useEffect(() => {
    if (progressUpload === 100) {
      setUploadStatus(UPLOAD_FOLDER_STATUS.SUCCESS);
    }
  }, [progressUpload]);

  const getDimensions = (file: any) =>
    new Promise<{ width: number; height: number }>((resolve, reject) => {
      try {
        const reader = new FileReader();

        //Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = function (e: any) {
          //Initiate the JavaScript Image object.
          // @ts-nocheck
          const image = new Image() as any;

          //Set the Base64 string return from FileReader as source.
          image.src = e.target.result;

          //Validate the File Height and Width.
          image.onload = function () {
            const height = this.height;
            const width = this.width;
            resolve({ height, width });
          };
        };
      } catch (error) {
        reject(error);
      }
    });

  const beforeUpload = async (file: any, fileList: any) => {
    const { webkitRelativePath } = file;
    const newFolderName = webkitRelativePath.split('/')[0];
    setFolderName(newFolderName);

    if (!fileList.length) return showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'nftBulk.folderInValid');

    // only check error for first file in fileList

    const firstFileInList = fileList[0].uid;

    if (firstFileInList === file.uid) {
      setListFileUploaded([]);
      setProgressUpload(0);
      const totalFileSize = fileList.reduce((acc: any, cur: any) => acc + cur.size, 0);
      const numberFile = fileList.length;
      if (numberFile > MAX_FOLDER_BULK_NFT_NUMBER_FILE) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E116', { maxNumberFile: MAX_FOLDER_BULK_NFT_NUMBER_FILE });
        setUploadStatus(UPLOAD_FOLDER_STATUS.ERROR);
        return Upload.LIST_IGNORE;
      }
      if (totalFileSize > MAX_FOLDER_BULK_NFT_SIZE) {
        showMessage(TYPE_OF_ANT_DESIGN.ERROR, 'message.E111', { imageName: "Folder", size: MAX_FOLDER_BULK_NFT_SIZE_GB });
        setUploadStatus(UPLOAD_FOLDER_STATUS.ERROR);
        return Upload.LIST_IGNORE;
      }
      // first file
      const totalFileError: any[] = [];
      fileList.forEach((item: any) => {
        const type = item?.type || get3DFileType(item?.name);
        if (!LIST_FILE_NFT_SUPPORT.includes(type)) {
          totalFileError.push({
            fileName: item.name,
            error: t('nftBulk.fileTypeNotSupport'),
          });
        }

        if (item?.size > MAX_FILE_SIZE_BYTE) {
          totalFileError.push({
            fileName: item.name,
            error: t('message.E117', { size: MAX_FILE_SIZE_MB }),
          });
        }
      });
      for (let index = 0; index < fileList.length; index++) {
        const element = fileList[index];
        let imgDimension;
        if (element.type.split("/")[0] === NFT_FORMAT.IMAGE) {
          imgDimension = await getDimensions(element);
          if (imgDimension.width < MIN_WIDTH_IMAGE_PREVIEW || imgDimension.height < MIN_HEIGHT_IMAGE_PREVIEW) {
            totalFileError.push({
              fileName: element.name,
              error: t('nftBulk.requireSizeImage'),
            });
          }
        }
      };

      if (!isEmpty(totalFileError)) {
        setErrors(totalFileError);
        setUploadStatus(UPLOAD_FOLDER_STATUS.ERROR);
        return Upload.LIST_IGNORE;
      } else {
        setUploadStatus(UPLOAD_FOLDER_STATUS.PROGRESS);
        return true;
      }
    }

    if (uploadStatus === UPLOAD_FOLDER_STATUS.ERROR) {
      // second -> last file
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const onChange = async (info: any) => {
    const { file, fileList } = info;
    const { status, name, response } = file;

    if (status === 'done') {
      if (isEmpty(errors)) {
        const numberOfFile = fileList.length;
        const newListFileUploaded = [
          ...listFileUploaded,
          {
            name,
            url: response?.url,
            fileType: getNftFormat(null, file),
            fileNameS3: response?.fileNameS3,
            size: file.size,
          },
        ];
        setListFileUploaded(newListFileUploaded);
        setProgressUpload(Math.floor((newListFileUploaded.length / numberOfFile) * 100));
      }
    }
    if (status === 'error') {
      setErrors((errors: any) => [
        ...errors,
        {
          fileName: name,
          error: 'Error when uploading file',
        },
      ]);
      setUploadStatus(UPLOAD_FOLDER_STATUS.ERROR);
    }
  };

  const onRemoveFolder = () => {
    setFolderName('');
    setErrors([]);
    setUploadStatus(UPLOAD_FOLDER_STATUS.NOT_UPLOAD);
    setListFileUploaded([]);
    setProgressUpload(0);
  };

  const dataTableError = errors.map((item: any) => ({
    fileName: item.fileName,
    error: item.error,
  }));

  return (
    <ContentCard className="nft-bulk__card">
      <div className="nft-bulk__card__title">{t('nftBulk.nftContent')} *</div>
      <div className="nft-bulk__card__desc">
        {t('nftBulk.nftContentDesc', {
          maxFileSize: MAX_FILE_SIZE_MB,
          maxFolderSize: MAX_FOLDER_BULK_NFT_SIZE_GB,
          maxNumberFile: MAX_FOLDER_BULK_NFT_NUMBER_FILE,
        })}
      </div>
      {folderName && (
        <>
          <Row align="middle" wrap={false} className="nft-bulk-content__folder w-100">
            <img src={FolderIcon} className="nft-bulk-content__folder__icon"></img>
            {uploadStatus === UPLOAD_FOLDER_STATUS.SUCCESS ? (
              <EllipsisText className="nft-bulk-content__folder__name" text={folderName} />
            ) : (
              <div className="w-100">
                <EllipsisText className="nft-bulk-content__folder__name" text={folderName} />
                <Progress percent={progressUpload} showInfo={false} className="nft-bulk-content__folder__progress" />
              </div>
            )}
            <Button
              onClick={onRemoveFolder}
              type="link"
              disabled={uploadStatus === UPLOAD_FOLDER_STATUS.PROGRESS}
              className="nft-bulk-content__folder__icon-delete"
            >
              <img src={DeleteFolderIcon} />
            </Button>
          </Row>
          {uploadStatus === UPLOAD_FOLDER_STATUS.ERROR && (
            <>
              <Row align="middle" className="nft-bulk-content__folder__error">
                <img src={WarningIcon} />
                <span>{t('common.somethingWentWrong')}</span>
              </Row>
              <div className="nft-bulk-content__folder__error--desc">{t('nftBulk.nftContentError')}</div>
              <TableComponent
                showPagination={false}
                total={0}
                bordered
                dataSource={dataTableError}
                columns={getColumnsTableError(t)}
                scroll={{ y: 300 }}
                rowKey="fileName"
                className="nft-bulk__table-error"
              />
            </>
          )}
          {uploadStatus === UPLOAD_FOLDER_STATUS.SUCCESS && (
            <Row align="middle" className="nft-bulk-content__folder__success">
              <img src={SuccessIcon} />
              <span>{t('nftBulk.folderValid')}</span>
            </Row>
          )}
        </>
      )}
      <div className="nft-bulk__card__upload">
        {uploadStatus !== UPLOAD_FOLDER_STATUS.ERROR && uploadStatus !== UPLOAD_FOLDER_STATUS.SUCCESS && (
          <Upload
            beforeUpload={beforeUpload}
            directory
            showUploadList={false}
            onChange={onChange}
            customRequest={(params: any) => customUploadRequest(params, address)}
          >
            <AppButton className="button--third small" variant="primary" disabled={uploadStatus !== UPLOAD_FOLDER_STATUS.NOT_UPLOAD} text={t('nftBulk.uploadFolder')} />
          </Upload>
        )}
      </div>
    </ContentCard>
  );
};

export default NftBulkContent;
