import React from 'react';
import selectedConfig from 'redux/config/selector';

import { TOKEN_SUPPORTS, TOKEN_SUPPORT_DEFAULT } from 'constants/common';
import { useAppSelector } from './useStore';
import { NftType } from 'connectors/constants';

export function useGetConfig() {
  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { currencies = [], mintingQuantityMax = 0, attributes = [], ipfsGateway, serviceFee } = general;
  const selectedCurrency = currencies?.find((currency: any) => currency?.name === TOKEN_SUPPORT_DEFAULT.value);
  const allTypeNft = general?.attributes?.find((attribute: any) => attribute.display == 'Type')?.value;

  const weaponCurrency = currencies.filter((value: any) => {
    return value.useFor === NftType.WEAPON || value.useFor === 'All';
  });

  const landCurrency = currencies.filter((value: any) => {
    return value.useFor === NftType.LAND || value.useFor === 'All';
  });

  const getSelectedCurrency = (option: string) => {
    const currency = currencies?.find((currency: any) => currency?.name === option);
    const icon = TOKEN_SUPPORTS[option]?.icon;
    return { currency, icon };
  };

  return {
    currency: { ...selectedCurrency, icon: TOKEN_SUPPORT_DEFAULT.icon },
    getSelectedCurrency,
    mintingQuantityMax,
    attributes,
    ipfsGateway,
    currencies,
    weaponCurrency,
    landCurrency,
    serviceFee
  };
}
