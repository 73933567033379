import React from 'react';

import Login from 'pages/login';
import Home from 'pages/home';
import NFTCreation from 'pages/nft-creation';
import NFTUpdateIpfs from 'pages/nft-update-ipfs';
import NFTCreationMultiple from 'pages/nft-creation-multiple';
import NFTManagement from 'pages/nft-management';
import NFTDetail from 'pages/nft-detail';
import PoolDetail from 'pages/pool-detail';
import NFTListForSale from 'pages/nft-list-for-sale';
import RevenueManagement from 'pages/revenue-management';
import AdminManagement from 'pages/admin-management';
import PoolManagement from 'pages/pool-management';
import AdminCreation from 'pages/admin-creation';
import PoolCreation from 'pages/pool-creation';
import Setting from 'pages/settings';
import Whitelist from 'pages/whitelist-management';
import SettingFeeRate from 'pages/setting-fee-rate';

import PrivateLayout from 'components/Layout/Private';
import PublicLayout from 'components/Layout/Public';

import DashboardIcon from 'resources/svg/DashboardIcon';
import NFTIcon from 'resources/svg/NFTIcon';
import RevenueIcon from 'resources/svg/RevenueIcon';
import AdminIcon from 'resources/svg/AdminIcon';
import PoolIcon from 'resources/svg/PoolIcon';
import SettingIcon from 'resources/svg/SettingIcon';
import WhitelistIcon from 'resources/svg/WhitelistIcon';

import { PAGE_TAB_QUERY } from './nft';

export const routeURLs = {
  LOGIN: '/login',
  HOME: '/',
  NFT: '/nft',
  NFT_DETAIL: '/nft/detail',
  NFT_EDITION: '/nft/edit',
  NFT_UPDATE_IPFS: '/nft/update-ipfs',
  NFT_CREATION: '/nft/create',
  NFT_CREATION_MULTIPLE: '/nft/create-multiple',
  NFT_LIST_FOR_SALE: '/nft/list-for-sale',
  DASHBOARD: '/dashboard',
  REVENUE: '/revenue',
  ADMIN: '/admin',
  POOL: '/pool',
  ADMIN_CREATION: '/admin/create',
  POOL_CREATION: '/pool/create',
  POOL_EDIT: '/pool/edit',
  SETTING: '/setting',
  WHITELIST: '/whitelist',
  SETTING_FEE_RATE: '/setting/platform-fee-rate',
  POOL_DETAIL: '/pool/detail',
};

export const renderRoutes = {
  NFT_DETAIL: (id: string | number, query?: string) => `${routeURLs.NFT_DETAIL}/${id}?${PAGE_TAB_QUERY}=${query}`,
  POOL_DETAIL: (id: string) => `${routeURLs.POOL_DETAIL}/${id}`,
  NFT_EDITION: (id: string | number) => `${routeURLs.NFT_EDITION}/${id}`,
  POOL_EDITION: (id: string | number) => `${routeURLs.POOL_EDIT}/${id}`,
  NFT_UPDATE_IPFS: (id: string | number) => `${routeURLs.NFT_UPDATE_IPFS}/${id}`,
  NFT_LIST_ON_SALE: (id: string | number) => `${routeURLs.NFT_LIST_FOR_SALE}/${id}`,
  ADMIN_LIST: () => `${routeURLs.ADMIN}`,
  ADMIN_DETAIL: (address: string) => `${routeURLs.ADMIN}/${address}`,
};

export const EXTERNAL_URL = {
  BSC_SCAN: `${process.env.REACT_APP_PUBLIC_BSC_SCAN}/tx`,
  BSC_SCAN_ADDRESS: `${process.env.REACT_APP_PUBLIC_BSC_SCAN}/address`,
  BSC_SCAN_TOKEN: `${process.env.REACT_APP_PUBLIC_BSC_SCAN}/token`,
};

export const externalRoutes = {
  MARKET_NFT_DETAIL: (id: string) => `${process.env.REACT_APP_PUBLIC_DOMAIN}/nft/${id}`,
  BSC_SCAN: (transaction: string) => `${EXTERNAL_URL.BSC_SCAN}/${transaction}`,
  BSC_SCAN_ADDRESS: (address: string) => `${EXTERNAL_URL.BSC_SCAN_ADDRESS}/${address}`,
  BSC_SCAN_TOKEN: (contractAddress: string, tokenId: string) =>
    `${EXTERNAL_URL.BSC_SCAN_TOKEN}/${contractAddress}?a=${tokenId}`,
};

const routes: any = {
  privateRoutes: [
    {
      name: 'menu.txt_home',
      path: routeURLs.HOME,
      component: Home,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_dashboard',
      path: routeURLs.HOME,
      component: Home,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: DashboardIcon,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: routeURLs.NFT,
      component: NFTManagement,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: NFTIcon,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_pool',
      path: routeURLs.POOL,
      component: PoolManagement,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: PoolIcon,
      roleCreatorAdmin: false,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_DETAIL}/:id`,
      component: NFTDetail,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_EDITION}/:id`,
      component: NFTCreation,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_UPDATE_IPFS}/:id`,
      component: NFTUpdateIpfs,
      layout: PrivateLayout,
      exact: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: routeURLs.NFT_CREATION,
      component: NFTCreation,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: routeURLs.NFT_CREATION_MULTIPLE,
      component: NFTCreationMultiple,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_nft',
      path: `${routeURLs.NFT_LIST_FOR_SALE}/:id`,
      component: NFTListForSale,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: false,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_revenue',
      path: routeURLs.REVENUE,
      component: RevenueManagement,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: RevenueIcon,
      roleCreatorAdmin: false,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_admin',
      path: routeURLs.ADMIN,
      component: AdminManagement,
      layout: PrivateLayout,
      exact: true,
      isShow: true,
      icon: AdminIcon,
      roleCreatorAdmin: false,
      roleSaleAdmin: false,
    },
    {
      name: 'menu.txt_admin',
      path: routeURLs.ADMIN_CREATION,
      component: AdminCreation,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: false,
      roleSaleAdmin: false,
    },
    {
      name: 'menu.txt_admin',
      path: `${routeURLs.ADMIN}/:address`,
      component: AdminCreation,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: false,
      roleSaleAdmin: false,
    },
    // {
    //   name: 'menu.txt_setting',
    //   path: routeURLs.SETTING,
    //   component: Setting,
    //   layout: PrivateLayout,
    //   isShow: true,
    //   exact: true,
    //   roleCreatorAdmin: false,
    //   roleSaleAdmin: false,
    //   icon: SettingIcon,
    // },
    // {
    //   name: 'menu.txt_setting',
    //   path: routeURLs.SETTING_FEE_RATE,
    //   component: SettingFeeRate,
    //   layout: PrivateLayout,
    //   exact: true,
    //   roleCreatorAdmin: false,
    //   roleSaleAdmin: false,
    // },

    {
      name: 'menu.txt_whitelist',
      path: routeURLs.WHITELIST,
      component: Whitelist,
      layout: PrivateLayout,
      isShow: true,
      exact: true,
      roleCreatorAdmin: true,
      roleSaleAdmin: true,
      icon: WhitelistIcon,
    },
    {
      name: 'menu.txt_pool',
      path: routeURLs.POOL_CREATION,
      component: PoolCreation,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: false,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_pool',
      path: `${routeURLs.POOL_DETAIL}/:id`,
      component: PoolDetail,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: false,
      roleSaleAdmin: true,
    },
    {
      name: 'menu.txt_pool',
      path: `${routeURLs.POOL_EDIT}/:id`,
      component: PoolCreation,
      layout: PrivateLayout,
      exact: true,
      roleCreatorAdmin: false,
      roleSaleAdmin: true,
    },
  ],
  publicRoutes: [
    {
      name: 'menu.txt_login',
      path: routeURLs.LOGIN,
      component: Login,
      exact: true,
      layout: PublicLayout,
    },
  ],
};

export default routes;
