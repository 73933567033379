import { api } from 'services/api';
import axios from 'axios';

import { NFTParamsTypes } from 'pages/nft-management';

class NFTServices {
  handleGetList = (params: NFTParamsTypes) => {
    return api.get('admin/nfts', params);
  };

  handleCreateNFT = (data: any) => {
    return api.postMultiplePart('admin/nfts', data);
  };

  handleUpdateNFT = (data: any, id: string) => {
    return api.patchMultipart(`admin/nfts/${id}`, data);
  };

  handleDeleteNFT = (id: string) => {
    return api.delete(`admin/nfts/${id}`);
  };

  handleGetNftDetail = (id: string) => {
    return api.get(`admin/nfts/${id}`);
  };

  handleGetOwnerListing = (id: string, params: any) => {
    const data = { ...params, ...{ 'sort[tokenId] ': 'desc' } };
    return api.get(`admin/nfts/${id}/owner`, data, { isHideErrorMessage: true });
  };

  handleListForSaleNFT = (id: string, data: any) => {
    return api.post(`admin/nfts/${id}/sale-orders`, data, { checkShowModalMessageListForSale: true });
  };

  handleGetListSaleHistory = (id: string, params: any) => {
    return api.get(`admin/nfts/${id}/transactions`, params);
  };

  putPresignToS3 = (url: any, file: any) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  };

  getPresignedUrl = (params: any) => {
    return api.get('/admin/nfts/presigned', params);
  };

  handleCreateMultiNFT = (data: any) => {
    return api.post('admin/nfts/create-multiple-nft', data);
  };

  handleUpdateIPFS = (data: any, id: string) => {
    return api.patchMultipart(`admin/nfts/ipfs/${id}`, data);
  };
}

const nftServices = new NFTServices();

export default nftServices;
