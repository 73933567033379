import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';
import ModalComponent from 'components/Modal';
import { Spin } from 'antd';

type ModalProps = {
  visible: boolean;
  onClose?: () => void;
  title?: string;
  onSubmitDelete: (id: string) => any;
  whitelistDetail: any;
  loading: boolean;
};

const ModalDeleteWhitelist = ({
  visible,
  onClose,
  title,
  whitelistDetail,
  onSubmitDelete,
  loading,
  ...props
}: ModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalComponent visible={visible} width={550} onClose={onClose} showCloseIcon={true} {...props}>
      <div className="delete-whitelist-modal">
        <div className="title">{title}</div>
        <Spin spinning={loading}>
          <div
            className="content-delete"
            dangerouslySetInnerHTML={{
              __html: t('whitelist.txt_content_delete', { whitelistName: whitelistDetail?.name }),
            }}
          />
          <div className="group-button">
            <AppButton
              htmlType="submit"
              className="mint-modal-form__button"
              text={t('common.txt_cancel')}
              variant="default"
              onClick={onClose}
            />
            <AppButton
              htmlType="submit"
              className="mint-modal-form__button"
              text={t('common.txt_delete')}
              variant="primary"
              onClick={onSubmitDelete(whitelistDetail?._id)}
            />
          </div>
        </Spin>
      </div>
    </ModalComponent>
  );
};

export default ModalDeleteWhitelist;
