import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import isString from 'lodash/isString';

import { initialValues } from '..';
import FormItem, { TYPE_INPUT } from 'components/FormItem';

import RefreshIcon from 'resources/svg/refresh_icon.svg';

import { useChangeAddress } from 'hooks/useChangeAddress';
import { disabledFromDate, disabledUntilDate } from 'utils';
import LENGTH_CONSTANTS from 'constants/length';
import { NFT_SALE_HISTORY_FIELD, NFT_MARKET_CHANNEL } from 'constants/nft';

const { KEYWORD, FROM, UNTIL, TYPE, PAGE, LIMIT } = NFT_SALE_HISTORY_FIELD;

const { DEFAULT_PAGE } = LENGTH_CONSTANTS;

const SearchForm = ({ onSetParams, params }: any) => {
  const { t } = useTranslation();
  const formRef = useRef<any>(null);

  const initialParams = omit({ ...initialValues }, [PAGE, LIMIT]) as any;

  const optionSelect = NFT_MARKET_CHANNEL.map((marketChannel) => ({
    ...marketChannel,
    name: t(marketChannel?.name),
  }));

  const handleResetForm = () => {
    formRef?.current?.resetForm();
    onSetParams({
      ...initialParams,
      ...initialValues,
      [PAGE]: DEFAULT_PAGE,
    });
  };

  useChangeAddress({ callback: handleResetForm });

  const handleFieldChange = (setFieldValue: any, name: string) => (value: any) => {
    setFieldValue(name, isString(value) ? trim(value) : value);
    onSetParams({
      ...params,
      [name]: isString(value) ? trim(value) : value,
      [PAGE]: DEFAULT_PAGE,
    });
  };

  return (
    <Formik initialValues={initialParams} onSubmit={noop} innerRef={formRef}>
      {({ setFieldValue, values }) => (
        <Form className="nft-detail-search-form">
          <Row gutter={20} className="nft-detail-search-form__row">
            <Col lg={8} sm={12} xs={24} className="nft-detail-search-form__col-search">
              <FormItem
                name={KEYWORD}
                placeholder={t('nft_detail.txt_search_by_token_id')}
                onSearch={handleFieldChange(setFieldValue, KEYWORD)}
                typeInput={TYPE_INPUT.SEARCH}
              />
            </Col>
            <Col lg={3} sm={12} xs={24} className="nft-detail-search-form__col-date">
              <FormItem
                className="nft-detail-search-form__date"
                name={FROM}
                placeholder={t('nft_detail.txt_start_date')}
                onChange={handleFieldChange(setFieldValue, FROM)}
                disabledDate={disabledFromDate(values?.[UNTIL])}
                typeInput={TYPE_INPUT.DATE}
              />
            </Col>
            <Col lg={3} sm={12} xs={24} className="nft-detail-search-form__col-date">
              <FormItem
                className="nft-detail-search-form__date"
                name={UNTIL}
                placeholder={t('nft_detail.txt_end_date')}
                onChange={handleFieldChange(setFieldValue, UNTIL)}
                disabledDate={disabledUntilDate(values?.[FROM])}
                typeInput={TYPE_INPUT.DATE}
              />
            </Col>
            <Col lg={3} sm={12} xs={24} className="nft-detail-search-form__col-select">
              <FormItem
                className="nft-detail-search__select"
                name={TYPE}
                placeholder={t('nft_detail.txt_market_channel')}
                onChange={handleFieldChange(setFieldValue, TYPE)}
                typeInput={TYPE_INPUT.SELECT}
                options={optionSelect}
                allowClear
              />
            </Col>
            <Col lg={1} sm={12} xs={24}>
              <img src={RefreshIcon} onClick={handleResetForm} className="cursor-pointer" />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default SearchForm;
