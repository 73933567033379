import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

import EllipsisText from 'components/EllipsisText';
import InfoItem from '../InfoItem';
import { NftDetailContext } from 'pages/nft-detail';

import { getImageAttribute, getImageAttributes, getValueAttribute } from 'utils';
import { LOCALIZATION_NFT_DETAIL_ATTRIBUTE, NFT_MANAGEMENT_ATTRIBUTE_FIELD } from 'constants/nft';
const { TYPE_WEAPON, RARITY } = NFT_MANAGEMENT_ATTRIBUTE_FIELD;

const Attribute = () => {
  const { t } = useTranslation();

  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { attributes = {} } = nftDetail;
  const imgWeapon = getImageAttributes(attributes, TYPE_WEAPON);
  const imgRarity = getImageAttributes(attributes, RARITY);
  const renderAttributeItem = Object.keys(LOCALIZATION_NFT_DETAIL_ATTRIBUTE).map((attribute: any, index: number) => {
    if (LOCALIZATION_NFT_DETAIL_ATTRIBUTE?.[attribute]?.nftType === nftDetail?.attributes?.type?.text) {
      const valueAttribute = getValueAttribute(attributes, attribute);
      const imageAttribute = getImageAttribute(attributes, attribute);

      const label = t(LOCALIZATION_NFT_DETAIL_ATTRIBUTE?.[attribute]?.text);
      return (
        <InfoItem label={label} key={index}>
          <div className="attribute">
            {label === 'Type' && imgWeapon && <img src={imgWeapon} alt="attribute nft" />}
            {label === 'Rarity' && imgRarity && <img src={imgRarity} alt="attribute nft" />}
            <EllipsisText text={valueAttribute} className="attribute__text" />
          </div>
        </InfoItem>
      );
    }
  });

  return <Row gutter={20}>{renderAttributeItem}</Row>;
};

export default Attribute;
