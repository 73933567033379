import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

import PageContent from 'components/PageContent';
import AppTab from 'components/AppTab';
import EllipsisText from 'components/EllipsisText';
import Info from './Info';
import Content from './Content';
import Preview from './Preview';
import NftOwnerListing from '../NftOwnerListing';
import { NftDetailContext } from 'pages/nft-detail';

import { NFT_MEDIA, NFT_PROFILE_TABS } from 'constants/nft';

const NftProfile = () => {
  const { t } = useTranslation();

  const { nftDetail }: any = useContext(NftDetailContext);
  const [activeTab, setActiveTab] = useState(NFT_PROFILE_TABS.PREVIEW.key);
  const handleChangeTab = (value: string) => setActiveTab(value);
  const { attributes = {} } = nftDetail;
  const nftType = attributes?.type?.text as string;

  const isVideoFormat = nftDetail?.media;

  const listProfileTab = [
    {
      key: NFT_PROFILE_TABS.PREVIEW.key,
      tab: isVideoFormat ? t(NFT_PROFILE_TABS.PREVIEW.label) : t(NFT_PROFILE_TABS.CONTENT.label),
      content: <Preview />,
    },
    {
      key: NFT_PROFILE_TABS.CONTENT.key,
      tab: t(NFT_PROFILE_TABS.CONTENT.label),
      content: <Content />,
    },
  ];

  const displayedProfileTab = isVideoFormat ? listProfileTab : [listProfileTab[0]];

  return (
    <Fragment>
      <PageContent>
        <Row gutter={26}>
          <Col lg={10} md={12} xs={24} xl={8}>
            <AppTab
              onChangeTab={handleChangeTab}
              activeKey={activeTab}
              listTab={displayedProfileTab}
              className="nft-profile__tab"
            />
            <EllipsisText text={nftType} className="nft-profile__type" />
          </Col>
          <Col lg={14} md={12} xs={24} xl={16}>
            <Info />
          </Col>
        </Row>
      </PageContent>
      <NftOwnerListing />
    </Fragment>
  );
};

export default NftProfile;
