import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import { useFormikContext } from 'formik';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import NumberFormat from 'components/NumberFormat';
import AppButton from 'components/AppButton';

import { useGetConfig } from 'hooks/useGetConfig';
import { limitMaxlengNumber } from 'utils';
import {
  MAX_LENGTH_TOTAL_SUPPLY,
  NFT_DECIMAL_SCALE,
  NFT_DECIMAL_SCALE_PRICE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_DEFAULT_PRICE,
  NFT_POSITIVE_SCALE,
} from 'constants/nft';
import { NftType } from 'connectors/constants';
import { TOKEN_SUPPORTS, TOKEN_SUPPORT_DEFAULT } from 'constants/common';

const { QUANTITY, TOTAL_SUPPLY, IS_PUT_ON_SALE, UNIT_PRICE, IS_PUT_WHITELIST } = NFT_DEFAULT_CREATE_FIELD;

const NFTListForSale = ({ formikRef, nftDetail }: any) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext() as any;
  const { weaponCurrency, landCurrency, currencies } = useGetConfig();
  const unitPrice = values?.[UNIT_PRICE];
  const unitCurrency = useMemo(() => {
    return currencies.find((value: any) => value.symbol === formikRef?.current?.values?.currency)?.usd;
  }, [formikRef?.current?.values?.currency]);
  const handleSetMaxQuantity = () =>
    setFieldValue(QUANTITY, Number(values?.[TOTAL_SUPPLY]) - Number(nftDetail?.token?.totalMinted || 0));

  const handleSwitchChange = (checked: boolean) => {
    if (!checked) {
      setFieldValue(QUANTITY, '');
      setFieldValue(UNIT_PRICE, '');
    }
  };

  const selectAfter = useMemo(() => {
    if (formikRef?.current?.values?.type === NftType.WEAPON) {
      formikRef?.current?.setFieldValue(NFT_DEFAULT_CREATE_FIELD.CURRENCY, TOKEN_SUPPORT_DEFAULT.value);
      return weaponCurrency?.map((value: any) => {
        return {
          label: value?.symbol,
          value: value?.symbol,
          disabled: value?.symbol === TOKEN_SUPPORTS.BNB.symbol ? false : true,
        };
      });
    } else if (formikRef?.current?.values?.type === NftType.LAND) {
      formikRef?.current?.setFieldValue(NFT_DEFAULT_CREATE_FIELD.CURRENCY, TOKEN_SUPPORT_DEFAULT.value);
      return landCurrency?.map((value: any) => {
        return {
          label: value?.symbol,
          value: value?.symbol,
          disabled: value?.symbol === TOKEN_SUPPORTS.BNB.symbol ? false : true,
        };
      });
    }
  }, [formikRef?.current?.values?.type]);

  const whitelistPrice = useMemo(() => {
    const currentPrice = Number(formikRef?.current?.values?.[UNIT_PRICE]);
    const discountValue = Number(formikRef?.current?.values?.discountValue);
    return (currentPrice * (100 - discountValue)) / 100;
  }, [formikRef?.current?.values?.[UNIT_PRICE], formikRef?.current?.values?.discountValue]);

  return (
    <Card className="nft-creation-page-list-for-sale">
      <div className="nft-creation-page-list-for-sale__header">
        <span className="title">{t('nft_creation.txt_list_for_sale')}</span>
        <FormItem typeInput={TYPE_INPUT.SWITCH} name={IS_PUT_ON_SALE} onChange={handleSwitchChange} />
      </div>
      {values?.[IS_PUT_ON_SALE] && (
        <Row className="nft-creation-page-list-for-sale__content" gutter={20}>
          <Col md={12} xs={24}>
            <FormItem
              typeInput={TYPE_INPUT.NUMBER}
              name={QUANTITY}
              placeholder={t('nft_creation.txt_edition_quantity_placeholder')}
              required
              thousandSeparator
              decimalScale={NFT_POSITIVE_SCALE}
              description={t('nft_creation.txt_edition_quantity_sub')}
              label={t('nft_creation.txt_onsale_quantity')}
              isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
              appendInput={
                <AppButton
                  text={t('nft_creation.txt_max')}
                  className="field__button"
                  onClick={handleSetMaxQuantity}
                  variant="primary"
                  disabled={!values?.[TOTAL_SUPPLY]}
                />
              }
            />
          </Col>
          <Col md={12} xs={24}>
            <div>
              <FormItem
                typeInput={TYPE_INPUT.NUMBER}
                name={UNIT_PRICE}
                thousandSeparator
                placeholder={t('nft_creation.txt_listing_price_placeholder')}
                required
                className="content__listing"
                decimalScale={NFT_DECIMAL_SCALE_PRICE}
                description={t('nft_creation.txt_listing_price_sub')}
                label={t('nft_creation.txt_listing_price')}
                isAllowed={limitMaxlengNumber()}
                addonAfter={
                  <FormItem
                    typeInput={TYPE_INPUT.SELECT}
                    name={NFT_DEFAULT_CREATE_FIELD.CURRENCY}
                    defaultValue={TOKEN_SUPPORT_DEFAULT.value}
                    options={selectAfter}
                  ></FormItem>
                }
              >
                <p className="content__price">
                  {unitPrice && unitCurrency ? (
                    <>
                      {'~ $ '}
                      <NumberFormat
                        thousandSeparator
                        value={unitPrice * unitCurrency}
                        displayType="text"
                        decimalScale={NFT_DECIMAL_SCALE}
                      />
                    </>
                  ) : (
                    NFT_DEFAULT_PRICE
                  )}
                </p>
              </FormItem>
              {formikRef?.current?.values?.[IS_PUT_WHITELIST] && (
                <div className="whitelist-price">
                  <p>WhiteList Price</p>
                  <p className="content__price">
                    {unitPrice && unitCurrency ? (
                      <>
                        {'~ $ '}
                        <NumberFormat
                          thousandSeparator
                          value={whitelistPrice * unitCurrency}
                          displayType="text"
                          decimalScale={NFT_DECIMAL_SCALE}
                        />
                      </>
                    ) : (
                      NFT_DEFAULT_PRICE
                    )}
                  </p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default NFTListForSale;
