import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import SuccessIcon from 'resources/svg/success.svg';
import FailedIcon from 'resources/svg/failed.svg';
import Modal from 'components/Modal';

const ModalCreateBulkSuccess: FC<{
  visible: boolean;
  dataSuccess: any;
  onClose: any;
  titleSuccess?: string;
  descSuccess?: string;
  titleError?: string;
  descError?: string;
  titleSuccessWithErr?: string;
  descSuccessWithErr?: string;
  wrapClassName?: any;
}> = ({
  visible,
  dataSuccess,
  onClose,
  titleSuccess = 'nftBulk.createNftSuccess',
  descSuccess = 'nftBulk.createNftSuccesDesc',
  titleError = 'nftBulk.createNftFailedTitle',
  descError = 'nftBulk.createNftFailedDesc',
  titleSuccessWithErr = 'nftBulk.createNftSuccessWithErrorDesc',
  wrapClassName,
}) => {

    const { t } = useTranslation();

    const { successQuantity, errorQuantity, linkFileReport } = dataSuccess || {};
    const haveError = !!errorQuantity;
    const haveSuccess = !!successQuantity;
    return (
      <Modal
        visible={visible}
        onClose={onClose}
        width={565}
        wrapClassName={wrapClassName}
      >
        <div className="modal-verify-bulk">
          <div className="modal-verify-bulk__title">{haveSuccess ? t(titleSuccess) : t(titleError)}</div>
          <div className="modal-verify-bulk__desc">
            {!haveSuccess ? (
              t(descError)
            ) : !haveError ? (
              t(descSuccess, { numberNft: successQuantity })
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: t(titleSuccessWithErr, {
                    numberSuccess: successQuantity,
                    numberFailed: errorQuantity,
                  }),
                }}
              />
            )}
          </div>
          <div>
            <img className="modal-verify-bulk__icon" src={haveSuccess ? SuccessIcon : FailedIcon} />
          </div>

          {haveError && (
            <div className="modal-verify-bulk__button">
              <Button className="button--third w-100">
                <a href={linkFileReport} download>
                  {t('nftBulk.downloadReport')}
                </a>
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  };

export default ModalCreateBulkSuccess;
