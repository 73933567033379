import React, { useContext } from 'react';
import { Image } from 'antd';

import MediaPlayer from 'components/MediaPlayer';
import ModelViewer from 'components/ModalViewer';
import { NftDetailContext } from 'pages/nft-detail';

import { NFT_MEDIA } from 'constants/nft';
import { IMAGE_TYPE } from 'constants/common';

const { AUDIO, VIDEO, MODEL } = NFT_MEDIA;

const NFTContent = () => {
  const { nftDetail = {} } = useContext(NftDetailContext) as any;

  const nftFormat = nftDetail?.media?.type || IMAGE_TYPE;
  const previewSrc = nftDetail?.image?.url;
  const contentSrc = nftDetail?.media?.url || nftDetail?.image?.url;

  const renderContent = () => {
    switch (nftFormat) {
      case AUDIO:
        return (
          <div className="nft-profile-content__audio">
            {previewSrc && <img src={previewSrc} className="image" />}
            <MediaPlayer src={contentSrc} wrapperClassName="audio" controllerClassName="controller" />
          </div>
        );
      case VIDEO:
        return <MediaPlayer src={contentSrc} isVideo isShowFullScreen={false} />;
      case MODEL:
        return (
          <div className="model-viewer">
            <ModelViewer auto-rotate autoplay camera-controls src={contentSrc} />
          </div>
        );
      default:
        return <Image src={contentSrc} />;
    }
  };

  return <div className="nft-profile-content">{renderContent()}</div>;
};

export default NFTContent;
