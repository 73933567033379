import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Spin } from 'antd';

import Modal from 'components/Modal';
import LoadingIcon from 'components/LoadingIcon';

import { handleSetConnectModal } from 'redux/connection/slice';
import selectedConnection from 'redux/connection/selector';

import MetaMaskLogo from 'resources/svg/metamask_logo.svg';
import DownloadMetamaskIcon from 'resources/svg/download_metamask_icon.svg';

import { useConnectWallet } from 'hooks/useConnectWallet';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { METAMASK_DEEPLINK } from 'connectors/constants';

declare let window: any;

const { Paragraph } = Typography;

const ModalConnectWallet = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { isConnectingWallet } = useAppSelector(selectedConnection.getConnection);
  const { connectInjected } = useConnectWallet();

  const [isShowMetaMask, setIsShowMetaMask] = useState(false);

  const handleHideModalConnectWallet = () => dispatch(handleSetConnectModal(false));

  const isEthereum = typeof window !== 'undefined' && !!window?.ethereum?.isMetaMask;

  const handleConnectMetamask = () => {
    connectInjected(() => setIsShowMetaMask(true));

    if (window.ethereum) {
      handleHideModalConnectWallet();
    }
  };

  useEffect(() => {
    if (isConnectingWallet) {
      handleConnectMetamask();
    }
  }, [isConnectingWallet]);

  const renderNoMetamask = () => (
    <div className="popup_metamask">
      {isEthereum ? (
        <div className="loading-metamask-modal">
          <Paragraph className="title">{t('common.txt_connecting_to_metamask')}</Paragraph>
          <img src={MetaMaskLogo} alt="" className="logo" />
          <Spin size="large" indicator={<LoadingIcon />} />
        </div>
      ) : (
        <div className="metamask-notfound-modal">
          <Paragraph className="title">{t('common.txt_metamask_not_found_title')}</Paragraph>
          <Paragraph className="sub-title">{t('common.txt_metamask_not_found_content')}</Paragraph>
          <img src={MetaMaskLogo} className="logo" />
          <div className="footer">
            <img src={DownloadMetamaskIcon} />
            <a target="_blank" href={METAMASK_DEEPLINK} className="link" rel="noreferrer">
              {t('common.txt_metamask_not_found_button')}
            </a>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal visible={isConnectingWallet} showCloseIcon={isShowMetaMask && isEthereum}>
      {renderNoMetamask()}
    </Modal>
  );
};

export default ModalConnectWallet;
