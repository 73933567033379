import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader';
import PageContent from 'components/PageContent';
import ItemWithLabel from 'components/ItemWithLabel';
import NumberFormat from 'components/NumberFormat';
import ListOwner from './ListOwner';
import SearchForm from './SearchForm';

import { useGetOwnerListing } from 'pages/nft-detail/hooks';

import LENGTH_CONSTANTS from 'constants/length';

export const FIELD_NAMES = {
  KEYWORD: 'keyword',
  PAGE: 'page',
  LIMIT: 'limit',
};

const { KEYWORD, PAGE, LIMIT } = FIELD_NAMES;

const initialValues = {
  [KEYWORD]: '',
  [PAGE]: LENGTH_CONSTANTS.DEFAULT_PAGE,
  [LIMIT]: LENGTH_CONSTANTS.DEFAULT_PAGE_SIZE,
};

const NftOwnerListing = () => {
  const { t } = useTranslation();
  const { id } = useParams() as string | any;

  const [params, setParams] = useState(initialValues);

  const { data, total, loading, totalOwner } = useGetOwnerListing(id, params) as any;

  const handleChangePaging = (page: number, limit: number) => {
    setParams({
      ...params,
      [PAGE]: limit !== params?.[LIMIT] ? LENGTH_CONSTANTS.DEFAULT_PAGE : page,
      limit,
    });
  };

  return (
    <div className="nft-owner-listing">
      <div className="nft-owner-listing__header">
        <PageHeader title={t('nft_detail.txt_owner_listing')} />
      </div>
      <PageContent>
        <SearchForm onSetParams={setParams} params={params} loading={loading} />
        <ItemWithLabel label={`${t('nft_detail.txt_total_owners')}:`}>
          <NumberFormat thousandSeparator value={totalOwner} displayType="text" />
        </ItemWithLabel>
        <ListOwner
          listOwner={data}
          total={total}
          page={params?.page as number}
          limit={params?.limit as number}
          loading={loading}
          handleChangePaging={handleChangePaging}
        />
      </PageContent>
    </div>
  );
};
export default NftOwnerListing;
