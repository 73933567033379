const LENGTH_CONSTANTS = {
  MAX_LENGTH_INPUT: 256,
  DEFAULT_PAGE_SIZE: 10,
  DEFAULT_PAGE_SIZE_OPTIONS: ['10', '20', '50'],
  DEFAULT_PAGE: 1,
  DEFAULT_TOTAL: 0,
  MAX_COLUMNS: 10,
  MAX_LIMIT: 999999999,
  MIN_VALUE: 1e-8,
  MIN_SIZE_IMAGE: 300,
};

export default LENGTH_CONSTANTS;
