import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import showMessage from 'components/Message';

import adminServices from 'services/admin';
import { checkSusscessRequest, excludeResponse } from 'services/api';

import TYPE_CONSTANTS from 'constants/type';
import { renderRoutes, routeURLs } from 'constants/routes';
import MetamaskService from 'services/account/MetamaskService';
import { useAppSelector } from 'hooks/useStore';
import selectedAddress from 'redux/address/selector';
import { ROLE_STATUS } from 'constants/common';

export const useCreateOrEditAdmin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState();
  const { address } = useAppSelector(selectedAddress.getAddress);
  const { library } = useWeb3React();
  const handleCreateAdmin = async ({
    values,
    onCallback,
    onCancelMetamask,
    onError,
  }: {
    values: any;
    onCallback: any;
    onCancelMetamask: any;
    onError: any;
  }) => {
    setLoading(true);
    try {
      const data = {
        ...values,
        status: values?.status ? 'active' : 'inactive',
      };
      const response: any = await adminServices.handleCreateADmin(data);
      if (checkSusscessRequest(response)) {
        if (values?.status) {
          const wallet = new MetamaskService().getInstance();
          await wallet.addAdminContract({
            account: address,
            library,
            data,
            onCallback,
            onCancelMetamask,
            onError,
            responseCreate: response?.data?.data,
          });
        } else {
          history.push(routeURLs.ADMIN);
          showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, 'message.S9');
        }
      } else {
        onCancelMetamask();
      }
    } catch (error) {
      onError();
      onCancelMetamask();
    } finally {
      setLoading(false);
    }
  };

  const handleEditAdmin = async ({
    data,
    id,
    adminDetail,
    onCallback,
    onCancelMetamask,
    onError,
  }: {
    data: any;
    id: string;
    adminDetail: any;
    onCallback: any;
    onCancelMetamask: any;
    onError: any;
  }) => {
    setLoading(true);
    try {
      const status = data?.status ? ROLE_STATUS.ACTIVE.value : ROLE_STATUS.INACTIVE.value;
      const dataCreate = {
        description: data?.description,
        name: data?.name,
      };
      if (adminDetail.status == status) {
        const response = await adminServices.handleUpdateAdmin(dataCreate, id);
        if (checkSusscessRequest(response)) {
          history.push(routeURLs.ADMIN);
          showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, 'message.S3');
        }
      } else {
        const wallet = new MetamaskService().getInstance();
        if (status === ROLE_STATUS.ACTIVE.value) {
          await wallet.addAdminContract({
            account: address,
            library,
            data,
            onCallback,
            onCancelMetamask,
            onError,
            dataApi: dataCreate,
            id,
          });
        } else {
          await wallet.revokeAdminContract({
            account: address,
            library,
            data,
            onCallback,
            onCancelMetamask,
            onError,
            dataApi: dataCreate,
            id,
          });
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    transactionHash,
    onCreateAdmin: handleCreateAdmin,
    onEditAdmin: handleEditAdmin,
  };
};

export const useGetDetailAdmin = (address: string) => {
  const history = useHistory();
  const [adminDetail, setAdminDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (address) {
      handleGetAdminDetail(address);
    }
  }, [address]);

  const handleGetAdminDetail = async (address: string) => {
    setLoading(true);
    try {
      const response = await adminServices.handleGetAdminDetail(address);
      if (checkSusscessRequest(response)) {
        setAdminDetail(response?.data);
      } else {
        history.push(routeURLs.ADMIN);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    adminDetail,
    loading,
    onGetAdminDetail: handleGetAdminDetail,
  };
};

export const useUpdateTransaction = () => {
  const handleUpdateTransaction = async (
    id: string,
    { onSuccess, onError }: { onSuccess: () => void; onError: () => void },
  ) => {
    try {
      const response = await adminServices.handleUpdateTransaction(id);
      if (checkSusscessRequest(response)) {
        if (!response?.data?.isAlreadyCompleted) {
          onSuccess && onSuccess();
        }
      } else {
        if (!excludeResponse.includes(response?.data?.code)) {
          onError && onError();
        }
      }
    } catch (error) {}
  };

  return {
    onUpdateTransaction: handleUpdateTransaction,
  };
};
