import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';

import { renderRoutes } from 'constants/routes';

const ListForSaleButton = ({ visiblity, id }: { visiblity?: boolean; id: string }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRedirectListForsale = () => history.push(renderRoutes.NFT_LIST_ON_SALE(id));

  return (
    <Fragment>
      {visiblity && (
        <AppButton text={t('nft_detail.txt_list_for_sale')} variant="primary" onClick={handleRedirectListForsale} />
      )}
    </Fragment>
  );
};

export default ListForSaleButton;
