import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import { columns } from './columns';
import { NftDetailContext } from 'pages/nft-detail';

import { getRowKey } from 'utils';
import { NFT_STANDARD } from 'constants/nft';
import { EMPTY_DEFAULT_TEXT } from 'constants/common';

type listOwnerProps = {
  total: number;
  listOwner: Array<any> | undefined;
  limit: number;
  page: number;
  loading: boolean;
  handleChangePaging: (page: number, limit: number) => void;
};

const COLUMNS = { QUANTITY: 'quantity', MINTED_BY: 'mintedBy' };

const ListOwner = ({ total, listOwner, limit, page, loading, handleChangePaging }: listOwnerProps) => {
  const { t } = useTranslation();

  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { token = {} } = nftDetail;
  const tokenAddress = token?.address ?? EMPTY_DEFAULT_TEXT;

  const is721Standard = token?.standard === NFT_STANDARD[0].value;

  const columnsByStandard = (column: any) => {
    return columns(t, page, limit, tokenAddress).filter((item) => item.key !== column);
  };

  const columns721 = columnsByStandard(COLUMNS.QUANTITY);
  const columns1155 = columnsByStandard(COLUMNS.MINTED_BY);

  const renderColumns = useMemo(() => {
    switch (true) {
      case is721Standard:
        return columns721;
      default:
        return columns1155;
    }
  }, [is721Standard, tokenAddress, page]);

  return (
    <div>
      <Table
        total={total}
        columns={renderColumns}
        dataSource={listOwner}
        loading={loading}
        pageSize={limit}
        current={page}
        onChangePagination={handleChangePaging}
        rowKey={getRowKey}
      />
    </div>
  );
};

export default ListOwner;
