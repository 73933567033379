import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import poolServices from 'services/pool';
import { checkSusscessRequest, excludeResponse } from 'services/api';

import { routeURLs } from 'constants/routes';
import MetamaskService from 'services/account/MetamaskService';
import { useAppSelector } from 'hooks/useStore';
import selectedAddress from 'redux/address/selector';

export const useCreateOrEditPool = () => {
  const [loading, setLoading] = useState(false);
  const { address } = useAppSelector(selectedAddress.getAddress);
  const { library } = useWeb3React();
  const handleCreatePool = async ({
    values,
    id,
    onCallback,
    onCancelMetamask,
    onError,
    handleCompleteDepositIsDraft,
  }: {
    values: any;
    id?: string;
    onCallback: any;
    onCancelMetamask: any;
    onError: any;
    handleCompleteDepositIsDraft: any;
  }) => {
    setLoading(true);
    try {
      let response: any;
      if (id) {
        response = await poolServices.handleUpdatePool(values, id);
      } else {
        response = await poolServices.handleCreatePool(values);
      }

      if (checkSusscessRequest(response)) {
        if (values?.isDraft) {
          handleCompleteDepositIsDraft(response?.data);
        } else {
          const wallet = new MetamaskService().getInstance();
          await wallet.addPoolContract({
            account: address,
            library,
            dataApi: response,
            onCallback,
            onCancelMetamask,
            onError,
          });
        }
      } else {
        onCancelMetamask();
      }
    } catch (error) {
      onError();
      onCancelMetamask();
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onCreatePool: handleCreatePool,
  };
};

export const useGetDetailPool = (id: string) => {
  const history = useHistory();
  const [poolDetail, setPoolDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      handleGetPoolDetail(id);
    }
  }, [id]);

  const handleGetPoolDetail = async (id: string) => {
    setLoading(true);
    try {
      const response = await poolServices.handleGetPoolDetail(id);
      if (checkSusscessRequest(response)) {
        setPoolDetail(response?.data);
      } else {
        history.push(routeURLs.ADMIN);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    poolDetail,
    loading,
    onGetPoolDetail: handleGetPoolDetail,
  };
};

export const useUpdateTransactionCreatePool = () => {
  const handleUpdateTransaction = async (
    data: any,
    { onSuccess, onError }: { onSuccess: () => void; onError: () => void },
  ) => {
    try {
      const response = await poolServices.handleUpdateTransactionStaking(data?.id, { hash: data.hash });
      if (checkSusscessRequest(response)) {
        if (!response?.data?.isAlreadyCompleted) {
          onSuccess && onSuccess();
        }
      } else {
        if (!excludeResponse.includes(response?.data?.code)) {
          onError && onError();
        }
      }
    } catch (error) {}
  };

  return {
    onUpdateTransaction: handleUpdateTransaction,
  };
};
