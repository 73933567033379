import i18n from 'i18n/i18n';
import { isBuffer, isEmpty, isNil, trim } from 'lodash';

import {
  ATTRIBUTES_REQUIRE_LAND,
  ATTRIBUTES_REQUIRE_WEAPON,
  ATTRIBUTES_WHITE_LIST,
  COLUMNS_INPUT_TYPE,
  COLUMNS_NFT_UPLOAD,
  EXCEL_INTERFACE_OPTION,
  EXCEL_MAP_OPTION,
  EXCEL_RARITY_OPTION,
  EXCEL_WEAPON_TYPE_OPTION,
  NFT_EXCEL_COLUMNS,
  ValidateConditionType,
} from 'constants/bulkUploadNft';
import { formatCommaNumber } from 'utils';
import { NftType } from 'connectors/constants';
import { FILE_TYPE_SUPPORT_PREVIEW } from 'constants/nft';
import moment from 'moment';

export const getNftDataFromExcelRow = (name: any, data: any) => {
  if (!data && data !== 0) {
    return { [name]: null };
  }

  switch (name) {
    case COLUMNS_NFT_UPLOAD.INTERFACE.name: {
      return { [name]: data };
    }
    // case COLUMNS_NFT_UPLOAD.COLLECTION.name: {

    case COLUMNS_NFT_UPLOAD.NAME.name: {
      return {
        [name]: data?.toString()?.trim(),
      };
    }
    default: {
      return { [name]: data };
    }
  }
};

export const getListNftFromExcel = (fileRows: any[]) => {
  const rowsData = fileRows;
  const listErrors: any = [];

  if (!fileRows.length) return { emptyFile: true };

  rowsData.forEach((item: any) => {
    const rowErrors = NFT_EXCEL_COLUMNS.reduce(
      (acc: any, cur: any, idx: any) => [...acc, ...validateNft(trim(item.row[idx + 1]), cur, item.row)],
      [],
    );
    if (!isEmpty(rowErrors)) {
      listErrors.push({ line: item.line, err: rowErrors });
    }
  });
  if (!isEmpty(listErrors)) {
    return { listErrors: listErrors };
  }
  const listNft: any = [];

  rowsData.forEach((item: any) => {
    const nftData = NFT_EXCEL_COLUMNS.reduce((acc: any, cur: any, index: any) => {
      const data = getNftDataFromExcelRow(cur.name, item.row[index + 1]);

      return {
        ...acc,
        ...data,
      };
    }, {});
    listNft.push({ ...nftData, row: item.line });
  });

  return { listNft };
};

const ignoreValidateAttribute = (nftData: any, label: string, requireDependency?: string) => {
  return (
    !(nftData[3] === NftType.WEAPON && ATTRIBUTES_REQUIRE_LAND.map((e: any) => e.label).includes(label)) &&
    !(nftData[3] === NftType.LAND && ATTRIBUTES_REQUIRE_WEAPON.map((e: any) => e.label).includes(label)) &&
    !(!Object.values(NftType).includes(nftData[3]) && requireDependency)
  );
};

const ignoreValidateWhiteList = (nftData: any, label: string) => {
  return !ATTRIBUTES_WHITE_LIST.map((e: any) => e.label).includes(label);
};

const validateWhiteList = (nftData: any, label: string) => {
  return nftData[15] && ATTRIBUTES_WHITE_LIST.map((e: any) => e.label).includes(label);
};

const validateNft = (fieldData: any, condition: ValidateConditionType, nftData: any) => {
  const errs: any = [];
  const { required, maxLength, type, min, max, decimalScale, label = '', requireDependency } = condition || {};

  if (required && !fieldData) {
    if (ignoreValidateAttribute(nftData, label, requireDependency) && ignoreValidateWhiteList(nftData, label)) {
      errs.push(i18n?.t('message.required', { fieldName: i18n?.t(label) }));
    }

    if (validateWhiteList(nftData, label)) errs.push(i18n?.t('message.required', { fieldName: i18n?.t(label) }));

    return errs;
  }

  const pushErrMessage = (isValid: any, mess?: any) => {
    if (isValid === false && ignoreValidateAttribute(nftData, label, requireDependency)) {
      errs.push(mess || i18n?.t('message.E8', { field: i18n?.t(label) }));
    }
  };

  switch (type) {
    case COLUMNS_INPUT_TYPE.NFT_PREVIEW: {
      const nameSplit = fieldData.split('.');

      if (fieldData && !FILE_TYPE_SUPPORT_PREVIEW.includes(fieldData.split('.')[nameSplit.length - 1]))
        pushErrMessage(false, i18n?.t('nftBulk.fileTypePreview', { field: i18n?.t(label), count: maxLength }));

      break;
    }
    case COLUMNS_INPUT_TYPE.STRING: {
      if (!maxLength) break;
      const isValid = !!maxLength && fieldData?.length <= maxLength;
      pushErrMessage(isValid, i18n?.t('nftBulk.longerErr', { field: i18n?.t(label), count: maxLength }));
      break;
    }
    case COLUMNS_INPUT_TYPE.INTERFACE: {
      const isValid = EXCEL_INTERFACE_OPTION.includes(fieldData);
      pushErrMessage(isValid);
      break;
    }
    case COLUMNS_INPUT_TYPE.COPIES: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });
      if (isValidMin === false) {
        pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: 0 }));
        break;
      }
      if (isValidMax === false) {
        pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: formatCommaNumber(max) }));
        break;
      }
      pushErrMessage(isValid);
      break;
    }
    case COLUMNS_INPUT_TYPE.ROYALTIES: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });

      if (isValidMin === false) {
        pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: 0 }));
        break;
      }
      if (isValidMax === false) {
        pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: `${max}%` }));
        break;
      }
      pushErrMessage(isValid);
      break;
    }
    case COLUMNS_INPUT_TYPE.WEAPON_TYPE: {
      const isValid = EXCEL_WEAPON_TYPE_OPTION.includes(fieldData);
      pushErrMessage(isValid);
      break;
    }
    case COLUMNS_INPUT_TYPE.RARITY: {
      const isValid = EXCEL_RARITY_OPTION.includes(fieldData);
      pushErrMessage(isValid);
      break;
    }
    case COLUMNS_INPUT_TYPE.MAP: {
      const isValid = EXCEL_MAP_OPTION.includes(fieldData);
      pushErrMessage(isValid);
      break;
    }

    case COLUMNS_INPUT_TYPE.DPS: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });

      // if (isValidMin === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: min }));
      //   break;
      // }
      // if (isValidMax === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: max }));
      //   break;
      // }
      pushErrMessage(isValid);
      break;
    }

    case COLUMNS_INPUT_TYPE.FIRING_RATE: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });

      // if (isValidMin === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: min }));
      //   break;
      // }
      // if (isValidMax === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.exceedErr', {
      //     field: i18n?.t(label), value: max
      //   }));
      //   break;
      // }
      pushErrMessage(isValid);
      break;
    }

    case COLUMNS_INPUT_TYPE.ACCURACY: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });

      // if (isValidMin === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: min }));
      //   break;
      // }
      // if (isValidMax === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: max }));
      //   break;
      // }
      pushErrMessage(isValid);
      break;
    }

    case COLUMNS_INPUT_TYPE.RANGE: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });

      // if (isValidMin === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: min }));
      //   break;
      // }
      // if (isValidMax === false) {
      //   pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: max }));
      //   break;
      // }
      pushErrMessage(isValid);
      break;
    }

    case COLUMNS_INPUT_TYPE.WHITELIST: {
      if (fieldData) {
        // check data whitelist is exist of list whitelist create for admin.
        pushErrMessage('');
      }
      break;
    }
    case COLUMNS_INPUT_TYPE.DISCOUNT_VALUE: {
      const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
        number: fieldData,
        decimalScale,
        min,
        max,
      });

      if (isValidMin === false) {
        pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: `${0}%` }));
        break;
      }
      if (isValidMax === false) {
        pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: `${max}%` }));
        break;
      }

      pushErrMessage(isValid);
      break;
    }
    case COLUMNS_INPUT_TYPE.LIMIT_PURCHASE: {
      if (fieldData) {
        const { isValid, isValidMin, isValidMax } = checkIsValidNumber({
          number: fieldData,
          decimalScale,
          min,
          max,
        });
        if (isValidMin === false) {
          pushErrMessage(false, i18n?.t('nftBulk.smallerErr', { field: i18n?.t(label), value: 0 }));
          break;
        }

        if (fieldData > nftData[6]) {
          pushErrMessage(false, i18n?.t('nftBulk.exceedErr', { field: i18n?.t(label), value: nftData[6] }));
          break;
        }

        pushErrMessage(isValid);
      }

      break;
    }
    case COLUMNS_INPUT_TYPE.START_DATE: {
      const isValidDateFormat = checkFormatDate(fieldData);
      if (nftData[15]) {
        if (!fieldData) {
          pushErrMessage(false, i18n?.t('nft_creation.txt_whitelist_time_required', { label: i18n?.t(label) }));
          break;
        }
        if (!isValidDateFormat) {
          pushErrMessage(false, i18n?.t('nft_creation.txt_wrong_format_start_date'));
          break;
        }
        if (moment() > moment(fieldData)) {
          pushErrMessage(false, i18n?.t('nft_creation.txt_start_time_invalid'));
          break;
        }
        break;
      }
      break;
    }

    case COLUMNS_INPUT_TYPE.END_DATE: {
      const isValidDateFormat = checkFormatDate(fieldData);
      if (nftData[15]) {
        if (!fieldData) {
          pushErrMessage(false, i18n?.t('nft_creation.txt_whitelist_time_required', { label: i18n?.t(label) }));
          break;
        }
        if (!isValidDateFormat) {
          pushErrMessage(false, i18n?.t('nft_creation.txt_wrong_format_end_date'));
          break;
        }
        if (moment(nftData[18]) >= moment(fieldData)) {
          pushErrMessage(false, i18n?.t('nft_creation.txt_end_time_invalid'));
          break;
        }
      }
    }
  }
  return errs;
};

export const checkIsValidNumber = ({
  number,
  decimalScale,
  min,
  max,
  maxLength,
}: {
  number: any;
  decimalScale?: any;
  min?: any;
  max?: any;
  maxLength?: any;
}) => {
  if (isNaN(number)) {
    return { isValid: false };
  }
  if (decimalScale && number) {
    const decimals = number.toString()?.split('.')[1]?.length || 0;
    if (decimals > decimalScale) {
      return { isValid: false };
    }
  }

  if (!decimalScale) {
    const reg = /^-?\d+$/;
    const isValid = number.match(reg);

    if (!isValid) return { isValid: false };
  }

  if (min >= 0 && Number(number) < min) {
    return { isValid: false, isValidMin: false };
  }
  if (max > 0 && Number(number) > max) {
    return { isValid: false, isValidMax: false };
  }

  if (maxLength && number.length > maxLength) {
    return { isValid: false };
  }
  return { isValid: true };
};

const FORMATE_DATE = 'YYYY-MM-DD HH:mm:ss';

const checkFormatDate = (date: string) => {
  return moment(date, FORMATE_DATE, true).isValid();
};
