import React from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { formatDate, renderName } from 'utils';
import { renderRoutes } from 'constants/routes';
import { POOL_STATUS } from 'constants/common';
import { Switch, Tooltip } from 'antd';
import NumberFormat from 'components/NumberFormat';
import AppNumber from 'components/AppNumber';

export const columns = (t: TFunction, page: number, limit: number, onShowHidePool?: any) => [
  {
    title: t('nft_management.txt_no'),
    dataIndex: 'no',
    key: 'no',
    width: 50,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('pool_management.txt_pool_created'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    sorter: true,
    render: (value: any) => formatDate(value),
  },
  {
    title: t('pool_management.txt_pool_name'),
    dataIndex: 'name',
    key: 'name',
    width: 100,
    render: (value: any) => {
      return renderName(value, 24);
    },
  },
  {
    title: t('pool_management.txt_duration'),
    dataIndex: 'duration',
    key: 'duration',
    width: 100,
    render: (value: any) => {
      const duration = value / 86400;
      return `${duration} days`;
    },
  },
  {
    title: t('pool_management.txt_placeholder_edition'),
    dataIndex: 'editionLimit',
    key: 'editionLimit',
    width: 125,
    sorter: true,
    render: (value: any) => {
      return <AppNumber value={value} isNotFormatDecimal />;
    },
  },
  {
    title: t('pool_management.txt_rewards_fund'),
    dataIndex: 'amount',
    key: 'amount',
    width: 125,
    sorter: true,
    render: (_value: any, row: any) => {
      return (
        <>
          <AppNumber value={_value} isNotFormatDecimal />
          &nbsp; {row?.tokenReward?.symbol}
        </>
      );
    },
  },
  {
    title: t('pool_management.txt_status'),
    dataIndex: 'status',
    key: 'status',
    width: 75,
    render: (value: string) => {
      const curStatus = Object.values(POOL_STATUS).find((val) => val.value === value);
      return <div className={`pool-status-text-${curStatus?.value}`}>{curStatus?.text}</div>;
    },
  },
  {
    title: t('pool_management.txt_show_hide'),
    dataIndex: 'isShow',
    key: 'isShow',
    width: 75,
    render: (value: boolean, row: any) => {
      const disabled = row?.status === POOL_STATUS.DRAFT.value ? true : false;
      const onChange = (checked: boolean) => {
        onShowHidePool(checked, row);
      };
      return <Switch checked={value} onChange={onChange} disabled={disabled} />;
    },
  },
  {
    title: t('nft_management.txt_action'),
    dataIndex: '_id',
    key: '_id',
    width: 75,
    render: (value: any) => {
      return (
        <Link className="view-nft-detail" to={renderRoutes.POOL_DETAIL(value)}>
          {t('common.txt_view_detail')}
        </Link>
      );
    },
  },
];
