import { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';

const ModalCreating: FC<{ visible: boolean; title?: any; content?: any }> = ({
  visible,
  title = 'nftBulk.creatingNft',
  content = 'nftBulk.creatingNftContent',
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      showCloseIcon={false}
      width={565}
      wrapClassName="nft-bulk--wrap"
    >
      <div className="modal-verify-bulk">
        <div className="modal-verify-bulk__title">{t(title)}</div>
        <div className="modal-verify-bulk__desc mb-4">{t(content)}</div>
        <Spin size={'large'} indicator={<LoadingOutlined spin />} />
      </div></Modal>
  );
};

export default ModalCreating;
