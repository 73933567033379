import { api } from 'services/api';

class TransactionServices {
  handleCreateTransaction = (data?: any) => {
    return api.post('transactions', data);
  };

  handleGetSaleAnalytics = (data?: any) => {
    return api.get(`/admin/transactions/sale-analytics`, data);
  };

  handleUpdateTransaction = (id: string, data?: any) => {
    return api.patch(`transactions/${id}`, data);
  };

  handleGetTransaction = (data?: any) => {
    return api.get(`/admin/transactions`, data);
  };

  handleGetOverview = (data?: any) => {
    return api.get(`/admin/transactions/overview`, data);
  };

  handleGetTopNfts = (data?: any) => {
    return api.get(`/admin/transactions/best-selling-nfts`, data);
  };

  handleGetTransactionDetail = (id: string) => {
    return api.get(`/admin/transactions/${id}`);
  };
}

const transactionServices = new TransactionServices();

export default transactionServices;
