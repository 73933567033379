import React from 'react';
import classNames from 'classnames';

import AppButton from 'components/AppButton';

import BackIcon from 'resources/svg/back_icon.svg';

type PageHeaderProps = {
  title: string;
  className?: string | any;
  showBack?: boolean;
  onBack?: () => void;
};

const PageHeader = ({ className, showBack, onBack, title }: PageHeaderProps) => {
  return (
    <div className={classNames('page-header', { [className]: !!className })}>
      {showBack && <AppButton onClick={onBack} text={<img src={BackIcon} />} className="page-header__back-button" />}
      <div className="page-header__title">{title}</div>
    </div>
  );
};

export default PageHeader;
