import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { isString } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  StarOutlined,
  AimOutlined,
  ThunderboltOutlined,
  NodeIndexOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';

import selectedConfig from 'redux/config/selector';

import { useAppSelector } from 'hooks/useStore';
import { limitMaxlengNumber, limitPercentage } from 'utils';
import LENGTH_CONSTANTS from 'constants/length';
import {
  ATTRIBUTE_EXCLUDE,
  LOCALIZATION_NFT_CREATION_ATTRIBUTE,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_TOTAL_SUPPLY,
  NFT_ATTRIBUTE_CREATED_FIELD,
  NFT_DECIMAL_SCALE,
  NFT_DEFAULT_CREATE_FIELD,
  NFT_POSITIVE_SCALE,
} from 'constants/nft';
import { NftType } from 'connectors/constants';

const NFTAtrribute = ({ formikRef, disabled }: any) => {
  const { t } = useTranslation();
  const { id } = useParams() as string | any;
  const nftType = formikRef?.current?.values?.type;
  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = {} } = general;
  const listAttribute = Object.values(attributes);
  const { DPS, ACCURACY, FIRING_RATE, MOBILITY, RANGE, TYPE, MAP, RARITY, TYPEOFWEAPON } = NFT_ATTRIBUTE_CREATED_FIELD;

  const excludeAttribute = listAttribute.filter((attribute: any) => ATTRIBUTE_EXCLUDE.includes(attribute?.name));

  const selectOptionsTypeWeapon: any = [
    {
      name: 'AR',
      value: 'AR',
    },
    {
      name: 'SMG',
      value: 'SMG',
    },
    {
      name: 'SR',
      value: 'SR',
    },
    {
      name: 'LMG',
      value: 'LMG',
    },
    {
      name: 'SG',
      value: 'SG',
    },
  ];

  const selectOptionsRarity: any = [
    {
      name: 'Common',
      value: 'Common',
    },
    {
      name: 'Rare',
      value: 'Rare',
    },
    {
      name: 'Super Rare',
      value: 'Super Rare',
    },
    {
      label: 'Legendary',
      value: 'Legendary',
    },
  ];

  const selectOptionsMap: any = [
    {
      name: 'Map 1',
      value: 'Map 1',
    },
    {
      name: 'Map 2',
      value: 'Map 2',
    },
    {
      name: 'Map 3',
      value: 'Map 3',
    },
  ];

  const handleChangeTypeNft = (value: string) => {
    if (value === NftType.WEAPON && id) {
      formikRef?.current?.setFieldValue(TYPEOFWEAPON, null);
      formikRef?.current?.setFieldValue(RARITY, null);
      formikRef?.current?.setFieldValue(DPS, 0);
      formikRef?.current?.setFieldValue(ACCURACY, 0);
      formikRef?.current?.setFieldValue(FIRING_RATE, 0);
      formikRef?.current?.setFieldValue(MOBILITY, 0);
      formikRef?.current?.setFieldValue(RANGE, 0);
      formikRef?.current?.setFieldValue('dpsInput', 0);
      formikRef?.current?.setFieldValue('accuracyInput', 0);
      formikRef?.current?.setFieldValue('firingrateInput', 0);
      formikRef?.current?.setFieldValue('mobilityInput', 0);
      formikRef?.current?.setFieldValue('rangeInput', 0);
    }
    if (value === NftType.LAND && id) {
      formikRef?.current?.setFieldValue(MAP, null);
    }
  };

  const renderAttributeFormItem = (attributes: Array<any>, options?: any) => {
    return attributes?.map((attribute: any) => {
      const label = t(LOCALIZATION_NFT_CREATION_ATTRIBUTE?.[attribute?.name]?.text);
      const typeInput = attribute?.type?.toUpperCase();
      const selectOptions =
        typeInput === TYPE_INPUT.SELECT
          ? (attribute?.value || [])?.map((item: any) => {
              const value = isString(item) ? item : item?.text;
              return {
                label: value,
                value,
              };
            })
          : [];
      return (
        <Col key={attribute?.name} {...options}>
          <FormItem
            name={attribute?.name}
            label={label}
            required={attribute?.required}
            typeInput={typeInput}
            defaultValue={NftType.WEAPON}
            options={selectOptions}
            thousandSeparator
            onChange={handleChangeTypeNft}
            maxLength={LENGTH_CONSTANTS.MAX_LENGTH_INPUT}
            disabled={disabled}
          />
        </Col>
      );
    });
  };

  const renderAttributeWeapon = (label: string, icon: any, name: string, nameInputSlider: string) => {
    return (
      <Col md={24} xs={24}>
        <FormItem
          min={0}
          max={10}
          name={name}
          nameInputSlider={nameInputSlider}
          label={
            <div className="slider-label">
              <div>{icon}</div>
              <div>
                <p>{`${label} *`}</p>
                <p>Numerical traits that show as progress bar</p>
              </div>
            </div>
          }
          typeInput={TYPE_INPUT.RANGE}
          disabled={disabled}
        />
      </Col>
    );
  };

  return (
    <Card className="nft-creation-page-attribute">
      <Row gutter={20}>
        {renderAttributeFormItem(excludeAttribute, { md: 24, xs: 24 })}

        <Col md={24} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.NAME}
            label={t('nft_creation.txt_name')}
            required
            placeholder={t('nft_creation.txt_name_placeholder')}
            disabled={disabled}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.ROYALTYFEE}
            label={t('nft_creation.txt_royalties')}
            required
            placeholder={t('nft_creation.txt_royalties_placeholder')}
            typeInput={TYPE_INPUT.NUMBER}
            thousandSeparator
            decimalScale={NFT_DECIMAL_SCALE}
            isAllowed={limitPercentage}
            appendInput={<span className="field__span">%</span>}
            disabled={disabled}
          />
        </Col>
        <Col md={12} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.TOTAL_SUPPLY}
            label={t('nft_creation.txt_total_supply')}
            required
            placeholder={t('nft_creation.txt_total_supply_placeholder')}
            typeInput={TYPE_INPUT.NUMBER}
            thousandSeparator
            decimalScale={NFT_POSITIVE_SCALE}
            isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
            disabled={disabled}
          />
        </Col>
        <Col md={24} xs={24}>
          <FormItem
            name={NFT_DEFAULT_CREATE_FIELD.DESCRIPTION}
            label={t('nft_creation.txt_description')}
            placeholder={t('nft_creation.txt_description_placeholder')}
            typeInput={TYPE_INPUT.TEXTAREA}
            description={t('nft_creation.txt_sub_description')}
            maxLength={MAX_LENGTH_DESCRIPTION}
            showCount
            disabled={disabled}
          />
        </Col>

        {(!nftType || nftType === NftType.WEAPON) && (
          <>
            <Col md={12} xs={24}>
              <FormItem
                name={NFT_ATTRIBUTE_CREATED_FIELD.TYPEOFWEAPON}
                label="Type of weapon"
                required
                placeholder="Select the weapon type"
                typeInput={TYPE_INPUT.SELECT}
                options={selectOptionsTypeWeapon}
                thousandSeparator
                disabled={disabled}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormItem
                name={NFT_ATTRIBUTE_CREATED_FIELD.RARITY}
                label="Rarity"
                required
                typeInput={TYPE_INPUT.SELECT}
                thousandSeparator
                options={selectOptionsRarity}
                placeholder="Select the rarity"
                disabled={disabled}
              />
            </Col>
            {renderAttributeWeapon('DPS', <StarOutlined />, NFT_ATTRIBUTE_CREATED_FIELD.DPS, 'dpsInput')}
            {renderAttributeWeapon('Accuracy', <AimOutlined />, NFT_ATTRIBUTE_CREATED_FIELD.ACCURACY, 'accuracyInput')}
            {renderAttributeWeapon(
              'Firing Rate',
              <ThunderboltOutlined />,
              NFT_ATTRIBUTE_CREATED_FIELD.FIRING_RATE,
              'firingrateInput',
            )}
            {renderAttributeWeapon('Range', <RadarChartOutlined />, NFT_ATTRIBUTE_CREATED_FIELD.RANGE, 'rangeInput')}
          </>
        )}

        {nftType === NftType.LAND && (
          <>
            <Col md={24} xs={24}>
              <FormItem
                name={NFT_ATTRIBUTE_CREATED_FIELD.MAP}
                label="Map"
                required
                typeInput={TYPE_INPUT.SELECT}
                thousandSeparator
                options={selectOptionsMap}
                placeholder="Select map"
              />
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
};

export default NFTAtrribute;
