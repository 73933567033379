import React from 'react';

const DashboardIcon = (props: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.99998 3.83331C4.0795 3.83331 3.33331 4.5795 3.33331 5.49998V7.99998C3.33331 8.92045 4.0795 9.66665 4.99998 9.66665H7.49998C8.42045 9.66665 9.16665 8.92045 9.16665 7.99998V5.49998C9.16665 4.5795 8.42045 3.83331 7.49998 3.83331H4.99998Z"
        fill="#EACD00"
      />
      <path
        d="M12.5 3.83331C11.5795 3.83331 10.8333 4.5795 10.8333 5.49998V7.99998C10.8333 8.92045 11.5795 9.66665 12.5 9.66665H15C15.9205 9.66665 16.6666 8.92045 16.6666 7.99998V5.49998C16.6666 4.5795 15.9205 3.83331 15 3.83331H12.5Z"
        fill="#EACD00"
      />
      <path
        d="M3.33331 13C3.33331 12.0795 4.0795 11.3333 4.99998 11.3333H7.49998C8.42045 11.3333 9.16665 12.0795 9.16665 13V15.5C9.16665 16.4205 8.42045 17.1666 7.49998 17.1666H4.99998C4.0795 17.1666 3.33331 16.4205 3.33331 15.5V13Z"
        fill="#EACD00"
      />
      <path
        d="M12.5 11.3333C11.5795 11.3333 10.8333 12.0795 10.8333 13V15.5C10.8333 16.4205 11.5795 17.1666 12.5 17.1666H15C15.9205 17.1666 16.6666 16.4205 16.6666 15.5V13C16.6666 12.0795 15.9205 11.3333 15 11.3333H12.5Z"
        fill="#EACD00"
      />
    </svg>
  );
};

export default DashboardIcon;
