import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select } from 'antd';
import { useFormikContext } from 'formik';

import Card from 'components/AppCard';
import FormItem, { TYPE_INPUT } from 'components/FormItem';

import { MAX_LENGTH_TOTAL_SUPPLY, NFT_DEFAULT_CREATE_FIELD } from 'constants/nft';
import { disabledEndDateWL, disabledStartDateWL, limitMaxlengNumber, limitPercentageWhitelist } from 'utils';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import moment from 'moment';
import { disabledTime } from './utils';

const { IS_PUT_WHITELIST, WHITELIST_NAME, DISCOUNT, NUMBER_OF_ITEM, NUMBER_OF_PUCHASE, START_TIME, END_TIME } =
  NFT_DEFAULT_CREATE_FIELD;

const NFTWhitelist = ({ formikRef, listWhiteList }: any) => {
  const { t } = useTranslation();

  const NUMBER_OF_PUCHASE_WHITELIST = {
    UNLIMITED: false,
    LIMITED: true,
  };

  useEffect(() => {
    const whiteListName = formikRef?.current?.values?.[WHITELIST_NAME];
    const limitedPurchases = formikRef?.current?.values?.[NUMBER_OF_PUCHASE];
    if (whiteListName === 'openCreateWhiteList') {
      formikRef?.current?.setFieldValue(WHITELIST_NAME, null);
    }
    if (!limitedPurchases) {
      formikRef?.current?.setFieldValue(NUMBER_OF_ITEM, '');
    }
  }, [formikRef?.current?.values]);

  const { values, setFieldValue } = useFormikContext() as any;

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      formikRef?.current?.setFieldValue(NUMBER_OF_ITEM, '');
      formikRef?.current?.setFieldValue(WHITELIST_NAME, null);
      formikRef?.current?.setFieldValue(NUMBER_OF_PUCHASE, true);
      formikRef?.current?.setFieldValue(DISCOUNT, '');
      formikRef?.current?.setFieldValue(START_TIME, '');
      formikRef?.current?.setFieldValue(END_TIME, '');
    } else {
      formikRef?.current?.setFieldValue(NUMBER_OF_PUCHASE, false);
    }
  };

  const selectOptionsListWhiteList = useMemo<any[]>(() => {
    const whitelists = listWhiteList?.map((value: any) => {
      return {
        name: (
          <div className="select-whitelists">
            <p>{value?.name}</p>
            <p className="total-address">{`${value?.userAddresses?.length || 0} addresses`}</p>
          </div>
        ),
        value: value?.name,
      };
    });
    return whitelists;
  }, [listWhiteList]);

  const handleFieldChange = (setFieldValue: any, name: string) => (value: any) => {
    setFieldValue(name, isString(value) ? trim(value) : value);
  };

  const [startDate, setStartDate] = useState<any>({
    defaultValue: moment('', 'HH:mm:ss'),
  });

  const [endDate, setEndDate] = useState<any>({
    defaultValue: moment('', 'HH:mm:ss'),
  });

  useEffect(() => {
    if (formikRef?.current?.values?.[START_TIME] && !formikRef?.current?.values?.[END_TIME]) {
      const timeField = formikRef?.current?.values?.[START_TIME];
      const hour = timeField?.hour();
      const minutes = timeField?.minutes();
      const second = timeField?.second();
      setEndDate({
        defaultValue: moment(`${hour}:${minutes}:${second}`, 'HH:mm:ss'),
      });
    }
  }, [formikRef?.current?.values?.[END_TIME], formikRef?.current?.values?.[START_TIME]]);

  const disabledStartTime = (value: any) => {
    const endTime = formikRef?.current?.values?.[END_TIME];
    return disabledTime(endTime, value, 'START_TIME');
  };
  const disabledEndTime = (value: any) => {
    const startTime = formikRef?.current?.values?.[START_TIME];
    return disabledTime(startTime, value, 'END_TIME');
  };

  return (
    <Card className="nft-creation-page-list-for-sale">
      <div className="nft-creation-page-list-for-sale__header">
        <span className="title">{`${t('nft_creation.txt_whitelist')} *`}</span>
        <FormItem typeInput={TYPE_INPUT.SWITCH} name={IS_PUT_WHITELIST} onChange={handleSwitchChange} />
      </div>
      {values?.[IS_PUT_WHITELIST] && (
        <>
          <Row className="nft-creation-page-list-for-sale__content" gutter={20}>
            <Col md={12} xs={24}>
              <FormItem
                name={WHITELIST_NAME}
                showSearch
                required
                placeholder={t('nft_creation.txt_search_whitelist_placeholder')}
                typeInput={TYPE_INPUT.SELECT}
                options={selectOptionsListWhiteList}
                afterSelect={true}
                thousandSeparator
                description={t('nft_creation.txt_sub_description_whitelist')}
              />
            </Col>
            <Col md={12} xs={24}></Col>
          </Row>

          <Row className="nft-creation-page-list-for-sale__content" gutter={20}>
            <Col md={12} xs={24}>
              <FormItem
                format="YYYY-MM-DD HH:mm:ss"
                className="nft-detail-search-form__date"
                name={START_TIME}
                placeholder={t('nft_detail.txt_start_date')}
                onChange={handleFieldChange(setFieldValue, START_TIME)}
                disabledDate={disabledStartDateWL(values?.[END_TIME])}
                typeInput={TYPE_INPUT.DATE}
                disabledTime={disabledStartTime}
                showTime
                showNow
                required
                label={t('nft_creation.whitelist_start_time')}
              />
            </Col>
            <Col md={12} xs={24}>
              <FormItem
                format="YYYY-MM-DD HH:mm:ss"
                className="nft-detail-search-form__date"
                name={END_TIME}
                placeholder={t('nft_detail.txt_end_date')}
                onChange={handleFieldChange(setFieldValue, END_TIME)}
                disabledDate={disabledEndDateWL(values?.[START_TIME])}
                typeInput={TYPE_INPUT.DATE}
                disabledTime={disabledEndTime}
                showTime
                showNow
                required
                label={t('nft_creation.whitelist_end_time')}
              />
            </Col>
          </Row>

          <Row gutter={20}>
            <Col md={12} xs={24}>
              <FormItem
                name={DISCOUNT}
                label={t('nft_creation.txt_discount')}
                required
                description={t('nft_creation.txt_discount_description')}
                typeInput={TYPE_INPUT.NUMBER}
                thousandSeparator
                decimalScale={0}
                isAllowed={limitPercentageWhitelist}
                appendInput={<span className="append-input">%</span>}
              />
            </Col>
            <Col md={12} xs={24}></Col>
          </Row>
          <Row gutter={20}>
            <Col md={12} xs={24}>
              <FormItem
                name={NUMBER_OF_PUCHASE}
                label={t('nft_creation.txt_limit_number_purchase')}
                description={t('nft_creation.txt_limit_number_purchase_placeholder')}
                defaultValue={NUMBER_OF_PUCHASE_WHITELIST.LIMITED}
                options={[
                  { value: NUMBER_OF_PUCHASE_WHITELIST.UNLIMITED, name: t('nft_creation.txt_unlimited') },
                  { value: NUMBER_OF_PUCHASE_WHITELIST.LIMITED, name: t('nft_creation.txt_limited') },
                ]}
                typeInput={TYPE_INPUT.RADIO}
                required
                placeholder={t('nft_creation.txt_name_placeholder')}
              />
            </Col>
            <Col md={12} xs={24}></Col>
          </Row>
          <br></br>
          {formikRef?.current?.values?.[NUMBER_OF_PUCHASE] ? (
            <Row gutter={20}>
              <Col md={12} xs={24}>
                <FormItem
                  name={NUMBER_OF_ITEM}
                  placeholder={t('nft_creation.txt_number_of_item')}
                  required
                  typeInput={TYPE_INPUT.NUMBER}
                  thousandSeparator
                  decimalScale={0}
                  isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
                />
              </Col>
              <Col md={12} xs={24} style={{ marginTop: '10px' }}>
                {t('nft_creation.txt_per_wallet_address')}
              </Col>
            </Row>
          ) : (
            ''
          )}
        </>
      )}
    </Card>
  );
};

export default NFTWhitelist;
