import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import SearchForm from '../SearchForm';
import ListNFT from '../ListNFT';
import ItemWithLabel from 'components/ItemWithLabel';
import NumberFormat from 'components/NumberFormat';

import { useGetListNFTs } from 'pages/nft-management/hooks';

type HeroProps = {
  values: any;
  onSubmit: (values: any) => void;
};

const Hero = ({ values, onSubmit }: HeroProps) => {
  const { t } = useTranslation();

  const { params, searched } = values;
  const { data, total, loading } = useGetListNFTs(params);

  const emtpyText = searched ? t('') : (t('') as string);

  return (
    <Fragment>
      <SearchForm onSubmit={onSubmit} params={params} />
      <ItemWithLabel label={`${t('nft_management.txt_total_nft')}:`}>
        <NumberFormat thousandSeparator value={total} displayType="text" />
      </ItemWithLabel>
      <ListNFT
        total={total}
        loading={loading}
        data={data}
        onSetParams={onSubmit}
        params={params}
        emtpyText={emtpyText}
      />
    </Fragment>
  );
};

export default Hero;
