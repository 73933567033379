import { NftType } from 'connectors/constants';
import {
  DECIMAL_SCALE_ROYALTY_FEE,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_INPUT,
  MAX_LENGTH_ROYALTY_FEE,
  MAX_LENGTH_TOTAL_COPIES,
  MAX_VALUE_ROYALTY_FEE,
  MAX_VALUE_TOTAL_COPIES,
} from './common';
import { MAP, MAX_LENGTH_WEAPON_SLIDE, MAX_WEAPON_SLIDE, MIN_WEAPON_SLIDE, RARITY, WEAPON_TYPE } from './nft';

export const MAX_FOLDER_BULK_NFT_SIZE_GB = 2;
export const MAX_FOLDER_BULK_NFT_SIZE = 2147483648;
export const MAX_FOLDER_BULK_NFT_NUMBER_FILE = 1000;

export const UPLOAD_FOLDER_STATUS = {
  NOT_UPLOAD: 'NOT_UPLOAD',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
  PROGRESS: 'PROGRESS',
};

export const VERIFY_BULK_NFT_STATUS = {
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};

export const MAX_SIZE_NFT_INFORMATION_EXCEL_MB = 20;
export const MAX_SIZE_WHITELIST_INFORMATION_EXCEL_MB = 2;
export const MAX_SIZE_NFT_INFORMATION_EXCEL = 20971520;
export const MAX_SIZE_WHITELIST_INFORMATION_EXCEL = 2097152;
export const MAX_SIZE_NFT_PREVIEW = 2097152;

export const EXCEL_DISPLAY_TYPE = {
  HIDE: 'Hide',
  SHOW: 'Show',
};
export const EXCEL_DISPLAY_OPTION = [EXCEL_DISPLAY_TYPE.HIDE, EXCEL_DISPLAY_TYPE.SHOW];
export const EXCEL_BOOSTS_TYPE = {
  PERCENTAGE: 'boost_percentage',
  NUMBER: 'boost_number',
};
export const EXCEL_BOOSTS_OPTION = [EXCEL_BOOSTS_TYPE.PERCENTAGE, EXCEL_BOOSTS_TYPE.NUMBER];

export const EXCEL_INTERFACE_OPTION = [NftType.WEAPON, NftType.LAND];
export const EXCEL_WEAPON_TYPE_OPTION = [
  WEAPON_TYPE.AR,
  WEAPON_TYPE.LMG,
  WEAPON_TYPE.SG,
  WEAPON_TYPE.SMG,
  WEAPON_TYPE.SR,
];
export const EXCEL_RARITY_OPTION = [RARITY.COMMON, RARITY.LEGENDARY, RARITY.RARE, RARITY.SUPER_RARE];
export const EXCEL_MAP_OPTION = [MAP.MAP_1, MAP.MAP_2, MAP.MAP_3];

export const COLUMNS_INPUT_TYPE = {
  NFT_PREVIEW: 'nftPreview',
  STRING: 'string',
  INTERFACE: 'interface',
  COPIES: 'copies',
  ROYALTIES: 'royalties',
  WEAPON_TYPE: 'typeofweapon',
  RARITY: 'rarity',
  DPS: 'dps',
  ACCURACY: 'accuracy',
  FIRING_RATE: 'firingrate',
  RANGE: 'range',
  MAP: 'map',
  WHITELIST: 'whitelist',
  DISCOUNT_VALUE: 'discountvalue',
  LIMIT_PURCHASE: 'limitpurchase',
  START_DATE: 'startdate',
  END_DATE: 'enddate',
};
export type ValidateConditionType = {
  name: string;
  required?: boolean;
  maxLength?: number;
  label?: string;
  type?: string;
  min?: any;
  max?: any;
  decimalScale?: any;
  requireDependency?: string;
};

export const COLUMNS_NFT_UPLOAD = {
  NFT_CONTENT: {
    name: 'nftContent',
    required: true,
    label: 'nft_creation.txt_nft_content',
  },
  NFT_PREVIEW: {
    name: 'nftPreview',
    label: 'nft_creation.txt_nft_preview',
    type: COLUMNS_INPUT_TYPE.NFT_PREVIEW,
  },
  NAME: {
    name: 'name',
    required: true,
    maxLength: MAX_LENGTH_INPUT,
    label: 'nft_creation.txt_name',
    type: COLUMNS_INPUT_TYPE.STRING,
  },
  INTERFACE: {
    name: 'type',
    required: true,
    label: 'nft_creation.txt_type',
    type: COLUMNS_INPUT_TYPE.INTERFACE,
  },
  ROYALTIES: {
    name: 'royaltyFee',
    required: true,
    type: COLUMNS_INPUT_TYPE.ROYALTIES,
    label: 'nft_creation.txt_royalties',
    maxLength: MAX_LENGTH_ROYALTY_FEE,
    decimalScale: DECIMAL_SCALE_ROYALTY_FEE,
    max: MAX_VALUE_ROYALTY_FEE,
    min: 0.01,
  },
  COPIES: {
    name: 'numberOfCopies',
    required: true,
    type: COLUMNS_INPUT_TYPE.COPIES,
    label: 'nft_creation.txt_total_supply',
    maxLength: MAX_LENGTH_TOTAL_COPIES,
    decimalScale: 0,
    min: 1,
    max: MAX_VALUE_TOTAL_COPIES,
  },
  DESCRIPTION: {
    name: 'description',
    maxLength: MAX_LENGTH_DESCRIPTION,
    label: 'nft_creation.txt_description',
    type: COLUMNS_INPUT_TYPE.STRING,
  },
  WEAPON_TYPE: {
    name: 'typeofweapon',
    label: 'nft_creation.txt_type_of_weapon',
    type: COLUMNS_INPUT_TYPE.WEAPON_TYPE,
    required: true,
    requireDependency: 'type',
  },
  RARITY: {
    name: 'rarity',
    label: 'nft_creation.txt_rarity',
    type: COLUMNS_INPUT_TYPE.RARITY,
    required: true,
    requireDependency: 'type',
  },
  DPS: {
    name: 'dps',
    label: 'nft_creation.txt_dps',
    type: COLUMNS_INPUT_TYPE.DPS,
    required: true,
    maxLength: MAX_LENGTH_WEAPON_SLIDE,
    decimalScale: 0,
    max: MAX_WEAPON_SLIDE,
    min: MIN_WEAPON_SLIDE,
    requireDependency: 'type',
  },
  ACCURACY: {
    name: 'accuracy',
    label: 'nft_creation.txt_accuracy',
    type: COLUMNS_INPUT_TYPE.ACCURACY,
    required: true,
    maxLength: MAX_LENGTH_WEAPON_SLIDE,
    decimalScale: 0,
    max: MAX_WEAPON_SLIDE,
    min: MIN_WEAPON_SLIDE,
    requireDependency: 'type',
  },
  FIRING_RATE: {
    name: 'firingrate',
    label: 'nft_creation.txt_firing',
    type: COLUMNS_INPUT_TYPE.FIRING_RATE,
    required: true,
    maxLength: MAX_LENGTH_WEAPON_SLIDE,
    decimalScale: 0,
    max: MAX_WEAPON_SLIDE,
    min: MIN_WEAPON_SLIDE,
    requireDependency: 'type',
  },
  RANGE: {
    name: 'range',
    label: 'nft_creation.txt_range',
    type: COLUMNS_INPUT_TYPE.RANGE,
    required: true,
    maxLength: MAX_LENGTH_WEAPON_SLIDE,
    decimalScale: 0,
    max: MAX_WEAPON_SLIDE,
    min: MIN_WEAPON_SLIDE,
    requireDependency: 'type',
  },
  MAP: {
    name: 'map',
    label: 'nft_creation.txt_map',
    type: COLUMNS_INPUT_TYPE.MAP,
    required: true,
    requireDependency: 'type',
  },
  WHITELIST: {
    name: 'whitelist',
    label: 'nft_creation.txt_whitelist',
    type: COLUMNS_INPUT_TYPE.WHITELIST,
    required: false,
  },
  DISCOUNT_VALUE: {
    name: 'discount',
    label: 'nft_creation.txt_discount',
    type: COLUMNS_INPUT_TYPE.DISCOUNT_VALUE,
    required: true,
    decimalScale: 0,
    min: 1,
    max: 100,
  },
  LIMIT_PURCHASE: {
    name: 'limitOfPurchase',
    label: 'nft_creation.txt_limit_purchase',
    type: COLUMNS_INPUT_TYPE.LIMIT_PURCHASE,
    required: false,
    decimalScale: 0,
    min: 1,
  },
  START_DATE: {
    name: 'startDate',
    label: 'nft_creation.txt_start_date',
    type: COLUMNS_INPUT_TYPE.START_DATE,
  },
  END_DATE: {
    name: 'endDate',
    label: 'nft_creation.txt_end_date',
    type: COLUMNS_INPUT_TYPE.END_DATE,
  },
};

export const NFT_EXCEL_COLUMNS = [
  COLUMNS_NFT_UPLOAD.NFT_CONTENT,
  COLUMNS_NFT_UPLOAD.NFT_PREVIEW,
  COLUMNS_NFT_UPLOAD.INTERFACE,
  COLUMNS_NFT_UPLOAD.NAME,
  COLUMNS_NFT_UPLOAD.ROYALTIES,
  COLUMNS_NFT_UPLOAD.COPIES, // supply
  COLUMNS_NFT_UPLOAD.DESCRIPTION,
  COLUMNS_NFT_UPLOAD.WEAPON_TYPE,
  COLUMNS_NFT_UPLOAD.RARITY,
  COLUMNS_NFT_UPLOAD.DPS,
  COLUMNS_NFT_UPLOAD.ACCURACY,
  COLUMNS_NFT_UPLOAD.FIRING_RATE,
  COLUMNS_NFT_UPLOAD.RANGE,
  COLUMNS_NFT_UPLOAD.MAP,
  COLUMNS_NFT_UPLOAD.WHITELIST,
  COLUMNS_NFT_UPLOAD.DISCOUNT_VALUE,
  COLUMNS_NFT_UPLOAD.LIMIT_PURCHASE,
  COLUMNS_NFT_UPLOAD.START_DATE,
  COLUMNS_NFT_UPLOAD.END_DATE,
];

export const ATTRIBUTES_REQUIRE_WEAPON = [
  COLUMNS_NFT_UPLOAD.WEAPON_TYPE,
  COLUMNS_NFT_UPLOAD.RARITY,
  COLUMNS_NFT_UPLOAD.DPS,
  COLUMNS_NFT_UPLOAD.ACCURACY,
  COLUMNS_NFT_UPLOAD.FIRING_RATE,
  COLUMNS_NFT_UPLOAD.RANGE,
];

export const ATTRIBUTES_REQUIRE_LAND = [COLUMNS_NFT_UPLOAD.MAP];
export const ATTRIBUTES_WHITE_LIST = [COLUMNS_NFT_UPLOAD.DISCOUNT_VALUE, COLUMNS_NFT_UPLOAD.LIMIT_PURCHASE];
