import { routeURLs } from 'constants/routes';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkSusscessRequest } from 'services/api';
import poolServices from 'services/pool';

export const useGetDetailPool = (id: string) => {
  const history = useHistory();
  const [poolDetail, setPoolDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      handleGetPoolDetail(id);
    }
  }, [id]);

  const handleGetPoolDetail = async (id: string) => {
    setLoading(true);
    try {
      const response = await poolServices.handleGetPoolDetail(id);
      if (checkSusscessRequest(response)) {
        setPoolDetail(response?.data);
      } else {
        history.push(routeURLs.POOL);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    poolDetail,
    loading,
    onGetPoolDetail: handleGetPoolDetail,
  };
};

export const useDeletePool = (id: string, { onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteNFT = async () => {
    setLoading(true);
    try {
      const response = await poolServices.handleDeletePool(id);
      if (checkSusscessRequest(response)) {
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    onDeleteNFT: handleDeleteNFT,
  };
};
