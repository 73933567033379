import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Dropdown, Menu } from 'antd';

import AppAddress from 'components/AppAddress';
import Notification from 'components/Notification';

import selectedAddress from 'redux/address/selector';
import { handleSetAddressNetwork } from 'redux/address/slice';
import { handleSetAuthenticationToken } from 'redux/authentication/slice';

import LogoutIcon from 'resources/svg/logout_icon.svg';
import AdminIcon from 'resources/svg/admin_icon.svg';
import BinanceIcon from 'resources/svg/binance_icon.svg';

import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import selectAuthentication from 'redux/authentication/selector';

const { Header: HeaderAntd } = Layout;
const { Item } = Menu;

const Header = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { address } = useAppSelector(selectedAddress.getAddress);
  const { name } = useAppSelector(selectAuthentication.getAuthenticationToken);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleDisconnect = () => {
    dispatch(handleSetAddressNetwork({}));
    dispatch(handleSetAuthenticationToken(''));
  };

  const overlay = () => {
    return (
      <div className="header-overlay">
        <div className="header-overlay__detail">
          <img className="icon" src={BinanceIcon} alt="Binance Icon" />
          <AppAddress address={address} />
        </div>
        <Menu className="overlay-menu">
          <Item
            key="overlay-menu"
            icon={<img src={LogoutIcon} />}
            onClick={handleDisconnect}
            className="overlay-menu__item"
          >
            {t('common.txt_disconnect')}
          </Item>
        </Menu>
      </div>
    );
  };

  const handleVisibleChange = (visible: any) => setDropdownVisible(visible);

  return (
    <HeaderAntd className="app-header">
      <div className="app-header__dropdown">
        <Notification />
        <span className="dropdown__text">{name || t('common.txt_admin')}</span>
        <Dropdown
          overlay={overlay()}
          trigger={['click']}
          className="dropdown"
          placement="bottomRight"
          getPopupContainer={(trigger: any) => trigger.parentElement}
          visible={dropdownVisible}
          onVisibleChange={handleVisibleChange}
        >
          <img src={AdminIcon} className="cursor-pointer logo" />
        </Dropdown>
      </div>
    </HeaderAntd>
  );
};
export default Header;
