import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Col, Row } from 'antd';

import PageHeader from 'components/PageHeader';
import AppLoading from 'components/AppLoading';
import PageContent from 'components/PageContent';
import ModalStep from 'components/Modal/ModalStep';
import ModalUnsavedChange from 'components/Modal/ModalUnsaveChange';
import Preview from './components/Preview';
import GroupButton from './components/GroupButton';
import SaleOrderForm from './components/SaleOrderForm';

import { useListForSaleNFT } from './hooks';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';
import { useGetDetailNFT } from 'pages/nft-creation/hooks';

import { renderRoutes } from 'constants/routes';
import { TOKEN_SUPPORT_DEFAULT, ZERO_VALUE } from 'constants/common';
import { NFT_DEFAULT_CREATE_FIELD } from 'constants/nft';

import { getListForSaleSchema } from 'utils/schema';

export const NftDetailContext = React.createContext({}) as any;

const { QUANTITY, CURRENCY, UNIT_PRICE } = NFT_DEFAULT_CREATE_FIELD;

const initFormValue = {
  [UNIT_PRICE]: '',
  [QUANTITY]: '',
  [CURRENCY]: TOKEN_SUPPORT_DEFAULT.value,
};

const LIST_FOR_SALE_STEPS = {
  PROCESSING: 1,
  SUCCESSFUL: 2,
  FAILED: 3,
  ERROR_TIME: 4,
};

const { PROCESSING, FAILED, SUCCESSFUL, ERROR_TIME } = LIST_FOR_SALE_STEPS;

const NftListForSale = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const formikRef = useRef(null) as any;

  const { id } = useParams() as any;

  const [stepListForSale, setStepListForSale] = useState(ZERO_VALUE);

  const { nftDetail, loading } = useGetDetailNFT(id) as any;
  const { token = {} } = nftDetail;

  const failed = FAILED === stepListForSale;
  const successful = SUCCESSFUL === stepListForSale;
  const processing = PROCESSING === stepListForSale;
  const errorTime = ERROR_TIME === stepListForSale;

  const handleCompleteListForSale = () => {
    handleUpdateListForSaleStep(ZERO_VALUE);
    history.push(renderRoutes.NFT_DETAIL(nftDetail?._id));
  };

  const onConfirmErrorTime = () => {
    handleUpdateListForSaleStep(ZERO_VALUE);
    history.push(renderRoutes.NFT_EDITION(nftDetail?._id));
  };

  const handleUncompleteListForSale = () => handleUpdateListForSaleStep(ZERO_VALUE);

  const handleUpdateListForSaleStep = (value: number) => setStepListForSale(value);

  const handleErrorListForSale = () => {
    handleUpdateListForSaleStep(FAILED);
    history.push(renderRoutes.NFT_DETAIL(id));
  };

  const handleErrorTime = () => {
    handleUpdateListForSaleStep(ERROR_TIME);
  };

  const { onListForSaleNFT } = useListForSaleNFT();

  const backUrl = renderRoutes.NFT_DETAIL(id);

  const totalMinted = token?.totalMinted ?? ZERO_VALUE;
  const totalSupply = token?.totalSupply ?? ZERO_VALUE;
  const maxOnSaleQuantity = totalSupply - totalMinted;

  const {
    visibleModalUnsaved,
    valueChange,
    setValueChange,
    onCloseModalUnsaved,
    onBackClick,
    onDiscard,
    afterCloseModalUnsaved,
  } = useWarnModalPage(backUrl);

  const handleSubmit = (values: any) => {
    const data = { ...values, currency: values.currency.toLowerCase() };
    handleUpdateListForSaleStep(PROCESSING);
    onListForSaleNFT(id, data, {
      onSuccess: () => handleUpdateListForSaleStep(SUCCESSFUL),
      onError: handleErrorListForSale,
      onErrorShowModal: handleErrorTime,
    });
  };

  return (
    <NftDetailContext.Provider value={{ nftDetail }}>
      <AppLoading loading={loading}>
        <div className="nft-list-for-sale-page">
          <PageHeader showBack title={t('nft_list_for_sale.txt_list_for_sale')} onBack={onBackClick} />
          <PageContent>
            <Row gutter={20}>
              <Formik
                innerRef={formikRef}
                initialValues={initFormValue}
                onSubmit={handleSubmit}
                validationSchema={getListForSaleSchema(t, maxOnSaleQuantity)}
              >
                {({ dirty }: any) => {
                  setValueChange(dirty);
                  return (
                    <Fragment>
                      <Col lg={8} md={12} xs={24}>
                        <Preview />
                      </Col>
                      <Col lg={16} md={12} xs={24}>
                        <Form className="nft-list-for-sale-page-form">
                          <SaleOrderForm
                            maxOnSaleQuantity={maxOnSaleQuantity}
                            nftDetail={nftDetail}
                            formikRef={formikRef}
                          />
                          <GroupButton onDiscard={onDiscard} />
                        </Form>
                      </Col>
                    </Fragment>
                  );
                }}
              </Formik>
            </Row>
          </PageContent>

          <ModalUnsavedChange
            visible={visibleModalUnsaved}
            onClose={onCloseModalUnsaved}
            backUrl={backUrl}
            afterClose={afterCloseModalUnsaved}
          />

          <ModalStep
            visible={stepListForSale !== ZERO_VALUE}
            failed={failed}
            successful={successful}
            processing={processing}
            errorTime={errorTime}
            showCloseIcon={failed || successful || errorTime}
            maskClosable={failed || successful || errorTime}
            onFailedClose={handleUncompleteListForSale}
            onSuccessfulClose={handleCompleteListForSale}
            onConfirmErrorTime={onConfirmErrorTime}
            textSuccessfulDescription={t('nft_list_for_sale.txt_successfull_description', { name: nftDetail?.name })}
            innerHtml
          />
        </div>
      </AppLoading>
    </NftDetailContext.Provider>
  );
};

export default NftListForSale;
