import React from 'react';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';

type GroupButtonProps = {
  onDiscard: () => void;
};

const GroupButton = ({ onDiscard }: GroupButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className="nft-list-for-sale-page-group-button">
      <AppButton text={t('nft_list_for_sale.txt_discard')} onClick={onDiscard} />
      <AppButton text={t('nft_list_for_sale.txt_list_for_sale')} htmlType="submit" variant="primary" />
    </div>
  );
};

export default GroupButton;
