import { PUBLISH_DATE } from 'constants/common';
import moment from 'moment';

const range = (start: any, end: any) => {
  const result = [];

  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};
export const disabledTime = (currentSelectTime: any) => {
  const currentDate = moment()?.format('DD/MM/YYYY');
  const dateSelect = currentSelectTime?.format('DD/MM/YYYY');

  const currentHour = moment()?.format('HH');
  const hourSelect = currentSelectTime?.format('HH');

  const currentMinutes = moment()?.format('mm');
  const minutesSelect = currentSelectTime?.format('mm');

  const date = moment();
  const hourNow = date?.hour();
  const minutesNow = date?.minutes();
  const secondsNow = date?.seconds();
  const dataCheck: any = {};

  if (currentDate === dateSelect) {
    dataCheck.disabledHours = () => range(0, hourNow);
  }
  if (currentDate === dateSelect && currentHour === hourSelect) {
    dataCheck.disabledMinutes = () => range(0, minutesNow);
  }
  if (currentDate === dateSelect && currentHour === hourSelect && currentMinutes === minutesSelect) {
    dataCheck.disabledSeconds = () => range(0, secondsNow);
  }
  return dataCheck;
};
export const disabledTimeEndPool = (timeSelect: any, endTimePool: any) => {
  const timeSelectDate = timeSelect?.format('DD/MM/YYYY');
  const endTimePoolDate = endTimePool?.format('DD/MM/YYYY');

  const timeSelectHour = timeSelect?.format('HH');
  const endTimePoolHour = endTimePool?.format('HH');

  const timeSelectMinutes = timeSelect?.format('mm');
  const endTimePoolMinutes = endTimePool?.format('mm');

  const timeSelectSecond = timeSelect?.format('ss');
  const endTimePoolSecond = endTimePool?.format('ss');
  const dataCheck: any = {};
  if (timeSelectDate === endTimePoolDate) {
    dataCheck.disabledHours = () => range(0, endTimePoolHour);
    dataCheck.disabledMinutes = () => range(0, endTimePoolMinutes);
    dataCheck.disabledSeconds = () => range(0, endTimePoolSecond);
  }

  return dataCheck;
};

export const getTimeSeconds = (datetime: any, datetime_: any) => {
  let data = 0;
  data = datetime && datetime_ && datetime.diff(datetime_, 'seconds');
  return data;
};

export const validatePublishDate = (value: any, datePublish: any) => {
  let error;
  if (value?.publishType === PUBLISH_DATE.PICK_A_DATE && !datePublish) {
    error = 'Pick a date is required.';
  }
  if (datePublish && getTimeSeconds(datePublish, moment()) < 0) {
    error = 'You can not choose Launch Date before the current time';
  }
  if (datePublish && value?.endTimeStaking && getTimeSeconds(value?.endTimeStaking, datePublish) < 0) {
    error = 'Launch Date can not be later than End Time For Staking.';
  }
  return error;
};

export const validateEdition = (edition: number) => {
  if (edition == 0) {
    return 'Please provide a positive value';
  }
};

export const validateTotalReward = (totalReward: number, minimumReward: any) => {
  if (totalReward == 0) {
    return 'Please provide a positive value';
  }
  if (Number(totalReward) < Number(minimumReward)) {
    return 'Total Rewards must be greater than Minimum Rewards ';
  }
};

export const validateEndTimeStaking = (value: any, endTimeStaking: any) => {
  let error;

  if (endTimeStaking && getTimeSeconds(endTimeStaking, moment()) < 0) {
    error = 'You can not choose End Time For Staking before the current time';
  }

  return error;
};

export const setValueEndTimePool = (formikRef: any, setEndTimePool: any) => {
  const endTimeStaking = formikRef?.current?.values?.endTimeStaking;
  const duration = formikRef?.current?.values?.duration;
  if (endTimeStaking && duration) {
    const endTimePool = moment(endTimeStaking).add(duration, 'seconds');
    setEndTimePool(endTimePool);
    formikRef?.current?.setFieldValue('endTimePool', endTimePool);
  }
};
