import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import AppButton from 'components/AppButton';
import AdminList from './components/AdminList';

import LENGTH_CONSTANTS from 'constants/length';
import { routeURLs } from 'constants/routes';
import { NFT_MANAGEMENT_FIELD, NFT_TABS } from 'constants/nft';

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;
const { KEYWORD, STATUS, PAGE, LIMIT, TYPE } = NFT_MANAGEMENT_FIELD;

const initParams = {
  [KEYWORD]: '',
  [STATUS]: '',
  role: '',
  [PAGE]: DEFAULT_PAGE,
  [LIMIT]: DEFAULT_PAGE_SIZE,
};

const NFTManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [adminList, setAdminList] = useState({
    params: { ...initParams, [TYPE]: NFT_TABS.HERO.type },
  });

  const handleSubmit = (values: any) => {
    setAdminList({
      ...adminList,
      params: {
        ...values,
      },
    });
  };

  const handleRedirectAdminCreationPage = () => history.push(routeURLs.ADMIN_CREATION);

  return (
    <div className="nft-management-page">
      <div className="nft-management-page__header">
        <PageHeader title={t('admin_management.txt_admin_management')} />
        <AppButton
          text={`+ ${t('admin_management.txt_create_admin')}`}
          onClick={handleRedirectAdminCreationPage}
          variant="primary"
          className="nft-management-page__button"
        />
      </div>
      <div className="nft-management-page__app-tab">
        <AdminList values={adminList} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default NFTManagement;
