import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ModalConfirm from 'components/Modal/ModalConfirm';

type ModalUnsavedChangeProps = {
  visible: boolean;
  onClose?: any;
  backUrl: string;
  afterClose?: any;
  isModalForm?: boolean;
  confirmModalForm?: any;
};

const ModalUnsavedChange = ({
  visible,
  onClose,
  backUrl,
  afterClose,
  isModalForm,
  confirmModalForm,
}: ModalUnsavedChangeProps) => {
  const history = useHistory();
  const onConfirm = () => {
    if (!isModalForm) {
      history.push(backUrl);
    } else {
      onClose();
      confirmModalForm();
    }
  };
  const { t } = useTranslation();
  return (
    <ModalConfirm
      visible={visible}
      title={t('common.txt_unsave_changed')}
      onClose={onClose}
      onConfirm={onConfirm}
      decsription={t('common.txt_unsave_changed_description')}
      confirmText={t('common.txt_leave_page')}
      afterClose={afterClose}
    />
  );
};

export default ModalUnsavedChange;
