import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Spin } from 'antd';

import InfoItem from '../InfoItem';
import { NftDetailContext } from 'pages/nft-detail';
import ModalComponent from 'components/Modal';
import { useGetDetailWhiteList } from 'pages/whitelist-management/hooks';
import AppAddress from 'components/AppAddress';
import { convertUtcToCurrentTime } from 'utils';

const Whitelist = () => {
  const { t } = useTranslation();

  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { whitelist = {} } = nftDetail;
  const [visible, setVisible] = useState<boolean>(false);
  const { whitelistDetail, handleGetDetailWhiteList } = useGetDetailWhiteList();
  const [loading, setLoading] = useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };
  const renderModalWhitelist = () => {
    handleGetDetailWhiteList(whitelist?.id, toggleModal, setLoading);
  };

  const whitelistInfor: any = [
    {
      label: t('nft_creation.txt_whitelist'),
      value: (
        <u className="whitelist-detail" onClick={renderModalWhitelist}>
          {whitelist?.whitelistName}
        </u>
      ),
      helpText: t('nft_creation.txt_tooltip_whitelist'),
    },
    {
      label: t('nft_creation.txt_discount'),
      value: `${whitelist?.discountValue} %`,
      helpText: t('nft_creation.txt_tooltip_discount'),
    },
    {
      label: t('nft_creation.whitelist_start_time'),
      value: `${convertUtcToCurrentTime(whitelist?.startTime)}`,
    },
    {
      label: t('nft_creation.whitelist_end_time'),
      value: `${convertUtcToCurrentTime(whitelist?.endTime)}`,
    },
  ];
  if (whitelist?.limitedPurchases === 'true') {
    whitelistInfor.push({
      label: t('nft_creation.txt_limit_number_purchase'),
      value: t('nft_creation.txt_number_of_purchase_detail', { totalUser: whitelist?.itemsPerWalletAddress }),
    });
  } else if (whitelist?.whitelistName) {
    whitelistInfor.push({
      label: t('nft_creation.txt_limit_number_purchase'),
      value: t('nft_creation.txt_number_of_purchase_detail_unlimited'),
    });
  }

  return (
    <Row gutter={20}>
      {whitelist?.whitelistName &&
        whitelistInfor.map((value: any, index: number) => {
          return (
            <InfoItem key={index} label={value?.label} helpText={value?.helpText}>
              {value?.value}
            </InfoItem>
          );
        })}

      <ModalComponent visible={visible} width={550} onClose={toggleModal} showCloseIcon={true}>
        <div className="list-address-modal">
          <div className="title">{whitelist?.whitelistName}</div>
          <Spin spinning={loading}>
            <Row className="title-list-adress">
              <Col span={8}>No</Col>
              <Col span={16}>Wallet Address</Col>
            </Row>
            <div className="list-adress">
              {whitelistDetail?.userAddresses?.map((address: string, index: number) => {
                return (
                  <Row key={index}>
                    <Col span={8}>{index + 1}</Col>
                    <Col span={16}>
                      <AppAddress address={address} totalPrefixNumber={10} />
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Spin>
        </div>
      </ModalComponent>
    </Row>
  );
};

export default Whitelist;
