import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Col, Row } from 'antd';
import get from 'lodash/get';

import AppLoading from 'components/AppLoading';
import PageHeader from 'components/PageHeader';
import AdminAtrribute from './components/AdminAtrribute';
import AdminGroupButton from './components/AdminGroupButton';

import selectedConfig from 'redux/config/selector';
import { useCreateOrEditAdmin, useGetDetailAdmin } from './hooks';
import { useAppSelector } from 'hooks/useStore';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';

import { checkValueNftChange, getAttributeFieldNFTValues, getDefaultFieldNFTValues } from 'utils';
import { getAdminSchema } from 'utils/schema';

import { externalRoutes, renderRoutes, routeURLs } from 'constants/routes';
import { ROLE, ROLE_STATUS, ZERO_VALUE } from 'constants/common';
import ModalStep from 'components/Modal/ModalStep';
import { REMOVE_FROM_SALE_STEPS } from 'constants/nft';
import AppButton from 'components/AppButton';
import ModalConfirm from 'components/Modal/ModalConfirm';
import { useUpdateTransaction } from 'pages/admin-creation/hooks';
import showMessage from 'components/Message';
import TYPE_CONSTANTS from 'constants/type';

const initFormValue = {
  status: true,
  name: '',
  address: '',
  role: ROLE.CREATOR_ADMIN.value,
  description: '',
} as any;

const NFTCreation = () => {
  const { PROCESSING, SUCCESSFUL, FAILED } = REMOVE_FROM_SALE_STEPS;
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;
  const { address } = useParams() as string | any;
  const { loading: loadingCreateNFT, onCreateAdmin, onEditAdmin } = useCreateOrEditAdmin();
  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = [] } = general;
  const { adminDetail, loading: loadingDetailNFT } = useGetDetailAdmin(address) as any;
  // const backUrl = address ? renderRoutes.ADMIN_DETAIL(address) : routeURLs.ADMIN;
  const backUrl = routeURLs.ADMIN;
  const { setValueChange } = useWarnModalPage(backUrl);
  const [visibleModalConfirm, setVisibleModalConfirm] = useState(false);
  const history = useHistory();
  const { onUpdateTransaction } = useUpdateTransaction();
  const [stepMint, setStepMint] = useState(ZERO_VALUE);
  const [transactionHash, setTransactionHash] = useState('');
  const [dataCreate, setDataCreate] = useState<any>();
  const failed = FAILED === stepMint;
  const successful = SUCCESSFUL === stepMint;
  const processing = PROCESSING === stepMint;

  useEffect(() => {
    if (adminDetail?._id) {
      formikRef.current.setValues({
        status: adminDetail?.status === ROLE_STATUS.ACTIVE.value,
        name: adminDetail?.name,
        address: adminDetail?.address,
        role: adminDetail?.role,
        description: adminDetail?.description,
      });
    }
  }, [adminDetail, attributes]);

  const handleSubmit = async (values: any = {}) => {
    const data = {
      ...values,
      name: values?.name?.replace(/\s+/g, ' ').trim(),
    };
    handleToggleCreateAdmin();
    setDataCreate(data);
  };

  const handleCompleteMinted = (data: any) => {
    setTransactionHash(data?.hash);
    onUpdateTransaction(data?.id, {
      onError: handleMintedError,
      onSuccess: () => {
        handleUpdateMintedStep(SUCCESSFUL);
      },
    });
  };

  const handleMintedError = () => {
    handleUpdateMintedStep(FAILED);
  };

  const handleUpdateMintedStep = (value: number) => setStepMint(value);

  const handleCloseMintedModal = () => {
    if (stepMint === SUCCESSFUL) {
      handleUpdateMintedStep(ZERO_VALUE);
      onBackClick();
      showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, address ? 'message.S3' : 'message.S9');
    }
    handleUpdateMintedStep(ZERO_VALUE);
  };

  const renderMintSuccessText = useMemo(
    () => (
      <div>
        {address ? (
          <>
            <p>You have successfully updated this account</p>
            <p className="address-admin">{dataCreate?.address}</p>
          </>
        ) : (
          <>
            <p>You have successfully added</p>
            <p className="address-admin">{dataCreate?.address}</p>
            <p>as an Admin</p>
          </>
        )}
        <a href={externalRoutes.BSC_SCAN(transactionHash)} target="_blank" rel="noreferrer">
          <AppButton text={t('nft_detail.txt_view_on_bscscan')} className="description__button" />
        </a>
      </div>
    ),
    [transactionHash],
  );

  const handleToggleCreateAdmin = () => {
    setVisibleModalConfirm(!visibleModalConfirm);
  };
  const onConfirmCreateAdmin = () => {
    setStepMint(PROCESSING);
    handleToggleCreateAdmin();
    if (address) {
      onEditAdmin({
        data: dataCreate,
        id: adminDetail?._id,
        adminDetail,
        onCallback: (data?: any) => handleCompleteMinted(data),
        onCancelMetamask: handleCloseMintedModal,
        onError: handleMintedError,
      });
    } else {
      onCreateAdmin({
        values: dataCreate,
        onCallback: (data: any) => handleCompleteMinted(data),
        onCancelMetamask: handleCloseMintedModal,
        onError: handleMintedError,
      });
    }
  };
  const onBackClick = () => {
    history.push(backUrl);
  };

  const nftSchema = getAdminSchema(t);
  return (
    <AppLoading loading={loadingDetailNFT || loadingCreateNFT}>
      <div className="admin-creation-page">
        <PageHeader
          showBack
          title={!address ? t('admin_management.txt_create_admin') : t('admin_management.txt_detail_admin')}
          onBack={onBackClick}
        />

        <Formik innerRef={formikRef} initialValues={initFormValue} onSubmit={handleSubmit} validationSchema={nftSchema}>
          {({ values }: any) => {
            setValueChange(checkValueNftChange(address ? adminDetail : initFormValue, values, !!address));
            return (
              <Form className="admin-creation-page-form">
                <Row gutter={20} justify="space-between">
                  <Col lg={24} xs={24}>
                    <AdminAtrribute formikRef={formikRef} adminDetail={adminDetail} />
                    <AdminGroupButton isSubmit={loadingCreateNFT} onDiscard={onBackClick} address={address} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>

        <ModalConfirm
          visible={visibleModalConfirm}
          onClose={handleToggleCreateAdmin}
          title={address ? 'Update Admin' : 'Add New Admin'}
          innerHtml
          decsription={
            address ? 'Are you sure you want to update this Admin?' : 'Are you sure you want to add this new Admin?'
          }
          confirmText="Yes"
          onConfirm={onConfirmCreateAdmin}
        />
      </div>
      <ModalStep
        visible={ZERO_VALUE !== stepMint}
        failed={failed}
        successful={successful}
        processing={processing}
        showCloseIcon={failed || successful}
        maskClosable={failed || successful}
        onFailedClose={handleCloseMintedModal}
        onSuccessfulClose={handleCloseMintedModal}
        successfulDescription={renderMintSuccessText}
        innerHtml
      />
    </AppLoading>
  );
};

export default NFTCreation;
