import React from 'react';
import { useTranslation } from 'react-i18next';

import AppButton from 'components/AppButton';

type PoolGroupButtonProps = {
  isSubmit: boolean;
  onDiscard: () => void;
  formikRef: any;
  id?: string;
};

const AdminGroupButton = ({ isSubmit, onDiscard, formikRef, id }: PoolGroupButtonProps) => {
  const handleLaunch = () => {
    formikRef.current.setFieldValue('isDraft', false);
  };
  const handleSave = () => {
    formikRef.current.setFieldValue('isDraft', true);
  };
  return (
    <div className="admin-creation-page-group-button">
      <AppButton
        text="Launch"
        htmlType="submit"
        disabled={isSubmit}
        loading={isSubmit}
        variant="primary"
        onClick={handleLaunch}
      />
      <AppButton text="Discard" onClick={onDiscard} disabled={isSubmit} />
      <AppButton
        text={id ? 'Save Changes' : 'Save As Draft'}
        htmlType="submit"
        loading={isSubmit}
        disabled={isSubmit}
        onClick={handleSave}
      />
    </div>
  );
};

export default AdminGroupButton;
