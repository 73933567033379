import React, { useEffect } from 'react';

import selectedAddress from 'redux/address/selector';

import { useAppSelector } from './useStore';

type useChangeAddressProps = {
  callback: () => void;
};

export const useChangeAddress = ({ callback }: useChangeAddressProps) => {
  const { address } = useAppSelector(selectedAddress.getAddress);

  useEffect(() => {
    address && callback && callback();
  }, [address]);
};
