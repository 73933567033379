import classNames from 'classnames';
import React, { ReactNode } from 'react';

type CardProps = {
  children: ReactNode;
  className?: string;
};

const Card = ({ children, className }: CardProps) => {
  return <div className={classNames('card', className)}>{children}</div>;
};

export default Card;
