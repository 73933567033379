import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ModalConfirm from 'components/Modal/ModalConfirm';
import showMessage from 'components/Message';
import { useDeletePool } from 'pages/pool-detail/hooks';

import { routeURLs } from 'constants/routes';
import TYPE_CONSTANTS from 'constants/type';

const DeleteButton = ({ poolDetail }: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [visible, setVisible] = useState(false);

  const handleToggleDeletePool = () => setVisible(!visible);

  const handleSuccessulDeletion = () => {
    history.push(routeURLs.POOL);
    handleToggleDeletePool();
    showMessage(TYPE_CONSTANTS.MESSAGE.SUCCESS, 'message.S12', { poolName: poolDetail?.name });
  };

  const handleErrorDeletion = () => {
    history.push(routeURLs.POOL);
    handleToggleDeletePool();
  };

  const { loading, onDeleteNFT } = useDeletePool(poolDetail?._id, {
    onSuccess: handleSuccessulDeletion,
    onError: handleErrorDeletion,
  });

  return (
    <Fragment>
      <div className="delete-button" onClick={handleToggleDeletePool}>
        Delete
      </div>
      <ModalConfirm
        visible={visible}
        onClose={handleToggleDeletePool}
        title="Deleting Pool"
        innerHtml
        decsription={`Are you sure you want to delete the pool named <b> ${poolDetail?.name} </b>?`}
        confirmText="Yes"
        onConfirm={onDeleteNFT}
        loading={loading}
      />
    </Fragment>
  );
};

export default DeleteButton;
