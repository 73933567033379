import { NftType } from 'connectors/constants';
import { TOKEN_SUPPORT_DEFAULT } from './common';
import { CURRENCY } from './revenue';

export const NFT_PREFIX_CODE = '#';
export const NFT_PERCENTAGE_SUFFIX = '%';

export const NFT_MEDIA = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  MODEL: '3d',
};

export const NFT_TABS = {
  HERO: {
    key: 'HERO',
    label: 'nft_management.txt_weapon',
    type: 'Weapon',
  },
  ITEM: {
    key: 'ITEM',
    label: 'nft_management.txt_land',
    type: 'Land',
  },
};

export const NFT_DETAIL_TABS = {
  PROFILE: {
    key: 'PROFILE',
    label: 'nft_detail.txt_profile',
    query: 'nft-profile',
  },
  SALE_HISTORY: {
    key: 'SALE_HISTORY',
    label: 'nft_detail.txt_sale_history',
    query: 'sale-history',
  },
};

export const NFT_PROFILE_TABS = {
  PREVIEW: {
    key: 'PREVIEW',
    label: 'nft_detail.txt_preview',
  },
  CONTENT: {
    key: 'CONTENT',
    label: 'nft_detail.txt_content',
  },
};

export const NFT_STATUS_ALL = 'all';
export const NFT_STATUS = [
  {
    name: 'nft_management.txt_offsale',
    value: 'off-sale',
  },
  {
    name: 'nft_management.txt_onsale',
    value: 'on-sale',
  },
  {
    name: 'nft_management.txt_soldout',
    value: 'sold-out',
  },
];

export const STATUS_NFT = [
  {
    name: 'nft_management.txt_all',
    value: NFT_STATUS_ALL,
  },
  {
    name: 'nft_management.txt_offsale',
    value: 'off-sale',
  },
  {
    name: 'nft_management.txt_onsale',
    value: 'on-sale',
  },
  {
    name: 'nft_management.txt_soldout',
    value: 'sold-out',
  },
];

export const LIST_SUPPORT_FILE = [
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/gif',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'video/ogg',
  'video/mp4',
  'video/webm',
  '3d/glb',
];

export const LIST_PREVIEW_SUPPORT_FILE = ['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif'];

export const EXTENSION_3D_SUPPORT_ARRAY = ['3d/glb'];

export const MAX_FILE_SIZE = 100;
export const MAX_PREVIEW_SIZE = 2;
export const MAX_FILE_SIZE_BYTE = 104857600; // byte

export const MAX_FILE_NUMBER = 1;
export const MIN_VALUE_TOTAL_COPIES = 1;
export const MAX_VALUE_TOTAL_COPIES = 1000000;
export const MAX_LENGTH_DESCRIPTION = 3000;
export const MAX_VALUE_ROYALTY_FEE = 50;
export const MAX_LENGTH_PRICE = 12;
export const MAX_LENGTH_TOTAL_SUPPLY = 7;
export const NFT_POSITIVE_SCALE = 0;
export const NFT_DECIMAL_SCALE = 2;
export const NFT_DECIMAL_SCALE_PRICE = 18;
export const NFT_USD_DECIMAL_SCALE = 8;

export const ATTRIBUTE_EXCLUDE = ['type'];

export const NFT_DEFAULT_ID = '0000';

export const NFT_DEFAULT_PRICE = '$--';

export const LOCALIZATION_NFT_CREATION_ATTRIBUTE = {
  type: {
    text: 'nft_creation.txt_type',
    placeholder: 'nft_creation.txt_type_placeholder',
  },
  mythology: {
    text: 'nft_creation.txt_dps',
    placeholder: 'nft_creation.txt_methology_placeholder',
  },
  god: {
    text: 'nft_creation.txt_god',
    placeholder: 'nft_creation.txt_god_placeholder',
  },
  level: {
    text: 'nft_creation.txt_level',
    placeholder: 'nft_creation.txt_level_placeholder',
  },
  class: {
    text: 'nft_creation.txt_class',
    placeholder: 'nft_creation.txt_class_placeholder',
  },
} as any;

export const LOCALIZATION_NFT_DETAIL_ATTRIBUTE = {
  typeofweapon: {
    text: 'nft_creation.txt_type',
    nftType: NftType.WEAPON,
  },
  rarity: {
    text: 'nft_creation.txt_rarity',
    nftType: NftType.WEAPON,
  },
  dps: {
    text: 'nft_creation.txt_dps',
    nftType: NftType.WEAPON,
  },
  accuracy: {
    text: 'nft_creation.txt_accuracy',
    nftType: NftType.WEAPON,
  },
  firingrate: {
    text: 'nft_creation.txt_firing',
    nftType: NftType.WEAPON,
  },
  range: {
    text: 'nft_creation.txt_range',
    nftType: NftType.WEAPON,
  },
  map: {
    text: 'nft_creation.txt_map',
    nftType: NftType.LAND,
  },
} as any;

export const NFT_DEFAULT_CREATE_FIELD = {
  FILE: 'file',
  FILE_PREVIEW: 'filePreview',
  NAME: 'name',
  ROYALTYFEE: 'royaltyFee',
  TOTAL_SUPPLY: 'totalSupply',
  DESCRIPTION: 'description',
  IS_PUT_ON_SALE: 'isPutOnSale',
  IS_PUT_WHITELIST: 'isPutWhitelist',
  QUANTITY: 'quantity',
  UNIT_PRICE: 'unitPrice',
  CURRENCY: 'currency',
  IMAGE_MEDIUM: 'imageMedium',
  IMAGE_SMALL: 'imageSmall',

  WHITELIST_NAME: 'whitelistName',
  DISCOUNT: 'discountValue',
  NUMBER_OF_ITEM: 'itemsPerWalletAddress',
  NUMBER_OF_PUCHASE: 'limitedPurchases',
  WHITELIST_PRICE: 'whitelistPrice',

  START_TIME: 'startTime',
  END_TIME: 'endTime',
};

export const NFT_ATTRIBUTE_CREATED_FIELD = {
  DPS: 'dps',
  ACCURACY: 'accuracy',
  FIRING_RATE: 'firingrate',
  MOBILITY: 'mobility',
  RANGE: 'range',
  TYPE: 'type',
  TYPEOFWEAPON: 'typeofweapon',
  RARITY: 'rarity',
  CLASS: 'class',
  GOD: 'god',
  MYTHOLOGY: 'mythology',
  LEVEL: 'level',
  MAP: 'map',
};
export const NFT_MANAGEMENT_ATTRIBUTE_FIELD = {
  TYPE: 'type',
  TYPE_WEAPON: 'typeofweapon',
  RARITY: 'rarity',
  DPS: 'dps',
  ACCURACY: 'accuracy',
  FIRING_RATE: 'firingrate',
  MOBILITY: 'mobility',
  RANGE: 'range',
  MAP: 'map',
};
export const NFT_ATTRIBUTE_CREATED_FIELD_SLIDER = {
  DPS_INPUT: 'dps',
  ACCURACY_INPUT: 'accuracy',
  FIRING_RATE_INPUT: 'firingrate',
  MOBILITY_INPUT: 'mobility',
  RANGE_INPUT: 'range',
};

export const NFT__CREATED_WHITELIST = {
  WHITELIST_NAME: 'whitelistName',
  DISCOUNT: 'discountValue',
  NUMBER_OF_ITEM: 'itemsPerWalletAddress',
  NUMBER_OF_PUCHASE: 'limitedPurchases',
  IS_PUT_WHITELIST: 'isPutWhitelist',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
};

export const SERVER_PARAMS_CONFIG = {
  TOKEN: 'token',
  SALE_ORDER: 'saleOrder',
  ATTRIBUTES: 'attributes',
  WHITELIST: 'whitelist',
};

export const NFT_MINTED_FIELD = {
  QUANTITY: 'quantity',
  TO_ADDRESS: 'toAddress',
};

export const NFT_TRANSACTION_TYPE = {
  LISTED: 'listed',
  DELISTED: 'delisted',
  MINTED: 'minted',
  TRANSFER: 'transfer',
  ADMIN_MINTED: 'admin-minted',
};

export const NFT_TRANSACTION_STATUS = {
  DRAFT: 'draft',
  SUCCESS: 'success',
  CANCEL: 'cancel',
  FAILED: 'failed',
};

export const NFT_STANDARD = [
  { value: 'erc-721', key: 0, label: 'common.txt_erc_721' },
  { value: 'erc-1155', key: 1, label: 'common.txt_erc_1155' },
];

export const NFT_SALE_HISTORY_FIELD = {
  KEYWORD: 'keyword',
  FROM: 'from',
  UNTIL: 'until',
  TYPE: 'type',
  PAGE: 'page',
  LIMIT: 'limit',
  ORDER: 'order',
  SORT: 'sort',
};

export const NFT_SALE_HISTORY_FIELD_SORTER = {
  DEFAULT: 'default',
  CREATED_AT: 'createdAt',
  QUANTITY: 'quantity',
  UNIT_PRICE: 'saleOrder.unitPrice',
  SALE_ORDER: 'saleOrder',
  REVENUE: 'revenue',
};

export const NFT_MANAGEMENT_FIELD = {
  KEYWORD: 'keyword',
  STATUS: 'status',
  PAGE: 'page',
  LIMIT: 'limit',
  SORT: 'sort',
  TYPE: 'type',
};

export const NFT_MANAGEMENT_FIELD_SORTER = {
  DEFAULT: 'default',
  CREATED_AT: 'createdAt',
  NFT_ID: 'code',
  NFT_NAME: 'name',
  TOTAL_SUPPLY: 'totalSupply',
  TOTAL_MINTED: 'totalMinted',
  ON_SALE_QUANTITY: 'onSaleQuantity',
};

export const NFT_MARKET_CHANNEL = [
  {
    name: 'nft_detail.txt_all',
    value: null,
  },
  {
    name: 'nft_detail.txt_primary_sale',
    value: 'sell',
  },
  {
    name: 'nft_detail.txt_secondary_sale',
    value: 'resell',
  },
];

export const DASHBOARD_CURRENCY = [
  {
    name: CURRENCY,
    value: CURRENCY,
  },
  {
    name: 'USD',
    value: 'usd',
  },
];

export const DASHBOARD_DATA_TYPES = [
  {
    name: 'dashboard.txt_volume',
    value: 'volume',
  },
  {
    name: 'dashboard.txt_revenue',
    value: 'revenue',
  },
];

export const DASHBOARD_SALE_ANALYTICS = {
  CURRENCY: 'currency',
  DATA_TYPE: 'dataType',
  TIME_TYPE: 'timeType',
};

export const DASHBOARD_OVERVIEW = {
  TIME_TYPE: 'timeType',
};

export const DASHBOARD_TIME_TYPES = [
  { name: 'dashboard.txt_all_time', value: 'all-time' },
  { name: 'dashboard.txt_today', value: 'day' },
  { name: 'dashboard.txt_this_week', value: 'week' },
  { name: 'dashboard.txt_this_month', value: 'month' },
  { name: 'dashboard.txt_this_quarter', value: 'quarter' },
  { name: 'dashboard.txt_this_year', value: 'year' },
];

export const DASHBOARD_TIME_FORMAT = {
  HOUR: 'HH',
  YEAR: 'YYYY',
  MONTH_DAY: 'MMM DD',
  MONTH_YEAR: 'MMM YYYY',
  TOOLTIP: 'MMM DD YYYY, HH:mm',
};

export const DASHBOARD_TOP_NFTS_TABS = {
  HERO: {
    key: 'WEAPON',
    label: 'dashboard.txt_hero',
  },
  ITEM: { label: 'dashboard.txt_item', key: 'LAND' },
};

export const DASHBOARD_TOP_NFTS_SORT = {
  TOTAL_VOLUME: 'sort[totalVolume]',
  DESC: 'desc',
};

export const REMOVE_FROM_SALE_STEPS = {
  PROCESSING: 1,
  SUCCESSFUL: 2,
  FAILED: 3,
};

export const PAGE_TAB_QUERY = 'tab';

export const CREATE_TYPE_NFT = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const COLLECTION_STATUS = {
  PROCESSING: 1,
  UNVERIFIED: 2,
  VERIFIED: 3,
  FAILED: 4,
};

export const LIST_FILE_EXCEL = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  IMG_DONE: 'done',
};

export const ATTRIBUTES_DEFAULT_VALUE = {
  VALUE: 3,
  TOTAL: 5,
  VALUE_PERCENTAGE: 10,
};

export const BOOSTS_TYPE = {
  NUMBER: {
    value: 0,
  },
  PERCENTAGE: {
    value: 1,
  },
};

export const META_DATA_TYPE = {
  PROPS: {
    index: 0,
    value: 'props',
    type: '',
    currentName: 'currentProps',
    defaultValues: {
      metaName: '',
      metaValue: '',
    },
  },
  LEVELS: {
    index: 1,
    value: 'levels',
    type: '',
    currentName: 'currentLevels',
    defaultValues: {
      metaName: '',
      metaValue: ATTRIBUTES_DEFAULT_VALUE.VALUE,
      metaTotal: ATTRIBUTES_DEFAULT_VALUE.TOTAL,
    },
  },
  STATS: {
    index: 2,
    value: 'stats',
    type: 'number',
    currentName: 'currentStats',
    defaultValues: {
      metaName: '',
      metaValue: ATTRIBUTES_DEFAULT_VALUE.VALUE,
      metaTotal: ATTRIBUTES_DEFAULT_VALUE.TOTAL,
    },
  },
  BOOSTS: {
    index: 3,
    value: 'boosts',
    type: 'boost_percentage',
    currentName: 'currentBoosts',
    defaultValues: {
      metaName: '',
      metaValue: ATTRIBUTES_DEFAULT_VALUE.VALUE_PERCENTAGE,
      metaTotal: '',
      metaType: BOOSTS_TYPE.PERCENTAGE.value,
    },
  },
  DATE: {
    index: 4,
    value: 'date',
    type: 'date',
    currentName: 'currentDate',
    defaultValues: {
      metaName: '',
      metaValue: '',
    },
  },
};

export const CHAIN_TYPE_ERC = {
  ERC_1155: {
    NAME: 'ERC-1155',
    VALUE: 1,
  },
  ERC_721: {
    NAME: 'ERC-721',
    VALUE: 2,
  },
};

export const OPTION_DISPLAY_NFT_SETTING = {
  SHOW: '1',
  HIDE: '0',
};

// collection
export const COLLECTION_ITEM_TYPE = {
  ERC721: '1',
  ERC1155: '2',
};

export const MAX_WEAPON_SLIDE = 10;
export const MIN_WEAPON_SLIDE = 0;
export const MAX_LENGTH_WEAPON_SLIDE = 2;

export const WEAPON_TYPE = {
  AR: 'AR',
  SMG: 'SMG',
  SR: 'SR',
  LMG: 'LMG',
  SG: 'SG',
};

export const RARITY = {
  COMMON: 'Common',
  RARE: 'Rare',
  SUPER_RARE: 'Super Rare',
  LEGENDARY: 'Legendary',
};

export const MAP = {
  MAP_1: 'Map 1',
  MAP_2: 'Map 2',
  MAP_3: 'Map 3',
};

export const MIN_HEIGHT_IMAGE_PREVIEW = 200;
export const MIN_WIDTH_IMAGE_PREVIEW = 200;
export const FILE_TYPE_SUPPORT_PREVIEW = ['jpg', 'png', 'gif', 'svg'];
