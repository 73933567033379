import cx from 'classnames';
import styles from './styles.module.scss';

const ContentCard = ({
  children,
  className,
  hideBoxShadow,
}: {
  children: any;
  className?: string;
  hideBoxShadow?: boolean;
}) => {
  const style: any = {};
  if (hideBoxShadow) {
    style.boxShadow = 'none';
  }
  return (
    <div className={cx(styles.container, className)} style={style}>
      {children}
    </div>
  );
};

export default ContentCard;
