import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import AppLoading from 'components/AppLoading';

import { externalRoutes, renderRoutes, routeURLs } from 'constants/routes';
import PageContent from 'components/PageContent';
import { useGetDetailPool } from './hooks';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { POOL_STATUS } from 'constants/common';
import { Col, Row, Tooltip } from 'antd';
import { convertTimeStampUtcToDate, DATE_FORMAT, formatDate, renderName } from 'utils';
import AppNumber from 'components/AppNumber';
import AppButton from 'components/AppButton';
import DeleteButton from './components/DeleteButton';
import AppAddress from 'components/AppAddress';
import RedirectIcon from 'resources/svg/redirect_icon.svg';
import classNames from 'classnames';
import HelpIcon from 'resources/svg/help_icon.svg';
const PoolDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams() as string | any;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNFTBack = () => history.push(routeURLs.POOL);
  const { poolDetail, loading: loading } = useGetDetailPool(id) as any;

  const status = Object.values(POOL_STATUS)?.find((status: any) => status.value === poolDetail?.status);
  const handleRedirectPoolEditPage = () => {
    history.push(renderRoutes.POOL_EDITION(id));
  };
  return (
    <AppLoading loading={loading}>
      <div className="pool-detail-page">
        <div className="pool-detail-page__header">
          <PageHeader showBack title="Pool Details" onBack={handleNFTBack} />
          {poolDetail?.status === POOL_STATUS?.DRAFT?.value && (
            <div className="group-button-detail">
              <AppButton
                text="Edit"
                onClick={handleRedirectPoolEditPage}
                variant="primary"
                className="nft-management-page__button"
              />
              &nbsp;&nbsp;
              <DeleteButton poolDetail={poolDetail} />
            </div>
          )}
        </div>
        <div className="pool-detail-content">
          <PageContent>
            <div className="pool-title">
              <div className="pool-name">
                {renderName(poolDetail?.name, 24)} {!poolDetail?.isShow && <EyeInvisibleOutlined />}
              </div>
              <div className={`pool-status-${status?.value}`}>
                <div className="pool-dot"></div>
                <div className={`pool-status-text-${status?.value}`}>{status?.text}</div>
              </div>
            </div>
            <div>Land NFT Staking</div>
            <br></br>
            <br></br>
            <div className="pool-detail-box-wrapper">
              <div className="box-content">Milestones</div>
              <div className="pool-content">
                <Row className="title">
                  <Col md={6} xs={24}>
                    Created Date
                  </Col>
                  <Col md={6} xs={24}>
                    Start Date
                  </Col>
                  <Col md={6} xs={24}>
                    End Date For Staking
                  </Col>
                  <Col md={6} xs={24}>
                    End Date For Pool
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={24}>
                    {formatDate(poolDetail?.createdAt)}
                  </Col>
                  <Col md={6} xs={24}>
                    {convertTimeStampUtcToDate(poolDetail?.publishTime, DATE_FORMAT)}
                  </Col>
                  <Col md={6} xs={24}>
                    {convertTimeStampUtcToDate(poolDetail?.endTimeStaking, DATE_FORMAT)}
                  </Col>
                  <Col md={6} xs={24}>
                    {convertTimeStampUtcToDate(poolDetail?.endTimePool, DATE_FORMAT)}
                  </Col>
                </Row>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className="pool-detail-box-wrapper">
              <div className="box-content">Statistics</div>
              <div className="pool-content">
                <Row className="title">
                  <Col md={6} xs={24}>
                    Duration Lock
                    <Tooltip
                      title={
                        'The period that the pool requires NFTs to be staked in to receive rewards. If users unstake before the duration term is over, the rewards will be lost. Once the duration term ends, users can either unstake to redeem their NFTs and receive settlement rewards or continue staking their NFTs in the pool to earn staking rewards until the pool ends'
                      }
                      overlayClassName="tooltip-detail"
                    >
                      <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
                    </Tooltip>
                  </Col>
                  <Col md={6} xs={24}>
                    Editions Limit
                    <Tooltip
                      title={'The maximum number of NFTs can be staked in the pool at one time'}
                      overlayClassName="tooltip-detail"
                    >
                      <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
                    </Tooltip>
                  </Col>
                  <Col md={6} xs={24}>
                    Total Rewards
                    <Tooltip
                      title={
                        'Total Rewards is the total amount of token allocated to the pool as rewards for the stakers'
                      }
                      overlayClassName="tooltip-detail"
                    >
                      <img src={HelpIcon} className={classNames('icon cursor-pointer')} />
                    </Tooltip>
                  </Col>
                  <Col md={6} xs={24}>
                    Remaining Rewards
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={24}>
                    {`${poolDetail?.duration / 86400} days`}
                  </Col>
                  <Col md={6} xs={24}>
                    <AppNumber isNotFormatDecimal value={poolDetail?.editionLimit} /> Editions
                  </Col>
                  <Col md={6} xs={24}>
                    <AppNumber value={poolDetail?.amount || 0} isNotFormatDecimal />
                    &nbsp; {poolDetail?.tokenReward?.symbol}
                  </Col>
                  <Col md={6} xs={24}>
                    <AppNumber value={poolDetail?.availableRewards || 0} isNotFormatDecimal />
                    &nbsp; {poolDetail?.tokenReward?.symbol}
                  </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="title">
                  <Col md={6} xs={24}>
                    Total Stakers
                  </Col>
                  <Col md={6} xs={24}>
                    Current Editions Locked
                  </Col>
                  <Col md={6} xs={24}>
                    Current Rewards Claimed
                  </Col>
                  <Col md={6} xs={24}>
                    Contract Address
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={24}>
                    {poolDetail?.userStaked?.length}
                  </Col>
                  <Col md={6} xs={24}>
                    <AppNumber value={poolDetail?.totalLocked || 0} isNotFormatDecimal /> Editions
                  </Col>
                  <Col md={6} xs={24}>
                    <AppNumber value={poolDetail?.rewardsClaimed || 0} isNotFormatDecimal /> &nbsp;{' '}
                    {poolDetail?.tokenReward?.symbol}
                  </Col>
                  <Col md={6} xs={24}>
                    <div className="contract-address-pool-detail">
                      <AppAddress address={process.env.REACT_APP_PUBLIC_PROXY_CONTRACT || ''} />{' '}
                      <a
                        href={externalRoutes.BSC_SCAN_ADDRESS(process.env.REACT_APP_PUBLIC_PROXY_CONTRACT || '')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={RedirectIcon} className="cursor-pointer" />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </PageContent>
        </div>
      </div>
    </AppLoading>
  );
};

export default PoolDetail;
