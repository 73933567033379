import { ParamsWhiteTypes } from 'pages/whitelist-management';
import { api } from 'services/api';

class WhiteList {
  handleUploadWhitelistApi = (data: any) => {
    return api.post('whitelists', data);
  };
  handleEditWhitelistApi = (data: any) => {
    return api.patch(`whitelists/${data?.id}`, data);
  };
  handleGetListWhitelistApi = (data?: ParamsWhiteTypes) => {
    return api.get('whitelists', data);
  };

  handleGetDetailWhitelistApi = (id: string) => {
    return api.get(`whitelists/${id}`);
  };
  handleGetDeleteWhitelistApi = (id: string) => {
    return api.delete(`whitelists/${id}`);
  };
}

const whitelistService = new WhiteList();

export default whitelistService;
