import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Col, Row } from 'antd';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';

import AppLoading from 'components/AppLoading';
import PageHeader from 'components/PageHeader';
import NFTContent from './components/NFTContent';
import NFTPreview from './components/NFTPreview';
import NFTGroupButton from './components/NFTGroupButton';
import ModalUnsavedChange from 'components/Modal/ModalUnsaveChange';

import selectedConfig from 'redux/config/selector';
import { useCreateOrEditNFT, useGetDetailNFT } from './hooks';
import { useAppSelector } from 'hooks/useStore';
import { useWarnModalPage } from 'hooks/useWarnModalForPage';

import {
  checkValueNftChange,
  clearRequestParams,
  getAttributeFieldNFTValues,
  getDefaultFieldNFTValues,
  getFormatedNFT,
} from 'utils';
import { getNftSchema, getNftSchemaUpdateIPFS } from 'utils/schema';
import { NFT_DEFAULT_CREATE_FIELD, NFT_MEDIA, NFT_STATUS } from 'constants/nft';

import { renderRoutes, routeURLs } from 'constants/routes';
import { ROLE } from 'constants/common';
import selectAuthentication from 'redux/authentication/selector';

const { IMAGE_MEDIUM, IMAGE_SMALL, FILE, FILE_PREVIEW } = NFT_DEFAULT_CREATE_FIELD;

const initFormValue = {
  [FILE]: {
    fileList: [],
    previewContent: '',
  },
  [FILE_PREVIEW]: {
    fileList: [],
    previewContent: '',
  },
  [IMAGE_MEDIUM]: '',
  [IMAGE_SMALL]: '',
} as any;

const NFTUpdateIpfs = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const formikRef = useRef(null) as any;
  const { id } = useParams() as string | any;
  const { loading: loadingCreateNFT, onEditNFT } = useCreateOrEditNFT();
  const { role } = useAppSelector(selectAuthentication.getAuthenticationToken);

  const { general = {} } = useAppSelector(selectedConfig.getConfig);
  const { attributes = [] } = general;

  const { nftDetail, loading: loadingDetailNFT } = useGetDetailNFT(id) as any;

  const backUrl = id ? renderRoutes.NFT_DETAIL(id) : routeURLs.NFT;

  const { visibleModalUnsaved, setValueChange, onCloseModalUnsaved, afterCloseModalUnsaved, onBackClick, onDiscard } =
    useWarnModalPage(backUrl);
  useEffect(() => {
    if (nftDetail?._id) {
      if (role !== ROLE.CREATOR_ADMIN.value) {
        if (nftDetail?.status !== NFT_STATUS?.[0]?.value || nftDetail?.token?.totalMinted > 0) {
          const attributeFieldValues = getAttributeFieldNFTValues(nftDetail) as object;
          const defaultFieldValues = getDefaultFieldNFTValues(nftDetail) as object;
          formikRef.current.setValues({
            ...defaultFieldValues,
            ...attributeFieldValues,
          });
        } else {
          history.push(routeURLs.HOME);
        }
      } else {
        history.push(routeURLs.HOME);
      }
    }
  }, [nftDetail, attributes]);

  const getOriginFile = (file: any) => get(file, ['fileList', 0, 'originFileObj']);

  const onFinally = () => {
    history.push(renderRoutes.NFT_DETAIL(id));
  };

  const handleSubmit = async (values: any = {}) => {
    const { file, filePreview, imageMedium, imageSmall } = values;

    let image = getOriginFile(file);
    const mediaType = getFormatedNFT(values?.file);
    if (mediaType !== NFT_MEDIA.IMAGE) {
      image = getOriginFile(filePreview);
    }

    const data = {
      mediaFile: mediaType !== NFT_MEDIA.IMAGE ? getOriginFile(file) : undefined,
      image,
      mediaType: mediaType !== NFT_MEDIA.IMAGE ? mediaType : undefined,
      [IMAGE_MEDIUM]: image && imageMedium,
      [IMAGE_SMALL]: image && imageSmall,
    } as any;

    const formatData = clearRequestParams(data);

    const formData = new FormData();
    for (const key in formatData) {
      formData.append(key, formatData[key]);
    }
    if (id) {
      onEditNFT(formData, id, onFinally);
    }
  };

  const nftSchema = getNftSchemaUpdateIPFS(t);
  return (
    <AppLoading loading={loadingDetailNFT || loadingCreateNFT}>
      <div className="nft-creation-page">
        <PageHeader showBack title={t('nft_creation.txt_update_ipfs')} onBack={onBackClick} />

        <Formik innerRef={formikRef} initialValues={initFormValue} onSubmit={handleSubmit} validationSchema={nftSchema}>
          {({ values }: any) => {
            setValueChange(checkValueNftChange(id ? nftDetail : initFormValue, values, !!id));
            return (
              <Form className="nft-creation-page-form">
                <Row gutter={20} justify="space-between">
                  <Col lg={16} xs={24}>
                    <NFTContent />
                    <NFTGroupButton isSubmit={loadingCreateNFT} onDiscard={onDiscard} id={id} />
                  </Col>
                  <Col lg={8} xs={24}>
                    <NFTPreview nftDetail={nftDetail} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>

        <ModalUnsavedChange
          visible={visibleModalUnsaved}
          onClose={onCloseModalUnsaved}
          backUrl={backUrl}
          afterClose={afterCloseModalUnsaved}
        />
      </div>
    </AppLoading>
  );
};

export default NFTUpdateIpfs;
