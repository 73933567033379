import React from 'react';

import Loading from 'resources/images/loading_icon.png';

import styles from './styles.module.scss';

const LoadingIcon = ({ src = Loading }) => {
  return <img src={src || Loading} className={styles.image} />;
};

export default LoadingIcon;
