import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExportModal from '../ExportModal';
import AppButton from 'components/AppButton';

import ExportIcon from 'resources/svg/export_icon.svg';

const ExportButton = () => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleToggleExportModal = () => {
    setVisible(!visible);
  };

  return (
    <Fragment>
      <AppButton
        prefixIcon={<img src={ExportIcon} className="button-icon" />}
        text={t('revenue_management.txt_export')}
        onClick={handleToggleExportModal}
        className="app-button"
      />
      <ExportModal visible={visible} onClose={handleToggleExportModal} />
    </Fragment>
  );
};

export default ExportButton;
