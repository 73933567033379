import React from 'react';

const NFTIcon = (props: any) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.4978 4.57974C17.4526 3.69967 16.7247 3 15.8333 3H4.16667C3.24619 3 2.5 3.74619 2.5 4.66667V13.75L6.83333 8.33333C7.0987 7.97951 7.59425 7.89462 7.96225 8.13996L12.3063 11.036L17.4978 4.57974Z"
        fill="#777E90"
      />
      <path
        d="M17.5 7.25L13.1667 12.6667C12.9013 13.0205 12.4057 13.1054 12.0377 12.86L7.69367 9.96399L2.50223 16.4203C2.54744 17.3003 3.27535 18 4.16667 18H15.8333C16.7538 18 17.5 17.2538 17.5 16.3333V7.25Z"
        fill="#777E90"
      />
    </svg>
  );
};

export default NFTIcon;
