import React, { useContext } from 'react';

import PreviewNFT from 'components/PreviewNFT';
import { NftDetailContext } from 'pages/nft-detail';

import { getAttributeFieldNFTValues, getDefaultFieldNFTValues } from 'utils';

const Preview = () => {
  const { nftDetail = {} } = useContext(NftDetailContext) as any;

  const previewSrc = nftDetail?.image?.url;

  const attributeFieldValues = getAttributeFieldNFTValues(nftDetail) as object;
  const defaultFieldValues = getDefaultFieldNFTValues(nftDetail) as object;
  const nft = {
    ...defaultFieldValues,
    ...attributeFieldValues,
  };

  return (
    <div className="nft-profile-preview">
      <PreviewNFT previewSrc={previewSrc} nft={nft} code={nftDetail?.code} nftDetail={nftDetail} />
    </div>
  );
};

export default Preview;
