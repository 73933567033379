import { createSlice } from '@reduxjs/toolkit';

export interface Authentication {
  authenticationToken: string;
  role: string;
  name: string;
  isShowMessageForbidden: boolean;
}

const initialState: Authentication = {
  authenticationToken: '',
  role: '',
  name: '',
  isShowMessageForbidden: false,
};

export const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    handleSetAuthenticationToken: (state: Authentication, action: any) => {
      const token = action.payload;
      return {
        ...state,
        authenticationToken: token,
      };
    },
    handleSetIsShowMessageForbidden: (state: any, action: any) => {
      const isShowMessageForbidden = action.payload;
      return {
        ...state,
        isShowMessageForbidden,
      };
    },
    handleSetRoleAdmin: (state: Authentication, action: any) => {
      const role = action.payload;
      return {
        ...state,
        role: role,
      };
    },
    handleSetNameAdmin: (state: Authentication, action: any) => {
      const name = action.payload;
      return {
        ...state,
        name: name,
      };
    },
  },
});

export const { handleSetAuthenticationToken, handleSetRoleAdmin, handleSetNameAdmin, handleSetIsShowMessageForbidden } =
  AuthenticationSlice.actions;

export const namespace = 'AuthenticationSlice';

export default AuthenticationSlice.reducer;
