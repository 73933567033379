import { useEffect, useState } from 'react';
import { omit } from 'lodash';

import selectedAddress from 'redux/address/selector';

import nftServices from 'services/nft';

import { useAppSelector } from 'hooks/useStore';
import { ORDERS } from 'utils';
import { NFT_MANAGEMENT_FIELD, NFT_MANAGEMENT_FIELD_SORTER } from 'constants/nft';

const { SORT } = NFT_MANAGEMENT_FIELD;

const { DEFAULT, CREATED_AT, NFT_ID, NFT_NAME, TOTAL_SUPPLY, TOTAL_MINTED, ON_SALE_QUANTITY } =
  NFT_MANAGEMENT_FIELD_SORTER;

const { ASC, DESC, FIELD, ORDER } = ORDERS;

export const useGetListNFTs = (params: any) => {
  const { address } = useAppSelector(selectedAddress.getAddress);

  const [listNFTs, setListNFTs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const INDEXED_SORTER = {
    [DEFAULT]: { [CREATED_AT]: DESC },
    [CREATED_AT]: { [CREATED_AT]: DESC },
    [NFT_ID]: { [NFT_ID]: ASC, [CREATED_AT]: DESC },
    [NFT_NAME]: { [NFT_NAME]: ASC, [CREATED_AT]: DESC },
    [TOTAL_SUPPLY]: { [TOTAL_SUPPLY]: ASC, [CREATED_AT]: DESC },
    [TOTAL_MINTED]: { [TOTAL_MINTED]: ASC, [CREATED_AT]: DESC },
    [ON_SALE_QUANTITY]: { [ON_SALE_QUANTITY]: ASC, [CREATED_AT]: DESC },
  };

  useEffect(() => {
    handleGetListNFTs();
  }, [params, address]);

  const handleGetListNFTs = async () => {
    setLoading(true);
    try {
      const newParams = omit({ ...params }, [FIELD, ORDER]) as any;
      const field = params?.[FIELD] || DEFAULT;

      for (const key in INDEXED_SORTER?.[field]) {
        if (key === field && params?.[ORDER] && params?.[FIELD]) {
          newParams[`${SORT}[${key}]`] = params?.[ORDER];
        } else {
          newParams[`${SORT}[${key}]`] = INDEXED_SORTER?.[field]?.[key];
        }
      }
      const response = await nftServices.handleGetList(newParams);
      const { docs = [], totalDocs = 0 } = response?.data || {};
      setListNFTs(docs);
      setTotal(totalDocs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    data: listNFTs,
    loading,
    total,
  };
};
