import React from 'react';
import { useTranslation } from 'react-i18next';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

import Table from 'components/Table';
import { NFTParamsTypes } from 'pages/nft-management';

import { getRowKey, ORDERS, setOrderSorter } from 'utils';
import { SorterTable } from 'types';
import { columns } from './columns';
import LENGTH_CONSTANTS from 'constants/length';
import { NFT_MANAGEMENT_FIELD } from 'constants/nft';
import { useAppSelector } from 'hooks/useStore';
import selectAuthentication from 'redux/authentication/selector';

const { PAGE, LIMIT } = NFT_MANAGEMENT_FIELD;
const { DEFAULT_PAGE } = LENGTH_CONSTANTS;
const { ORDER, FIELD } = ORDERS;

type ListNFTProps = {
  total: number;
  data: Array<any>;
  loading: boolean;
  onSetParams: (values: any) => void;
  params: NFTParamsTypes;
  emtpyText?: string;
};

const ListNFT = ({ total, data, loading, onSetParams, params, emtpyText }: ListNFTProps) => {
  const { t } = useTranslation();
  const { limit, page } = params;
  const { role } = useAppSelector(selectAuthentication.getAuthenticationToken);

  const handleChangePaging = (page: number, limit: number) => {
    onSetParams({
      ...params,
      [PAGE]: limit !== limit ? DEFAULT_PAGE : page,
      [LIMIT]: limit,
    });
  };

  const handleChangeTable = (
    _pagination: TablePaginationConfig,
    _filter: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    _extra: TableCurrentDataSource<any>,
  ) => {
    const { order, field } = sorter as SorterTable;
    const newOrder = setOrderSorter(order);

    const dataFilter: any = {
      ...params,
      [FIELD]: field,
      [PAGE]: DEFAULT_PAGE,
    };

    if (newOrder) {
      dataFilter.order = newOrder;
    } else {
      delete dataFilter.order;
      delete dataFilter.field;
    }

    onSetParams(dataFilter);
  };

  return (
    <Table
      total={total}
      columns={columns(t, page, limit, role)}
      loading={loading}
      dataSource={data}
      pageSize={limit}
      current={page}
      onChangePagination={handleChangePaging}
      onChangeTable={handleChangeTable}
      emptyText={emtpyText}
      rowKey={getRowKey}
    />
  );
};

export default ListNFT;
