import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

import selectAuthentication from 'redux/authentication/selector';
import selectedAddress from 'redux/address/selector';

import './i18n/i18n';
import { useAppSelector } from 'hooks/useStore';
import routes, { routeURLs } from 'constants/routes';

import './App.css';
import { ROLE } from 'constants/common';

const App = () => {
  const { address } = useAppSelector(selectedAddress.getAddress);
  const { authenticationToken, role } = useAppSelector(selectAuthentication.getAuthenticationToken);

  //Fix bug wallet connect for react
  window.Buffer = window.Buffer || require('buffer').Buffer;

  let route: any = address && authenticationToken ? routes.privateRoutes : routes.publicRoutes;
  if (role === ROLE.CREATOR_ADMIN.value && address && authenticationToken) {
    route = route.filter((value: any) => value.roleCreatorAdmin);
  }
  if (role === ROLE.SALE_ADMIN.value && address && authenticationToken) {
    route = route.filter((value: any) => value.roleSaleAdmin);
  }
  return (
    <Router>
      <Switch>
        {route.map((route: any) => {
          const Component = route?.component || Fragment;
          const Layout = route?.layout || Fragment;

          return (
            <Route
              path={route.path}
              key={route.path}
              exact={route.exact}
              render={(props: any) => <Layout>{<Component {...props} />}</Layout>}
            />
          );
        })}
        <Route
          path="*"
          exact
          render={(props: any) => (address ? <Redirect to={routeURLs.HOME} /> : <Redirect to={routeURLs.LOGIN} />)}
        />
      </Switch>
    </Router>
  );
};

export default withTranslation()(App);
