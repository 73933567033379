import { useTranslation } from 'react-i18next';

import Table from 'components/Table';
import AppLoading from 'components/AppLoading';
import EllipsisText from 'components/EllipsisText';
import NumberFormat from 'components/NumberFormat';
import ResponsiveImage from 'components/ResponsiveImage';

import DetailIcon from 'resources/svg/detail_icon.svg';
import RedirectIcon from 'resources/svg/redirect_icon.svg';

import { useGetConfig } from 'hooks/useGetConfig';
import { useGetTopNfts } from 'pages/home/hooks';

import { getRowKey, nFormatter } from 'utils';

import { REVENUE_DECIMAL_SCALE } from 'constants/revenue';
import { DOLLAR_TEXT, ZERO_VALUE } from 'constants/common';
import { externalRoutes, renderRoutes } from 'constants/routes';
import { DASHBOARD_TOP_NFTS_SORT, NFT_STANDARD } from 'constants/nft';
import { NftType } from 'connectors/constants';
import AppNumber from 'components/AppNumber';

const { TOTAL_VOLUME, DESC } = DASHBOARD_TOP_NFTS_SORT;

const Hero = () => {
  const { t } = useTranslation();

  const { currency = {} } = useGetConfig();
  const { loading, data } = useGetTopNfts({
    nftType: NftType.WEAPON,
    [TOTAL_VOLUME]: DESC,
  });

  const columns = [
    {
      title: t('dashboard.txt_no'),
      width: '5%',
      render: (_value: any, _row: any, index: number) => index + 1,
    },
    {
      title: t('dashboard.txt_nft_name'),
      width: '25%',
      dataIndex: '_id',
      key: '_id',
      ellipsis: true,
      render: (_value: string, row: any) => {
        return (
          <div className="view-nft-name">
            <ResponsiveImage src={`${row?._id?.image?.smallUrl}?random=${new Date().getTime()}`} />
            <EllipsisText text={row?._id?.name} />
          </div>
        );
      },
    },
    {
      title: t('dashboard.txt_sale_volume'),
      width: '15%',
      key: 'nft',
      render: (_value: any, row: any) => {
        return (
          <div className="content-single">
            <img src={currency?.icon} />
            <div>
              <div className="currency">
                <p className="symbol">
                  <AppNumber value={row?.totalVolume} suffix={currency?.symbol} isNotFormatDecimal minDecimal={4} />
                  &nbsp;
                  <span className="">{currency.symbol}</span>
                </p>
                <p className="usd">
                  <AppNumber
                    value={row?.totalVolumeUsd}
                    suffix={currency?.symbol}
                    isNotFormatDecimal
                    minDecimal={4}
                    isUsd={true}
                  />
                </p>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: '',
      width: '15%',
      dataIndex: 'hash',
      key: 'hash',
      render: (_value: any, row: any) => {
        return (
          <div className="view-nft-link">
            <a href={renderRoutes.NFT_DETAIL(row?._id?.id)} target="_blank" rel="noreferrer">
              <img src={DetailIcon} />
            </a>
            <a href={externalRoutes.MARKET_NFT_DETAIL(row?._id?.id)} target="_blank" rel="noreferrer">
              <img src={RedirectIcon} />
            </a>
          </div>
        );
      },
    },
  ];

  return (
    <AppLoading loading={loading}>
      <Table
        columns={columns}
        total={ZERO_VALUE}
        dataSource={data}
        scroll={{ x: 397 }}
        rowKey={getRowKey}
        className="dashboad-top-nfts__table"
      />
    </AppLoading>
  );
};

export default Hero;
