import { Spin } from 'antd';
import LoadingIcon from 'components/LoadingIcon';
import React, { ReactNode } from 'react';

type AppLoadingProps = {
  loading?: boolean;
  children?: ReactNode;
};

const AppLoading = ({ loading, children }: AppLoadingProps) => {
  return (
    <Spin className="app-loading" spinning={loading} indicator={<LoadingIcon />}>
      {children}
    </Spin>
  );
};

export default AppLoading;
