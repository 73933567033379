import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import moment from 'moment';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import FileSaver from 'file-saver';

import ModalComponent from 'components/Modal';
import AppButton from 'components/AppButton';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import EllipsisText from 'components/EllipsisText';

import { useGetRevenue } from 'pages/revenue-management/hooks';
import { exportExcel } from 'pages/revenue-management/hooks/export';

import { disabledFromDate, disabledUntilDate } from 'utils';
import { getExportModalSchema } from 'utils/schema';

import { NFT_MARKET_CHANNEL } from 'constants/nft';
import LENGTH_CONSTANTS from 'constants/length';
import { FORMAT_EXPORT_DATA_DATE, REVENUE_FIELD } from 'constants/revenue';

const { MAX_LIMIT } = LENGTH_CONSTANTS;

const { FROM, UNTIL, TYPE, LIMIT } = REVENUE_FIELD;

type ExportModalProps = {
  visible: boolean;
  onClose: () => void;
};

const initialValues = {
  [FROM]: null,
  [UNTIL]: null,
  [TYPE]: null,
};

const ExportModal = ({ visible, onClose }: ExportModalProps) => {
  const { t } = useTranslation();

  const { onExportRevenue } = useGetRevenue();

  const optionSelect = NFT_MARKET_CHANNEL.map((type) => ({
    ...type,
    name: t(type?.name),
  }));

  const handleExecutionExport = (data: Array<any>, values: any) => {
    try {
      exportExcel({
        [FROM]: values?.[FROM],
        [UNTIL]: values?.[UNTIL],
        [TYPE]: values?.[TYPE],
        result: data,
        t,
      })
        .then((buffer: any) => {
          FileSaver.saveAs(
            new Blob([buffer], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            `${t('revenue_management.txt_revenue')}_${moment(new Date()).format(FORMAT_EXPORT_DATA_DATE)}.xlsx`,
          );
        })
        .catch((err) => {});
    } catch (error) {
    } finally {
      onClose();
    }
  };

  const handleExportData = debounce((values: any) => {
    const params = {
      [FROM]: values?.[FROM],
      [UNTIL]: values?.[UNTIL],
      [TYPE]: values?.[TYPE],
      [LIMIT]: MAX_LIMIT,
    };
    onExportRevenue(params, { onError: noop, onSuccess: (data: Array<any>) => handleExecutionExport(data, values) });
  }, 1000);

  const handleFieldChange = (setFieldValue: any, name: string) => (value: any) => {
    setFieldValue(name, value);
  };

  return (
    <ModalComponent visible={visible} width={409} onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleExportData} validationSchema={getExportModalSchema(t)}>
        {({ setFieldValue, values }) => {
          return (
            <Form className="revenue-management-search-form">
              <div className="export-modal">
                <EllipsisText className="export-modal__title" text={t('revenue_management.txt_export_data')} />
                <div className="export-modal__body">
                  <div className="market-channel">
                    <EllipsisText className="market-channel__text" text={t('revenue_management.txt_market_channel')} />
                    <FormItem
                      name={TYPE}
                      className="market-channel__select"
                      placeholder={t('revenue_management.txt_type')}
                      typeInput={TYPE_INPUT.SELECT}
                      options={optionSelect}
                    />
                  </div>
                  <div className="export-date">
                    <EllipsisText className="export-date__text" text={t('revenue_management.txt_export_date')} />
                    <div className="export-date__picker">
                      <FormItem
                        name={FROM}
                        className="picker-date"
                        placeholder={t('revenue_management.txt_start_date')}
                        typeInput={TYPE_INPUT.DATE}
                        onChange={handleFieldChange(setFieldValue, FROM)}
                        disabledDate={disabledFromDate(values?.[UNTIL])}
                      />
                      <FormItem
                        name={UNTIL}
                        className="picker-date"
                        placeholder={t('revenue_management.txt_end_date')}
                        typeInput={TYPE_INPUT.DATE}
                        onChange={handleFieldChange(setFieldValue, UNTIL)}
                        disabledDate={disabledUntilDate(values?.[FROM])}
                      />
                    </div>
                  </div>
                </div>
                <AppButton
                  className="export-modal__button"
                  text={t('revenue_management.txt_export')}
                  htmlType="submit"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalComponent>
  );
};

export default ExportModal;
