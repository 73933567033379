import React from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { formatDate, shortenAddress } from 'utils';
import { renderRoutes } from 'constants/routes';
import { NFT_DETAIL_TABS } from 'constants/nft';
import { ROLE, ROLE_STATUS } from 'constants/common';
import EllipsisText from 'components/EllipsisText';
const { PROFILE } = NFT_DETAIL_TABS;
import { Typography } from 'antd';
const { Paragraph } = Typography;
import CopyIcon from 'resources/svg/copy_icon.svg';
import AppAddress from 'components/AppAddress';

export const columns = (t: TFunction, page: number, limit: number) => [
  {
    title: t('nft_management.txt_no'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 50,
    render: (_value: any, _row: any, index: number) => (page - 1) * limit + index + 1,
  },
  {
    title: t('nft_management.txt_created_date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    sorter: true,
    render: (value: any) => formatDate(value),
  },
  {
    title: 'Admin Name',
    dataIndex: 'name',
    key: 'name',
    width: 75,
    sorter: true,
    render: (value: any) => {
      return <EllipsisText text={value} />;
    },
  },
  {
    title: 'Wallet Address',
    dataIndex: 'address',
    key: 'address',
    width: 125,
    ellipsis: true,
    render: (_value: string, row: any) => {
      return (
        <div className="address-admins-list">
          <AppAddress address={_value} />
        </div>
      );
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    width: 100,
    render: (value: any) => {
      const curStatus = Object.values(ROLE).find((val) => val.value === value);
      return <div>{curStatus?.text}</div>;
    },
  },
  {
    title: t('nft_management.txt_status'),
    dataIndex: 'status',
    key: 'status',
    width: 75,
    render: (value: string) => {
      const curStatus = Object.values(ROLE_STATUS).find((val) => val.value === value);
      return <div className={`${value}-status-admin`}>{curStatus?.text}</div>;
    },
  },
  {
    title: t('nft_management.txt_action'),
    dataIndex: 'address',
    key: 'address',
    width: 75,
    render: (value: any) => {
      return (
        <Link className="view-nft-detail" to={renderRoutes.ADMIN_DETAIL(value)}>
          {t('common.txt_view_detail')}
        </Link>
      );
    },
  },
];
