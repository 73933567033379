import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ItemWithLabel from 'components/ItemWithLabel';
import NumberFormat from 'components/NumberFormat';
import PageContent from 'components/PageContent';
import ListSale from './ListSale';
import SearchForm from './SearchForm';

import { useGetListSaleHistory } from 'pages/nft-detail/hooks';

import LENGTH_CONSTANTS from 'constants/length';
import { NFT_SALE_HISTORY_FIELD } from 'constants/nft';
import AppNumber from 'components/AppNumber';
import { TOKEN_SUPPORT_DEFAULT } from 'constants/common';

const { KEYWORD, FROM, UNTIL, TYPE, PAGE, LIMIT } = NFT_SALE_HISTORY_FIELD;

const { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } = LENGTH_CONSTANTS;

export const initialValues = {
  [KEYWORD]: '',
  [FROM]: null,
  [UNTIL]: null,
  [TYPE]: undefined,
  [PAGE]: DEFAULT_PAGE,
  [LIMIT]: DEFAULT_PAGE_SIZE,
};

const NftSaleHistory = () => {
  const { t } = useTranslation();
  const { id } = useParams() as string | any;

  const [params, setParams] = useState(initialValues);

  const { data, total, loading, totalRevenue } = useGetListSaleHistory(id, params) as any;

  const handleChangePaging = (page: number, limit: number) => {
    setParams({
      ...params,
      [PAGE]: limit !== params?.[LIMIT] ? DEFAULT_PAGE : page,
      [LIMIT]: limit,
    });
  };

  return (
    <div className="nft-sale-history">
      <PageContent>
        <SearchForm onSetParams={setParams} params={params} loading={loading} />
        <div className="nft-sale-history__txt-total">
          <ItemWithLabel helpText={t('nft_detail.txt_help_text')} label={`${t('nft_detail.txt_total_revenue')}: `}>
            {/* <NumberFormat thousandSeparator value={totalRevenue} displayType="text" /> */}
            <img src={TOKEN_SUPPORT_DEFAULT.icon} />
            &nbsp; &nbsp;
            <AppNumber value={totalRevenue} isNotFormatDecimal /> <span>BNB</span>
          </ItemWithLabel>
          <ItemWithLabel label={`${t('nft_detail.txt_total_transactions')} : `}>
            &nbsp;
            <NumberFormat thousandSeparator value={total} displayType="text" />
          </ItemWithLabel>
        </div>
        <ListSale
          total={total}
          listSale={data}
          loading={loading}
          page={params?.page as number}
          limit={params?.limit as number}
          handleChangePaging={handleChangePaging}
          onSetParams={setParams}
          params={params}
        />
      </PageContent>
    </div>
  );
};

export default NftSaleHistory;
