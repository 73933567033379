import TokenLogo from 'resources/svg/ekoios_token.svg';
import BnbLogo from 'resources/images/binance_logo.png';
import BulLogo from 'resources/images/bul_logo.png';
import BcnLogo from 'resources/images/bcn_logo.png';

export const SECOND_NUMBER = 60;

export const PERCENTAGE_NUMBER = 100;

export const FILE_BINARY_SIZE = 1024;

export const IMAGE_MEDIUM_SIZE = {
  WIDTH: 300,
  HEIGHT: 300,
};

export const ROLE = {
  SUPER_ADMIN: {
    value: 'super-admin',
    text: 'Super Admin',
  },
  CREATOR_ADMIN: {
    value: 'creator-admin',
    text: 'Creator Admin',
  },
  SALE_ADMIN: {
    value: 'sale-admin',
    text: 'Sale Admin',
  },
};
export const ROLE_STATUS = {
  ACTIVE: {
    value: 'active',
    text: 'Active',
  },
  INACTIVE: {
    value: 'inactive',
    text: 'Inactive',
  },
};

export const POOL_STATUS = {
  DRAFT: {
    value: 'draft',
    text: 'Draft',
  },
  WAITING: {
    value: 'waiting',
    text: 'Coming Soon',
  },
  LIVE: {
    value: 'live',
    text: 'Live',
  },
  FINISHED: {
    value: 'finished',
    text: 'Finished',
  },
};

export const MAX_ALLOWANCE = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const PUBLISH_DATE = {
  NOW: 'NOW',
  PICK_A_DATE: 'PICK_A_DATE',
};

export const DURATION = [
  {
    name: '30 days',
    value: '2592000',
  },
  {
    name: '60 days',
    value: '5184000',
  },
  {
    name: '90 days',
    value: '7776000',
  },
  {
    name: '180 days',
    value: '15552000',
  },
];

export const IMAGE_SMALL_SIZE = {
  WIDTH: 50,
  HEIGHT: 50,
};

export const IMAGE_TYPE = 'image/png';

export const UPLOAD_STATUS = {
  DONE: 'done',
  ERROR: 'error',
  OK: 'ok',
};

export const TOKEN_SUPPORT_DEFAULT = {
  symbol: 'BNB',
  icon: TokenLogo,
  value: 'BNB',
};

export const TOKEN_SUPPORTS: any = {
  BNB: {
    symbol: 'BNB',
    icon: BnbLogo,
    value: 'bnb',
  },
  BCN: {
    symbol: 'BCN',
    icon: BcnLogo,
    value: 'bcn',
  },
  BUL: {
    symbol: 'BUL',
    icon: BulLogo,
    value: 'bul',
  },
};

export const SOCKET_EVENT = {
  NOTIFICATION: 'notification',
  REMOVE_FROM_SALE: 'removeFromSale',
  ADMIN_MINT: 'adminMint',
};

export const ZERO_VALUE = 0;
export const DOLLAR_TEXT = '$';
export const PAD_START_TEXT = '0';
export const PAD_START_MAX_LENGTH = 4;
export const MAX_NFT_CODE_LENGTH = 10;
export const EMPTY_DEFAULT_TEXT = '--';
export const FORMAT_TIME_PICKER = 'HH:mm:ss';
export const FORMAT_DATE_PICKER = 'DD-MM-YYYY';
export const DEFAULT_SEARCH_DATE_FORMAT = 'DD-MM-YYYY';

export const EVENT_NOTIFICATION = {
  ADMIN_PUT_ON_SALE: 'admin-put-on-sale',
  BUY_FROM_ADMIN: 'buy-from-admin',
  BUY_FROM_USER: 'buy-from-user',
  DEACTIVE_SELLORDER: 'deactive-sellorder',
  ACTIVE_SELLORDER: 'active-sellorder',
  DEACTIVE_SELLORDER_ADMIN: 'deactive-sellorder-admin',
};

export const NFT_FORMAT = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  MODEL: '3d',
};

export const DECIMAL_SCALE_ROYALTY_FEE = 2;
export const MAX_LENGTH_DESCRIPTION = 3000;
export const MAX_LENGTH_INPUT = 256;
export const MAX_LENGTH_PROPS_NFT = 50;
export const MAX_LENGTH_ROYALTY_FEE = 2;
export const MAX_LENGTH_TOTAL_COPIES = 7;
export const MAX_VALUE_ROYALTY_FEE = 50;
export const MAX_VALUE_TOTAL_COPIES = 1000000;
export const MAX_LENGTH_PAGE_TITLE_NFT = 70;
export const MAX_LENGTH_SEO_DES_NFT = 320;
export const MAX_FILE_SIZE_MB = 100; // MB

export const htmlEntities = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&apos;': "'",
  '&nbsp;': ' ',
};
export const OPTION_UNLOCKABLE_CONTENT = {
  LOCK: 0,
  UNLOCK: 1,
  LOCK_BOOL: false,
};

export const HTTP_RESPONSE = {
  SUCCESS: 200,
  SUCCESS_201: 201,
  ERROR_CODE_401: 401,
  ERROR_CODE_400: 400,
  ERROR_CODE_500: 500,
};

export const LIST_FILE_NFT_SUPPORT = [
  'image/png',
  'image/jpeg',
  // 'image/svg+xml',
  'image/gif',
  'audio/mpeg',
  'audio/ogg',
  'audio/wav',
  'audio/webm',
  'video/ogg',
  'video/mp4',
  'video/webm',
  '3d/glb',
];
