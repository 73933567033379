import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Tooltip } from 'antd';

import AppButton from 'components/AppButton';
import FormItem, { TYPE_INPUT } from 'components/FormItem';
import ModalComponent from 'components/Modal';
import { NftDetailContext } from 'pages/nft-detail';

import WarningIcon from 'resources/svg/warning_icon.svg';
import HelpIcon from 'resources/svg/help_icon.svg';

import { useGetConfig } from 'hooks/useGetConfig';
import { ZERO_VALUE } from 'constants/common';
import { MAX_LENGTH_TOTAL_SUPPLY, MAX_VALUE_TOTAL_COPIES, NFT_MINTED_FIELD, NFT_STANDARD } from 'constants/nft';
import { getNumberValue, limitMaxlengNumber } from 'utils';
import { getMintSchema } from 'utils/schema';
import { useAppSelector } from 'hooks/useStore';
import selectedAddress from 'redux/address/selector';

const { QUANTITY, TO_ADDRESS } = NFT_MINTED_FIELD;

type MintModalProps = {
  visible: boolean;
  onClose?: () => void;
  title?: string;
  onSubmit: (values?: any) => void;
};

const MintModal = ({ visible, onClose, title, onSubmit, ...props }: MintModalProps) => {
  const { t } = useTranslation();

  const { mintingQuantityMax } = useGetConfig();
  const { nftDetail = {} } = useContext(NftDetailContext) as any;
  const { address } = useAppSelector(selectedAddress.getAddress);

  const { token = {}, saleOrder = {} } = nftDetail;

  const is721Standard = NFT_STANDARD[0].value === nftDetail?.token?.standard;
  const totalMinted = getNumberValue(token?.totalMinted);
  const totalSupply = getNumberValue(token?.totalSupply);
  const onSaleQuantity = getNumberValue(saleOrder?.quantity);

  const maxLimit = totalSupply - totalMinted - onSaleQuantity;

  const limitMinted = useMemo(() => {
    switch (true) {
      case is721Standard && maxLimit > mintingQuantityMax:
        return mintingQuantityMax;
      default:
        return maxLimit;
    }
  }, [totalSupply, totalMinted, onSaleQuantity, is721Standard, mintingQuantityMax]);

  const handleSetMaxQuantity = (setFieldValue: any) => () => {
    setFieldValue(QUANTITY, limitMinted);
  };

  return (
    <ModalComponent visible={visible} width={550} onClose={onClose} {...props}>
      <div className="mint-modal">
        <div className="title">{title}</div>
        <Formik
          initialValues={{
            [QUANTITY]: '',
            [TO_ADDRESS]: address,
          }}
          onSubmit={onSubmit}
          validationSchema={getMintSchema(t, limitMinted)}
        >
          {({ setFieldValue, values }) => {
            const isShowWarning =
              is721Standard && (values?.[QUANTITY] > mintingQuantityMax || maxLimit > mintingQuantityMax);

            return (
              <Form className="mint-modal-form">
                <FormItem
                  containerClassName="mint-modal-form__input"
                  typeInput={TYPE_INPUT.NUMBER}
                  placeholder={t('nft_detail.txt_enter_mint_quantity')}
                  decimalScale={ZERO_VALUE}
                  maxLength={MAX_VALUE_TOTAL_COPIES}
                  required
                  thousandSeparator
                  name={QUANTITY}
                  label={t('nft_detail.txt_mint_quantity')}
                  appendInput={
                    <AppButton
                      text={t('nft_creation.txt_max')}
                      className="field__button"
                      onClick={handleSetMaxQuantity(setFieldValue)}
                      variant="primary"
                    />
                  }
                  isAllowed={limitMaxlengNumber(MAX_LENGTH_TOTAL_SUPPLY)}
                />

                <div className="mint-modal-form__sub">
                  {t('nft_detail.txt_minting_limit', {
                    number: limitMinted,
                  })}
                  <Tooltip title={t('nft_detail.txt_mint_tooltip')}>
                    <img src={HelpIcon} />
                  </Tooltip>
                </div>

                <FormItem
                  name={TO_ADDRESS}
                  containerClassName="mint-modal-form__input"
                  placeholder={t('nft_detail.txt_recipient_wallet_address_placeholder')}
                  required
                  label={t('nft_detail.txt_recipient_wallet_address')}
                />

                {isShowWarning && (
                  <div className="mint-modal-form__note">
                    <img src={WarningIcon} />
                    <p
                      className="text"
                      dangerouslySetInnerHTML={{ __html: t('nft_detail.txt_note', { number: mintingQuantityMax }) }}
                    />
                  </div>
                )}

                <div className="group-button">
                  <AppButton
                    htmlType="submit"
                    className="mint-modal-form__button"
                    text={t('nft_detail.txt_proceed_to_mint')}
                    variant="primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalComponent>
  );
};

export default MintModal;
